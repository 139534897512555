import { Box, Text, Button, HStack, Center } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  listingInputAtom,
  listingUploadStatus,
  newListingId,
} from "../../recoil/listings/atoms";
import { useNavigate } from "react-router-dom";
import { Globe, Home } from "react-feather";
import { ICON_SIZE } from "../../theme";
import { useListingApi } from "../../hooks/Listings";
import { listingFamily } from "../../recoil/listings";
import { ListingStatus } from "../../types";
import { EditViewings } from "../../pages/EditViewings";

export const ListingSuccess = () => {
  const navigate = useNavigate();
  const resetStatus = useResetRecoilState(listingUploadStatus);
  const resetInput = useResetRecoilState(listingInputAtom);
  const listingId = useRecoilValue(newListingId);
  const listing = useRecoilValue(listingFamily(listingId));
  const onManage = useCallback(() => {
    navigate("/manage/listings");
  }, [navigate]);
  const { updateDraftListingToReview } = useListingApi();

  const onPublish = useCallback(async () => {
    if (await updateDraftListingToReview(listingId)) {
      onManage();
    }
  }, [listingId, onManage, updateDraftListingToReview]);

  useEffect(() => {
    resetStatus();
  }, [resetStatus]);
  useEffect(() => {
    resetInput();
  }, [resetInput]);

  return (
    <Box justifyContent={"space-between"}>
      <Text size={"lg"} mb={4}>
        Your listing has been uploaded! Please create a showing schedule:
      </Text>
      <EditViewings overrideId={listingId} />
      <Center>
        <HStack>
          {![
            ListingStatus.Active,
            ListingStatus.PreSale,
            ListingStatus.NewBuild,
          ].includes(listing?.listing_status ?? 0) && (
            <Button onClick={onPublish} variant="primary">
              <Globe size={ICON_SIZE} />
              <Text ml={1}>Publish Listing</Text>
            </Button>
          )}
          <Button variant="secondary" onClick={onManage}>
            <Home size={ICON_SIZE} />
            <Text ml={1}>Manage</Text>
          </Button>
        </HStack>
      </Center>
    </Box>
  );
};
