import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { userState } from "../recoil/atoms";
import { Logo } from "./Logo";
import { useEffect, useMemo, useState } from "react";
import { MobileDrawer } from "./MobileDrawer";
import {
  Sun,
  Moon,
  Calendar,
  Menu as HamburgerIcon,
  Home,
  Mail,
  LogOut,
  LogIn,
  PlusSquare,
  DollarSign,
  Shield,
  Settings,
  Users,
  Map,
  User,
} from "react-feather";
import { ICON_SIZE } from "../theme";
import { useIsMobile } from "../hooks/utils/useWindowDimensions";
import { useOffersApi } from "../hooks/Offers";
import useMessagesApi from "../hooks/useMessagesApi";
import {
  selectAxiosInstance,
  selectOfferCount,
  selectTotalBadgeCount,
  selectUnreadMessageCount,
} from "../recoil/selectors";
import { useLogOut } from "../recoil/transactions";
import { NumberBadge } from "./common/NumberBadge";
import { KycButton } from "./kyc/KycButton";
import { useAdminState, useUserKycd } from "../recoil/users/selectors";
import { useTriggerKycWindow } from "../recoil/users/transactions";
import { selectAllPendingViewingRequests } from "../recoil/viewings/selectors";

export const NavBar = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const SwitchIcon = useColorModeValue(Moon, Sun);
  const isSmallScreen = useIsMobile();
  const handleSignOut = useLogOut();
  const { toggleColorMode } = useColorMode();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const bg = useColorModeValue("#fff", "darkBg");
  const isKyced = useUserKycd();
  const isAdmin = useAdminState();
  const triggerKycWindow = useTriggerKycWindow();
  const { loadOffersForUser } = useOffersApi();
  const { getLatestMessages } = useMessagesApi();
  useEffect(() => {
    loadOffersForUser();
  }, [loadOffersForUser]);
  useEffect(() => {
    getLatestMessages();
  }, [getLatestMessages]);
  const axiosI = useRecoilValue(selectAxiosInstance);
  const requestCount = useRecoilValue(selectAllPendingViewingRequests).length;
  const badgeCount = useRecoilValue(selectTotalBadgeCount) + requestCount;
  const offerCount = useRecoilValue(selectOfferCount);
  const unreadCount = useRecoilValue(selectUnreadMessageCount);

  const MenuStack = useMemo(() => {
    return (
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <HamburgerIcon />
        {user && (
          <>
            <Avatar
              size={"xs"}
              name={user ? user.first_name + " " + user.last_name : undefined}
            />
            <NumberBadge count={badgeCount} />
          </>
        )}
      </Stack>
    );
  }, [badgeCount, user]);

  return (
    <Box
      bg={bg}
      sx={{
        borderBottom: "0.5px solid",
        borderColor: "border",
        position: "fixed",
        width: "100%",
        zIndex: 10,
      }}
      alignItems={"baseline"}
      paddingY={1}
      paddingRight={{ base: 2, md: 8 }}
      paddingLeft={{ base: 2, md: 8 }}
    >
      {isSmallScreen && (
        <MobileDrawer
          isOpen={mobileDrawerOpen}
          handleClose={() => setMobileDrawerOpen(false)}
          unreadCount={unreadCount}
          offerCount={offerCount}
        />
      )}
      <Flex p={3} alignItems={"center"}>
        <Box
          sx={{
            maxWidth: 100,
            maxHeight: 100,
          }}
        >
          <Link to="/">
            <Logo scale={0.2} />
          </Link>
        </Box>
        <Spacer />

        {isSmallScreen ? (
          <Button
            backgroundColor={"nav-button"}
            variant={"unstyled"}
            border={"1px solid"}
            borderColor={"border"}
            borderRadius={"3xl"}
            padding={3}
            height={"fit-content"}
            w={"fit-content"}
            onClick={() => setMobileDrawerOpen(true)}
          >
            {MenuStack}
          </Button>
        ) : (
          <>
            <KycButton />
            <Menu>
              <MenuButton
                border={"1px solid"}
                borderColor={"border"}
                borderRadius={"3xl"}
                padding={3}
                height={"fit-content"}
                w={"fit-content"}
                aria-label="Options"
                as={IconButton}
                backgroundColor={"nav-button"}
              >
                {MenuStack}
              </MenuButton>
              <MenuList width={225}>
                {user ? (
                  <>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"bold"}
                      textColor={"gray.500"}
                      paddingX={3}
                      paddingY={1.5}
                    >
                      Selling Property
                    </Text>
                    <MenuItem
                      onClick={() => navigate("/listings/create")}
                      icon={<PlusSquare size={ICON_SIZE} />}
                    >
                      Post Listing
                    </MenuItem>
                    <MenuItem
                      icon={<Home size={ICON_SIZE} />}
                      onClick={() => navigate("/manage/listings")}
                    >
                      Manage Listings
                    </MenuItem>
                    <MenuItem
                      icon={<DollarSign size={ICON_SIZE} />}
                      onClick={() => navigate("/manage/offers")}
                      alignItems={"center"}
                    >
                      Offers <NumberBadge count={offerCount} />
                    </MenuItem>
                    <MenuItem
                      icon={<Users size={ICON_SIZE} />}
                      onClick={() => navigate("/manage/requests")}
                    >
                      Viewing Requests <NumberBadge count={requestCount} />
                    </MenuItem>
                    <MenuDivider />
                    <Text
                      fontSize={"sm"}
                      fontWeight={"bold"}
                      textColor={"gray.500"}
                      paddingX={3}
                      paddingY={1.5}
                    >
                      Buying Property
                    </Text>
                    <MenuItem
                      onClick={() => {
                        navigate("/listings");
                      }}
                      icon={<Map size={ICON_SIZE} />}
                    >
                      Explore Listings
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (isKyced) {
                          navigate("/viewings-schedule/upcoming");
                        } else triggerKycWindow();
                      }}
                      icon={<Calendar size={ICON_SIZE} />}
                    >
                      Upcoming Viewings
                    </MenuItem>
                    <MenuDivider />
                    <Text
                      fontSize={"sm"}
                      fontWeight={"bold"}
                      textColor={"gray.500"}
                      paddingX={3}
                      paddingY={1.5}
                    >
                      Account & Activity
                    </Text>
                    <MenuItem
                      icon={<Mail size={ICON_SIZE} />}
                      onClick={() => {
                        navigate("/messages");
                      }}
                      alignItems={"center"}
                    >
                      Messages <NumberBadge count={unreadCount} />
                    </MenuItem>
                    <MenuItem
                      icon={<Settings size={ICON_SIZE} />}
                      onClick={() => navigate("/account-settings")}
                    >
                      Preferences
                    </MenuItem>
                    {isAdmin && (
                      <MenuItem
                        icon={<Shield size={ICON_SIZE} />}
                        onClick={() => navigate("/admin")}
                      >
                        Admin
                      </MenuItem>
                    )}
                    <MenuDivider />
                    <MenuItem
                      icon={<LogOut size={ICON_SIZE} />}
                      onClick={async () => {
                        await axiosI.post("/auth/logout");
                        handleSignOut();
                      }}
                    >
                      Sign out
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => {
                        navigate("/listings");
                      }}
                      icon={<Map size={ICON_SIZE} />}
                    >
                      Explore Listings
                    </MenuItem>
                    <MenuItem
                      icon={<LogIn size={ICON_SIZE} />}
                      onClick={() => navigate("/login")}
                    >
                      Log In
                    </MenuItem>
                    <MenuItem
                      icon={<User size={ICON_SIZE} />}
                      onClick={() => navigate("/signup")}
                    >
                      Sign Up
                    </MenuItem>
                  </>
                )}
                <MenuDivider />
                <MenuItem onClick={toggleColorMode}>
                  Toggle Dark Mode:&nbsp;
                  <SwitchIcon size={ICON_SIZE} />
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        )}
      </Flex>
    </Box>
  );
};
