import {
  ACType,
  FireplaceType,
  FormattedFile,
  HeatingType,
  Listing,
  ListingForm,
  ListingStatus,
  PriceCurrency,
  PropertyType,
  StoveType,
  ViewFacing,
  ViewingRequestForm,
  ViewingScheduleForm,
} from "../types";

export function buildCreateListingInput(
  form: ListingForm,
  files: FormattedFile[]
) {
  const sanitizedForm = Object.fromEntries(
    Object.entries(form).map(([key, value]) => {
      if (
        [
          "price",
          "property_tax",
          "hoa_fee",
          "lot_size",
          "total_area",
          "floor_area",
          "balcony_area",
        ].includes(key)
      ) {
        return [key, value === "" ? undefined : Number(value)];
      }
      return [key, value === "" ? undefined : value];
    })
  );

  return {
    ...sanitizedForm,
    files: files.length
      ? files.map((f) => ({
          s3_file_name: f.storageName,
          media_type: f.mediaType,
          s3_bucket: "openhaus-files",
          s3_folder_path: "listing-assets",
        }))
      : undefined,
  };
}

export function buildUpdateListingForm(listing: Listing): ListingForm {
  const newForm = {} as ListingForm;
  newForm.title = listing.title ?? "";
  newForm.description = listing.description ?? "";
  newForm.listing_status = listing.listing_status ?? ListingStatus.Draft;
  newForm.property_type = listing.property_type ?? PropertyType.Unknown;
  newForm.price = listing.price ?? "";
  newForm.price_currency = listing.price_currency ?? PriceCurrency.CAD;
  newForm.hoa_fee = listing.hoa_fee ?? "";
  newForm.property_tax = listing.property_tax ?? "";
  newForm.floor_area = listing.floor_area ?? "";
  newForm.balcony_area = listing.balcony_area ?? "";
  newForm.total_area = listing.total_area ?? "";
  newForm.lot_size = listing.lot_size ?? "";
  newForm.latitude = listing.latitude ?? 0;
  newForm.longitude = listing.longitude ?? 0;
  newForm.lot_size_unit = listing.lot_size_unit ?? 0;
  newForm.area_unit = listing.area_unit ?? 0;
  newForm.bedrooms_total = listing.bedrooms_total ?? 0;
  newForm.bathrooms_half = listing.bathrooms_half ?? 0;
  newForm.bathrooms_full = listing.bathrooms_full ?? 0;
  newForm.parking_stalls_uncovered = listing.parking_stalls_uncovered ?? 0;
  newForm.parking_stalls_covered = listing.parking_stalls_covered ?? 0;
  newForm.has_natural_gas = !!listing.has_natural_gas;
  newForm.ac_type = listing.ac_type ?? ACType.Unknown;
  newForm.heating_type = listing.heating_type ?? HeatingType.Unknown;
  newForm.stove_type = listing.stove_type ?? StoveType.Unknown;
  newForm.fireplace_type = listing.fireplace_type ?? FireplaceType.Unknown;
  newForm.view_facing = listing.view_facing ?? ViewFacing.Unknown;
  newForm.view_description = listing.view_description ?? "";
  newForm.address = listing.address ?? "";
  newForm.state_province = listing.state_province ?? "";
  newForm.city = listing.city ?? "";
  newForm.country = listing.country ?? "";
  newForm.zip = listing.zip ?? "";
  newForm.apt_no = listing.apt_no ?? "";
  newForm.year_built = listing.year_built ?? undefined;
  newForm.year_renovated = listing.year_renovated ?? undefined;
  newForm.building_floor = listing.building_floor ?? undefined;
  newForm.building_total_floors = listing.building_total_floors ?? undefined;
  newForm.amenities = listing.amenities ?? { custom: [], standard: [] };
  newForm.is_rental = listing.is_rental;

  return newForm;
}

export function buildCreateViewingScheduleInput(form: ViewingScheduleForm) {
  //format date with start time and end time
  const start_time = new Date(form.date + " " + form.start_time);
  const end_time = new Date(form.date + " " + form.end_time);
  return {
    listing_id: form.listing_id,
    start_time: start_time,
    end_time: end_time,
    max_attendance: form.max_attendance
      ? parseInt(form.max_attendance, 10)
      : undefined,
    days_of_week: form.days_of_week ?? [],
  };
}

export function buildCreateViewingRequestInput(form: ViewingRequestForm) {
  return {
    start_time: form.start_time,
    end_time: form.end_time,
    viewing_schedule_id: form.viewing_schedule_id,
  };
}
