import { OfferSteps } from "../components/Offers";
import { DrawerWithClose } from "../components/common";
import { useRecoilState } from "recoil";
import { newOfferDrawerOpenAtom } from "../recoil/offers/atoms";
import { useColorModeValue } from "@chakra-ui/react";

export const NewOfferDrawer = () => {
  const [open, setOpen] = useRecoilState(newOfferDrawerOpenAtom);
  const listBg = useColorModeValue("lightBg", "darkBg");
  return (
    <DrawerWithClose
      full
      isOpen={open}
      handleClose={() => setOpen(false)}
      bg={listBg}
    >
      <OfferSteps />
    </DrawerWithClose>
  );
};
