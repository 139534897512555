import {
  Heading,
  Text,
  Flex,
  Box,
  VStack,
  Badge,
  Link,
  Spinner,
} from "@chakra-ui/react";
import { PriceInput } from "../../common";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  draftPriceAtom,
  nearbyPropertyEstimateAtomFamily,
  offerAtomFamily,
  selectedOfferAtom,
} from "../../../recoil/offers/atoms";
import { useEffect, useState } from "react";
import { Tip } from "./DepositInfo";
import { useEstimateApi } from "../../../hooks/Offers";
import { selectedListingAtom } from "../../../recoil/listings";
import { formatPrice } from "../../../utils/formatters";

export const OfferPriceInfo = () => {
  const [offerPrice, setOfferPrice] = useRecoilState(draftPriceAtom);
  const selected = useRecoilValue(selectedOfferAtom);
  const selectedListing = useRecoilValue(selectedListingAtom);
  const [loading, setLoading] = useState(false);
  const offer = useRecoilValue(offerAtomFamily(selected));
  const { estimateListingById } = useEstimateApi();
  const similarProperty = useRecoilValue(
    nearbyPropertyEstimateAtomFamily(selectedListing ?? "")
  );
  useEffect(() => {
    setOfferPrice(offer ? offer.offer_price : "");
  }, [offer, setOfferPrice]);

  useEffect(() => {
    if (!similarProperty && selectedListing) {
      (async () => {
        setLoading(true);
        await estimateListingById(selectedListing);
        setLoading(false);
      })();
    }
  }, [estimateListingById, selectedListing, similarProperty]);

  return (
    <VStack align="stretch" w="100%" gap={3}>
      <Heading size="md">Offer Price</Heading>
      <Text>
        Please enter the amount you are willing to offer for this property.
        <br /> Your offer price should reflect the value of the property and
        your budget.
      </Text>
      <Tip value="Consider the condition and unique features of the property." />
      <Tip value="Take into account current market trends." />
      <PriceInput setPrice={setOfferPrice} price={offerPrice} />

      <Box bg="container-bg" w="full" rounded="lg" p={4} shadow="sm">
        {loading ? (
          <Flex justify={"space-between"} align={"center"}>
            <Heading size="sm">Looking for similar properties</Heading>
            <Spinner />
          </Flex>
        ) : similarProperty ? (
          <VStack align="stretch" spacing={3}>
            <Heading size="sm">Offerland Value Estimate:</Heading>
            <Flex justify="space-between" align={"center"}>
              <Text fontWeight="bold">
                {similarProperty.addressNumber} {similarProperty.addressStreet}
                {similarProperty.addressUnit &&
                  ` Unit ${similarProperty.addressUnit}`}
                , {similarProperty.city}, {similarProperty.province}
              </Text>
              <Badge colorScheme="green" p={2} rounded={"xl"} fontSize={"sm"}>
                Value Estimate: ${formatPrice(similarProperty.estimatedValue)}
              </Badge>
            </Flex>
          </VStack>
        ) : (
          <Heading size="sm">Similar properties not found</Heading>
        )}
        {(similarProperty || loading) && (
          <Text fontSize="xs" textAlign="center" my={4} textColor={"gray.500"}>
            powered by{" "}
            <Link
              color={"chakra-body-text"}
              fontWeight={600}
              href={"https://offerland.ca/"}
              target="_blank"
            >
              Offerland
            </Link>
          </Text>
        )}
        <Text fontSize={"xs"} textColor={"gray.300"} textAlign="center">
          Disclaimer: The AI appraisal estimate provided by Offerland is
          intended for reference purposes only. It is an automated estimation
          and may not reflect the true market value of the property. Buyers are
          encouraged to conduct their own research, seek professional advice,
          and consider various factors before making an offer. Openhaus and
          Offerland do not assume liability for decisions made based on this
          estimate.
        </Text>
      </Box>
    </VStack>
  );
};
