import {
  Text,
  Input,
  VStack,
  Box,
  Flex,
  Heading,
  HStack,
} from "@chakra-ui/react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  draftDepositInfoAtom,
  draftPriceAtom,
} from "../../../recoil/offers/atoms";
import { ReactNode } from "react";
import { PercentSlider } from "../../common/PercentSlider";
import { formatPrice } from "../../../utils/formatters";
import { GuideBox } from "../../common";
import { Info } from "react-feather";
import { ICON_SIZE } from "../../../theme";

export const DepositInfo = ({ viewOnly }: { viewOnly?: boolean }) => {
  const [depositInfo, setDepositInfo] = useRecoilState(draftDepositInfoAtom);
  const fullPrice = Number(
    useRecoilValue(draftPriceAtom).replaceAll(",", "") ?? "0"
  );

  return (
    <Box>
      <VStack w={"100%"} align={"start"} spacing={2}>
        <Heading size="md" mb={2}>
          Deposit Info
        </Heading>
        <Text fontWeight={"semibold"}>
          Enter the amount you plan to offer as a deposit.
          <br /> A deposit demonstrates your commitment to purchasing the
          property.
        </Text>
        <GuideBox title="What is a Deposit?">
          The deposit is a sum of money paid upfront to show your serious
          intention to buy the property. <br />
          It is typically a percentage of the offer price and is held in trust
          until closing.
        </GuideBox>
        <GuideBox title="Common Practices for Deposit Amounts">
          The deposit amount is usually 5% of the offer price and is typically
          due within 24 hours of subject removal. <br />
          In competitive markets, higher deposits can make your offer more
          attractive.
        </GuideBox>
        <Tip value="Ensure the deposit amount is sufficient to demonstrate your commitment." />
        <Tip value="Understand the conditions under which the deposit might be forfeited." />
        <Box pb={3} w={"100%"} maxW={"100%"}>
          <Text fontWeight={400}>Post-Inspection Deposit Percentage:</Text>
          {viewOnly && depositInfo.deposit ? (
            <Flex alignItems={"center"} justify={"space-evenly"}>
              <Text fontWeight={"semibold"} fontSize={"lg"}>
                {depositInfo.deposit}%
              </Text>
              <Text textColor={"black"}>
                {"  "} ($
                {formatPrice(fullPrice * (Number(depositInfo.deposit) / 100))})
              </Text>
            </Flex>
          ) : (
            <PercentSlider
              value={Number(depositInfo.deposit) ?? 5}
              onChange={(v) =>
                setDepositInfo((prev) => ({
                  ...prev,
                  deposit: v.toString(),
                }))
              }
              fullPrice={fullPrice}
              recommendedPercent={10}
            />
          )}
        </Box>
        <Box w={"100%"} maxW={"100%"} pb={1}>
          <Flex my={2} justify={"space-between"} align={"center"}>
            <Text fontWeight={400}>Lawyer/Notary Info:</Text>
            <Text fontSize={12} color={"gray.500"}>
              optional
            </Text>
          </Flex>
          <Input
            bg={"input-bg"}
            value={depositInfo.trust_info}
            onChange={(e) => {
              setDepositInfo((prev) => ({
                ...prev,
                trust_info: e.target.value,
              }));
            }}
            disabled={viewOnly}
          />
        </Box>
      </VStack>
    </Box>
  );
};

export const Tip = ({ value }: { value: ReactNode }) => {
  return (
    <HStack spacing={3}>
      <Info size={ICON_SIZE} />
      <Text fontWeight={"semibold"}>{value}</Text>
    </HStack>
  );
};
