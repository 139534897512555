import { atom, atomFamily } from "recoil";
import { Message } from "../../types";

// keyed by listingId+userId
export const messageHistoryAtomFamily = atomFamily<Message[], string>({
  key: "messageHistoryAtomFamily",
  default: [],
});

export const latestMessagesAtom = atom<Message[]>({
  key: "latestMessagesAtom",
  default: [],
});

export const oldestMessageAtom = atom<Message | null>({
  key: "oldestMessageAtom",
  default: null,
});
