import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { userState } from "../../recoil/atoms";
import { selectAxiosInstance } from "../../recoil/selectors";
import { useCustomToast } from "../useCustomToast";
import { listingForPaymentAtom } from "../../recoil/listings/atoms";
import { useCart } from "./useCart";
import { Product } from "../../types/services";

export const usePaymentApi = () => {
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const user = useRecoilValue(userState);
  const { fail } = useCustomToast();
  const { cart } = useCart();
  const listing_id = useRecoilValue(listingForPaymentAtom);

  const startPayment = useCallback(async () => {
    if (!user) return false;
    try {
      const resp = await axiosInstance.post(`/pay/create-checkout-session`, {
        line_items: cart.map((id) => ({
          price: id,
          quantity: 1,
        })),
        listing_id,
      });
      const sessionUrl = resp.data.sessionUrl as string;
      window.open(sessionUrl, "_blank");
    } catch (error) {
      console.error({ error });
      fail({ title: String(error) });
    }
  }, [axiosInstance, cart, fail, listing_id, user]);

  const instantCheckout = useCallback(
    async (productId: Product, listingId?: string) => {
      if (!user) return false;

      try {
        const resp = await axiosInstance.post(`/pay/create-checkout-session`, {
          line_items: [{ price: productId, quantity: 1 }],
          listing_id: listingId,
        });
        const sessionUrl = resp.data.sessionUrl as string;
        window.open(sessionUrl, "_blank");
      } catch (error) {
        console.error({ error });
        fail({ title: String(error) });
      }
    },
    [axiosInstance, fail, user]
  );

  return { startPayment, instantCheckout };
};
