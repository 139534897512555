import { Flex, Text } from "@chakra-ui/react";
import { Eye } from "react-feather";
import { ICON_SIZE } from "../../theme";

export const ViewCounter = ({ value }: { value: number }) => {
  return (
    <Flex align={"center"} gap={1} textColor={"gray.500"}>
      <Text>{value}</Text>
      <Eye size={ICON_SIZE} />
    </Flex>
  );
};
