import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  semanticTokens: {
    colors: {
      "chakra-body-text": {
        _light: "gray.900",
        _dark: "darkWhite",
      },
      "chakra-body-bg": {
        _light: "#fff",
        _dark: "#000",
      },
      "input-bg": {
        _light: "#fff",
        _dark: "darkContainer",
      },
      "container-bg": {
        _light: "#fff",
        _dark: "darkContainer",
      },
      "white-gradient-bg": {
        _light: "linear-gradient(to bottom, #fff, #f8f8f8);",
        _dark: "linear-gradient(to bottom, #181D24, #11191c);",
      },
      "promoted-gradient-bg": {
        _dark: "linear-gradient(to bottom, #181D24 68%, #2745F275);",
        _light: "linear-gradient(to bottom, #fff 58%, #2745F233);",
      },
      "dark-container": {
        _light: "#fff",
        _dark: "darkBg",
      },
      "expandable-bg": {
        _light: "lightBg",
        _dark: "darkContainer",
      },
      "container-txt": {
        _light: "gray.700",
        _dark: "white",
      },
      "paper-bg": {
        _light: "lightSecondary",
        _dark: "darkSecondary",
      },
      "card-bg": {
        _light: "#fff",
        _dark: "darkContainer",
      },
      "nav-button": {
        _light: "lighterBg",
        _dark: "darkContainer",
      },
      "subheading-txt": {
        _light: "blackAlpha.700",
        _dark: "darkWhite",
      },
      border: {
        _light: "lightgray",
        _dark: "lightGunmetal",
      },
      "drawer-color": {
        _light: "white",
        _dark: "gunmetal",
      },
      "drawer-color-secondary": {
        _light: "white.100",
        _dark: "gunmetal2",
      },
      "subject-list-item": {
        _light: "white.500",
        _dark: "gunmetal",
      },
      "slider-mark-percent": {
        _light: "ohBlue.300",
        _dark: "#fff",
      },
      "uploader-bg": {
        _light: "gray.100",
        _dark: "darkContainer",
      },
      "uploader-border": {
        _light: "gray.400",
        _dark: "lightGunmetal",
      },
      "text-secondary": {
        _light: "gray.600",
        _dark: "gray.400",
      },
    },
  },
  components: {
    Table: {
      variants: {
        custom: {
          th: {
            paddingInlineStart: "1",
            paddingInlineEnd: "1",
            color: "container-txt",
            borderBottom: "1px",
            borderColor: "lightgray",
          },
          td: {
            paddingInlineStart: "1",
            paddingInlineEnd: "1",
            borderBottom: "1px",
            borderColor: "lightgray",
          },
          caption: {
            color: "container-txt",
          },
          tfoot: {
            tr: {
              "&:last-of-type": {
                th: { borderBottomWidth: 0 },
              },
            },
          },
        },
      },
    },
    Button: {
      // Here you can change the default styles of the button
      baseStyle: {
        fontWeight: "bold", // Normally, it's "semibold"
        borderRadius: 100,
      },
      sizes: {
        xl: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
      },
      variants: {
        primary: {
          bg: "ohBlue.100",
          color: "white",
          _hover: {
            bg: "ohBlue.300",
          },
          _disabled: {
            bg: "ohBlue.100",
            color: "white",
            _hover: {
              bg: "ohBlue.100 !important",
              cursor: "not-allowed",
            },
          },
        },
        secondary: ({ colorMode }: { colorMode: string }) => ({
          border: `1px solid`,
          borderColor: colorMode === "dark" ? "white" : "blue.100",
          color: colorMode === "dark" ? "white" : "blue.100",
          _hover: {
            color: colorMode === "dark" ? "gunmetal" : "white",
            bg: colorMode === "dark" ? "white" : "blue.100",
          },
        }),
        tertiary: ({ colorMode }: { colorMode: string }) => ({
          bg: colorMode === "dark" ? "darkGunmetal" : "gray.100",
          color: colorMode === "dark" ? "white" : "gray.900",
          _hover: {
            bg: colorMode === "dark" ? "gunmetal" : "gray.300",
          },
        }),
        orange: {
          bg: "#F2994Acb",
          color: "white",
          _hover: {
            bg: "#F2994A",
          },
          _active: {
            bg: "#F2994A",
          },
        },
        danger: {
          bg: "red.500",
          color: "white",
          _hover: {
            bg: "red.600",
          },
          _active: {
            bg: "red.700",
          },
        },
        danger_outline: {
          bg: "transparent",
          border: "1.9px solid",
          color: "red.500",
          borderColor: "red.500 !important",
          _hover: {
            color: "white",
            bg: "red.500",
          },
          _active: {
            color: "white",
            bg: "red.700",
          },
        },
        plain: {
          bg: "transparent",
          background: "transparent !important",
          color: "gray.600",
          _hover: {
            bg: "white.100 !important",
          },
          _active: {
            bg: "white.500 !important",
          },
        },
        plainOutline: ({ colorMode }: { colorMode: string }) => ({
          border: `1px solid`,
          borderColor: colorMode === "dark" ? "white" : "gray.800",
          bg: "transparent",
          background: "transparent !important",
          color: colorMode === "dark" ? "white.500" : "gray.800",
          _hover: {
            bg: "white.100 !important",
            color: colorMode === "dark" ? "black" : "gray.800",
          },
          _active: {
            bg: "white.500 !important",
          },
        }),
        orangeOutline: ({ colorMode }: { colorMode: string }) => ({
          border: `1.9px solid`,
          borderColor:
            colorMode === "dark" ? "orange.300" : "brutalistSecondary",
          color: colorMode === "dark" ? "orange.300" : "brutalistSecondary",
          _hover: {
            color: "white",
            bg: "#F2994Acb",
          },
        }),
        simple: {
          background: "transparent !important",
          color: "gray.900",
          _hover: {
            bg: "gray.100 !important",
          },
          _active: {
            bg: "gray.200 !important",
          },
        },
        MenuItemLink: ({ colorMode }: { colorMode: string }) => ({
          background: "transparent !important",
          borderRadius: "md",
          _hover: {
            bg:
              colorMode === "dark"
                ? "ohBlue.200 !important"
                : "gray.200 !important",
            color:
              colorMode === "dark"
                ? "gray.200 !important"
                : "gray.900 !important",
          },
          _active: {
            bg: "gray.200 !important",
          },
        }),
      },
    },
    Tabs: {
      variants: {
        "soft-rounded": {
          tab: {
            _selected: {
              color: "white.100",
              bg: "blue.100",
            },
          },
        },
        custom: {
          tab: {
            border: "1px solid",
            borderColor: "border-color",
            borderRadius: "full",
            _selected: {
              bg: "ohBlue.300",
              color: "darkWhite",
              borderColor: "ohBlue.300",
            },
          },
        },
      },
    },
  },
  colors: {
    blue: {
      "50": "#E7EAFE",
      "100": "#2745F2",
      "200": "#90A0F8",
      "300": "#657AF6",
      "400": "#3A55F3",
      "500": "#0E30F1",
      "600": "#0C26C0",
      "700": "#091D90",
      "800": "#061360",
      "900": "#030A30",
    },
    ivory: {
      100: "#F4F9E9", // Ivory
      500: "#E0D8AF", //yellowish
    },
    white: { 100: "#F1F1F1", 500: "#D8D8D8" },
    ohBlue: {
      50: "#e6f0ff",
      100: "#2745F2",
      200: "#1c36c9",
      300: "#0423D9",
      400: "#031dbf",
      500: "#071A8D",
      600: "#051570",
      700: "#040f53",
      800: "#030a36",
      900: "#02051a",
    },
    celestial: "#279AF1",
    primary: "#092C4C",
    secondary: "#2745F2",
    brutalistSecondary: "#F2994A",
    info: "#2F80ED",
    lightSecondary: "#B5C4C6",
    darkSecondary: "#3B4941",
    // darkBg: "#030a36",
    // darkBg: "#0E1017",
    darkBg: "#0E0F15",
    darkLightGrey: "#21272C",
    onyx: "#3B4941",
    darkOnyx: "#21272C",
    gunmetal: "#2D3748",
    gunmetal2: "#252D3B",
    // darkGunmetal: "#2E3438",
    darkGunmetal: "#282E32",
    // lightGunmetal: "#30363d",
    lightGunmetal: "#30363e",
    palletteWhite: "#D9D9D9",
    darkWhite: "#E4ECF3",
    whiteBg: "#F7F8FA",
    lightBg: "#F4F4F4",
    // lighterBg: "#F6F7F8",
    // lighterBg: "#FAFCFC", //too light
    lighterBg: "#F7F8FA",
    // lighterBg: "#F8F8F9", //too light
    // lighterBg: "#F5F6F7", //too light
    lightestBg: "#F9FAFB",
    // darkContainer: "#2d3748",
    // darkContainer: "#161B22",
    // darkContainer: "#171C23",
    darkContainer: "#181D24",
    lightContainer: "#B5C4C6",
  },
  fonts: {
    body: `Cabin Variable, sans-serif`,
    signature: `Cedarville Cursive, sans-serif`,
  },
  typography: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.5",
  },
  spacing: {
    padding: {
      default: "8px",
      large: "16px",
      huge: "24px",
    },
    margin: {
      default: "8px",
      large: "16px",
      huge: "24px",
    },
  },
});

export const ICON_SIZE = 18;
