import { selector } from "recoil";
import { userState } from "./atoms";
import axios from "axios";
import { latestMessagesAtom } from "./messages/atoms";
import { selectBuyerOffers } from "./offers/selectors";
import { OfferStatus } from "../types";
import { DEV_MODE, STAGING_MODE } from "../utils/misc";

export const API_URL = DEV_MODE
  ? "http://localhost:3001/"
  : `https://${STAGING_MODE ? "staging" : "api"}.openhausapp.com/`;

export const selectAxiosInstance = selector({
  key: "selectAxiosInstance",
  get: ({ get }) => {
    const user = get(userState);
    return axios.create({
      baseURL: API_URL,
      timeout: 5000,
      headers: {
        "Content-Type": "application/json",
        Authorization: user ? user.token : undefined,
      },
    });
  },
});

export const selectTotalBadgeCount = selector({
  key: "selectTotalBadgeCount",
  get: ({ get }) => {
    const unreadConversations = get(selectUnreadMessageCount);
    const offers = get(selectOfferCount);
    return offers + unreadConversations;
  },
});

export const selectOfferCount = selector({
  key: "selectOfferCount",
  get: ({ get }) => {
    const now = new Date();
    return get(selectBuyerOffers).reduce((acc, o) => {
      return (
        acc +
        (o.offer_status === OfferStatus.Pending &&
        new Date(o.offer_end_date) > now
          ? 1
          : 0)
      );
    }, 0);
  },
});

// only count unread conversations and not all individual messages
export const selectUnreadMessageCount = selector({
  key: "selectUnreadMessageCount",
  get: ({ get }) => {
    const latestMessages = get(latestMessagesAtom);
    const user = get(userState);
    if (!user) return 0;
    return latestMessages.reduce((acc, c) => {
      return acc + (!c.date_read && c.sender_id !== user.id ? 1 : 0);
    }, 0);
  },
});
