import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { useIsMobile } from "../hooks/utils/useWindowDimensions";
import { Mail, Save } from "react-feather";
import { ICON_SIZE } from "../theme";
import { useRecoilState, useRecoilValue } from "recoil";
import { notificationSettingsAtom } from "../recoil/users/atoms";
import { ResetPage } from "./Reset";
import { userState } from "../recoil/atoms";
import useUserApi from "../hooks/useUserApi";

export const SettingsPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const isMobile = useIsMobile();
  const content = (
    <Box rounded={"2xl"} bg={"container-bg"} minW={isMobile ? undefined : "xl"}>
      <Tabs
        justifyContent={"center"}
        variant="soft-rounded"
        px={isMobile ? "0" : "1em"}
        py={"1em"}
        tabIndex={tabIndex}
        onChange={(i) => setTabIndex(i)}
      >
        <Flex justify={"center"}>
          <TabList bgColor={"paper-bg"} w={"fit-content"} rounded={"3xl"}>
            <Tab color={"white"}>Account</Tab>
            <Tab color={"white"}>Email Preferences</Tab>
          </TabList>
        </Flex>
        <TabPanels>
          <TabPanel>
            <VStack p={1}>
              <NamePreferences />
              <ResetPage loggedIn />
            </VStack>
          </TabPanel>
          <TabPanel>
            <EmailPreferences />
          </TabPanel>
        </TabPanels>
        <ContactSupportButton />
      </Tabs>
    </Box>
  );
  return isMobile ? content : <Flex justify={"center"}>{content}</Flex>;
};

export const EmailPreferences = () => {
  const [form, setForm] = useRecoilState(notificationSettingsAtom);
  const [loaded, setLoaded] = useState(false);
  const { updateEmailPreferences, fetchEmailPreferences } = useUserApi();
  useEffect(() => {
    if (!loaded) {
      fetchEmailPreferences();
      setLoaded(true);
    }
  }, [fetchEmailPreferences, loaded]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setForm({
      ...form,
      [name]: checked,
    });
  };

  return (
    <VStack spacing={6} my={6}>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <FormLabel htmlFor="email-alerts" mb="0">
          Inbound Viewing Request
        </FormLabel>
        <Switch
          name="inbound_viewing_request"
          isChecked={form.inbound_viewing_request}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <FormLabel htmlFor="email-alerts" mb="0">
          Viewing Request Accepted
        </FormLabel>
        <Switch
          name="viewing_request_accepted"
          isChecked={form.viewing_request_accepted}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <FormLabel htmlFor="email-alerts" mb="0">
          Offer Received
        </FormLabel>
        <Switch
          name="offer_received"
          isChecked={form.offer_received}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <FormLabel htmlFor="email-alerts" mb="0">
          Offer Accepted
        </FormLabel>
        <Switch
          name="offer_accepted"
          isChecked={form.offer_accepted}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <FormLabel htmlFor="email-alerts" mb="0">
          Offer Countered
        </FormLabel>
        <Switch
          name="offer_countered"
          isChecked={form.offer_countered}
          onChange={handleInputChange}
        />
      </FormControl>
      <Flex justifyContent={"end"} w={"full"}>
        <Button
          onClick={updateEmailPreferences}
          variant={"primary"}
          leftIcon={<Save size={ICON_SIZE} />}
        >
          Save
        </Button>
      </Flex>
    </VStack>
  );
};

export const NamePreferences = () => {
  const user = useRecoilValue(userState);
  const [first, setFirst] = useState(user?.first_name ?? "");
  const [last, setLast] = useState(user?.last_name ?? "");
  const { updateUser } = useUserApi();
  const onSave = useCallback(() => {
    updateUser(first, last);
  }, [first, last, updateUser]);

  return (
    <VStack spacing={6} my={6} w={"full"}>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <FormLabel minW={"24"}>First Name</FormLabel>
        <Input
          name="first"
          value={first}
          onChange={(e) => setFirst(e.target.value)}
        />
      </FormControl>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <FormLabel minW={"24"}>Last Name</FormLabel>
        <Input
          name="last"
          value={last}
          onChange={(e) => setLast(e.target.value)}
        />
      </FormControl>

      <Flex justifyContent={"end"} w={"full"}>
        <Button
          onClick={onSave}
          variant={"primary"}
          leftIcon={<Save size={ICON_SIZE} />}
        >
          Save
        </Button>
      </Flex>
    </VStack>
  );
};

export const ContactSupportButton = () => {
  return (
    <Center py={5}>
      <Button
        leftIcon={<Mail size={ICON_SIZE} />}
        variant={"ghost"}
        onClick={() => {
          const supportLink = `mailto:saman@openhaus.ca?subject=Support Request`;
          window.location.href = supportLink;
        }}
      >
        Contact Support
      </Button>
    </Center>
  );
};
