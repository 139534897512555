import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  Text,
  Link,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { SignupForm, LoginForm } from "../types";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useAuthApi";
import { useCustomToast } from "../hooks/useCustomToast";
import { useIsMobile } from "../hooks/utils/useWindowDimensions";
import { ForgotPasswordModal } from "../components/ForgotPasswordModal";
import { Mail } from "react-feather";
import { ICON_SIZE } from "../theme";
import { API_URL } from "../recoil/selectors";
import { FcGoogle } from "../assets/icons/ListingIcons";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginModalOpen, userState } from "../recoil/atoms";

export const LoginModal = () => {
  const [isOpen, setIsOpen] = useRecoilState(loginModalOpen);
  const user = useRecoilValue(userState);
  return (
    <Modal
      isOpen={isOpen && !user}
      onClose={() => setIsOpen(false)}
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent bg={"container-bg"}>
        <ModalCloseButton />
        <ModalBody>
          <SignUpPage isLogin />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const SignUpPage = ({ isLogin }: { isLogin?: boolean }) => {
  const [tabIndex, setTabIndex] = useState(isLogin ? 1 : 0);
  const isMobile = useIsMobile();
  const content = (
    <Box rounded={"2xl"} bg={"container-bg"} minW={isMobile ? undefined : "xl"}>
      <Tabs
        justifyContent={"center"}
        variant="soft-rounded"
        px={isMobile ? "0" : "1em"}
        py={"1em"}
        tabIndex={tabIndex}
        onChange={(i) => setTabIndex(i)}
        defaultIndex={tabIndex}
      >
        <Flex justify={"center"}>
          <TabList
            bgColor={"paper-bg"}
            w={"fit-content"}
            height={"40px"}
            rounded={"3xl"}
          >
            <Tab color={"white"} tabIndex={0}>
              Sign Up
            </Tab>
            <Tab color={"white"} tabIndex={1}>
              Log In
            </Tab>
          </TabList>
        </Flex>
        <TabPanels>
          <TabPanel>
            <SignUp />
          </TabPanel>
          <TabPanel>
            <LogIn />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
  return isMobile ? content : <Flex justify={"center"}>{content}</Flex>;
};

const SignUp = () => {
  const [form, setForm] = useState<SignupForm>({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const { createUser } = useApi();
  const { success, fail } = useCustomToast();

  const [sent, setSent] = useState(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await createUser(form);
      if (response) {
        success({
          title: "User created, please verify email",
          description:
            "We've created your account for you. You should receive an email with a verification link",
          duration: 15000,
        });
        setSent(true);
      }
    } catch (err) {
      fail({ title: "Error signing up", description: String(err) });
    }
  };

  const inputBg = "input-bg";
  if (sent)
    return (
      <VStack spacing={2}>
        <Mail size={60} />
        <Text fontWeight={"bold"} fontSize={20}>
          Please check your inbox for a verification link email
        </Text>
        <Text fontWeight={"thin"} fontSize={12}>
          If you don't see the verification email, check your 'junk' folder
        </Text>
      </VStack>
    );
  return (
    <Box as="form" onSubmit={handleSubmit} mx="auto">
      <VStack spacing="24px">
        <FormControl id="first_name" isRequired>
          <Input
            height={"45px"}
            placeholder="First Name"
            border={"1px solid lightgray"}
            backgroundColor={inputBg}
            type="text"
            name="first_name"
            autoComplete="given-name"
            value={form.first_name}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl id="last_name" isRequired>
          <Input
            height={"45px"}
            placeholder="Last Name"
            backgroundColor={inputBg}
            border={"1px solid lightgray"}
            type="text"
            name="last_name"
            autoComplete="family-name"
            value={form.last_name}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl id="semail" isRequired>
          <Input
            height={"45px"}
            placeholder="Email"
            border={"1px solid lightgray"}
            backgroundColor={inputBg}
            autoComplete="on"
            type="email"
            name="email"
            value={form.email}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl id="spassword" isRequired>
          <Input
            height={"45px"}
            placeholder="Password"
            border={"1px solid lightgray"}
            backgroundColor={inputBg}
            autoComplete="new-password"
            type="password"
            name="password"
            value={form.password}
            onChange={handleInputChange}
          />
        </FormControl>
        <Text textAlign={"left"}>
          By clicking Sign up, you agree to our{" "}
          <Link
            href="https://openhaus.ca/legal/terms"
            isExternal
            color="ohBlue.100"
          >
            Terms
          </Link>{" "}
          and{" "}
          <Link
            href="https://openhaus.ca/legal/privacy"
            isExternal
            color="ohBlue.100"
          >
            Privacy Policy
          </Link>
          .
        </Text>
        <Button variant="primary" type="submit" width={"100%"} height={"50px"}>
          Sign up
        </Button>
        <Flex width={"100%"} alignItems={"center"} justify={"space-around"}>
          <Divider width={"40%"} /> <Text>Or</Text> <Divider width={"40%"} />
        </Flex>

        <Button
          as={"a"}
          href={`${API_URL}auth/google`}
          variant="secondary"
          width={"100%"}
          height={"50px"}
          leftIcon={<FcGoogle size={ICON_SIZE} />}
        >
          Sign up with Google
        </Button>
      </VStack>
    </Box>
  );
};

const LogIn = () => {
  const [form, setForm] = useState<LoginForm>({
    email: "",
    password: "",
  });
  const { login } = useApi();
  const nav = useNavigate();
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const [forgotOpen, setForgotOpen] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const success = await login(form.email, form.password);
    if (success && ["/signup", "/login"].includes(window.location.pathname))
      nav("/manage/listings", {
        replace: true,
      });
  };
  const inputBg = "input-bg";
  return (
    <Box as="form" onSubmit={handleSubmit} mx="auto">
      <VStack spacing="24px">
        <FormControl id="lemail" isRequired>
          <Input
            height={"45px"}
            placeholder="Email"
            border={"1px solid lightgray"}
            backgroundColor={inputBg}
            type="email"
            name="email"
            autoComplete="on"
            value={form.email}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl id="lpassword" isRequired>
          <Input
            height={"45px"}
            placeholder="Password"
            border={"1px solid lightgray"}
            backgroundColor={inputBg}
            type="password"
            name="password"
            autoComplete="current-password"
            value={form.password}
            onChange={handleInputChange}
          />
        </FormControl>
        <Button variant="primary" type="submit" width={"100%"} height={"50px"}>
          Log in
        </Button>
        <Button
          variant={"ghost"}
          fontWeight={"light"}
          onClick={() => setForgotOpen(true)}
        >
          Forgot Password?
        </Button>
        <Flex width={"100%"} alignItems={"center"} justify={"space-around"}>
          <Divider width={"40%"} /> <Text>Or</Text> <Divider width={"40%"} />
        </Flex>

        <Button
          as={"a"}
          href={`${API_URL}auth/google`}
          variant="secondary"
          width={"100%"}
          height={"50px"}
          leftIcon={<FcGoogle size={ICON_SIZE} />}
        >
          Sign in with Google
        </Button>
      </VStack>
      <ForgotPasswordModal
        isOpen={forgotOpen}
        onClose={() => setForgotOpen(false)}
      />
    </Box>
  );
};

export default SignUpPage;
