import { useRecoilValue, useRecoilCallback } from "recoil";
import { selectAxiosInstance } from "../../recoil/selectors";
import {
  OfferValueResponse,
  nearbyPropertyEstimateAtomFamily,
} from "../../recoil/offers/atoms";
import { walkscoreAtomFamily, WalkScoreResponse } from "../../recoil/listings";

export const useEstimateApi = () => {
  const axiosInstance = useRecoilValue(selectAxiosInstance);

  const estimateListingById = useRecoilCallback(
    ({ set }) =>
      async (id: string) => {
        try {
          const { data } = await axiosInstance.get<OfferValueResponse>(
            `/estimate/${id}`,
            {
              timeout: 20000,
            }
          );
          set(nearbyPropertyEstimateAtomFamily(id), data);
          return data;
        } catch (error) {
          console.error("Error fetching estimate:", error);
        }
      },
    []
  );

  const getWalkscoreByListingId = useRecoilCallback(
    ({ set }) =>
      async (id: string) => {
        try {
          const { data } = await axiosInstance.get<WalkScoreResponse>(
            `/estimate/walkscore/${id}`,
            {
              timeout: 20000,
            }
          );
          set(walkscoreAtomFamily(id), data);
          return data;
        } catch (error) {
          console.error("Error fetching walkscore:", error);
        }
      },
    []
  );

  return { estimateListingById, getWalkscoreByListingId };
};
