import { selectorFamily } from "recoil";
import { messageHistoryAtomFamily } from "./atoms";

export const selectMessageHistory = selectorFamily({
  key: "selectMessageHistory",
  get:
    ({ listingId, userId }: { listingId: string; userId: string }) =>
    ({ get }) => {
      const messages = get(messageHistoryAtomFamily(listingId + userId));
      return messages.map((m) => {
        return {
          ...m,
          fromUser: m.receiver_id === userId,
        };
      });
    },
});
