import { Text, Input, VStack, Box, Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedOfferAtom,
  offerAtomFamily,
  draftDepositInfoAtom,
  draftPriceAtom,
} from "../../../../recoil/offers/atoms";
import { formatPrice } from "../../../../utils/formatters";
import { PercentSlider } from "../../../common/PercentSlider";
import { ExpandableBox } from "../../../common";

export const DepositInfo = ({ viewOnly }: { viewOnly?: boolean }) => {
  const selectedOffer = useRecoilValue(selectedOfferAtom);
  const offer = useRecoilValue(offerAtomFamily(selectedOffer));
  const [depositInfo, setDepositInfo] = useRecoilState(draftDepositInfoAtom);
  const fullPrice = Number(
    useRecoilValue(draftPriceAtom).replaceAll(",", "") ?? "0"
  );

  useEffect(() => {
    if (offer && offer.deposit)
      setDepositInfo({
        deposit: offer.deposit,
        trust_info: offer.deposit_info ?? "",
      });
  }, [offer, setDepositInfo]);

  return (
    <ExpandableBox title="Deposit Info">
      <VStack w={"100%"} p={3} align={"start"} spacing={3}>
        <Box pb={3} w={"100%"} maxW={"100%"}>
          <Text fontWeight={400}>Post-Inspection Deposit Percentage:</Text>
          {viewOnly && depositInfo.deposit ? (
            <Flex alignItems={"center"} justify={"space-evenly"}>
              <Text fontWeight={"semibold"} fontSize={"lg"}>
                {depositInfo.deposit}%
              </Text>
              <Text textColor={"black"}>
                {"  "} ($
                {formatPrice(fullPrice * (Number(depositInfo.deposit) / 100))})
              </Text>
            </Flex>
          ) : (
            <PercentSlider
              value={Number(depositInfo.deposit) ?? 5}
              onChange={(v) =>
                setDepositInfo((prev) => ({
                  ...prev,
                  deposit: v.toString(),
                }))
              }
              fullPrice={fullPrice}
              recommendedPercent={10}
            />
          )}
        </Box>
        <Box w={"100%"} maxW={"100%"} pb={1}>
          <Text fontWeight={400} mb={2}>
            Escrow Info:
          </Text>
          <Input
            bg={"input-bg"}
            value={depositInfo.trust_info}
            onChange={(e) => {
              setDepositInfo((prev) => ({
                ...prev,
                trust_info: e.target.value,
              }));
            }}
            disabled={viewOnly}
          />
        </Box>
      </VStack>
    </ExpandableBox>
  );
};
