import React from "react";
import {
  Box,
  Text,
  VStack,
  Link,
  ListItem,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";

export interface ArticleText {
  isNumbered?: boolean;
  display_table_of_contents?: boolean;
  content?: ArticleContent[];
}

export interface ArticleContent {
  title?: string;
  content?: SectionContent[];
}

export interface SectionContent {
  subtitle?: string;
  body?: string;
  bullet_points?: { list_type: listType; items: string[] };
}

export enum listType {
  alphabetic = 0,
  bullet = 1,
  numeric = 2,
}

interface Props {
  article: ArticleText;
}

const ArticleTextComponent: React.FC<Props> = ({ article }) => {
  return (
    <VStack>
      {article.display_table_of_contents && (
        <VStack spacing="2" mb="8">
          <Text fontSize="xl" fontWeight="medium" mb="2">
            Table of Contents
          </Text>
          {article.content?.map((article, index) => (
            <Link
              href={`#${index + 1}`}
              key={index + 1}
              fontWeight="medium"
              textDecoration="underline"
            >
              {index + 1}. {article.title}
            </Link>
          ))}
        </VStack>
      )}
      {article.content?.map((article, index) => (
        <Box key={index} mb="4">
          {article.title && (
            <Text id={`${index}`} fontSize="xl" fontWeight="semibold" mb="2">
              {article.title}
            </Text>
          )}
          {article.content?.map((section, idx) => (
            <VStack key={idx} align="start" mb={4}>
              {(section.body || section.subtitle) && (
                <Text mb={4}>
                  {section.subtitle && (
                    <Text as="span" fontWeight="semibold">
                      {index + 1}.{idx === 0 ? null : idx} {section.subtitle}
                    </Text>
                  )}{" "}
                  {section.body}
                </Text>
              )}
              {section.bullet_points && renderList(section.bullet_points)}
            </VStack>
          ))}
        </Box>
      ))}
    </VStack>
  );

  function renderList(bullet_points: { list_type: listType; items: string[] }) {
    switch (bullet_points.list_type) {
      case listType.alphabetic:
        return (
          <OrderedList styleType="lower-alpha" ps={5} mb={4} mt={0}>
            {bullet_points.items.map((item, i) => (
              <ListItem key={i} mt="1">
                {item}
              </ListItem>
            ))}
          </OrderedList>
        );
      case listType.bullet:
        return (
          <UnorderedList styleType="disc" ps={5} mb={4} mt={0}>
            {bullet_points.items.map((item, i) => (
              <ListItem key={i} mt="1">
                {item}
              </ListItem>
            ))}
          </UnorderedList>
        );
      case listType.numeric:
        return (
          <OrderedList styleType="decimal" ps={5} mb={4} mt={0}>
            {bullet_points.items.map((item, i) => (
              <ListItem key={i} mt="1">
                {item}
              </ListItem>
            ))}
          </OrderedList>
        );
      default:
        return null;
    }
  }
};

export default ArticleTextComponent;
