import { PriceCurrency, Unit } from ".";
import { Purchase } from "./services";

export enum ListingStatus {
  Draft = 0,
  InReview = 1,
  Active = 2,
  Sold = 3,
  Archived = 4,
  Deleted = 5,
  PreSale = 6,
  NewBuild = 7,
}

export enum HeatingType {
  Unknown = 0,
  None = 1,
  ElectricBaseboard = 2,
  HydronicBaseboard = 3,
  Furnace = 4,
  Boiler = 5,
  HeatPump = 6,
  Radiant = 7,
}

export const heatingTypeLabels: Record<HeatingType, string> = {
  [HeatingType.Unknown]: "Unknown",
  [HeatingType.None]: "None",
  [HeatingType.ElectricBaseboard]: "Electric Baseboard",
  [HeatingType.HydronicBaseboard]: "Hydronic Baseboard",
  [HeatingType.Furnace]: "Furnace",
  [HeatingType.Boiler]: "Boiler",
  [HeatingType.HeatPump]: "Heat Pump",
  [HeatingType.Radiant]: "Radiant",
};

export enum ACType {
  Unknown = 0,
  None = 1,
  Central = 2,
  Split = 3,
}

export const acTypeLabels: Record<ACType, string> = {
  [ACType.Unknown]: "Unknown",
  [ACType.None]: "None",
  [ACType.Central]: "Central",
  [ACType.Split]: "Split",
};

export enum StoveType {
  Unknown = 0,
  None = 1,
  Electric = 2,
  Gas = 3,
  Wood = 4,
  Induction = 5,
}

export const stoveTypeLabels: Record<StoveType, string> = {
  [StoveType.Unknown]: "Unknown",
  [StoveType.None]: "None",
  [StoveType.Electric]: "Electric",
  [StoveType.Gas]: "Gas",
  [StoveType.Wood]: "Wood",
  [StoveType.Induction]: "Induction",
};

export enum FireplaceType {
  Unknown = 0,
  None = 1,
  Gas = 2,
  Electric = 3,
  Wood = 4,
}

export const fireplaceTypeLabels: Record<FireplaceType, string> = {
  [FireplaceType.Unknown]: "Unknown",
  [FireplaceType.None]: "None",
  [FireplaceType.Gas]: "Gas",
  [FireplaceType.Electric]: "Electric",
  [FireplaceType.Wood]: "Wood",
};

export enum ViewFacing {
  Unknown = 0,
  North = 1,
  South = 2,
  East = 3,
  West = 4,
  Northeast = 5,
  Northwest = 6,
  Southeast = 7,
  Southwest = 8,
}

export const viewTypeLabels: Record<ViewFacing, string> = {
  [ViewFacing.Unknown]: "Unknown",
  [ViewFacing.North]: "North",
  [ViewFacing.South]: "South",
  [ViewFacing.East]: "East",
  [ViewFacing.West]: "West",
  [ViewFacing.Northeast]: "Northeast",
  [ViewFacing.Northwest]: "Northwest",
  [ViewFacing.Southeast]: "Southeast",
  [ViewFacing.Southwest]: "Southwest",
};

export enum PropertyType {
  Unknown = 0,
  House = 1,
  Apartment = 2,
  Townhouse = 3,
  Lot = 4,
}

export enum RentPropertyType {
  Unknown = 0,
  House = 1,
  Apartment = 2,
  Townhouse = 3,
}

export const propertyTypeLabels: Record<PropertyType, string> = {
  [PropertyType.Unknown]: "Unknown",
  [PropertyType.House]: "House",
  [PropertyType.Apartment]: "Apartment",
  [PropertyType.Townhouse]: "Townhouse",
  [PropertyType.Lot]: "Lot",
};

export type StandardAmenities =
  | "pool"
  | "gym"
  | "sauna"
  | "hot tub"
  | "tennis court"
  | "theatre"
  | "steam room"
  | "zen garden"
  | "party room"
  | "guest room";

// Custom Amenities as a string, assuming it should not include any of the standard amenities
export type CustomAmenities = string;

// An interface representing the complete amenities structure
export interface Amenities {
  standard: StandardAmenities[];
  custom: CustomAmenities[];
}

export type ListingForm = {
  title: string;
  is_rental: boolean;
  listing_status: ListingStatus;
  property_type: PropertyType;
  description: string;
  price_currency: PriceCurrency;
  price: string;
  property_tax: string;
  hoa_fee?: string;
  bathrooms_full: number;
  bedrooms_total: number;
  bathrooms_half: number;
  parking_stalls_uncovered: number;
  parking_stalls_covered: number;
  address: string;
  city: string;
  state_province: string;
  zip: string;
  apt_no?: string;
  building_floor?: number;
  building_total_floors?: number;
  country: string;
  ac_type: ACType;
  heating_type: HeatingType;
  stove_type: StoveType;
  fireplace_type: FireplaceType;
  has_natural_gas: boolean;
  lot_size?: string;
  total_area: string;
  floor_area?: string;
  latitude: number;
  longitude: number;
  balcony_area?: string;
  lot_size_unit?: Unit;
  area_unit: Unit;
  view_facing?: ViewFacing;
  view_description?: string;
  amenities: Amenities;
  year_built?: number;
  year_renovated?: number;
  buyer_agent_fee?: string;
};

export enum MediaType {
  Image = 0,
  Video = 1,
  Doc = 2,
}

export type MediaItem = { src: string; id: string; type: MediaType };

export interface Listing extends ListingForm {
  id: string;
  date_created: string;
  date_posted: string | null;
  date_updated: string | null;
  date_deleted: string | null;
  seller_id: string;
  listing_assets: ListingAsset[];
  file_urls: MediaItem[];
  views?: number;
}

export interface ListingWithServices extends Listing {
  services: Purchase[];
}

export interface ListingAsset {
  id: string;
  listing_id: string;
  media_type: number;
  original_file_name: string | null;
  file_size: number | null;
  file_size_unit: string | null;
  tags: string[] | null;
  s3_file_name: string;
  s3_folder_path: string;
  s3_bucket: string;
  date_created: string;
  date_updated: string | null;
  date_deleted: string | null;
}

export enum ListingSort {
  priceAsc,
  priceDesc,
  totalAreaAsc,
  totalAreaDesc,
}
