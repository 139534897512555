import { Button, Flex, Text, HStack } from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import { Edit, Eye, Info, Plus, X } from "react-feather";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
  selectedOfferAtom,
  subjectIdsForOfferAtom,
  subjectAtomFamily,
  contingencyDrawerOpenAtom,
} from "../../../../recoil/offers/atoms";
import { selectOfferSubjects } from "../../../../recoil/offers/selectors";
import { ICON_SIZE } from "../../../../theme";
import { ExpandableBox } from "../../../common";
import DraggableItem from "../../../common/DraggableItem";
import { OHTooltip } from "../../../common/OHTooltip";
import { SubjectEditModal } from "../SubjectEditModal";

export const OfferContingencies = ({ viewOnly }: { viewOnly?: boolean }) => {
  const selectedOffer = useRecoilValue(selectedOfferAtom);
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const [contingencies, setContingencies] = useRecoilState(
    subjectIdsForOfferAtom
  );
  const setContingenciesOpen = useSetRecoilState(contingencyDrawerOpenAtom);
  const selectedSubjects = useRecoilValue(selectOfferSubjects(selectedOffer));
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    setContingencies(selectedSubjects.map((i) => i.id));
  }, [selectedSubjects, setContingencies]);

  const onDragStart = (index: number) => {
    !viewOnly && setDraggingIndex(index);
  };

  const onDragOver = (index: number) => {
    if (draggingIndex === null) return;
    const newCustomTextBlocks = [...contingencies];
    const item = newCustomTextBlocks[draggingIndex];
    newCustomTextBlocks.splice(draggingIndex, 1);
    newCustomTextBlocks.splice(index, 0, item);
    setDraggingIndex(index);
    setContingencies(newCustomTextBlocks);
  };

  const removeSubject = useCallback(
    (subjectId: string) => {
      setContingencies(contingencies.filter((c) => c !== subjectId));
    },
    [contingencies, setContingencies]
  );

  const onDragEnd = () => {
    setDraggingIndex(null);
  };

  return (
    <ExpandableBox title="Contingencies">
      {contingencies.map((id, index) => (
        <DraggableItem
          index={index}
          key={id}
          onDragStart={() => onDragStart(index)}
          onDragOver={() => onDragOver(index)}
          onDragEnd={onDragEnd}
        >
          <SubjectListItem
            subjectId={id}
            onDelete={() => {
              removeSubject(id);
            }}
            onEdit={() => {
              setSelectedSubjectId(id);
              setEditing(true);
            }}
            viewOnly={viewOnly}
          />
        </DraggableItem>
      ))}
      {!viewOnly && (
        <Button
          my={2}
          ml={2}
          onClick={() => {
            setContingenciesOpen(true);
          }}
          variant={"ghost"}
        >
          Add <Plus size={ICON_SIZE} />
        </Button>
      )}
      <SubjectEditModal
        subjectId={selectedSubjectId}
        isOpen={editing}
        handleClose={() => setEditing(false)}
        viewOnly={viewOnly}
      />
    </ExpandableBox>
  );
};

export const SubjectListItem = ({
  subjectId,
  onDelete,
  onEdit,
  viewOnly,
}: {
  subjectId: string;
  onDelete: () => void;
  onEdit: () => void;
  viewOnly?: boolean;
}) => {
  const subject = useRecoilValue(subjectAtomFamily(subjectId));
  if (!subject) return null;
  const edit = (
    <Edit
      size={ICON_SIZE}
      onClick={onEdit}
      style={{
        cursor: "pointer",
      }}
    />
  );
  const del = (
    <X
      size={ICON_SIZE}
      onClick={onDelete}
      style={{
        cursor: "pointer",
      }}
    />
  );
  const view = (
    <Eye
      size={ICON_SIZE}
      onClick={onEdit}
      style={{
        cursor: "pointer",
      }}
    />
  );
  return (
    <Flex
      justifyContent={"space-between"}
      alignItems={"center"}
      p={3}
      mx={1}
      bg={"subject-list-item"}
      rounded={"lg"}
    >
      <Text>{subject.subject_name}</Text>
      <HStack alignItems={"center"} spacing={2}>
        {viewOnly && view}
        {!viewOnly && edit}
        {subject.subject_description.length && (
          <OHTooltip
            hasArrow
            placement="top"
            label={<p>{subject.subject_description}</p>}
          >
            <Info size={ICON_SIZE} />
          </OHTooltip>
        )}
        {!viewOnly && del}
      </HStack>
    </Flex>
  );
};
