import { Box, Center, Spinner, useColorModeValue } from "@chakra-ui/react";
import { Routes as Switch, Route } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import SignUpPage, { LoginModal } from "./pages/SignUp";
import { useRecoilValue } from "recoil";
import { userState } from "./recoil/atoms";
import { VerifySuccess } from "./pages/VerifySuccess";
import { ResetPage } from "./pages/Reset";
import { SettingsPage } from "./pages/Settings";
import { KycWall } from "./components/kyc/KycWall";
import { useAdminState, useUserKycd } from "./recoil/users/selectors";
import { ViewingRequest } from "./pages/ViewingRequest";
import CloseTab from "./pages/Close";
import { TermsOfService } from "./components/kyc/TermsModal";
import { Entryway } from "./pages/Entryway";
import { TokenLogin } from "./pages/TokenLogin";
import { ListingSuccess } from "./components/createListing/ListingSuccess";
import { NewOfferDrawer } from "./pages/NewOfferDrawer";
import { OfferEditDrawer } from "./components/Offers/OfferEdit";
import { DeveloperPackages } from "./pages/services/DeveloperPackages";
import { Suspense, lazy } from "react";
import {
  TierUpdgradeModal,
  TierUpgradePage,
} from "./components/upgrade/TierUpgradeModal";

// Added some lazy loading to reduce bundle size
const Inbox = lazy(() => import("./pages/Inbox"));
const Viewings = lazy(() => import("./pages/Viewings"));
const Listings = lazy(() => import("./pages/Listings"));
const CreateListing = lazy(() => import("./pages/CreateListing"));
const NewConversation = lazy(
  () => import("./components/messages/NewConversation")
);
const ListingPage = lazy(() => import("./pages/ListingPage"));
const AdminPage = lazy(() => import("./pages/AdminPage"));
const ManageListings = lazy(() => import("./pages/ManageListings"));
const Rentals = lazy(() => import("./pages/rent/Rentals"));
const RentalPage = lazy(() => import("./pages/rent/RentalPage"));
const CreateRental = lazy(() => import("./pages/rent/CreateRental"));
const EditListing = lazy(() => import("./pages/EditListing"));
const EditViewings = lazy(() => import("./pages/EditViewings"));

export const Router = () => {
  const bg = useColorModeValue("whiteBg", "darkBg");
  const isAdmin = useAdminState();
  const isKyced = useUserKycd();
  const user = useRecoilValue(userState);

  return (
    <Box minH={"100vh"}>
      <NavBar />
      <Box
        bg={bg}
        minHeight={"100vh"}
        alignItems="center"
        gap="2"
        paddingTop={100}
        paddingRight={{ base: 2, md: 8 }}
        paddingLeft={{ base: 2, md: 8 }}
      >
        <Suspense
          fallback={
            <Center pt={"40vh"}>
              <Spinner size={"xl"} />
            </Center>
          }
        >
          <Switch>
            <Route path="/package" Component={DeveloperPackages} />
            <Route path="/propertypackage" Component={DeveloperPackages} />
            <Route path="/reset/:verification_code" element={<ResetPage />} />
            <Route path="/user/verify/:code" element={<VerifySuccess />} />
            {user ? (
              <>
                {isKyced && (
                  <>
                    <Route
                      path="/viewings-schedule/upcoming"
                      Component={Viewings}
                    />
                    <Route
                      path="/viewing-schedule/manage/:id"
                      Component={EditViewings}
                    />
                    <Route
                      path="/viewing-request/:id"
                      Component={ViewingRequest}
                    />
                  </>
                )}
                <Route path="/messages" element={<Inbox />} />
                <Route
                  path="/message/:listingId"
                  element={<NewConversation />}
                />
                <Route path="/" Component={ManageListings} />
                <Route path="/listings/create" Component={CreateListing} />
                <Route
                  path="/listings/create/success"
                  Component={ListingSuccess}
                />
                <Route path="/listings/edit/:id" Component={EditListing} />
                <Route path="/manage/:tab" Component={ManageListings} />
                <Route path="/account-settings" Component={SettingsPage} />
                {isAdmin && (
                  <>
                    <Route path="/rental/create" Component={CreateRental} />
                    <Route path="/rentals" Component={Rentals} />
                    <Route path="/rental/:id" Component={RentalPage} />
                    <Route path="/admin" Component={AdminPage} />
                  </>
                )}
              </>
            ) : (
              <>
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/login" element={<SignUpPage isLogin />} />
                <Route path="/*" element={<SignUpPage isLogin />} />
              </>
            )}
            <Route path="/token/:user/:token" Component={TokenLogin} />
            <Route path="/listing/:id" Component={ListingPage} />
            <Route path="/close" Component={CloseTab} />
            <Route path="/listings" Component={Listings} />
            <Route path="/upgrade" Component={TierUpgradePage} />
            <Route path="/" Component={Entryway} />
            <Route path="/*" Component={Entryway} />
          </Switch>
        </Suspense>
      </Box>
      {user && <OfferEditDrawer />}
      {user && <NewOfferDrawer />}
      {user && <TierUpdgradeModal />}
      {!user && <LoginModal />}
      <TermsOfService />
      <KycWall />
    </Box>
  );
};
