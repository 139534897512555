import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Text,
  Spinner,
  Button,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import useViewingsApi from "../../hooks/useViewingsApi";
import { ViewingSchedule } from "../../types";
import {
  listingScheduleAtomFamily,
  viewingRequestInputAtom,
} from "../../recoil/viewings/atoms";
import { ViewingScheduleTableRow } from "./ViewingScheduleTableRow";
import WeekNavigation from "../common/WeekNavigation";
import useWeekNavigation from "../../hooks/utils/useWeekNavigation";
import {
  selectCurrentWeekViewings,
  selectFirstAvailableViewingDate,
  selectPendingViewingRequestsForBuyer,
  selectUpcomingViewingsForBuyer,
} from "../../recoil/viewings/selectors";
import { userState } from "../../recoil/atoms";
import { useNavigate } from "react-router-dom";
import { LogIn } from "react-feather";
import { ICON_SIZE } from "../../theme";

export const ListingViewingScheduleTable: React.FC<{
  listingId: string;
  disableButtons?: boolean;
}> = ({ listingId, disableButtons = false }) => {
  const {
    getListingSchedule,
    createViewingRequest,
    getAllBuyerViewingRequests,
  } = useViewingsApi();
  const viewingSchedule = useRecoilValue(listingScheduleAtomFamily(listingId));
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useRecoilState(viewingRequestInputAtom);
  const buyerViewings = useRecoilValue(selectUpcomingViewingsForBuyer);
  const pendingViewingRequests = useRecoilValue(
    selectPendingViewingRequestsForBuyer
  );
  const firstAvailabilityDate = useRecoilValue(
    selectFirstAvailableViewingDate(listingId)
  );
  const resetFormData = useResetRecoilState(viewingRequestInputAtom);
  const navigate = useNavigate();

  const handleSendViewingRequest = useCallback(
    async (schedule: ViewingSchedule) => {
      try {
        const updatedFormState = {
          ...formState,
          viewing_schedule_id: schedule.id,
          start_time: schedule.start_time,
          end_time: schedule.end_time,
        };
        if (updatedFormState) {
          await createViewingRequest(updatedFormState);
          setFormState(updatedFormState); // Update the state after successful API call
          resetFormData(); // Reset the form data after successful API call
        }
      } catch (err) {
        console.log(err);
      }
    },
    [createViewingRequest, formState, resetFormData, setFormState]
  );

  useEffect(() => {
    setLoading(true);
    getListingSchedule(listingId).finally(() => {
      setLoading(false);
    });
  }, [getListingSchedule, listingId]);

  const { activeWeek, goToNextWeek, goToPrevWeek, addDays } = useWeekNavigation(
    firstAvailabilityDate
  );

  const currentWeekViewings = useRecoilValue(
    selectCurrentWeekViewings({ listing_id: listingId, weekStart: activeWeek })
  );

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    getAllBuyerViewingRequests();
  }, [getAllBuyerViewingRequests, user?.id]);

  return (
    <Box maxWidth={"100vw"}>
      {loading ? (
        <Spinner />
      ) : !user?.token ? (
        <>
          <Text mb={5}>Must be logged in to request a viewing.</Text>

          <Button
            onClick={() => {
              navigate("/login");
            }}
            rightIcon={<LogIn size={ICON_SIZE} />}
          >
            Login
          </Button>
        </>
      ) : viewingSchedule.availabilities.length > 0 ? (
        //check all nonrecurreing avalabilities aren't booked
        viewingSchedule.availabilities.some(
          (schedule) =>
            schedule.days_of_week && schedule.days_of_week.length > 0
        ) ||
        viewingSchedule.availabilities.length !==
          viewingSchedule.bookings.length ? (
          <>
            <Text fontSize="md" mb={8}>
              Select an available time below, or click 'request viewing' to
              request a specific time with the seller.
            </Text>
            <Box
              pt={4}
              px={2}
              border={"0.5px solid lightgray"}
              borderRadius={"md"}
            >
              <WeekNavigation
                currentWeekStart={activeWeek}
                goToPrevWeek={goToPrevWeek}
                goToNextWeek={goToNextWeek}
                addDays={addDays}
              />
              <TableContainer
                marginBottom={6}
                overflowY="scroll"
                height={325}
                maxHeight={325}
                px={2}
                scrollBehavior={"smooth"}
                className="box-scroll"
              >
                <Table variant="custom" size="md">
                  <Thead
                    top={0}
                    bg={"drawer-color"}
                    position={"sticky"}
                    zIndex={1}
                  >
                    <Tr>
                      <Th>Type</Th>
                      <Th>Time</Th>
                      <Th>Date</Th>
                      <Th>RSVP</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentWeekViewings.length > 0 ? (
                      currentWeekViewings.map((schedule, i) => {
                        return (
                          <ViewingScheduleTableRow
                            key={i + schedule.id}
                            viewingSchedule={schedule}
                            handleSendViewingRequest={handleSendViewingRequest}
                            buyerUpcomingViewings={buyerViewings ?? []}
                            buyerPendingViewingRequests={
                              pendingViewingRequests ?? []
                            }
                            disableButtons={disableButtons}
                          />
                        );
                      })
                    ) : (
                      <Tr>
                        <Td colSpan={4} textAlign="center">
                          <Text>No viewings this week.</Text>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </>
        ) : (
          // Display message if all are booked
          <Text fontSize="lg" fontWeight="medium" mb={10}>
            No viewings available at this time.
          </Text>
        )
      ) : (
        // Display message if no availabilities scheduled
        <Text fontSize="lg" fontWeight="medium" mb={10}>
          No viewings scheduled at this time.
        </Text>
      )}
    </Box>
  );
};
