import { selectorFamily, selector } from "recoil";
import { Offer, OfferStatus, Subject } from "../../types";
import {
  listingOfferIdsAtomFamily,
  offerAtomFamily,
  allSubjectIdsAtom,
  subjectAtomFamily,
  draftCostBreakdown,
  selectedOfferAtom,
} from "./atoms";
import { userListingsAtom } from "../listings";

export const selectDraftOffers = selectorFamily({
  key: "selectDraftOffers",
  get:
    ({ listingId, userId }: { listingId: string; userId?: string }) =>
    ({ get }) => {
      const listingOfferIds = get(listingOfferIdsAtomFamily(listingId));
      const draftOffers = (
        listingOfferIds
          .map((id) => get(offerAtomFamily(id)))
          .filter((o) => {
            if (!o || o.offer_status) return false;
            if (userId && ![o.buyer_id, o.seller_id].includes(userId))
              return false;
            return true;
          }) as Offer[]
      ).sort((a1, b1) => {
        const a = (a1.date_updated ?? a1.date_created).valueOf();
        const b = (b1.date_updated ?? b1.date_created).valueOf();
        return b - a;
      });
      return draftOffers;
    },
});

export const selectBuyerOffers = selector({
  key: "selectBuyerOffers",
  get: ({ get }) => {
    const listingIds = get(userListingsAtom);
    const offers = listingIds.reduce((prev, curr) => {
      const offerIds = get(listingOfferIdsAtomFamily(curr));
      offerIds.forEach((id) => {
        const offer = get(offerAtomFamily(id));
        offer && prev.push(offer);
      });
      return prev;
    }, [] as Offer[]);

    return offers;
  },
});

export const selectOffersForUserIds = selector({
  key: "selectOffersForUserIds",
  get: ({ get }) => {
    const listingIds = get(userListingsAtom);
    const offers = listingIds.reduce((prev, curr) => {
      const offerIds = get(listingOfferIdsAtomFamily(curr));
      prev.push(
        ...offerIds.filter((id) => {
          const offer = get(offerAtomFamily(id));
          if (!offer) return false;
          return offer.offer_status === OfferStatus.Pending;
        })
      );
      return prev;
    }, [] as string[]);

    return offers;
  },
});

export const selectUserSubjects = selector<Subject[]>({
  key: "selectUserSubjects",
  get: ({ get }) => {
    const ids = get(allSubjectIdsAtom);
    return ids
      .map((id) => get(subjectAtomFamily(id)))
      .filter((s) => !!s && s.editable) as Subject[];
  },
});

export const selectOfferSubjects = selectorFamily({
  key: "selectOfferSubjects",
  get:
    (offerId: string) =>
    ({ get }) => {
      const offer = get(offerAtomFamily(offerId));
      if (!offer) return [];
      const subjectIds = offer.subject_ids ?? [];
      const offerSubjects = subjectIds
        .map((id) => get(subjectAtomFamily(id)))
        .filter((o) => !!o) as Subject[];
      return offerSubjects;
    },
});

export const selectSubjectsById = selectorFamily({
  key: "selectSubjectsById",
  get:
    (subjectIds: string[]) =>
    ({ get }) => {
      const offerSubjects = subjectIds
        .map((id) => get(subjectAtomFamily(id)))
        .filter((o) => !!o) as Subject[];
      return offerSubjects;
    },
});

export const selectOfferCosts = selectorFamily({
  key: "selectOfferCosts",
  get:
    (offerId: string) =>
    ({ get }) => {
      const offer = get(offerAtomFamily(offerId));
      const costs = offer?.costs ?? get(draftCostBreakdown);
      return costs;
    },
});

export const selectOfferIsCountered = selectorFamily({
  key: "selectOfferIsCountered",
  get:
    (offerId: string) =>
    ({ get }) => {
      const offer = get(offerAtomFamily(offerId));
      if (!offer) return false;
      const offers = get(listingOfferIdsAtomFamily(offer.listing_id));
      for (const oId of offers) {
        const o = get(offerAtomFamily(oId));
        if (
          o &&
          o.offer_id === offerId &&
          [
            OfferStatus.Pending,
            OfferStatus.Accepted,
            OfferStatus.Rejected,
          ].includes(o.offer_status)
        ) {
          return true;
        }
      }
      return false;
    },
});

export const selectOffer = selector({
  key: "selectOffer",
  get: ({ get }) => {
    const selected = get(selectedOfferAtom);
    const offer = get(offerAtomFamily(selected));
    return offer;
  },
});
