import { Flex, PlacementWithLogical, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";

export const OHTooltip = ({
  children,
  label,
  hasArrow,
  color,
  placement,
}: {
  children: React.ReactNode;
  label: React.ReactNode;
  hasArrow?: boolean;
  color?: string;
  placement?: PlacementWithLogical;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Tooltip
      isOpen={isOpen}
      label={label}
      hasArrow={hasArrow}
      color={color}
      placement={placement}
    >
      <Flex
        justify={"space-between"}
        onClick={() => {
          setIsOpen((s) => !s);
        }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {children}
      </Flex>
    </Tooltip>
  );
};
