import { useColorModeValue } from "@chakra-ui/react";

export const Logo: React.FC<{ scale?: number }> = ({ scale }) => {
  const color = useColorModeValue("blue", "white");
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="130%"
      viewBox="0 0 472 168"
    >
      <path
        fill={color}
        opacity="1.000000"
        stroke="none"
        d="
M53.000015,128.000580 
	C44.862873,128.000580 37.225712,128.000580 29.081789,128.000580 
	C29.081789,119.918785 29.081789,112.029037 29.081789,103.869736 
	C21.313881,103.869736 14.046710,103.869736 6.034977,103.869736 
	C6.034977,96.540321 5.999640,89.470818 6.099115,82.403198 
	C6.107469,81.809639 6.907105,81.026726 7.532204,80.672989 
	C14.720214,76.605347 21.945591,72.603737 29.807507,68.224457 
	C29.807507,80.098442 29.807507,91.282036 29.807507,102.850830 
	C46.318893,102.850830 62.380226,102.850830 78.719139,102.850830 
	C78.719139,111.300323 78.719139,119.365631 78.719139,128.000580 
	C70.349091,128.000580 61.924545,128.000580 53.000015,128.000580 
z"
      />
      <path
        fill={color}
        opacity="1.000000"
        stroke="none"
        d="
M91.058182,35.810253 
	C89.017090,36.551975 86.839790,37.044537 84.956177,38.074295 
	C67.761017,47.474792 50.617043,56.968895 33.457767,66.435013 
	C32.478401,66.975296 31.495939,67.509972 29.868246,68.401413 
	C29.868246,59.512077 29.797939,51.272526 29.958660,43.037483 
	C29.978390,42.026482 31.005001,40.628662 31.950417,40.102146 
	C51.351315,29.297518 70.808266,18.593540 91.107666,7.398796 
	C91.107666,17.067410 91.107666,25.955196 91.060425,35.336617 
	C91.013191,35.830254 91.058182,35.810257 91.058182,35.810253 
z"
      />
      <path
        fill={color}
        opacity="1.000000"
        stroke="none"
        d="
M90.971741,36.284637 
	C91.058182,35.810257 91.013191,35.830254 91.014450,35.859810 
	C92.040138,36.045971 93.207611,35.937870 94.065987,36.401749 
	C100.050194,39.635719 106.027992,42.891891 111.862175,46.384308 
	C113.023087,47.079243 114.254501,48.814926 114.270592,50.085674 
	C114.443176,63.713749 114.378014,77.344826 114.378014,91.369400 
	C106.582268,91.369400 99.056107,91.369400 90.885307,91.369400 
	C90.885307,73.215263 90.885307,54.987141 90.971741,36.284637 
z"
      />
      <path
        fill={color}
        opacity="1.000000"
        stroke="none"
        d="
M156.694183,146.559677 
	C154.725952,145.310684 153.096176,144.273483 150.962280,142.915451 
	C150.962280,150.036514 150.962280,156.745300 150.962280,163.729858 
	C147.949158,163.729858 145.362320,163.729858 142.458939,163.729858 
	C142.458939,145.061981 142.458939,126.343796 142.458939,107.302246 
	C144.662964,107.302246 146.904419,107.302246 149.550751,107.302246 
	C149.890259,108.809364 150.272049,110.504189 150.774063,112.732658 
	C156.248184,107.912872 162.005386,105.786835 168.884125,107.599953 
	C179.906006,110.505142 185.715515,121.531815 182.472443,133.691452 
	C179.606445,144.437347 169.875031,150.058807 158.964142,147.270859 
	C158.319839,147.106216 157.676468,146.938004 156.694183,146.559677 
M170.931564,137.358902 
	C171.752045,136.113068 172.763458,134.952209 173.361816,133.607529 
	C177.107483,125.190323 172.504486,115.343201 164.459152,114.408478 
	C158.619644,113.730034 154.038574,116.204201 151.856293,121.215042 
	C149.339981,126.992882 150.836716,134.604370 155.199341,138.215622 
	C159.329300,141.634277 164.995178,141.511276 170.931564,137.358902 
z"
      />
      <path
        fill={color}
        opacity="1.000000"
        stroke="none"
        d="
M331.169861,108.538338 
	C337.407684,111.428612 339.604767,116.582878 339.841187,122.492828 
	C340.172943,130.786240 339.925018,139.102859 339.925018,147.713608 
	C337.149139,147.713608 334.575867,147.713608 331.572418,147.713608 
	C331.572418,140.393829 331.769836,133.078781 331.520721,125.778984 
	C331.231781,117.313416 327.128387,113.502403 319.884399,114.434959 
	C314.611969,115.113701 311.082825,119.415230 310.915466,125.934052 
	C310.732178,133.071960 310.873779,140.218216 310.873779,147.688629 
	C308.024658,147.688629 305.444763,147.688629 302.147308,147.688629 
	C302.147308,138.754623 302.147308,129.662994 302.147308,120.571350 
	C302.147308,111.595871 302.147308,102.620384 302.147308,93.313866 
	C304.993530,93.313866 307.579712,93.313866 310.585205,93.313866 
	C310.585205,99.232605 310.585205,105.325935 310.585205,112.550873 
	C317.096466,107.064301 323.628143,105.503487 331.169861,108.538338 
z"
      />
      <path
        fill={color}
        opacity="1.000000"
        stroke="none"
        d="
M381.855591,145.663223 
	C379.596680,146.993317 377.340820,147.856522 374.582123,148.912140 
	C374.172455,146.124100 373.942413,144.558426 373.602173,142.242813 
	C368.083160,147.304718 362.126801,149.328384 355.163116,147.325272 
	C348.878632,145.517517 345.816803,141.954819 345.777252,136.124268 
	C345.735870,130.025955 348.865784,125.708481 354.964630,124.125092 
	C357.820557,123.383636 360.850098,123.203568 363.816376,123.024902 
	C366.788452,122.845879 369.779785,122.985573 374.469635,122.985573 
	C372.728638,119.948730 372.010376,116.930069 370.150696,115.814987 
	C366.124725,113.400986 360.858673,112.946518 357.612457,116.391441 
	C354.333313,119.871300 351.114258,119.364822 346.890808,119.227371 
	C347.391907,117.569183 347.613342,116.112442 348.246155,114.864250 
	C351.463226,108.518478 361.003540,105.449959 370.349792,107.656471 
	C378.165192,109.501572 381.736938,113.937553 381.838928,122.232574 
	C381.932983,129.886078 381.858734,137.541672 381.855591,145.663223 
M358.413086,140.518829 
	C368.393036,142.084854 373.564575,138.078125 372.867706,129.010422 
	C368.914978,129.010422 364.830627,128.471268 360.992462,129.210373 
	C358.648224,129.661804 355.704224,131.683594 354.872009,133.744171 
	C354.262299,135.253769 356.648438,137.973312 358.413086,140.518829 
z"
      />
      <path
        fill={color}
        opacity="1.000000"
        stroke="none"
        d="
M204.755264,140.015320 
	C210.773331,141.291901 215.382431,140.605133 219.134659,135.311844 
	C220.383713,133.549820 224.439529,133.777374 227.701111,132.975113 
	C225.239624,144.512451 216.004425,149.872040 204.640991,147.438934 
	C192.757126,144.894394 186.469086,134.498947 189.295120,122.069023 
	C191.777588,111.150246 201.858734,105.048996 213.294785,107.544121 
	C224.038284,109.888138 230.099609,118.571060 227.898895,128.908142 
	C218.055954,128.908142 208.133545,128.908142 198.042328,128.908142 
	C197.564606,134.348495 199.980423,137.688828 204.755264,140.015320 
M207.014679,114.375763 
	C202.288696,115.274147 199.058472,117.678200 197.844528,123.007965 
	C202.015289,123.007965 205.500458,123.007957 208.985611,123.007957 
	C212.541382,123.007957 216.097168,123.007965 219.818497,123.007965 
	C217.795670,116.173820 215.318069,114.501503 207.014679,114.375763 
z"
      />
      <path
        fill={color}
        opacity="1.000000"
        stroke="none"
        d="
M114.054749,106.985855 
	C127.418320,107.549110 135.153824,115.330048 135.093857,127.705078 
	C135.036728,139.494736 127.288094,147.572067 115.611877,148.013504 
	C103.404297,148.475037 94.613472,140.991119 93.878975,129.511536 
	C93.039398,116.389610 100.039238,108.399948 114.054749,106.985855 
M103.605080,133.581589 
	C105.446869,137.680954 108.427917,140.256027 113.050438,140.616226 
	C122.440613,141.347961 128.267181,133.768173 126.073158,123.681679 
	C124.789749,117.781487 120.446465,114.345634 114.313301,114.378807 
	C108.450569,114.410522 103.971115,117.750534 103.023331,123.544250 
	C102.529945,126.560226 103.158859,129.759796 103.605080,133.581589 
z"
      />
      <path
        fill={color}
        opacity="1.000000"
        stroke="none"
        d="
M426.706787,119.023071 
	C426.706787,128.812851 426.706787,138.107285 426.706787,147.708588 
	C424.163879,147.708588 421.923859,147.708588 419.373810,147.708588 
	C419.026276,146.493103 418.638123,145.135681 418.445343,144.461472 
	C413.805786,145.699951 409.499023,147.647690 405.112091,147.849152 
	C396.804321,148.230667 390.587433,142.681244 390.081055,134.387695 
	C389.536041,125.461197 389.957886,116.475662 389.957886,107.248199 
	C392.459656,107.248199 395.020477,107.248199 398.123138,107.248199 
	C398.123138,114.748329 397.950043,122.185158 398.169800,129.610382 
	C398.408905,137.689758 402.712128,141.619919 409.607452,140.516159 
	C414.363281,139.754883 417.712646,135.730804 417.945953,129.576294 
	C418.222260,122.287323 418.010925,114.979874 418.010925,107.332054 
	C420.954803,107.332054 423.526398,107.332054 426.706787,107.332054 
	C426.706787,110.981010 426.706787,114.754372 426.706787,119.023071 
z"
      />
      <path
        fill={color}
        opacity="1.000000"
        stroke="none"
        d="
M236.491455,107.552963 
	C238.910248,107.186638 241.051300,107.186638 243.482483,107.186638 
	C243.820236,108.564262 244.160141,109.950699 244.540009,111.500137 
	C245.049545,111.425247 245.604218,111.512985 245.893417,111.276321 
	C252.077972,106.215019 258.863251,105.532097 265.901459,108.969498 
	C272.698578,112.289139 273.956299,118.640709 273.985229,125.426949 
	C274.016357,132.733917 273.992279,140.041138 273.992279,147.684784 
	C271.208618,147.684784 268.630981,147.684784 265.317902,147.684784 
	C265.317902,143.749390 265.347534,139.669647 265.309814,135.590546 
	C265.269806,131.265152 265.395966,126.920563 265.025879,122.620827 
	C264.646881,118.217567 262.262299,115.171036 257.636719,114.468445 
	C252.502243,113.688545 248.210861,115.614235 246.442459,119.937798 
	C245.465088,122.327347 245.018478,125.065201 244.938202,127.664757 
	C244.733887,134.280930 244.870255,140.907639 244.870255,147.772278 
	C241.880310,147.772278 239.294754,147.772278 236.213715,147.772278 
	C236.213715,134.446671 236.213715,121.182983 236.491455,107.552963 
z"
      />
      <path
        fill={color}
        opacity="1.000000"
        stroke="none"
        d="
M464.022583,111.957039 
	C465.494202,114.625641 466.719635,117.026436 468.252014,120.028549 
	C463.475220,120.440308 460.106354,121.495689 457.491089,117.034294 
	C455.525574,113.681297 447.386536,113.246048 444.176117,115.792908 
	C443.068115,116.671913 442.019196,118.617592 442.220642,119.870636 
	C442.414764,121.078079 444.106781,122.650566 445.404724,122.961853 
	C449.413300,123.923256 453.569244,124.247498 457.628876,125.023788 
	C464.922028,126.418404 468.337952,130.096527 468.230011,136.035141 
	C468.147919,140.552841 465.749603,143.672852 461.969116,145.503738 
	C454.497528,149.122238 446.878723,149.058411 439.477203,145.174500 
	C435.345703,143.006500 433.160004,139.453125 432.902679,134.361710 
	C436.728760,133.991287 440.087372,132.668167 442.684387,137.618988 
	C444.910278,141.862183 454.511108,142.370544 458.054260,139.033859 
	C459.087738,138.060593 460.035645,135.907349 459.640167,134.779999 
	C459.139618,133.353226 457.388885,131.776566 455.891937,131.400330 
	C451.730957,130.354538 447.380920,130.084778 443.181549,129.162567 
	C436.760468,127.752487 433.957672,124.844475 433.742889,119.926949 
	C433.513977,114.686882 436.620056,110.060982 442.151764,108.308922 
	C449.835358,105.875267 457.225647,106.365257 464.022583,111.957039 
z"
      />
    </svg>
  );
};
