import {
  Textarea,
  Text,
  Box,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
  ModalFooter,
  Button,
  Flex,
  HStack,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Info } from "react-feather";
import { DEFAULT_SUBJECTS, TAG_REGEX } from "../../../assets/DefaultSubjects";
import { useOfferSubjectsApi } from "../../../hooks/Offers";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { useAddSubjectToOffer } from "../../../recoil/offers/transactions";
import { ICON_SIZE } from "../../../theme";
import { OHTooltip } from "../../common/OHTooltip";

export const DefaultSubjectModal = ({
  index,
  onClose,
}: {
  index: number | null;
  onClose: () => void;
}) => {
  const indexNotNull = index !== null;
  const { createCustomSubjects } = useOfferSubjectsApi();
  const [inputs, setInputs] = useState<{ [key: string]: string }>({});
  const subject = indexNotNull ? DEFAULT_SUBJECTS[index] : null;
  const { fail } = useCustomToast();
  const addToOffer = useAddSubjectToOffer();

  const resetInputs = useCallback(() => {
    if (subject) {
      const initialInputs = subject.custom_fields.reduce((acc, field) => {
        acc[field.field_name] = "";
        return acc;
      }, {} as { [key: string]: string });
      setInputs(initialInputs);
    }
  }, [subject]);

  useEffect(() => {
    resetInputs();
  }, [resetInputs]);

  const allRequiredFilled = useMemo(() => {
    return subject?.custom_fields.every((field) =>
      field.required ? inputs[field.field_name] !== "" : true
    );
  }, [inputs, subject]);

  const handleInputChange = (fieldName: string, value: string) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [fieldName]: value,
    }));
  };

  const handleSave = useCallback(async () => {
    if (!subject) return;

    const regex = /<#\$([\w_]+)\$#>/g;
    const lines = subject.subject_text.split("\n");
    try {
      const reconstructedText = lines
        .map((line) => {
          return line
            .split(regex)
            .map((part, index) => {
              if (index % 2 === 0) {
                return part.replace(/\t/g, "\\t");
              }
              const inputValue = inputs[part];
              if (inputValue !== undefined) {
                return inputValue.replace(/\n/g, "\n").replace(/\t/g, "\t");
              }
              return "";
            })
            .join("");
        })
        .join("\n");

      const [id] = await createCustomSubjects({
        offer_subjects: [
          {
            subject_name: subject.subject_name,
            subject_text: reconstructedText,
            subject_description: subject.subject_description,
          },
        ],
      });
      addToOffer(id);
      resetInputs();
      onClose();
    } catch (error) {
      fail({ title: "Error", description: String(error) });
    }
  }, [
    addToOffer,
    createCustomSubjects,
    fail,
    inputs,
    onClose,
    resetInputs,
    subject,
  ]);

  const renderSubjectText = () => {
    if (!subject) return null;
    const lines = subject.subject_text.split("\n");

    return (
      <Flex wrap={"wrap"} justify={"flex-start"} gap={2}>
        {lines.map((line, lineIndex) => (
          <React.Fragment key={lineIndex}>
            {line.split(TAG_REGEX).map((part, partIndex) => {
              const processedPart = part.replace(
                /\t/g,
                '<span style="margin-left: 2em;"></span>'
              );

              if (partIndex % 2 === 0) {
                return (
                  <span
                    key={partIndex}
                    dangerouslySetInnerHTML={{ __html: processedPart }}
                  />
                );
              }
              const field = subject.custom_fields.find(
                (f) => f.field_name === part
              );
              if (!field) return null;
              return (
                <OHTooltip
                  key={partIndex}
                  label={field.field_description || ""}
                  hasArrow
                >
                  {field.large ? (
                    <Textarea
                      value={inputs[field.field_name]}
                      onChange={(e) =>
                        handleInputChange(field.field_name, e.target.value)
                      }
                      placeholder={field.placeholder ?? field.field_name}
                      isRequired={field.required}
                      required={field.required}
                      borderColor={
                        field.required && !inputs[field.field_name]
                          ? "red.500"
                          : "gray.200"
                      }
                      mr={2}
                    />
                  ) : (
                    <Input
                      display="inline-block"
                      verticalAlign="middle"
                      size="md" //ensure font size is at least 16px to prevent the browser from zooming in on focus; it breaks page.
                      value={inputs[field.field_name]}
                      onChange={(e) =>
                        handleInputChange(field.field_name, e.target.value)
                      }
                      placeholder={field.placeholder ?? field.field_name}
                      isRequired={field.required}
                      borderColor={
                        field.required && !inputs[field.field_name]
                          ? "red.300"
                          : "gray.200"
                      }
                      px={1}
                      mr={1}
                    />
                  )}
                </OHTooltip>
              );
            })}
            {lineIndex < lines.length - 1 && <br />}{" "}
          </React.Fragment>
        ))}
      </Flex>
    );
  };

  if (!subject) return null;

  return (
    <Modal onClose={onClose} isOpen={!!subject}>
      <ModalOverlay />
      <ModalContent bg={"chakra-body-bg"}>
        <ModalHeader>{subject.subject_name} Preview</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{subject.subject_description}</Text>
          <Divider h={0.8} bg={"chakra-body-text"} />
          <Box maxH={350} overflowY={"scroll"} p={2}>
            {renderSubjectText()}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant={"secondary"} mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={"primary"}
            mr={3}
            onClick={handleSave}
            isDisabled={!allRequiredFilled}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const DefaultSubjects = () => {
  const [selected, setSelected] = useState<number | null>(null);

  return (
    <Box
      borderRadius={"md"}
      pb={4}
      px={3}
      border={"1px solid"}
      borderColor={"border"}
    >
      <Flex
        rounded={"md"}
        px={2}
        py={3}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontSize={"lg"} fontWeight={"semibold"}>
          Standard Contingencies
        </Text>
      </Flex>
      <Box pb={4} pt={2} bg={"container-bg"} borderRadius={"md"}>
        <Box
          borderRadius={"md"}
          bg={"container-bg"}
          maxH={315}
          overflowY={"scroll"}
        >
          <VStack>
            <DefaultSubjectModal
              index={selected}
              onClose={() => setSelected(null)}
            />
            {DEFAULT_SUBJECTS.map((s, i) => (
              <Flex
                key={"default" + i}
                rounded={"md"}
                w={"100%"}
                p={3}
                mt={2}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Text>{s.subject_name}</Text>
                <HStack alignItems={"center"} spacing={2}>
                  <Button
                    maxH={6}
                    variant={"secondary"}
                    onClick={() => {
                      setSelected(i);
                    }}
                  >
                    Use
                  </Button>
                  {s.subject_description.length && (
                    <OHTooltip
                      hasArrow
                      placement="top"
                      label={<p>{s.subject_description}</p>}
                    >
                      <Info size={ICON_SIZE} />
                    </OHTooltip>
                  )}
                </HStack>
              </Flex>
            ))}
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};
