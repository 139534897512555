import {
  Box,
  Button,
  Center,
  Text,
  Flex,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { EditViewingsTable } from "../components/createSchedule/EditViewingsTable";
import { useParams } from "react-router-dom";
import { ScheduleForm } from "../components/createSchedule/ScheduleForm";
import { useState } from "react";
import { BackLinkButton } from "../components/common/BackLinkButton";
import { Calendar } from "react-feather";
import { ICON_SIZE } from "../theme";

export const EditViewings = ({ overrideId }: { overrideId?: string }) => {
  const { id: paramId } = useParams<{ id: string }>();
  const id = overrideId ?? paramId;
  const bg = useColorModeValue("white", "darkBg");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedViewingScheduleId, setSelectedViewingScheduleId] = useState<
    string | undefined
  >(undefined);

  const onEdit = (viewingScheduleId: string) => {
    setSelectedViewingScheduleId(viewingScheduleId);
    onOpen();
  };
  return (
    <Center width="100%" px={4} py={2}>
      <Box width="100%" maxW={{ base: "100%", md: "3xl" }}>
        <Flex justifyContent={"space-between"} mb={10}>
          {!overrideId && (
            <BackLinkButton
              navigateTo="/manage/listings"
              label="Back to Manage Listings"
            />
          )}
          {!overrideId && <Text fontSize="2xl">Edit Viewings</Text>}
          <Button
            variant={"primary"}
            onClick={onOpen}
            rightIcon={<Calendar size={ICON_SIZE} />}
          >
            Schedule showing
          </Button>
        </Flex>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent bg={bg}>
            <ModalHeader>Schedule Showing Time</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ScheduleForm
                listingId={id}
                onClose={onClose}
                viewingScheduleId={selectedViewingScheduleId}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
        {id ? (
          <EditViewingsTable listingId={id} onEdit={onEdit} />
        ) : (
          <Text size={"lg"}>No viewings scheduled</Text>
        )}
      </Box>
    </Center>
  );
};

export default EditViewings;
