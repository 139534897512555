import React from "react";
import { Button, Box } from "@chakra-ui/react";
import { Share } from "react-feather";
import { useCustomToast } from "../../hooks/useCustomToast";

interface ShareButtonProps {
  listingId: string;
}

export const ShareButton: React.FC<ShareButtonProps> = ({ listingId }) => {
  const { success, fail } = useCustomToast();

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`${window.location.origin}/listing/${listingId}`)
      .then(() => {
        success({ title: "Link copied to clipboard!" });
      })
      .catch((err) => {
        console.error("Error copying link to clipboard: ", err);
        fail({ title: "Failed to copy link to clipboard" });
      });
  };

  return (
    <Box position="relative" zIndex="1">
      <Button
        size={"sm"}
        variant="outline"
        rightIcon={<Share size={14} />}
        onClick={handleCopy}
      >
        Share
      </Button>
    </Box>
  );
};
