import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { theme } from "../../../theme";
import useMapboxApi from "../../../hooks/useMapbox";

interface MapboxMapProps {
  lng: number;
  lat: number;
}

export const MapboxMap: React.FC<MapboxMapProps> = ({ lng, lat }) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const { accessToken } = useMapboxApi();
  if (accessToken) mapboxgl.accessToken = accessToken;

  useEffect(() => {
    if (accessToken) {
      try {
        if (mapContainerRef.current) {
          const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: [lng, lat],
            zoom: 15,
          });

          new mapboxgl.Marker({ color: theme.colors.ohBlue[400] })
            .setLngLat([lng, lat])
            .addTo(map);

          map.addControl(new mapboxgl.NavigationControl());
          return () => map.remove();
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [accessToken, lat, lng]);

  return (
    <div
      ref={mapContainerRef}
      style={{ minWidth: "100%", height: "400px", borderRadius: 8 }}
    />
  );
};
