import { Box, Text, Flex, Heading } from "@chakra-ui/react";
import { addDays, subDays } from "date-fns";
import { useEffect, useMemo } from "react";
import { useRecoilValue, useRecoilState, SetterOrUpdater } from "recoil";
import { useCustomToast } from "../../../hooks/useCustomToast";
import {
  selectedOfferAtom,
  offerAtomFamily,
  draftOfferCompletionDateAtom,
  draftOfferPossessionDateAtom,
  draftOfferViewedDateAtom,
  draftOfferAdjustmentsDateAtom,
  draftOfferEndAtom,
} from "../../../recoil/offers/atoms";
import { convertDateToString } from "../../../utils/date";
import { DateInput } from "../../common";
import { InputName } from "../../common/InputNameWithInfo";
import { Tip } from "./DepositInfo";

export const BaseInfo = ({ viewOnly }: { viewOnly?: boolean }) => {
  const selectedOffer = useRecoilValue(selectedOfferAtom);
  const offer = useRecoilValue(offerAtomFamily(selectedOffer));
  const [closing, setClosing] = useRecoilState(draftOfferCompletionDateAtom);
  const [possesion, setPossession] = useRecoilState(
    draftOfferPossessionDateAtom
  );
  const { fail } = useCustomToast();
  const [viewed, setViewed] = useRecoilState(draftOfferViewedDateAtom);
  const [adj, setAdj] = useRecoilState(draftOfferAdjustmentsDateAtom);

  const [offerEnd, setOfferEnd] = useRecoilState(draftOfferEndAtom);
  const current = convertDateToString(offer?.offer_start_date);
  const plusYear = convertDateToString(offer ? offer.offer_end_date : current);
  const err = (title: string) => fail({ title });
  useEffect(() => {
    if (!offer) return;
    setOfferEnd(plusYear);

    setAdj(
      convertDateToString(
        offer.offer_possession_date ?? addDays(new Date(), 50)
      )
    );
    setClosing(
      convertDateToString(
        offer.offer_completion_date ?? addDays(new Date(), 40)
      )
    );
    setPossession(
      convertDateToString(
        offer.offer_possession_date ?? addDays(new Date(), 50)
      )
    );
    setViewed(
      convertDateToString(offer.offer_viewed_date ?? subDays(new Date(), 1))
    );
  }, [
    current,
    offer,
    plusYear,
    setAdj,
    setClosing,
    setOfferEnd,
    setPossession,
    setViewed,
  ]);

  const dates = useMemo(() => {
    return {
      adj: new Date(adj),
      closing: new Date(closing),
      offerEnd: new Date(offerEnd),
      viewed: new Date(viewed),
      possesion: new Date(possesion),
    };
  }, [adj, closing, offerEnd, possesion, viewed]);

  return (
    <Flex direction={"column"} gap={"3"} my={2}>
      <Heading size="md">Timeline</Heading>
      <Text fontWeight={"semibold"}>
        Set the proposed closing date and possession date.
      </Text>
      <Box w={"100%"} mb={2}>
        <Text fontWeight={600} mb={1}>
          Offer effective until:
        </Text>
        <DateInput
          date={offerEnd}
          setDate={(value) =>
            dateHandler({
              value,
              setter: setOfferEnd,
              type: "end",
              dates,
              err,
            })
          }
          disabled={viewOnly}
        />
      </Box>
      <Box w={"100%"} mb={2}>
        <Text fontWeight={600} mb={1}>
          Property viewed on:
        </Text>
        <DateInput
          date={viewed}
          setDate={(value) =>
            dateHandler({
              value,
              setter: setViewed,
              type: "viewed",
              dates,
              err,
            })
          }
          disabled={viewOnly}
        />
      </Box>
      <Box w={"100%"} mb={1}>
        <InputName
          name="Closing Date:"
          description="The closing date marks the legal transfer of property ownership from the seller to the buyer, involving finalizing all documents and payments."
        />
        <DateInput
          date={closing}
          setDate={(value) =>
            dateHandler({
              value,
              setter: setClosing,
              type: "closing",
              dates,
              err,
            })
          }
          disabled={viewOnly}
        />
      </Box>

      <Box w={"100%"} mb={1}>
        <InputName
          name="Possession Date:"
          description="This is when the buyer officially takes control and can move into the property, typically occurring after the closing date."
        />
        <DateInput
          date={possesion}
          setDate={(value) =>
            dateHandler({
              value,
              setter: setPossession,
              type: "posession",
              dates,
              err,
            })
          }
          disabled={viewOnly}
        />
      </Box>
      <Tip
        value={
          "Choose realistic dates based on your and the seller’s timelines."
        }
      />
      <Tip
        value={"Understand what prorated costs you might be responsible for."}
      />
    </Flex>
  );
};

export const dateHandler = ({
  value,
  setter,
  type,
  dates,
  err,
}: {
  value: string;
  setter: SetterOrUpdater<string>;
  type: "closing" | "posession" | "adjustments" | "viewed" | "end";
  dates: {
    adj: Date;
    closing: Date;
    offerEnd: Date;
    viewed: Date;
    possesion: Date;
  };
  err: (message: string) => void;
}) => {
  const val = new Date(value);

  switch (type) {
    case "adjustments":
      if (val >= dates.closing) {
        err("Adjustments date has to be before closing and possession");
      } else {
        setter(value);
      }
      break;
    case "closing":
      if (val >= dates.possesion || val < dates.adj || val < dates.viewed) {
        err(
          "Closing date has to be before possession and after adjustemnts, viewed"
        );
      } else {
        setter(value);
      }
      break;
    case "posession":
      if (val < dates.adj || val < dates.closing || val < dates.viewed) {
        err("Posession date has to be after closing, adjustemnts, viewed");
      } else {
        setter(value);
      }
      break;
    case "viewed":
      if (val > new Date()) {
        err("Viewed date can't be in the future");
      } else {
        setter(value);
      }
      break;
    case "end":
      if (val < new Date()) {
        err("Offer expiration can't be in the past");
      } else {
        setter(value);
      }
      break;
    default:
      break;
  }
};
