import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useViewingsApi from "../hooks/useViewingsApi";
import { useRecoilValue } from "recoil";
import { userState } from "../recoil/atoms";
import { ViewingRequestCard } from "../components/viewings/ViewingRequestCard";
import { selectAllPendingViewingRequests } from "../recoil/viewings/selectors";
import { useEffect } from "react";

export const ViewingRequest = () => {
  const { id } = useParams<{ id: string }>();
  const { getAllSellerViewingRequests } = useViewingsApi();
  const user = useRecoilValue(userState);
  const pending_requests = useRecoilValue(selectAllPendingViewingRequests);

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    getAllSellerViewingRequests();
  }, [getAllSellerViewingRequests, user?.id]);

  const pending_request = pending_requests?.find(
    (request) => request.id === id
  );

  return (
    <Center width="100%" px={4} py={2}>
      <Box width="100%" maxW={{ base: "100%", md: "3xl" }}>
        <Flex justifyContent="center">
          {user && pending_request ? (
            <ViewingRequestCard
              request_mode={true}
              viewingRequestId={pending_request.id}
              seller_view={true}
            />
          ) : (
            <Text fontSize={"xl"} py={10}>
              No viewing request found
            </Text>
          )}
        </Flex>
      </Box>
    </Center>
  );
};
