import { useEffect } from "react";
import { Box, Text, Flex, Link, Spinner } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { walkscoreAtomFamily } from "../../recoil/listings";
import { useEstimateApi } from "../../hooks/Offers";

const WalkScoreDisplay = ({ listingId }: { listingId: string }) => {
  const walkscore = useRecoilValue(walkscoreAtomFamily(listingId));
  const { getWalkscoreByListingId } = useEstimateApi();

  useEffect(() => {
    if (!walkscore) {
      getWalkscoreByListingId(listingId);
    }
  }, [getWalkscoreByListingId, listingId, walkscore]);

  if (!walkscore) {
    return (
      <Flex justifyContent="center" alignItems="center" mt={4}>
        <Spinner />
        <Text ml={2}>Loading Walk Score...</Text>
      </Flex>
    );
  }

  if (walkscore.status !== 1) {
    return (
      <Box mt={4}>
        <Text>Walk Score data is currently unavailable.</Text>
      </Box>
    );
  }

  return (
    <Box mt={4} w={"full"} gap={2}>
      <Flex alignItems="start" gap={2}>
        <a
          href="https://www.walkscore.com/how-it-works/"
          target="_blank"
          rel="noreferrer"
        >
          <Text fontSize="md" fontWeight="bold">
            Walk Score®:
          </Text>
        </a>
        <Text fontSize="md" fontWeight="bold">
          {walkscore.walkscore} {`- ${walkscore.description}`}
        </Text>
      </Flex>
      {walkscore.transit && (
        <Flex alignItems="start" gap={2}>
          <a
            href="https://www.walkscore.com/how-it-works/"
            target="_blank"
            rel="noreferrer"
          >
            <Text fontSize="md" fontWeight="bold">
              Transit Score®:
            </Text>
          </a>
          <Text fontSize="md" fontWeight="bold">
            {walkscore.transit.score} {`- ${walkscore.transit.description}`}
          </Text>
        </Flex>
      )}
      {walkscore.bike && (
        <Flex alignItems="start" gap={2}>
          <a
            href="https://www.walkscore.com/how-it-works/"
            target="_blank"
            rel="noreferrer"
          >
            <Text fontSize="md" fontWeight="bold">
              Bike Score®:
            </Text>
          </a>
          <Text fontSize="md" fontWeight="bold">
            {walkscore.bike.score} {`- ${walkscore.bike.description}`}
          </Text>
        </Flex>
      )}

      <Link href={walkscore.ws_link} isExternal fontSize={14}>
        View more details on Walk Score
      </Link>
    </Box>
  );
};

export default WalkScoreDisplay;
