import { useRecoilState } from "recoil";
import { cartAtom } from "../../recoil/services/atoms";
import { Product } from "../../types/services";

export const useCart = () => {
  const [cart, setCart] = useRecoilState(cartAtom);

  const addToCart = (product: Product) => {
    if (!cart.includes(product)) {
      setCart([...cart, product]);
    }
  };
  const removeFromCart = (product: Product) => {
    setCart(cart.filter((item) => item !== product));
  };

  return { addToCart, cart, removeFromCart };
};
