import { atom, atomFamily } from "recoil";
import {
  ListingSchedule,
  ViewingRequest,
  ViewingRequestForm,
} from "../../types";
import { ViewingScheduleForm } from "../../types";

export const viewingRequestInputAtom = atom<ViewingRequestForm>({
  key: "viewingRequestInputAtom",
  default: {
    start_time: "",
    end_time: "",
    viewing_schedule_id: "",
  },
});

export const viewingScheduleInputAtom = atom<
  Omit<ViewingScheduleForm, "listing_id">
>({
  key: "viewingScheduleInputAtom",
  default: {
    date: "",
    start_time: "",
    end_time: "",
    days_of_week: [],
    max_attendance: undefined,
  },
});

// keyed by listingID
export const listingScheduleAtomFamily = atomFamily<ListingSchedule, string>({
  key: "listingScheduleAtomFamily",
  default: {
    availabilities: [],
    bookings: [],
  },
});

// requestS  (keyed by request id)
export const viewingRequestAtomFamily = atomFamily<
  ViewingRequest | undefined,
  string
>({
  key: "viewingRequestAtomFamily",
  default: undefined,
});

// stores viewing request ids for all listings of a seller (thats logged in)
export const viewingRequestsForBuyerAtom = atom<string[]>({
  key: "viewingRequestsForBuyerAtom",
  default: [],
});

// stores viewing request ids for all listings of a seller (thats logged in)
export const viewingRequestsForSellerAtom = atom<string[]>({
  key: "viewingRequestsForSellerAtom",
  default: [],
});
