import { selector, selectorFamily } from "recoil";
import {
  listingScheduleAtomFamily,
  viewingRequestAtomFamily,
  viewingRequestsForBuyerAtom,
  viewingRequestsForSellerAtom,
} from "./atoms";
import {
  ListingSchedule,
  RequestStatus,
  SellerViewing,
  ViewingRequest,
  ViewingSchedule,
} from "../../types";
import {
  calculateDateForDayOfWeek,
  compareDates,
  isTimeEqual,
  setHoursAndMinutes,
} from "../../utils/date";
import { parseISO, isPast, addDays } from "date-fns";
import { userListingsAtom } from "../listings";

export const selectAllPendingViewingRequests = selector({
  key: "selectAllPendingViewingRequests",
  get: ({ get }) => {
    const allRequestIds = get(viewingRequestsForSellerAtom);
    return allRequestIds
      .map((r) => get(viewingRequestAtomFamily(r)))
      .filter(
        (r) => !!r && r.request_status === RequestStatus.Pending
      ) as ViewingRequest[];
  },
});

export const selectListingPendingRequests = selectorFamily({
  key: "selectListingPendingRequests",
  get:
    (listingId: string) =>
    ({ get }) => {
      const allRequestIds = get(viewingRequestsForSellerAtom);
      return allRequestIds
        .map((r) => get(viewingRequestAtomFamily(r)))
        .filter(
          (r) =>
            !!r &&
            r.request_status === RequestStatus.Pending &&
            r.listing_id === listingId
        ) as ViewingRequest[];
    },
});

export const selectUpcomingViewingsForBuyer = selector({
  key: "selectUpcomingBuyerRequests",
  get: ({ get }) => {
    const allRequestIds = get(viewingRequestsForBuyerAtom);
    return allRequestIds
      .map((r) => get(viewingRequestAtomFamily(r)))
      .filter(
        (r) => !!r && r.request_status === RequestStatus.Accepted
      ) as ViewingRequest[];
  },
});

export const selectPendingViewingRequestsForBuyer = selector({
  key: "selectPendingBuyerRequests",
  get: ({ get }) => {
    const allRequestIds = get(viewingRequestsForBuyerAtom);
    return allRequestIds
      .map((r) => get(viewingRequestAtomFamily(r)))
      .filter(
        (r) => !!r && r.request_status === RequestStatus.Pending
      ) as ViewingRequest[];
  },
});

export const selectUpcomingViewingsForSeller = selector({
  key: "selectUpcomingViewingsForSeller",
  get: ({ get }) => {
    const userListingIds = get(userListingsAtom);
    const allListingSchedules = userListingIds.flatMap(
      (id) => get(listingScheduleAtomFamily(id)) || []
    );

    const openHouseViewings = allListingSchedules.flatMap((listingSchedule) => {
      return listingSchedule.availabilities.filter((avail) => {
        if (avail.max_attendance) {
          if (
            (!avail.days_of_week || avail.days_of_week.length === 0) &&
            isPast(parseISO(avail.start_time))
          ) {
            return false;
          }
          return true;
        }
        return false;
      });
    });

    const privateViewings: SellerViewing[] = allListingSchedules.flatMap(
      (listingSchedule) => {
        return listingSchedule.bookings.reduce<SellerViewing[]>(
          (acc, booking) => {
            const isPrivateViewing = listingSchedule.availabilities.some(
              (avail) => {
                const bookingStartDate = new Date(booking.start_time);
                if (avail.days_of_week && avail.days_of_week.length > 0) {
                  for (const day of avail.days_of_week) {
                    const weekStart = getStartOfWeek(bookingStartDate);
                    const date = calculateDateForDayOfWeek(day, weekStart);
                    const scheduleStartDate = new Date(booking.start_time);
                    const scheduleEndDate = new Date(booking.end_time);
                    const viewing: ViewingSchedule = {
                      ...avail,
                      start_time: setHoursAndMinutes(
                        date,
                        scheduleStartDate.getUTCHours(),
                        scheduleStartDate.getUTCMinutes()
                      ).toISOString(),
                      end_time: setHoursAndMinutes(
                        date,
                        scheduleEndDate.getUTCHours(),
                        scheduleEndDate.getUTCMinutes()
                      ).toISOString(),
                    };
                    if (
                      !avail.max_attendance &&
                      avail.id === booking.viewing_schedule_id &&
                      isTimeEqual(
                        booking.start_time,
                        booking.end_time,
                        viewing.start_time,
                        viewing.end_time
                      )
                    ) {
                      return true;
                    }
                  }
                  return false;
                } else {
                  return (
                    !avail.max_attendance &&
                    avail.id === booking.viewing_schedule_id &&
                    isTimeEqual(
                      booking.start_time,
                      booking.end_time,
                      avail.start_time,
                      avail.end_time
                    )
                  );
                }
              }
            );
            if (isPrivateViewing) {
              acc.push({
                id: booking.viewing_schedule_id,
                viewing_request_id: booking.id,
                start_time: booking.start_time,
                end_time: booking.end_time,
                listing_id: booking.listing_id,
              });
            }
            return acc;
          },
          []
        );
      }
    );

    let combinedViewings: (ViewingSchedule | SellerViewing)[] = [
      ...privateViewings,
      ...openHouseViewings,
    ];

    return combinedViewings;
  },
});

export const selectFirstAvailableViewingDate = selectorFamily({
  key: "selectFirstAvailableViewingDate",
  get:
    (listing_id: string) =>
    ({ get }) => {
      const now = new Date();
      const listingSchedule = get(listingScheduleAtomFamily(listing_id));
      //go through availabilities
      let firstAvailableDate = new Date();
      for (const avail of listingSchedule.availabilities) {
        //go through recurring schowings
        if (avail.days_of_week && avail.days_of_week.length > 0) {
          const scheduleStartDate = new Date(avail.start_time);
          const scheduleEndDate = new Date(avail.end_time);
          const scheduleWeek = getStartOfWeek(
            scheduleStartDate > now ? scheduleStartDate : now
          );
          for (let i = 0; i < MAX_ITERATIONS; i++) {
            const currentWeekStart = addDays(scheduleWeek, 7 * i);
            //go through each recurring day
            for (const day of avail.days_of_week) {
              const date = calculateDateForDayOfWeek(day, currentWeekStart);
              if (
                date >= currentWeekStart &&
                date < addDays(currentWeekStart, 7)
              ) {
                const viewing: ViewingSchedule = {
                  ...avail,
                  start_time: setHoursAndMinutes(
                    date,
                    scheduleStartDate.getUTCHours(),
                    scheduleStartDate.getUTCMinutes()
                  ).toISOString(),
                  end_time: setHoursAndMinutes(
                    date,
                    scheduleEndDate.getUTCHours(),
                    scheduleEndDate.getUTCMinutes()
                  ).toISOString(),
                };

                if (
                  ((!viewing.max_attendance &&
                    !isBooked(listingSchedule, viewing)) ||
                    (viewing.max_attendance &&
                      viewing.max_attendance > 0 &&
                      !isFullyBooked(listingSchedule, viewing)) ||
                    viewing.max_attendance === -1) &&
                  new Date(viewing.start_time) > now
                ) {
                  firstAvailableDate =
                    new Date(viewing.start_time) < firstAvailableDate
                      ? new Date(viewing.start_time)
                      : firstAvailableDate;
                }
              }
            }
          }
        } else {
          if (now > new Date(avail.start_time)) continue;
          if (
            (!avail.max_attendance && !isBooked(listingSchedule, avail)) ||
            (avail.max_attendance &&
              avail.max_attendance > 0 &&
              !isFullyBooked(listingSchedule, avail)) ||
            avail.max_attendance === -1
          )
            firstAvailableDate =
              new Date(avail.start_time) < firstAvailableDate
                ? new Date(avail.start_time)
                : firstAvailableDate;
        }
      }
      return firstAvailableDate;
    },
});

export const selectCurrentWeekViewings = selectorFamily({
  key: "selectCurrentWeekViewings",
  get:
    ({ weekStart, listing_id }: { listing_id: string; weekStart: Date }) =>
    ({ get }) => {
      const listingSchedule = get(listingScheduleAtomFamily(listing_id));
      const currentWeekViewings: ViewingSchedule[] = [];
      listingSchedule.availabilities
        .filter((s) => {
          if (s.days_of_week && s.days_of_week.length > 0) return true;
          if (
            new Date(s.start_time) < weekStart ||
            new Date(s.end_time) > addDays(weekStart, 7)
          ) {
            return false;
          }
          return true;
        })
        .forEach((schedule) => {
          const scheduleStartDate = new Date(schedule.start_time);
          const scheduleEndDate = new Date(schedule.end_time);

          if (schedule.days_of_week && schedule.days_of_week.length > 0) {
            if (scheduleStartDate > addDays(weekStart, 7)) {
              return;
            }
            schedule.days_of_week.forEach((day) => {
              const date = calculateDateForDayOfWeek(day, weekStart);
              if (date >= weekStart && date < addDays(weekStart, 7)) {
                const viewing: ViewingSchedule = {
                  ...schedule,
                  start_time: setHoursAndMinutes(
                    date,
                    scheduleStartDate.getUTCHours(),
                    scheduleStartDate.getUTCMinutes()
                  ).toISOString(),
                  end_time: setHoursAndMinutes(
                    date,
                    scheduleEndDate.getUTCHours(),
                    scheduleEndDate.getUTCMinutes()
                  ).toISOString(),
                };
                if (
                  !isBooked(listingSchedule, viewing) &&
                  new Date(viewing.start_time) > new Date()
                )
                  currentWeekViewings.push(viewing);
              }
            });
          } else if (!isBooked(listingSchedule, schedule))
            currentWeekViewings.push(schedule);
        });

      return currentWeekViewings.sort(
        (a, b) =>
          new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
      );
    },
});

const MAX_ITERATIONS = 10;

const isBooked = (listingSchedule: ListingSchedule, viewing: ViewingSchedule) =>
  listingSchedule.bookings.some(
    (booking) =>
      !viewing.max_attendance &&
      viewing.id === booking.viewing_schedule_id &&
      compareDates(
        booking.start_time,
        booking.end_time,
        viewing.start_time,
        viewing.end_time
      )
  );

const isFullyBooked = (
  listingSchedule: ListingSchedule,
  viewing: ViewingSchedule
) => {
  if (!viewing.max_attendance || viewing.max_attendance === -1) return false;
  const rsvps = listingSchedule.bookings.reduce(
    (acc, booking) =>
      acc +
      (viewing.id === booking.viewing_schedule_id &&
      compareDates(
        booking.start_time,
        booking.end_time,
        viewing.start_time,
        viewing.end_time
      )
        ? 1
        : 0),
    0
  );
  return rsvps >= viewing.max_attendance;
};

export const getStartOfWeek = (date: Date) => {
  const d = new Date(date);
  d.setDate(d.getDate() - d.getDay() + (d.getDay() === 0 ? -6 : 1));
  d.setHours(0, 0, 0, 0);
  return d;
};
