import {
  Box,
  Center,
  Flex,
  Progress,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  FileBatchType,
  filesToUploadAtomFamily,
} from "../../recoil/listings/atoms";
import { useDropzone } from "react-dropzone";
import { generate32String } from "../../utils/crypto";
import { FormattedFile, MediaType } from "../../types";
import imageCompression from "browser-image-compression";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { PlusCircle } from "react-feather";
import { useCustomToast } from "../../hooks/useCustomToast";
import { useIsMobile } from "../../hooks/utils/useWindowDimensions";
import { userState } from "../../recoil/atoms";

const ffmpeg = new FFmpeg();

export const compressImage = async (file: File): Promise<File> => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    return await imageCompression(file, options);
  } catch (error) {
    console.error("Error while compressing the image", error);
    return file;
  }
};

// major todo is folder path support
// so listingAssetUrl will send presigned listingAsset url for s3 with folder path included
// we also need support for multiple files at the same time

export const FileUploader: React.FC<{
  fileLimit: number;
  type: FileBatchType;
  setIsUploading?: (isUploading: boolean) => void;
}> = ({ fileLimit, type, setIsUploading }) => {
  const [files, setFiles] = useRecoilState(filesToUploadAtomFamily(type));
  const { fail } = useCustomToast();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileIndex, setFileIndex] = useState(0);
  const user = useRecoilValue(userState);
  const [numberOfAcceptedFiles, setNumberOfAcceptedFiles] = useState(0);
  const isMobile = useIsMobile();
  const MAX_IMAGES = fileLimit;
  const MAX_IMAGE_SIZE = 100 * 1024 * 1024; // 100MB

  useEffect(() => {
    if (setIsUploading) {
      setIsUploading(loading);
    }
  }, [loading, setIsUploading]);

  const processFile = useCallback(
    async (file: File, fileProgress: number) => {
      const [filetype, extension] = file.type.split("/");
      let processedFile = file;

      if (filetype === "image") {
        try {
          // skip compression
          // processedFile = await compressImage(file);
          setFileIndex((index) => index + 1);
          setProgress((currentProgress) => {
            return fileProgress + currentProgress;
          });
        } catch (error) {
          fail({ title: `Error processing image ${file.name}` });
        }
      } else if (filetype === "video") {
        if (!ffmpeg.loaded) {
          await ffmpeg.load();
        }
        try {
          const filename = file.name;

          const data = new Uint8Array(await file.arrayBuffer());
          await ffmpeg.writeFile(filename, data); // Writing file to FFmpeg's virtual file system

          // await ffmpeg.exec([
          //   "-i",
          //   filename,
          //   "-vf",
          //   "scale=1280:-2",
          //   "-preset",
          //   "ultrafast",
          //   "-b:v",
          //   "2000k",
          //   "-b:a",
          //   "64k",
          //   outputFilename,
          // ]);
          const output = await ffmpeg.readFile(filename); // Reading the compressed file from FFmpeg's virtual file system
          processedFile = new File([output], filename, {
            type: file.type,
          });
          setFileIndex((index) => index + 1);
          setProgress((currentProgress) => {
            return fileProgress + currentProgress;
          });
        } catch (error) {
          fail({ title: `Error processing video ${file.name}` });
        }
      }

      return Object.assign(processedFile, {
        preview: URL.createObjectURL(processedFile),
        storageName:
          (type === "listing-assets" ? `${user?.id ?? "unknown"}/` : "") +
          `${generate32String() + "." + extension}`,
        mediaType:
          filetype === "image" ? 0 : filetype === "application" ? 2 : 1,
      }) as FormattedFile;
    },
    [fail, type, user]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setNumberOfAcceptedFiles(acceptedFiles.length);
      setLoading(true);

      //flag if images exceed max length
      let files = acceptedFiles;
      if (files.length > MAX_IMAGES) {
        fail({ title: `Max image capacity reached. ` });
        files = files.slice(MAX_IMAGES - 1, files.length);
        files.forEach((file) => {
          fail({
            title: `File: ${file.name} failed to upload. Max number of images reached.`,
          });
        });
      }

      //flag oversized images
      const sizeableFiles: File[] = [];

      files.forEach((file) => {
        if (file.size <= MAX_IMAGE_SIZE) {
          sizeableFiles.push(file);
        } else {
          fail({
            title: `File: ${file.name} failed to upload. File size exceeds 100MB.`,
          });
        }
      });

      const numberOfFiles = sizeableFiles.length;
      const processedFiles = await Promise.all(
        sizeableFiles.map((file) => {
          const fileProgress = 100 / numberOfFiles;
          const processedFile = processFile(file, fileProgress);
          return processedFile;
        })
      );

      //set files to upload to state
      setFiles((prevFiles: FormattedFile[]) => {
        const spaceAvailable = MAX_IMAGES - prevFiles.length;
        const filesToAdd = processedFiles.slice(0, spaceAvailable);
        return [...prevFiles, ...filesToAdd];
      });

      setLoading(false);
      setProgress(0);
      setFileIndex(1);
    },
    [processFile, setFiles, MAX_IMAGE_SIZE, MAX_IMAGES, fail]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      type === "listing-assets"
        ? { "image/*": [], "video/*": [] }
        : { "image/*": [], "application/pdf": [] },
  });

  const removeFile = useCallback(
    (file: any) => {
      const newFiles = [...files];
      newFiles.splice(newFiles.indexOf(file), 1);
      setFiles(newFiles);
    },
    [files, setFiles]
  );

  React.useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const thumbnails = useMemo(
    () =>
      files
        .filter((f) => !!f)
        .map((file) => (
          <Box key={file.name} minW={"20"}>
            <Flex key={file.name} justify={"space-between"}>
              {file.mediaType === MediaType.Doc ? (
                <Text maxW={"3xs"}>{file.name}</Text>
              ) : file.mediaType === MediaType.Video ? (
                <video style={{ maxWidth: 200 }} src={file.preview} controls />
              ) : (
                <img
                  key={file.name}
                  src={file.preview}
                  style={{ width: "200px" }}
                  alt="preview"
                />
              )}
              <button onClick={() => removeFile(file)}>Remove File</button>
            </Flex>
          </Box>
        )),
    [files, removeFile]
  );

  return (
    <Box>
      {loading ? (
        <Box
          maxH={isMobile ? 60 : 200}
          opacity={0.5}
          backgroundColor={"uploader-bg"}
          border={"dashed 2px"}
          borderColor={"uploader-border"}
          borderRadius={4}
          rounded={"xl"}
          py={isMobile ? 2 : 4}
          px={6}
          my={2}
        >
          <Flex justify={"space-evenly"} mb={4}>
            <Spinner />
            <Text>
              Processing {fileIndex} of {numberOfAcceptedFiles} (Larger files
              take a longer time to process)
            </Text>
          </Flex>
          <Progress isAnimated hasStripe value={progress} />
        </Box>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Box
            minH={isMobile ? 50 : 175}
            maxH={isMobile ? 60 : 200}
            opacity={0.5}
            backgroundColor={"uploader-bg"}
            border={"dashed 2px"}
            borderColor={"uploader-border"}
            borderRadius={4}
            mb={2}
            py={isMobile ? 2 : 10}
            px={3}
            cursor={"pointer"}
          >
            {isDragActive ? (
              <Center>
                <Text>Drop the file here ...</Text>
              </Center>
            ) : (
              <Center>
                <VStack>
                  <PlusCircle size={28} />
                  <Text fontSize={isMobile ? "sm" : "xl"} textAlign={"center"}>
                    {fileLimit > 1
                      ? "Drag & drop or click to select files"
                      : "Drag & drop or click to select a file"}
                  </Text>
                  {fileLimit > 1 && (
                    <Text>Max uploads: {fileLimit} images.</Text>
                  )}
                </VStack>
              </Center>
            )}
          </Box>
        </div>
      )}
      {thumbnails}
    </Box>
  );
};
