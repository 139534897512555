import { useRecoilState, useRecoilValue } from "recoil";
import { selectAxiosInstance } from "../recoil/selectors";
import { useCallback, useEffect } from "react";
import { useUpdateListingGeocode } from "../recoil/listings/transactions";
import { mapBoxKey } from "../recoil/atoms";

const useMapboxApi = () => {
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const [key, setKey] = useRecoilState(mapBoxKey);
  const updateListingGeocodeTxn = useUpdateListingGeocode();

  const getKey = useCallback(async () => {
    const now = Math.floor(Date.now() / 1000);
    if (!key || key.expiresAt <= now) {
      const { data } = await axiosInstance.get<{
        token: string;
        expiresAt: number;
      }>("/kyc/map");
      setKey(data);
      return data.token;
    } else {
      return key.token;
    }
  }, [axiosInstance, key, setKey]);

  useEffect(() => {
    try {
      getKey();
    } catch (error) {
      getKey();
    }
  }, [getKey]);

  const getListingGeocode = useCallback(
    async (
      listingId: string,
      address: string,
      city: string,
      state: string,
      country: string,
      postal_code: string
    ) => {
      try {
        const fullAddress = `${address}, ${city}, ${state}, ${country}, ${postal_code}`;
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          fullAddress
        )}.json?access_token=${await getKey()}`;
        const response = await axiosInstance.get(url);
        const data = response.data;
        if (data.features && data.features.length > 0) {
          const [lng, lat] = data.features[0].center;
          updateListingGeocodeTxn(listingId, { lat, lng });
          return { lat, lng };
        } else {
          throw new Error("No results found");
        }
      } catch (error) {
        console.error({ error });
        return null;
      }
    },
    [axiosInstance, getKey, updateListingGeocodeTxn]
  );

  return {
    getListingGeocode,
    accessToken: key?.token,
    getKey,
  };
};

export default useMapboxApi;
