import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Input,
  Textarea,
  ModalFooter,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { useOfferSubjectsApi } from "../../../hooks/Offers";
import { subjectAtomFamily } from "../../../recoil/offers/atoms";

export const SubjectEditModal = ({
  handleClose,
  isOpen,
  subjectId,
  viewOnly,
}: {
  isOpen: boolean;
  handleClose: (newIds?: string[]) => void;
  subjectId?: string;
  viewOnly?: boolean;
}) => {
  const subject = useRecoilValue(subjectAtomFamily(subjectId ?? ""));
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [description, setDescription] = useState("");
  const bg = useColorModeValue("white", "darkBg");

  useEffect(() => {
    if (subject) {
      setText(subject.subject_text);
      setTitle(subject.subject_name);
      setDescription(subject.subject_description);
    }
  }, [subject]);
  const { createCustomSubjects, updateSubject } = useOfferSubjectsApi();

  const saveSubject = useCallback(async () => {
    try {
      let newIds = [] as string[];
      if (subject && subject.user_id) {
        await updateSubject({
          id: subject.id,
          subject_description: description,
          subject_name: title,
          subject_text: text,
        });
      } else {
        newIds.push(
          ...(await createCustomSubjects({
            offer_subjects: [
              {
                subject_description: description,
                subject_name: title,
                subject_text: text,
              },
            ],
          }))
        );
      }

      setDescription("");
      setTitle("");
      setText("");
      handleClose(newIds);
    } catch (error) {
      console.error({ error });
    }
  }, [
    createCustomSubjects,
    description,
    handleClose,
    subject,
    text,
    title,
    updateSubject,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent bg={bg}>
        <ModalHeader>
          {viewOnly ? (
            "View Details"
          ) : (
            <>{subject ? "Edit" : "Create"} Contingency</>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2}>
            <Input
              bg={"input-bg"}
              value={title}
              placeholder="Title"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              required
              disabled={viewOnly}
            />
            <Textarea
              required
              bg={"input-bg"}
              minH={320}
              onChange={(e) => {
                setText(e.target.value);
              }}
              _disabled={{
                color: "chakra-body-text",
              }}
              value={text}
              disabled={viewOnly}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={() => handleClose()}>
            Close
          </Button>
          {!viewOnly && (
            <Button colorScheme="blue" onClick={saveSubject}>
              Save
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
