import {
  Heading,
  VStack,
  Flex,
  Button,
  Text,
  useColorModeValue,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Edit, Info, PlusSquare, Trash2 } from "react-feather";
import { useRecoilState, useRecoilValue } from "recoil";
import { useOfferSubjectsApi } from "../../../hooks/Offers";
import { selectUserSubjects } from "../../../recoil/offers/selectors";
import { useAddSubjectToOffer } from "../../../recoil/offers/transactions";
import { ICON_SIZE } from "../../../theme";
import { DrawerWithClose } from "../../common";
import { OHTooltip } from "../../common/OHTooltip";
import { SubjectEditModal, DefaultSubjects, DeleteSubjectModal } from ".";
import { contingencyDrawerOpenAtom } from "../../../recoil/offers/atoms";

export const ContingencyDrawer = () => {
  const [isOpen, setOpen] = useRecoilState(contingencyDrawerOpenAtom);
  const { loadUserSubjects } = useOfferSubjectsApi();
  const addToOffer = useAddSubjectToOffer();
  const [isCreating, setIsCreating] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [subjectId, setSubjectId] = useState("");
  const bg = useColorModeValue("lightBg", "darkBg");
  const itemBg = useColorModeValue("white.500", "blue.500");
  useEffect(() => {
    loadUserSubjects();
  }, [loadUserSubjects]);

  const userSubjects = useRecoilValue(selectUserSubjects);
  const handleClose = useCallback(
    (newSubjectIds?: string[]) => {
      newSubjectIds &&
        newSubjectIds.length &&
        newSubjectIds.forEach((s) => addToOffer(s));
      setSubjectId("");
      setIsCreating(false);
      setOpen(false);
    },
    [addToOffer, setOpen]
  );
  return (
    <DrawerWithClose handleClose={handleClose} isOpen={isOpen} bg={bg}>
      <Heading size="md" mb={2} my={4}>
        Contingencies
      </Heading>
      <DefaultSubjects />
      {!!userSubjects.length && (
        <>
          {" "}
          <Divider bg={"chakra-body-text"} h={0.9} my={2} />
          <Text my={2}>Custom</Text>
        </>
      )}
      <VStack>
        {userSubjects.map((s) => (
          <Flex
            key={s.id}
            bg={itemBg}
            rounded={"md"}
            w={"100%"}
            p={3}
            mt={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text>{s.subject_name}</Text>
            <HStack alignItems={"center"} spacing={2}>
              <Edit
                size={ICON_SIZE}
                onClick={() => {
                  setSubjectId(s.id);
                  setIsCreating(true);
                }}
                style={{
                  cursor: "pointer",
                }}
              />
              <PlusSquare
                onClick={() => addToOffer(s.id)}
                size={ICON_SIZE}
                style={{
                  cursor: "pointer",
                }}
              />
              {s.subject_description.length && (
                <OHTooltip
                  hasArrow
                  placement="top"
                  label={<p>{s.subject_description}</p>}
                >
                  <Info size={ICON_SIZE} />
                </OHTooltip>
              )}
              {s.user_id && (
                <Trash2
                  size={ICON_SIZE}
                  color="red"
                  onClick={() => {
                    setSubjectId(s.id);
                    setDeleteOpen(true);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
              )}
            </HStack>
          </Flex>
        ))}
      </VStack>
      <DeleteSubjectModal
        subject_id={subjectId}
        onClose={() => {
          setDeleteOpen(false);
          setSubjectId("");
        }}
        isOpen={deleteOpen}
      />
      <SubjectEditModal
        isOpen={isCreating}
        handleClose={handleClose}
        subjectId={subjectId}
      />
      <Button
        mt={6}
        mb={4}
        h={12}
        w={"full"}
        onClick={() => setIsCreating(true)}
        variant={"primary"}
      >
        Create New
      </Button>
    </DrawerWithClose>
  );
};
