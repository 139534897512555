import { Badge } from "@chakra-ui/react";

export const NumberBadge = ({ count }: { count: number }) => {
  if (!count) return null;
  return (
    <Badge bg="red.600" color={"white"} rounded={"full"} fontSize={14}>
      {count}
    </Badge>
  );
};
