import React from "react";
import { Button, Text, ButtonProps } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "../../hooks/utils/useWindowDimensions";
import { ChevronLeft } from "react-feather";

interface BackLinkButtonProps extends ButtonProps {
  navigateTo: string;
  label: string;
}

export const BackLinkButton: React.FC<BackLinkButtonProps> = ({
  navigateTo,
  label,
  ...buttonProps
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <>
      {!isMobile ? (
        <Button
          variant="link"
          onClick={() => navigate(navigateTo)}
          {...buttonProps}
        >
          <Text fontSize="md" fontWeight="normal">
            ←
          </Text>
          <Text fontSize="md" fontWeight="light">
            {label}
          </Text>
        </Button>
      ) : (
        <Button
          width={"min-content"}
          px={2}
          variant="ghost"
          onClick={() => navigate(navigateTo)}
          {...buttonProps}
        >
          <ChevronLeft size={25} />
        </Button>
      )}
    </>
  );
};
