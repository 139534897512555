import { selector, useRecoilValue } from "recoil";
import { userFamily, userIdListAtom } from "./atoms";
import { Listing, ListingStatus, PrivateUser } from "../../types";
import { userState } from "../atoms";
import {
  UserSort,
  listingFilterAtom,
  listingSortAtom,
  liveListingsOnlyAtom,
  userFilterAtom,
  userSortAtom,
} from "../admin";
import { listingFamily, listingIdsAtom } from "../listings";

export const selectAllUsers = selector({
  key: "selectAllUsers",
  get: ({ get }) => {
    const userIds = get(userIdListAtom);
    return (
      userIds
        .map((id) => get(userFamily(id)))
        .filter((user) => !!user) as PrivateUser[]
    ).sort((a, b) => {
      if (a.permissions?.includes("KYC_COMPLETE")) return 1;
      if (b.permissions?.includes("KYC_COMPLETE")) return -1;
      const aHasKYCPending = a.permissions?.includes("KYC_PENDING") ? -1 : 0;
      const bHasKYCPending = b.permissions?.includes("KYC_PENDING") ? -1 : 0;
      return aHasKYCPending - bHasKYCPending;
    }) as PrivateUser[];
  },
});

export const selectAdminUsers = selector({
  key: "selectAdminUsers",
  get: ({ get }) => {
    const all = get(selectAllUsers);
    const filter = get(userFilterAtom);
    const sorter = get(userSortAtom);
    return all
      .filter((u) =>
        (
          u.email?.toLowerCase() +
          u.first_name.toLowerCase() +
          " " +
          u.last_name.toLowerCase() +
          u.id
        ).includes(filter.toLowerCase())
      )
      .sort((a, b) => {
        switch (sorter) {
          case UserSort.createdAsc:
            return a.date_created > b.date_created ? 1 : -1;
          case UserSort.createdDesc:
            return a.date_created < b.date_created ? 1 : -1;
          default:
            return 0;
        }
      })
      .map(({ id }) => id);
  },
});

export const selectAdminListings = selector({
  key: "selectAdminListings",
  get: ({ get }) => {
    const allIds = get(listingIdsAtom);
    const filter = get(listingFilterAtom);
    const liveOnly = get(liveListingsOnlyAtom);

    const all = allIds
      .map((id) => get(listingFamily(id)))
      .filter((l) => !!l) as Listing[];
    const sorter = get(listingSortAtom);
    return all
      .filter(
        (l) =>
          `${l.address} ${l.city}
       ${l.country} ${l.state_province} 
       ${l.title} ${l.apt_no} ${l.year_built} ${l.seller_id}
          `
            .toLowerCase()
            .includes(filter.toLowerCase()) &&
          (liveOnly
            ? [
                ListingStatus.Active,
                ListingStatus.PreSale,
                ListingStatus.NewBuild,
              ].includes(l.listing_status)
            : true)
      )
      .sort((a, b) => {
        switch (sorter) {
          case UserSort.createdAsc:
            return a.date_created > b.date_created ? 1 : -1;
          case UserSort.createdDesc:
            return a.date_created < b.date_created ? 1 : -1;
          default:
            return 0;
        }
      })
      .map(({ id }) => id);
  },
});

export const selectListingStats = selector({
  key: "selectListingStats",
  get: ({ get }) => {
    const allIds = get(listingIdsAtom);
    const filter = get(listingFilterAtom);
    const liveOnly = get(liveListingsOnlyAtom);

    const all = allIds
      .map((id) => get(listingFamily(id)))
      .filter((l) => !!l) as Listing[];
    return all
      .filter(
        (l) =>
          `${l.address} ${l.city}
       ${l.country} ${l.state_province} 
       ${l.title} ${l.apt_no} ${l.year_built} ${l.seller_id}
          `
            .toLowerCase()
            .includes(filter.toLowerCase()) &&
          (liveOnly
            ? [
                ListingStatus.Active,
                ListingStatus.PreSale,
                ListingStatus.NewBuild,
              ].includes(l.listing_status)
            : true)
      )
      .reduce(
        (prev, curr) => {
          prev.totalValue += Number(curr.price);
          return prev;
        },
        { totalValue: 0 }
      );
  },
});

export const useAdminState = () => {
  const user = useRecoilValue(userState);
  if (!user) return false;
  return user.permissions && user.permissions.includes("ADMIN_ACCESS");
};

export const useUserKycd = () => {
  const user = useRecoilValue(userState);
  if (!user) return false;
  return user.permissions && user.permissions.includes("KYC_COMPLETE");
};

export const useKycPending = () => {
  const user = useRecoilValue(userState);
  if (!user) return false;
  return user.permissions && user.permissions.includes("KYC_PENDING");
};
