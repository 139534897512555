import { useCallback } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { Permission, SignupForm, User } from "../types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useCustomToast } from "./useCustomToast";
import { selectAxiosInstance } from "../recoil/selectors";
import { userState } from "../recoil/atoms";

const useAuthApi = () => {
  const { success, fail } = useCustomToast();
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const setUser = useSetRecoilState(userState);

  const createUser = useCallback(
    async (user: SignupForm): Promise<User> => {
      try {
        const response: AxiosResponse<User> = await axiosInstance.post(
          "/auth/signup",
          user
        );
        return response.data;
      } catch (error: any) {
        throw new Error(`Couldn't create user: ${error.message}`);
      }
    },
    [axiosInstance]
  );

  const getMyUser = useCallback(async () => {
    try {
      const {
        data: { user },
      }: AxiosResponse<{ user: User }> = await axiosInstance.get("/user");
      setUser((u) => (!u ? null : { ...u, tier: user.tier }));
    } catch (error: any) {
      throw new Error(`Couldn't get user: ${error.message}`);
    }
  }, [axiosInstance, setUser]);

  const login = useCallback(
    async (email: string, password: string): Promise<boolean> => {
      try {
        const response: AxiosResponse<User> = await axiosInstance.post(
          "/auth/login",
          { email, password }
        );
        const { token, id, first_name, last_name, permissions, tier } =
          response.data;

        if (token) {
          setUser({
            email,
            token: `Bearer ${token}`,
            id,
            tier,
            first_name,
            last_name,
            permissions,
          });
          success({ title: "Login Successful" });
        }
        return true;
      } catch (error: any) {
        fail({
          title: "Login failed",
          description: (error as AxiosError).message,
        });
        return false;
      }
    },
    [axiosInstance, fail, setUser, success]
  );

  const verifyUser = useCallback(
    async (verification_code: string): Promise<boolean> => {
      try {
        const response = await axiosInstance.post(`/auth/verify`, {
          verification_code,
        });
        success({ title: "verified" });
        return response.status === 200;
      } catch (e: any) {
        fail({ title: "Couldn't verify", description: e.message });
        return false;
      }
    },
    [axiosInstance, fail, success]
  );

  const updateUser = useCallback(
    async (id: number, user: User): Promise<User | null> => {
      try {
        const response: AxiosResponse<User> = await axiosInstance.put(
          `/users/${id}`,
          user
        );
        return response.data;
      } catch (error: any) {
        fail({ title: "error" });
        return null;
      }
    },
    [axiosInstance, fail]
  );

  const resetPassword = useCallback(
    async (verification_code: string, password: string) => {
      try {
        const response: AxiosResponse<boolean> = await axiosInstance.post(
          `/auth/reset`,
          { verification_code, password }
        );
        return response.data;
      } catch (error: any) {
        fail({ title: "error" });
      }
    },
    [axiosInstance, fail]
  );
  const updatePassword = useCallback(
    async (old_password: string, password: string) => {
      try {
        const response: AxiosResponse<boolean> = await axiosInstance.post(
          `/auth/change-pw`,
          { old_password, password }
        );
        return response.data;
      } catch (error: any) {
        fail({ title: "error" });
      }
    },
    [axiosInstance, fail]
  );

  const resetPasswordRequest = useCallback(
    async (email: string) => {
      try {
        const response: AxiosResponse<boolean> = await axiosInstance.post(
          `/auth/reset/request`,
          { email }
        );
        return response.data;
      } catch (error: any) {
        fail({ title: "error" });
      }
    },
    [axiosInstance, fail]
  );

  const tokenLogin = useCallback(
    async (login_token: string): Promise<boolean> => {
      try {
        const response: AxiosResponse<{
          token: string;
          id: string;
          email: string;
          first_name: string;
          tier: number;
          last_name: string;
          permissions: Permission[];
        }> = await axiosInstance.post("/auth/login/token", {
          token: login_token,
        });
        const { token, id, email, first_name, last_name, permissions, tier } =
          response.data;

        if (response.status === 200 && response.data.token) {
          setUser({
            email,
            token: `Bearer ${token}`,
            id,
            first_name,
            last_name,
            permissions,
            tier,
          });
          success({ title: "Login Successful" });
        }
        return true;
      } catch (error: any) {
        fail({
          title: "Login failed",
          description: (error as AxiosError).message,
        });
        return false;
      }
    },
    [axiosInstance, fail, setUser, success]
  );

  return {
    createUser,
    login,
    updateUser,
    getMyUser,
    verifyUser,
    resetPassword,
    resetPasswordRequest,
    updatePassword,
    tokenLogin,
  };
};

export default useAuthApi;
