import { useRecoilTransaction_UNSTABLE } from "recoil";
import {
  listingScheduleAtomFamily,
  viewingRequestAtomFamily,
  viewingRequestsForBuyerAtom,
} from "./atoms";
import { RequestStatus, ViewingRequest, ViewingSchedule } from "../../types";

export const useCreateSchedule = () =>
  useRecoilTransaction_UNSTABLE<[ViewingSchedule, string]>(
    ({ set }) =>
      (newSchedule, listingId) => {
        set(listingScheduleAtomFamily(listingId), (prevListingSchedule) => {
          if (!prevListingSchedule) {
            return {
              availabilities: [newSchedule],
              bookings: [],
            };
          }
          return {
            ...prevListingSchedule,
            availabilities: [
              ...prevListingSchedule.availabilities,
              newSchedule,
            ],
          };
        });
      }
  );

export const useUpdateSchedule = () =>
  useRecoilTransaction_UNSTABLE<[ViewingSchedule, string]>(
    ({ set }) =>
      (updatedSchedule, listingId) => {
        set(listingScheduleAtomFamily(listingId), (prevListingSchedule) => {
          if (!prevListingSchedule)
            return {
              availabilities: [],
              bookings: [],
            };
          // Map over the availabilities and update the matching schedule
          const updatedAvailabilities = prevListingSchedule.availabilities.map(
            (schedule) =>
              schedule.id === updatedSchedule.id ? updatedSchedule : schedule
          );
          return {
            ...prevListingSchedule,
            availabilities: updatedAvailabilities,
          };
        });
      }
  );
export const useDeleteSchedule = () =>
  useRecoilTransaction_UNSTABLE<[string, string]>(
    ({ set }) =>
      (viewingScheduleId, listingId) => {
        set(listingScheduleAtomFamily(listingId), (prevListingSchedule) => {
          if (!prevListingSchedule)
            return {
              availabilities: [],
              bookings: [],
            }; // If there's no previous listing schedule, return empty

          // Filter out the deleted viewing schedule
          const updatedAvailabilities =
            prevListingSchedule.availabilities.filter(
              (s) => s.id !== viewingScheduleId
            );
          return {
            ...prevListingSchedule,
            availabilities: updatedAvailabilities,
          };
        });
      },
    []
  );

export const useAcceptRequest = () =>
  useRecoilTransaction_UNSTABLE<[string]>(
    ({ set, get }) =>
      (requestId) => {
        const req = get(viewingRequestAtomFamily(requestId));
        if (!req) return;
        const acceptedRequest = {
          ...req,
          request_status: RequestStatus.Accepted,
        };
        set(viewingRequestAtomFamily(requestId), acceptedRequest);
        set(
          listingScheduleAtomFamily(req.listing_id),
          (prevListingSchedule) => {
            if (!prevListingSchedule)
              return {
                availabilities: [],
                bookings: [],
              };

            const newBookigs = [
              ...prevListingSchedule.bookings,
              acceptedRequest,
            ];
            return {
              ...prevListingSchedule,
              bookings: newBookigs,
            };
          }
        );
      },
    []
  );

export const useRejectRequest = () =>
  useRecoilTransaction_UNSTABLE<[string]>(
    ({ set, get }) =>
      (requestId) => {
        const req = get(viewingRequestAtomFamily(requestId));
        if (!req) return;
        set(viewingRequestAtomFamily(requestId), {
          ...req,
          request_status: RequestStatus.Rejected,
        });
      },
    []
  );

export const useDeleteRequest = () =>
  useRecoilTransaction_UNSTABLE<[string, string?]>(
    ({ set, get }) =>
      (requestId, listingId) => {
        try {
          const req = get(viewingRequestAtomFamily(requestId));
          if (!req) return;
          set(viewingRequestAtomFamily(requestId), {
            ...req,
            request_status: RequestStatus.Deleted,
          });

          if (listingId) {
            set(
              listingScheduleAtomFamily(req.listing_id),
              (prevListingSchedule) => {
                if (!prevListingSchedule)
                  return {
                    availabilities: [],
                    bookings: [],
                  };
                const updatedBookings = prevListingSchedule.bookings.filter(
                  (s) => s.id !== requestId
                );
                return {
                  ...prevListingSchedule,
                  bookings: updatedBookings,
                };
              }
            );
          }
        } catch (error) {
          console.error(`Failed to delete request: ${error}`);
        }
      },
    []
  );

export const useUpdateViewingRequest = () =>
  useRecoilTransaction_UNSTABLE<[ViewingRequest]>(
    ({ set, get }) =>
      (viewingRequest) => {
        const requestIds = get(viewingRequestsForBuyerAtom);
        const updatedRequestIds = [...requestIds, viewingRequest.id];
        set(viewingRequestsForBuyerAtom, updatedRequestIds);
        set(viewingRequestAtomFamily(viewingRequest.id), {
          ...viewingRequest,
        });
      },
    []
  );
