import React, { useRef, useState, useEffect } from "react";
import { Input, InputGroup, InputLeftAddon, Text } from "@chakra-ui/react";

export const PriceInput = ({
  price,
  setPrice,
  placeholder,
  disabled,
  isRequired,
}: {
  price: string;
  setPrice: (p: string) => void;
  placeholder?: string;
  disabled?: boolean;
  isRequired?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);

  const countCommas = (str: string, index: number) => {
    return str
      .substring(0, index)
      .split("")
      .filter((char) => char === ",").length;
  };

  const formatPrice = (value: string): string => {
    const parts = value.split(".");
    parts[0] = parts[0]
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (parts[1] && parts[1].length > 2) parts[1] = parts[1].substring(0, 2);
    return parts.slice(0, 2).join(".");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { selectionStart } = event.target;
    let inputValue = event.target.value.replace(/,/g, ""); // Remove existing commas for accurate index
    const originalCommasBeforeCursor = countCommas(price, selectionStart || 0);

    inputValue = formatPrice(inputValue);
    setPrice(inputValue);

    if (selectionStart !== null) {
      const newCommasBeforeCursor = countCommas(
        inputValue,
        selectionStart + originalCommasBeforeCursor
      );
      setCursorPosition(
        selectionStart + newCommasBeforeCursor - originalCommasBeforeCursor
      );
    }
  };

  useEffect(() => {
    if (cursorPosition !== null && inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition, price]);

  return (
    <InputGroup>
      <InputLeftAddon children={<Text fontWeight={600}>$ CAD</Text>} />
      <Input
        isRequired={isRequired}
        ref={inputRef}
        disabled={disabled}
        type="text"
        placeholder={placeholder}
        value={price}
        onChange={handleChange}
        background={"input-bg"}
        _disabled={{
          background: "input-bg",
        }}
      />
    </InputGroup>
  );
};
