import { useMemo } from "react";
import { Box, Button, Flex, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { CheckCircle, Plus } from "react-feather";
import { ICON_SIZE } from "../../theme";
import { Product, ProductDict } from "../../types/services";
import { useCart } from "../../hooks/Stripe/useCart";

export const ProductSelection = ({ sqftTier }: { sqftTier: 0 | 1 | 2 }) => {
  const { cart, addToCart } = useCart();

  const sized = useMemo(() => {
    switch (sqftTier) {
      case 0:
        return [
          Product.PhotographySmall,
          Product.VideoSmall,
          Product.FloorPlanSmall,
          Product.MatterportSmall,
        ];
      case 1:
        return [
          Product.PhotographyMedium,
          Product.VideoMedium,
          Product.FloorPlanMedium,
          Product.MatterportMedium,
        ];
      case 2:
        return [
          Product.PhotographyLarge,
          Product.VideoLarge,
          Product.FloorPlanLarge,
          Product.MatterportLarge,
        ];
    }
  }, [sqftTier]);
  const products = [
    ...sized,
    Product.VirtualStaging3,
    Product.VirtualStaging5,
    Product.VirtualStaging10,
    Product.VirtualStaging20,
  ];
  return (
    <VStack>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing="4">
        {products.map((item) => (
          <Flex
            key={item}
            align="center"
            justify="space-between"
            p="4"
            borderWidth="1px"
            borderRadius="lg"
          >
            <Box>
              <Text mr={4}>{ProductDict[item].description}</Text>
              <Text mr={4} fontSize={14}>
                ${ProductDict[item].price.toFixed(2)}
              </Text>
            </Box>

            <Button
              variant={"primary"}
              onClick={() => addToCart(item)}
              isDisabled={cart.includes(item)}
            >
              {!cart.includes(item) ? (
                <Plus size={ICON_SIZE} />
              ) : (
                <CheckCircle size={ICON_SIZE} />
              )}
            </Button>
          </Flex>
        ))}
      </SimpleGrid>
    </VStack>
  );
};
