import {
  Box,
  Button,
  HStack,
  Input,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import SignatureCanvas from "react-signature-canvas";
import React, { useRef, useEffect, useCallback, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";

export const SignaturePad = ({
  onFileReady,
}: {
  onFileReady: (url: string) => void;
}) => {
  const sigCanvasRef = useRef<ReactSignatureCanvas>(null);
  const typeRef = useRef<HTMLInputElement>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [typedText, setTypedText] = useState("");

  useEffect(() => {
    const sigCanvas = sigCanvasRef.current;
    const clearCanvas = () => {
      if (sigCanvas) {
        sigCanvas.clear();
      }
    };
    clearCanvas();
  }, []);

  const onClear = useCallback(() => {
    const sigCanvas = sigCanvasRef.current;
    sigCanvas && sigCanvas.clear();
  }, []);

  useEffect(() => {
    if (tabIndex === 1 && typeRef.current) {
      typeRef.current.focus();
    }
  }, [tabIndex]);

  const onSaveSignature = useCallback(() => {
    if (tabIndex === 0) {
      const sigCanvas = sigCanvasRef.current;
      if (sigCanvas) {
        const fileUrl = sigCanvas.toDataURL();
        onFileReady(fileUrl);
      }
    } else {
      const canvas = document.createElement("canvas");
      canvas.width = 50 + typedText.length * 28;
      canvas.height = 90;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.font = "42px Cedarville Cursive";
        ctx.fillText(typedText, 10, 60);
        const fileUrl = canvas.toDataURL();
        onFileReady(fileUrl);
      }
    }
  }, [onFileReady, tabIndex, typedText]);

  return (
    <Box w={"100%"} border={"1px"} borderRadius={"2xl"} p={3} bg={"input-bg"}>
      <Tabs isFitted onChange={(index) => setTabIndex(index)}>
        <TabList mb="1em">
          <Tab>Draw</Tab>
          <Tab>Type</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <SignatureCanvas
              ref={sigCanvasRef}
              canvasProps={{ width: 300, height: 200 }}
            />
          </TabPanel>
          <TabPanel>
            <Input
              w={"full"}
              h={90}
              fontSize={30}
              ref={typeRef}
              value={typedText}
              onChange={(e) => setTypedText(e.target.value)}
              fontFamily={"Cedarville Cursive"}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <HStack>
        <Button variant={"secondary"} onClick={onClear}>
          Clear Signature
        </Button>
        <Spacer />
        <Button variant={"secondary"} onClick={onSaveSignature}>
          Save
        </Button>
      </HStack>
    </Box>
  );
};
