import { atom, selector } from "recoil";

export const perchDataAtom = atom<
  {
    amortizationYears: number;
    maxLTV: number;
    minPrincipal: number;
    mortgageRate: number;
    rateType: string;
    termYears: number;
  }[]
>({
  key: "perchDataAtom",
  default: [],
});

export const selectBestRate = selector({
  key: "selectBestRate",
  get: ({ get }) => {
    const data = get(perchDataAtom);

    if (data.length) {
      const bestRate = data.reduce((prev, curr) => {
        return prev.mortgageRate < curr.mortgageRate ? prev : curr;
      });
      return bestRate.mortgageRate;
    }
    return null;
  },
});
