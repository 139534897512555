import { useRecoilCallback, useRecoilValue } from "recoil";
import {
  listingFamily,
  listingIdsAtom,
  userListingsAtom,
  activeUserListingIdsAtom,
} from "../../recoil/listings";
import { latestMessagesAtom } from "../../recoil/messages/atoms";
import { selectAxiosInstance } from "../../recoil/selectors";
import { Listing, ListingStatus } from "../../types";

export const useLoadListings = () => {
  const axiosInstance = useRecoilValue(selectAxiosInstance);

  const loadListings = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const response = await axiosInstance.get<Listing[]>("/listings");
          let listingIds = [] as string[];
          response.data.forEach((listing) => {
            listingIds.push(listing.id);
            set(listingFamily(listing.id), listing);
          });
          set(listingIdsAtom, listingIds);
        } catch (error) {
          console.error("Error fetching listings:", error);
        }
      },
    []
  );

  const loadInboxListings = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const recentMessages = snapshot
          .getLoadable(latestMessagesAtom)
          .getValue();
        const notLoaded = [] as string[];
        for (const { listing_id } of recentMessages) {
          const listing = snapshot
            .getLoadable(listingFamily(listing_id))
            .getValue();
          if (!listing) notLoaded.push(listing_id);
        }
        try {
          await Promise.all(notLoaded.map((id) => loadListing(id)));
        } catch (error) {
          console.error("Error fetching listings:", error);
        }
      },
    []
  );

  const loadListing = useRecoilCallback(
    ({ set }) =>
      async (id: string) => {
        try {
          const { data: listing } = await axiosInstance.get<Listing>(
            `/listings/id/${id}`
          );
          set(listingFamily(listing.id), listing);
          return listing;
        } catch (error) {
          console.error("Error fetching listings:", error);
        }
      },
    []
  );

  const loadUserListings = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const response = await axiosInstance.get<Listing[]>(`/listings/user`);
          let activeUserListingIds = [] as string[];
          const userListingIds = response.data.map((listing) => {
            set(listingFamily(listing.id), listing);
            if (
              [
                ListingStatus.Active,
                ListingStatus.PreSale,
                ListingStatus.NewBuild,
              ].includes(listing.listing_status)
            ) {
              activeUserListingIds.push(listing.id);
            }
            return listing.id;
          });
          set(userListingsAtom, userListingIds);
          set(activeUserListingIdsAtom, activeUserListingIds);
        } catch (error) {
          console.error("Error fetching listings:", error);
        }
      },
    []
  );
  return { loadListing, loadListings, loadUserListings, loadInboxListings };
};
