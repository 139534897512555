import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useCustomToast } from "../hooks/useCustomToast";
import { useCallback, useState } from "react";
import useAuthApi from "../hooks/useAuthApi";

export const ForgotPasswordModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { success } = useCustomToast();
  const { resetPasswordRequest } = useAuthApi();
  const [email, setEmail] = useState("");
  const handleSend = useCallback(async () => {
    resetPasswordRequest(email);
  }, [email, resetPasswordRequest]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={"container-bg"}>
        <ModalHeader>Reset password</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="lemail" isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              border={"1px solid lightgray"}
              backgroundColor={"input-bg"}
              type="email"
              name="email"
              autoComplete="on"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Button
            my={3}
            variant={"primary"}
            w={"full"}
            onClick={async () => {
              await handleSend();
              success({
                title:
                  "If this user exists, an email will be sent with reset instructions",
                duration: 5000,
              });
              onClose();
            }}
          >
            Send Reset Link
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
