import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import { selectAxiosInstance } from "../../recoil/selectors";
import { useCustomToast } from "../useCustomToast";
import { useCallback } from "react";
import { useAddOfferSubject } from "../../recoil/messages/transactions";
import { Subject } from "../../types";
import {
  allSubjectIdsAtom,
  subjectAtomFamily,
} from "../../recoil/offers/atoms";
import uniq from "lodash/uniq";
import { AxiosResponse } from "axios";
import { useDeleteSubject } from "../../recoil/offers/transactions";
import { userState } from "../../recoil/atoms";

export type SubjectInput = Omit<Subject, "id" | "date_created" | "editable">;
export type UpdateSubjectInput = Omit<Subject, "editable" | "date_created">;
export interface CreateOfferSubjectsRequest {
  offer_subjects: SubjectInput[];
}

export interface OfferSubjectsBridgeInput {
  offer_subject_id: string;
  order_index: number;
}

export const useOfferSubjectsApi = () => {
  const { fail, success } = useCustomToast();
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const addSubject = useAddOfferSubject();
  const deleteSubjectFromState = useDeleteSubject();
  const [allSubjectIds, setAllSubjectIds] = useRecoilState(allSubjectIdsAtom);
  const user = useRecoilValue(userState);

  const updateSubject = useRecoilCallback(
    ({ set }) =>
      async ({
        id,
        subject_description,
        subject_name,
        subject_text,
      }: UpdateSubjectInput) => {
        try {
          const {
            data: { updated },
          }: AxiosResponse<{ updated: Subject }> = await axiosInstance.post(
            `/offer-subjects/update/custom/${id}`,
            {
              id,
              subject_name,
              subject_description,
              subject_text,
            }
          );

          if (updated) {
            set(subjectAtomFamily(updated.id), updated);
          }
        } catch (error) {
          console.error(`Couldn't update subjects: ${error}`);
        }
      },
    [axiosInstance]
  );
  const createCustomSubjects = useCallback(
    async (data: CreateOfferSubjectsRequest): Promise<string[]> => {
      if (!user) return [];
      try {
        const {
          data: { offer_subjects, message },
        } = await axiosInstance.post(`/offer-subjects/create/custom`, data);
        const newIds = data.offer_subjects.map((s, i) => {
          const id = offer_subjects[i].id;
          addSubject({
            ...s,
            id,
            date_created: new Date(),
            editable: true,
            user_id: user.id,
          } as Subject);
          return id;
        });
        success({ title: message });
        setAllSubjectIds(uniq([...allSubjectIds, ...newIds]));
        return newIds;
      } catch (error: any) {
        fail({
          title: "Couldn't create custom contingency",
        });
        throw new Error(`Couldn't create custom contingency: ${error.message}`);
      }
    },
    [
      addSubject,
      allSubjectIds,
      axiosInstance,
      fail,
      setAllSubjectIds,
      success,
      user,
    ]
  );

  const loadUserSubjects = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const { data: subjects }: AxiosResponse<Subject[]> =
            await axiosInstance.get(`/offer-subjects/all`);

          if (subjects) {
            const ids = subjects.map((s) => {
              set(subjectAtomFamily(s.id), s);
              return s.id;
            });
            set(allSubjectIdsAtom, ids);
          }
        } catch (error) {
          console.error(`Couldn't fetch user subjects: ${error}`);
        }
      },
    [axiosInstance]
  );

  const deleteSubject = useCallback(
    async (subject_id: string) => {
      try {
        const success: AxiosResponse<boolean> = await axiosInstance.delete(
          `/offer-subjects/${subject_id}`
        );
        if (success) {
          deleteSubjectFromState(subject_id);
        }
      } catch (error) {
        console.error(`Couldn't fetch user subjects: ${error}`);
      }
    },
    [axiosInstance, deleteSubjectFromState]
  );

  return {
    createCustomSubjects,
    loadUserSubjects,
    updateSubject,
    deleteSubject,
  };
};
