import {
  TableContainer,
  Box,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Spinner,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import useViewingsApi from "../../hooks/useViewingsApi";
import { useEffect, useState } from "react";
import { listingScheduleAtomFamily } from "../../recoil/viewings/atoms";
import { EditViewingScheduleRow } from "./EditViewingsScheduleRow";

export const EditViewingsTable = ({
  listingId,
  onEdit,
}: {
  listingId: string;
  onEdit: (viewingScheduleId: string) => void;
}) => {
  const { getListingSchedule, deleteViewingSchedule } = useViewingsApi();
  const viewingSchedule = useRecoilValue(listingScheduleAtomFamily(listingId)); // Retrieve the viewing schedule from Recoil
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getListingSchedule(listingId).finally(() => {
      setLoading(false);
    });
  }, [getListingSchedule, listingId]);

  return (
    <Box>
      {loading ? (
        <Spinner />
      ) : viewingSchedule.availabilities.length > 0 ? (
        <TableContainer marginBottom={8}>
          <Table variant="simple">
            <Thead maxWidth={"100%"}>
              <Tr>
                <Th>Type</Th>
                <Th>Time</Th>
                <Th>Date</Th>
                <Th>Recurring</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {viewingSchedule.availabilities.map((schedule) => (
                <EditViewingScheduleRow
                  key={schedule.id}
                  schedule={schedule}
                  deleteViewingSchedule={deleteViewingSchedule}
                  onEdit={onEdit}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Box>No viewing schedule set</Box>
      )}
    </Box>
  );
};
