import { AxiosResponse } from "axios";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { useCustomToast } from "./useCustomToast";
import { selectAxiosInstance } from "../recoil/selectors";
import { RentalDetails } from "../types/rentals";
import { listingFamily } from "../recoil/listings";
import { Listing } from "../types";
import {
  applicationOpenAtom,
  rentalDetailsFamily,
  rentalInputForm,
  rentalListingIdsAtom,
} from "../recoil/rentals";

export const useRentalApi = () => {
  const { success, fail } = useCustomToast();
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const rentalDetails = useRecoilValue(rentalInputForm);

  const openApplicationDrawer = useRecoilCallback(
    ({ set }) =>
      async () => {
        set(applicationOpenAtom, true);
      },
    []
  );

  const closeApplicationDrawer = useRecoilCallback(
    ({ set }) =>
      async () => {
        set(applicationOpenAtom, true);
      },
    []
  );

  const loadRentals = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const response = await axiosInstance.get<{
            listings: Listing[];
            details: RentalDetails[];
          }>("/rentals");
          let listingIds = [] as string[];
          const { listings, details } = response.data;
          listings.forEach((listing) => {
            listingIds.push(listing.id);
            set(listingFamily(listing.id), listing);
          });

          details.forEach((d) => {
            set(rentalDetailsFamily(d.listing_id), {
              ...d,
              preferred_start_date: new Date(d.preferred_start_date),
              end_date: d.end_date ? new Date(d.end_date) : undefined,
            });
          });
          set(rentalListingIdsAtom, listingIds);
        } catch (error) {
          console.error("Error fetching listings:", error);
        }
      },
    []
  );

  const saveRentalDetails = useRecoilCallback(
    ({ snapshot, set }) =>
      async (): Promise<any> => {
        try {
          const existing = snapshot
            .getLoadable(rentalDetailsFamily(rentalDetails.listing_id))
            .getValue();
          if (existing) {
            const response: AxiosResponse<RentalDetails> =
              await axiosInstance.put("/rentals", {
                rentalDetails: {
                  ...rentalDetails,
                  preferred_start_date: new Date(
                    rentalDetails.preferred_start_date
                  ),
                  end_date: rentalDetails.end_date
                    ? new Date(rentalDetails.end_date)
                    : undefined,
                },
              });
            set(rentalDetailsFamily(rentalDetails.listing_id), response.data);
          } else {
            const response: AxiosResponse<RentalDetails> =
              await axiosInstance.post("/rentals", rentalDetails);
            set(rentalDetailsFamily(rentalDetails.listing_id), response.data);
          }
        } catch (error: any) {
          fail({
            title: "Couldn't upload details",
          });
        }
      },
    [axiosInstance, fail, success]
  );

  return {
    saveRentalDetails,
    loadRentals,
    openApplicationDrawer,
    closeApplicationDrawer,
  };
};
