import React, { useEffect } from "react";

const CloseTab: React.FC = () => {
  useEffect(() => {
    window.close();
  }, []);

  return <div>Closing tab...</div>;
};

export default CloseTab;
