import { atom, atomFamily } from "recoil";
import { PrivateUser } from "../../types";

export const userFamily = atomFamily<PrivateUser | null, string>({
  key: "userFamily",
  default: null,
});

export const userIdListAtom = atom<string[]>({
  key: "userIdListAtom",
  default: [],
});

export const kycFilesAtom = atom<string[]>({
  key: "kycFilesAtom",
  default: [],
});

export const notificationSettingsAtom = atom<NotificationPreferences>({
  key: "notificationSettingsAtom",
  default: {
    inbound_viewing_request: true,
    viewing_request_accepted: true,
    offer_received: true,
    offer_countered: true,
    offer_accepted: true,
  },
});

export type NotificationPreferences = {
  inbound_viewing_request: boolean;
  viewing_request_accepted: boolean;
  offer_received: boolean;
  offer_countered: boolean;
  offer_accepted: boolean;
};
