import { useRecoilValue, useSetRecoilState } from "recoil";
import { useOffersApi } from "../../hooks/Offers";
import { DrawerWithClose } from "../common";
import { selectDraftOffers } from "../../recoil/offers/selectors";
import { selectedListingAtom } from "../../recoil/listings";
import { Flex, VStack, Text, Button, HStack } from "@chakra-ui/react";
import { currency, formatPrice } from "../../utils/formatters";
import {
  newOfferDrawerOpenAtom,
  selectedOfferAtom,
} from "../../recoil/offers/atoms";
import { timeDifferenceFromNow } from "../../utils/date";
import { Trash2 } from "react-feather";
import { ICON_SIZE } from "../../theme";

export const OfferDraftsDrawer = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  return (
    <DrawerWithClose isOpen={isOpen} handleClose={handleClose}>
      <OfferDrafts />
    </DrawerWithClose>
  );
};

export const OfferDrafts = () => {
  const listingId = useRecoilValue(selectedListingAtom);
  const draftOffers = useRecoilValue(
    selectDraftOffers({ listingId: listingId ?? "" })
  );
  const setDrawer = useSetRecoilState(newOfferDrawerOpenAtom);
  const { deleteDraft } = useOffersApi();
  const setSelectedOffer = useSetRecoilState(selectedOfferAtom);
  const onEdit = (id: string) => {
    setSelectedOffer(id);
    setDrawer(true);
  };

  return (
    <>
      <VStack
        textAlign={"left"}
        justifyItems={"flex-start"}
        w={"full"}
        maxH={240}
        borderY={draftOffers.length ? "1px" : ""}
        overflowY={"scroll"}
        px={1}
        py={4}
        mt={2}
      >
        {draftOffers.map((offer) => {
          return (
            <Flex
              w={"100%"}
              direction={"row"}
              justifyContent={"space-between"}
              outline={"1px solid blue"}
              rounded={"3xl"}
              alignItems={"center"}
              cursor={"pointer"}
              p={3}
              _hover={{
                bg: "blue",
                color: "white",
              }}
              bg={"input-bg"}
              key={offer.id}
              onClick={() => onEdit(offer.id)}
            >
              <Text fontWeight={"semibold"}>
                {currency(offer.offer_price_currency)}&nbsp;
                {formatPrice(offer.offer_price)}
              </Text>
              <HStack spacing={1}>
                <Text fontSize={"sm"} fontStyle={"italic"}>
                  {offer.date_updated
                    ? timeDifferenceFromNow(new Date(offer.date_updated))
                    : offer.date_created
                    ? timeDifferenceFromNow(new Date(offer.date_created))
                    : ""}
                </Text>

                <Button
                  variant={"text"}
                  p={0}
                  cursor={"grabbing"}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteDraft(offer.id);
                  }}
                >
                  <Trash2 color="red" size={ICON_SIZE} />
                </Button>
              </HStack>
            </Flex>
          );
        })}
        {!draftOffers.length && (
          <Text fontStyle={"italic"}>No Counter-Offers</Text>
        )}
      </VStack>
    </>
  );
};
