import {
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  Tooltip,
  SliderThumb,
} from "@chakra-ui/react";
import React, { useState } from "react";

export const PercentSlider = ({
  fullPrice,
  recommendedPercent,
  disabled,
  value,
  onChange,
}: {
  fullPrice: number;
  recommendedPercent?: number;
  value: number;
  onChange: (n: number) => void;
  disabled?: boolean;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <Slider
      id="slider"
      min={0}
      max={100}
      isDisabled={disabled}
      colorScheme="blue"
      value={value}
      onChange={onChange}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <SliderMark
        value={value}
        my="4"
        defaultValue={recommendedPercent}
        ml="-2.5"
        fontSize="md"
        color={"slider-mark-percent"}
      >
        {value}%
      </SliderMark>
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <Tooltip
        hasArrow
        bg="blue.200"
        placement={value < 70 ? "right" : "left"}
        isOpen={showTooltip}
        label={`${value}% ($${(fullPrice * (value / 100)).toFixed(2)})`}
      >
        <SliderThumb />
      </Tooltip>
    </Slider>
  );
};
