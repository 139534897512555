import axios from "axios";
import { useCallback } from "react";
import { useRecoilValue, useRecoilCallback } from "recoil";
import { offerAtomFamily, subjectAtomFamily } from "../../recoil/offers/atoms";
import {
  useUpdateOffers,
  useUpdateOffer,
  useSetOfferStatus,
  useAddOfferSubject,
  useDeleteOffer,
} from "../../recoil/messages/transactions";
import { selectAxiosInstance } from "../../recoil/selectors";
import {
  OfferDraft,
  FormattedFile,
  Offer,
  OfferStatus,
  Subject,
  ListingStatus,
} from "../../types";
import { decrypt } from "../../utils/crypto";
import { useCustomToast } from "../useCustomToast";
import { useResetDraft } from "../../recoil/offers/transactions";
import { useOfferSubjectsApi } from "./useOfferSubjectsApi";
import { selectOfferSubjects } from "../../recoil/offers/selectors";
import { useLoadListings } from "../Listings";
import { userState } from "../../recoil/atoms";

export const useOffersApi = () => {
  const { fail, success } = useCustomToast();
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const updateOffers = useUpdateOffers();
  const updateOffer = useUpdateOffer();
  const deleteOffer = useDeleteOffer();
  const resetDraft = useResetDraft();
  const user = useRecoilValue(userState);
  const addSubject = useAddOfferSubject();
  const { loadListing } = useLoadListings();
  const setOfferStatus = useSetOfferStatus();
  const { createCustomSubjects } = useOfferSubjectsApi();

  const loadOffer = useRecoilCallback(
    ({ set }) =>
      async (id: string) => {
        try {
          const {
            data: { offer, subjects },
          } = await axiosInstance.get<{ offer: Offer; subjects: Subject[] }>(
            `/offers/id/${id}`
          );
          subjects.forEach((s) => set(subjectAtomFamily(s.id), s));
          updateOffer(offer);
        } catch (error) {
          console.error("Error fetching offer:", error);
        }
      },
    []
  );

  const loadOffers = useCallback(async () => {
    try {
      const {
        data: { offers, subjects },
      } = await axiosInstance.get<{ offers: Offer[]; subjects: Subject[] }>(
        `/offers/user`
      );
      subjects.forEach((s) => addSubject(s));
      updateOffers(offers);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  }, [addSubject, axiosInstance, updateOffers]);

  const loadOffersForUser = useCallback(async () => {
    try {
      if (!user || !user?.token) return;
      const {
        data: { offers, subjects },
      } = await axiosInstance.get<{ offers: Offer[]; subjects: Subject[] }>(
        `/offers/forUser`
      );
      subjects.forEach((s) => addSubject(s));
      updateOffers(offers);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  }, [addSubject, axiosInstance, updateOffers, user]);

  const saveDraft = useCallback(
    async (data: OfferDraft): Promise<string> => {
      try {
        const {
          data: { offer },
        } = await axiosInstance.post(`/offers/create`, {
          listing_offer: serializeOffer(data),
        });
        updateOffer(offer);
        success({ title: "Offer draft saved" });
        return offer.id as string;
      } catch (error: any) {
        fail({
          title: "Couldn't save draft",
        });
        throw new Error(`Couldn't save draft: ${error.message}`);
      }
    },
    [axiosInstance, fail, success, updateOffer]
  );

  const updateDraft = useCallback(
    async (offerId: string, data: OfferDraft, subjects?: string[]) => {
      try {
        const {
          data: { updated_offer },
        } = await axiosInstance.post(`/offers/update/${offerId}`, {
          offer: serializeOffer(data),
          offer_subjects_bridge: subjects?.map((offer_subject_id, index) => ({
            offer_subject_id,
            order_index: index,
          })),
        });
        updateOffer(updated_offer);
        return updated_offer.id as string;
      } catch (error: any) {
        console.error(error);
        return null;
      }
    },
    [axiosInstance, updateOffer]
  );

  const deleteDraft = useCallback(
    async (offer_id: string) => {
      try {
        const { data: res } = await axiosInstance.delete(`/offers/${offer_id}`);
        success({ title: "Draft Removed" });
        deleteOffer(offer_id);
        return res as boolean;
      } catch (error: any) {
        fail({
          title: "Couldn't delete draft",
        });
        throw new Error(`Couldn't delete draft: ${error.message}`);
      }
    },
    [axiosInstance, deleteOffer, fail, success]
  );

  const signOffer = useRecoilCallback(
    ({ snapshot }) =>
      async (offerId: string, signatureId: string): Promise<boolean> => {
        try {
          const offer = snapshot
            .getLoadable(offerAtomFamily(offerId))
            .getValue();
          if (!offer) return false;
          const listing = await loadListing(offer.listing_id);
          if (
            ![
              ListingStatus.Active,
              ListingStatus.PreSale,
              ListingStatus.NewBuild,
            ].includes(listing?.listing_status ?? 0)
          ) {
            fail({ title: "Seller has already accepted another offer" });
          }
          await axiosInstance.post(`/offers/submit/${offerId}`, {
            signature_id: signatureId,
          });
          await loadOffer(offerId);
          resetDraft();
          setOfferStatus(offerId, OfferStatus.Pending);
          return true;
        } catch (error: any) {
          fail({
            title: "Couldn't sign offer",
          });
          throw new Error(`Couldn't sign offer: ${error.message}`);
        }
      },
    [axiosInstance, fail, loadOffer, resetDraft, setOfferStatus]
  );

  const revokeOffer = useCallback(
    async (offerId: string): Promise<string> => {
      try {
        const { data } = await axiosInstance.post(`/offers/revoke`, {
          offer_id: offerId,
        });
        await loadOffer(offerId);
        setOfferStatus(offerId, OfferStatus.Revoked);
        return data;
      } catch (error: any) {
        fail({
          title: "Couldn't revoke offer",
        });
        return "";
      }
    },
    [axiosInstance, fail, loadOffer, setOfferStatus]
  );

  const acceptOffer = useCallback(
    async (offerId: string, signatureId: string) => {
      try {
        await axiosInstance.post(`/offers/accept/${offerId}`, {
          signature_id: signatureId,
        });
        setOfferStatus(offerId, OfferStatus.Accepted);
        loadOffers();
      } catch (error: any) {
        fail({
          title: "Couldn't accept offer",
        });
      }
    },
    [axiosInstance, fail, loadOffers, setOfferStatus]
  );

  const rejectOffer = useCallback(
    async (offerId: string) => {
      try {
        await axiosInstance.post(`/offers/reject`, {
          offer_id: offerId,
        });
        setOfferStatus(offerId, OfferStatus.Rejected);
      } catch (error: any) {
        fail({
          title: "Couldn't reject offer",
        });
      }
    },
    [axiosInstance, fail, setOfferStatus]
  );

  const saveCounter = useRecoilCallback(
    ({ snapshot }) =>
      async (offerId: string, price: string) => {
        try {
          const currentOffer = snapshot
            .getLoadable(offerAtomFamily(offerId))
            .getValue();
          const currentOfferSubjects = snapshot
            .getLoadable(selectOfferSubjects(offerId))
            .getValue();
          if (!currentOffer) throw Error("No offer to counter");
          let offer_subjects_bridge = undefined as
            | {
                offer_subject_id: string;
                order_index: number;
              }[]
            | undefined;
          if (currentOffer.subject_ids?.length) {
            const ids = await createCustomSubjects({
              offer_subjects: currentOfferSubjects.map((s) => ({
                subject_description: s.subject_description,
                subject_name: s.subject_name,
                subject_text: s.subject_text,
              })),
            });

            offer_subjects_bridge = ids.map((offer_subject_id, index) => ({
              offer_subject_id,
              order_index: index,
            }));
            currentOfferSubjects.forEach(
              ({ subject_name, subject_text, subject_description }, i) => {
                addSubject({
                  subject_name,
                  subject_description,
                  subject_text,
                  id: ids[i],
                  editable: true,
                  date_created: new Date(),
                });
              }
            );
          }

          const {
            data: { offer },
          } = await axiosInstance.post(`/offers/create`, {
            listing_offer: serializeOffer({
              offer_price: price,
              offer_start_date: currentOffer.offer_start_date,
              offer_end_date: currentOffer.offer_end_date,
              offer_id: currentOffer.id,
              offer_price_currency: currentOffer.offer_price_currency,
              listing_id: currentOffer.listing_id,
              included_items: currentOffer.included_items ?? "",
              excluded_items: currentOffer.excluded_items ?? "",
              offer_completion_date: currentOffer.offer_completion_date,
              offer_possession_date: currentOffer.offer_possession_date,
              offer_adjustments_date: currentOffer.offer_possession_date,
              offer_viewed_date: currentOffer.offer_viewed_date,
              deposit: currentOffer.deposit,
              deposit_info: currentOffer.deposit_info,
              costs: currentOffer.costs,
            }),
            offer_subjects_bridge,
          });

          updateOffer({
            ...offer,
            subject_ids: offer_subjects_bridge
              ? offer_subjects_bridge.map((b) => b.offer_subject_id)
              : [],
          });
          resetDraft();
          return offer as Offer;
        } catch (error: any) {
          fail({
            title: "Couldn't counter offer",
          });
        }
      },
    [axiosInstance, fail, resetDraft, updateOffer]
  );
  const uploadSignature = useCallback(
    async (file: FormattedFile, key: string): Promise<string> => {
      try {
        const response = await axiosInstance.get(
          `/signatures/upload/${file.storageName}`
        );
        const presignedUrl = decrypt(response.data.file_url, key);
        let res;

        res = await axios.put(presignedUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
        if (res.status !== 200)
          throw new Error("Something went wring with file upload");

        const {
          data: { signatureId },
        } = await axiosInstance.post("/signatures/create", {
          s3_file_name: file.storageName,
        });
        success({ title: "signature uploaded" });
        return signatureId;
      } catch (error) {
        console.error({ error });
        fail({ title: String(error) });
        return "";
      }
    },
    [axiosInstance, fail, success]
  );

  return {
    saveDraft,
    updateDraft,
    deleteDraft,
    uploadSignature,
    signOffer,
    rejectOffer,
    acceptOffer,
    revokeOffer,
    loadOffer,
    loadOffers,
    loadOffersForUser,
    saveCounter,
  };
};

const serializeOffer = (data: Offer | OfferDraft) => ({
  ...data,
  offer_price: data.offer_price.replaceAll(",", ""),
  deposit: Number(data.deposit ?? 0),
});
