import "@fontsource-variable/cabin";
import "@fontsource-variable/cabin/wdth.css";
import "@fontsource/cedarville-cursive";
import { ChakraProvider } from "@chakra-ui/react";
import { Router } from "./Router";
import { theme } from "./theme";
import { SocketProvider } from "./SocketProvider";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <SocketProvider>
        <Router />
      </SocketProvider>
    </ChakraProvider>
  );
};
