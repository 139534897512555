import {
  VStack,
  Box,
  Text,
  useColorModeValue,
  Divider,
  Flex,
} from "@chakra-ui/react";
import { PriceInput, DateInput } from "../common";
import { useEffect, useMemo } from "react";
import { useRecoilValue, useRecoilState, SetterOrUpdater } from "recoil";
import {
  selectedOfferAtom,
  offerAtomFamily,
  draftPriceAtom,
  draftOfferEndAtom,
  draftOfferAdjustmentsDateAtom,
  draftOfferCompletionDateAtom,
  draftOfferPossessionDateAtom,
  draftOfferViewedDateAtom,
} from "../../recoil/offers/atoms";
import { convertDateToString } from "../../utils/date";
import { addDays, subDays } from "date-fns";
import { useCustomToast } from "../../hooks/useCustomToast";
import { InputName } from "../common/InputNameWithInfo";
import {
  CostBreakdown,
  OfferContingencies,
  Items,
  DepositInfo,
} from "./OfferSteps/OldSteps";

export const OfferInputs = () => {
  const listBg = useColorModeValue("lightBg", "darkBg");

  return (
    <Flex
      direction={"column"}
      w={"full"}
      maxW={750}
      rounded={"lg"}
      mr={3}
      maxH={600}
      bg={listBg}
      p={2}
      overflowY={"scroll"}
      border={"1px"}
      borderColor={"border"}
    >
      <BaseInfo />
      <Divider my={4} />
      <DepositInfo />
      <Box h={2} />
      <CostBreakdown />
      <Box h={2} />
      <OfferContingencies />
      <Box h={2} />
      <Items />
    </Flex>
  );
};

export const OfferInputsViewOnly = () => {
  return (
    <Box
      w={"100%"}
      rounded={"lg"}
      mr={3}
      maxH={600}
      p={2}
      overflowY={"scroll"}
      border={"1px"}
      borderColor={"border"}
    >
      <BaseInfo viewOnly />
      <Divider my={4} />
      <DepositInfo viewOnly />
      <Box h={2} />
      <CostBreakdown viewOnly />
      <Box h={2} />
      <OfferContingencies viewOnly />
      <Box h={2} />
      <Items viewOnly />
    </Box>
  );
};

export const BaseInfo = ({ viewOnly }: { viewOnly?: boolean }) => {
  const selectedOffer = useRecoilValue(selectedOfferAtom);
  const offer = useRecoilValue(offerAtomFamily(selectedOffer));
  const [closing, setClosing] = useRecoilState(draftOfferCompletionDateAtom);
  const [possesion, setPossession] = useRecoilState(
    draftOfferPossessionDateAtom
  );
  const { fail } = useCustomToast();
  const [viewed, setViewed] = useRecoilState(draftOfferViewedDateAtom);
  const [adj, setAdj] = useRecoilState(draftOfferAdjustmentsDateAtom);
  const [offerPrice, setOfferPrice] = useRecoilState(draftPriceAtom);
  const [offerEnd, setOfferEnd] = useRecoilState(draftOfferEndAtom);
  const current = convertDateToString(offer?.offer_start_date);
  const plusYear = convertDateToString(offer ? offer.offer_end_date : current);
  const err = (title: string) => fail({ title });
  useEffect(() => {
    if (!offer) return;
    setOfferEnd(plusYear);
    setOfferPrice(offer.offer_price);
    setAdj(
      convertDateToString(
        offer.offer_possession_date ?? addDays(new Date(), 30)
      )
    );
    setClosing(
      convertDateToString(
        offer.offer_completion_date ?? addDays(new Date(), 40)
      )
    );
    setPossession(
      convertDateToString(
        offer.offer_possession_date ?? addDays(new Date(), 50)
      )
    );
    setViewed(
      convertDateToString(offer.offer_viewed_date ?? subDays(new Date(), 1))
    );
  }, [
    current,
    offer,
    plusYear,
    setAdj,
    setClosing,
    setOfferEnd,
    setOfferPrice,
    setPossession,
    setViewed,
  ]);

  const dates = useMemo(() => {
    return {
      adj: new Date(adj),
      closing: new Date(closing),
      offerEnd: new Date(offerEnd),
      viewed: new Date(viewed),
      possesion: new Date(possesion),
    };
  }, [adj, closing, offerEnd, possesion, viewed]);

  return (
    <VStack spacing={"3"} my={2}>
      <Box w={"100%"} mb={2}>
        <Text fontWeight={600} mb={1}>
          Offer Price:
        </Text>
        <PriceInput
          setPrice={setOfferPrice}
          price={offerPrice}
          disabled={viewOnly}
        />
      </Box>
      <Box w={"100%"} mb={2}>
        <Text fontWeight={600} mb={1}>
          Offer effective until:
        </Text>
        <DateInput
          date={offerEnd}
          setDate={(value) =>
            dateHandler({
              value,
              setter: setOfferEnd,
              type: "end",
              dates,
              err,
            })
          }
          disabled={viewOnly}
        />
      </Box>
      <Box w={"100%"} mb={2}>
        <Text fontWeight={600} mb={1}>
          Property viewed on:
        </Text>
        <DateInput
          date={viewed}
          setDate={(value) =>
            dateHandler({
              value,
              setter: setViewed,
              type: "viewed",
              dates,
              err,
            })
          }
          disabled={viewOnly}
        />
      </Box>
      <Box w={"100%"} mb={2}>
        <InputName
          name="Possession Date:"
          description="This is when the buyer officially takes control and can move into the property, typically occurring after the closing date."
        />
        <DateInput
          date={possesion}
          setDate={(value) =>
            dateHandler({
              value,
              setter: setPossession,
              type: "posession",
              dates,
              err,
            })
          }
          disabled={viewOnly}
        />
      </Box>
      <Box w={"100%"} mb={2}>
        <InputName
          name="Closing Date:"
          description="The closing date marks the legal transfer of property ownership from the seller to the buyer, involving finalizing all documents and payments."
        />
        <DateInput
          date={closing}
          setDate={(value) =>
            dateHandler({
              value,
              setter: setClosing,
              type: "closing",
              dates,
              err,
            })
          }
          disabled={viewOnly}
        />
      </Box>
    </VStack>
  );
};

export const dateHandler = ({
  value,
  setter,
  type,
  dates,
  err,
}: {
  value: string;
  setter: SetterOrUpdater<string>;
  type: "closing" | "posession" | "adjustments" | "viewed" | "end";
  dates: {
    adj: Date;
    closing: Date;
    offerEnd: Date;
    viewed: Date;
    possesion: Date;
  };
  err: (message: string) => void;
}) => {
  const val = new Date(value);

  switch (type) {
    case "adjustments":
      if (val >= dates.closing) {
        err("Adjustments date has to be before closing and possession");
      } else {
        setter(value);
      }
      break;
    case "closing":
      if (val >= dates.possesion || val < dates.adj || val < dates.viewed) {
        err(
          "Closing date has to be before possession and after adjustemnts, viewed"
        );
      } else {
        setter(value);
      }
      break;
    case "posession":
      if (val < dates.adj || val < dates.closing || val < dates.viewed) {
        err("Posession date has to be after closing, adjustemnts, viewed");
      } else {
        setter(value);
      }
      break;
    case "viewed":
      if (val > new Date()) {
        err("Viewed date can't be in the future");
      } else {
        setter(value);
      }
      break;
    case "end":
      if (val < new Date()) {
        err("Offer expiration can't be in the past");
      } else {
        setter(value);
      }
      break;
    default:
      break;
  }
};
