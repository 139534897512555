import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Spinner,
  Avatar,
  useColorModeValue,
  HStack,
  Drawer,
  DrawerContent,
  DrawerBody,
  Select,
  VStack,
  Grid,
  GridItem,
  Badge,
} from "@chakra-ui/react";
import { DialogWindow } from "../components/messages/DialogWindow";
import { useLoadListings } from "../hooks/Listings";
import { useRecoilValue } from "recoil";
import useMessagesApi from "../hooks/useMessagesApi";
import {
  oldestMessageAtom,
  latestMessagesAtom,
} from "../recoil/messages/atoms";
import { userState } from "../recoil/atoms";
import { Message, UserTier } from "../types";
import { userFamily } from "../recoil/users/atoms";
import { useIsMobile } from "../hooks/utils/useWindowDimensions";
import { selectListingCoverImg } from "../recoil/listings/selectors";
import { listingFamily } from "../recoil/listings";
import { useOpenUpgradeWindow } from "../recoil/transactions";

export const Inbox = () => {
  const { loadInboxListings } = useLoadListings();
  const { getLatestMessages, readMessage } = useMessagesApi();
  const [loading, setLoading] = useState(true);
  const isMobile = useIsMobile();
  const [selectedUser, setSelectedUser] = useState<null | string>(null);
  const [listingFilter, setListingFilter] = useState<string | undefined>(
    undefined
  );
  const itemBg = useColorModeValue("#fff", "blue.100");
  const myUser = useRecoilValue(userState);
  const drawerBg = useColorModeValue("white.100", "darkContainer");
  const recentMessages = useRecoilValue(latestMessagesAtom);
  const [selectedListing, setSelectedListing] = useState("");

  const recentListingIds = useMemo(
    () =>
      recentMessages.reduce((prev, curr) => {
        if (!prev.includes(curr.listing_id)) prev.push(curr.listing_id);
        return prev;
      }, [] as string[]),
    [recentMessages]
  );

  useEffect(() => {
    if (!recentMessages.length) return;
    loadInboxListings();
  }, [loadInboxListings, recentMessages]);

  useEffect(() => {
    setLoading(true);
    getLatestMessages().then(() => {
      setLoading(false);
    });
  }, [getLatestMessages]);

  const onClose = () => setSelectedUser(null);

  return (
    <Grid
      templateColumns={isMobile ? "1fr" : "2fr 3fr"}
      gap={4}
      justifyContent="space-between"
    >
      {loading ? (
        <Spinner />
      ) : (
        <GridItem
          colSpan={1}
          border="1px solid lightgray"
          p={3}
          bg="container-bg"
          height={"84vh"}
          backgroundBlendMode="exclusion"
          borderRadius={8}
          overflowY="auto"
        >
          <HStack w="100%" mb={3}>
            <Select
              placeholder="Filter by listing"
              value={listingFilter}
              onChange={(e) => setListingFilter(e.target.value)}
              bg={itemBg}
            >
              {recentListingIds.map((l) => (
                <ListingOption key={l} listingId={l} />
              ))}
            </Select>
          </HStack>
          <Box>
            {[...recentMessages]
              .sort((a, b) => b.date_sent.valueOf() - a.date_sent.valueOf())
              .filter((m) => !listingFilter || m.listing_id === listingFilter)
              .map((message) => (
                <LastMessage
                  key={message.id}
                  message={message}
                  selected={
                    selectedListing === message.listing_id &&
                    (selectedUser === message.sender_id ||
                      selectedUser === message.receiver_id)
                  }
                  onSelect={() => {
                    const otherUser =
                      myUser?.id === message.sender_id
                        ? message.receiver_id
                        : message.sender_id;
                    setSelectedListing(message.listing_id);
                    setSelectedUser(otherUser);
                    if (myUser && myUser.id === message.receiver_id)
                      readMessage(message.id);
                  }}
                />
              ))}
          </Box>
        </GridItem>
      )}
      {isMobile ? (
        <Drawer
          isOpen={!!selectedUser}
          onClose={onClose}
          placement="bottom"
          isFullHeight
        >
          <DrawerContent backgroundColor={drawerBg}>
            <DrawerBody>
              {selectedUser && (
                <DialogWindow
                  userId={selectedUser}
                  listingId={selectedListing}
                  onClose={onClose}
                />
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      ) : selectedUser ? (
        <GridItem
          colSpan={1}
          height={"84vh"}
          border="1px solid lightgray"
          p={3}
          bg="container-bg"
          borderRadius={8}
        >
          <DialogWindow
            userId={selectedUser}
            listingId={selectedListing}
            onClose={onClose}
          />
        </GridItem>
      ) : null}
    </Grid>
  );
};

export const ListingOption = ({ listingId }: { listingId: string }) => {
  const listing = useRecoilValue(listingFamily(listingId));
  if (!listing) return null;

  return (
    <option value={listing.id} key={listing.id}>
      {listing.address} {listing.city} {listing.state_province}
    </option>
  );
};

export const LastMessage = ({
  message,
  selected,
  onSelect,
}: {
  message: Message;
  onSelect: () => void;
  selected: boolean;
}) => {
  const openUpgradeWindow = useOpenUpgradeWindow();
  const itemBg = useColorModeValue("#fff", "#111d2b");
  const hoveredBg = useColorModeValue("#f3f3f3", "#212d3b");
  const myUser = useRecoilValue(userState);
  const sentByMe = message.sender_id === myUser?.id;
  const conversationUser = useRecoilValue(
    userFamily(sentByMe ? message.receiver_id : message.sender_id)
  );
  const oldestMessage = useRecoilValue(oldestMessageAtom);
  const isMobile = useIsMobile();
  const listing = useRecoilValue(listingFamily(message.listing_id));
  const listingCover = useRecoilValue(
    selectListingCoverImg(message.listing_id)
  );
  const isOwnListing = listing?.seller_id === myUser?.id;
  if (!conversationUser || !myUser) return null;
  const fullName =
    conversationUser.first_name + " " + conversationUser.last_name;
  const unread = !message.date_read && !sentByMe;
  const noNeedToUpgrade =
    myUser.tier > UserTier.Basic ||
    !isOwnListing ||
    (message.listing_id === oldestMessage?.listing_id &&
      [message.sender_id, message.receiver_id].includes(
        oldestMessage.sender_id
      ));

  return (
    <Flex
      onClick={noNeedToUpgrade ? onSelect : openUpgradeWindow}
      bg={selected ? "celestial" : itemBg}
      style={{
        border: `1px solid lightgray`,
        padding: 6,
        borderRadius: 8,
        marginBottom: 6,
      }}
      fontWeight={unread ? 600 : 400}
      align={"center"}
      justifyContent={"space-between"}
      _hover={{
        bg: hoveredBg,
      }}
      cursor={"pointer"}
    >
      <HStack>
        <Avatar src={listingCover} name={fullName} />
        <VStack align={"start"}>
          <Text fontSize={12}>
            {fullName.substring(0, fullName.length > 20 ? 19 : undefined)}
            {fullName.length > 20 ? "..." : ""}
          </Text>
          <Box
            bg={isOwnListing ? "blue.100" : "brutalistSecondary"}
            rounded={"md"}
            p={0.2}
            px={1}
          >
            <Text fontSize={12} color={"white"}>
              {listing?.address}
            </Text>
          </Box>
        </VStack>
      </HStack>
      {noNeedToUpgrade ? (
        <Text
          ml={3}
          fontSize={isMobile ? 12 : 14}
          fontStyle={sentByMe ? "italic" : undefined}
        >
          {message.content.substring(0, 48)}
          {message.content.length > 48 ? "..." : ""}
        </Text>
      ) : (
        <Badge>Upgrade to view</Badge>
      )}
    </Flex>
  );
};

export default Inbox;
