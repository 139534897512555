import { useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { perchDataAtom } from "../recoil/perch/atoms";
import { selectAxiosInstance } from "../recoil/selectors";

export const useFetchPerchData = () => {
  const perchData = useRecoilValue(perchDataAtom);
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const setPerchData = useSetRecoilState(perchDataAtom);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      if (perchData.length === 0) {
        try {
          const response = await axiosInstance.get("/estimate/perch", {
            signal: controller.signal,
          });

          if (response.status === 200 && response.data) {
            console.log(response.data);

            setPerchData(response.data);
          }
        } catch (error) {
          console.error("Error fetching perch data:", error);
        }
      }
    };

    fetchData();
    return () => controller.abort();
  }, [axiosInstance, perchData.length, setPerchData]);

  return;
};
