import { useRecoilCallback } from "recoil";
import { allSubjectIdsAtom, subjectIdsForOfferAtom } from "./offers/atoms";
import { userState } from "./atoms";
import { latestMessagesAtom } from "./messages/atoms";
import { cartAtom, upgradeOpenAtom } from "./services/atoms";
import { reviewListingIdsAtom, userListingsAtom } from "./listings";

export const useLogOut = () => {
  return useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(userState);
        reset(reviewListingIdsAtom);
        reset(subjectIdsForOfferAtom);
        reset(userListingsAtom);
        reset(latestMessagesAtom);
        reset(allSubjectIdsAtom);
        reset(cartAtom);
      },
    []
  );
};

export const useOpenUpgradeWindow = () => {
  return useRecoilCallback(
    ({ set }) =>
      () => {
        set(upgradeOpenAtom, true);
      },
    []
  );
};
