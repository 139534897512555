import React, { useMemo } from "react";
import {
  Tr,
  Td,
  Button,
  Stack,
  Text,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { formatDateTimes } from "../../utils/formatters";
import { SellerViewing, ViewingRequest, ViewingSchedule } from "../../types";
import { useNavigate } from "react-router-dom";
import useViewingsApi from "../../hooks/useViewingsApi";
import { ICON_SIZE } from "../../theme";
import { useSetRecoilState } from "recoil";
import { selectedListingScheduleAtom } from "../../recoil/listings";
import { Edit, Trash } from "react-feather";
import { compareDates } from "../../utils/date";
import { useUserKycd } from "../../recoil/users/selectors";
import { useTriggerKycWindow } from "../../recoil/users/transactions";
import { DeleteViewingModal } from "./DeleteViewingModal";

interface ViewingScheduleRowProps {
  viewingSchedule: ViewingSchedule | SellerViewing;
  handleSendViewingRequest?: (schedule: ViewingSchedule) => void;
  seller_mode?: boolean;
  buyerUpcomingViewings?: ViewingRequest[];
  buyerPendingViewingRequests?: ViewingRequest[];
  disableButtons?: boolean;
}

export const ViewingScheduleTableRow: React.FC<ViewingScheduleRowProps> = ({
  viewingSchedule,
  handleSendViewingRequest,
  seller_mode = false,
  buyerUpcomingViewings,
  buyerPendingViewingRequests,
  disableButtons = false,
}) => {
  const { deleteViewingSchedule, deleteViewingRequest } = useViewingsApi();
  const setSelectedViewingSchedule = useSetRecoilState(
    selectedListingScheduleAtom
  );
  const navigate = useNavigate();
  const isKyced = useUserKycd();
  const triggerKycWindow = useTriggerKycWindow();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { day, date, start_time, end_time } = useMemo(() => {
    return formatDateTimes(
      viewingSchedule.start_time,
      viewingSchedule.end_time
    );
  }, [viewingSchedule.start_time, viewingSchedule.end_time]);

  const isViewingScheduled = buyerUpcomingViewings?.some((booking) => {
    return (
      booking.viewing_schedule_id === viewingSchedule.id &&
      compareDates(
        viewingSchedule.start_time,
        viewingSchedule.end_time,
        booking.start_time,
        booking.end_time
      )
    );
  });

  const isRequestPending = buyerPendingViewingRequests?.some(
    (viewing) =>
      viewing.viewing_schedule_id === viewingSchedule.id &&
      compareDates(
        viewingSchedule.start_time,
        viewingSchedule.end_time,
        viewing.start_time,
        viewing.end_time
      )
  );

  if (!viewingSchedule) return null;

  return (
    <Tr>
      <Td>{viewingSchedule.max_attendance ? "Open House" : "Private"}</Td>
      <Td>
        <Stack direction={{ base: "column", md: "row" }} align="left">
          <Text>{start_time.replace(/^0/, "")}</Text>
          <Text>{`- ${end_time.replace(/^0/, "")}`}</Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction={{ base: "column", md: "row" }} align="left">
          <Text>{`${day},`} </Text> <Text> {`${date}`} </Text>
        </Stack>
      </Td>
      <Td>
        {!seller_mode && handleSendViewingRequest ? (
          isViewingScheduled ? (
            <Button
              variant={"primary"}
              size={"sm"}
              isDisabled={true}
              width={"80%"}
            >
              Booked
            </Button>
          ) : viewingSchedule.max_attendance ? (
            <Button
              variant={"primary"}
              size={"sm"}
              onClick={() => handleSendViewingRequest(viewingSchedule)}
              width={"80%"}
              isDisabled={disableButtons ? true : false}
            >
              RSVP
            </Button>
          ) : !isRequestPending ? (
            <Button
              width={"80%"}
              variant={"secondary"}
              size={"sm"}
              isDisabled={disableButtons ? true : false}
              onClick={() => {
                if (isKyced) {
                  handleSendViewingRequest(viewingSchedule);
                } else {
                  triggerKycWindow();
                }
              }}
            >
              Request
            </Button>
          ) : (
            <Button
              variant={"primary"}
              size={"sm"}
              isDisabled={true}
              width={"80%"}
            >
              Requested
            </Button>
          )
        ) : (
          <Flex>
            <Button
              variant="plain"
              size={"sm"}
              onClick={() => {
                setSelectedViewingSchedule(viewingSchedule.id);
                navigate(`/viewing-schedule/edit/${viewingSchedule.id}`);
              }}
            >
              <Edit size={ICON_SIZE} />
            </Button>
            <Button variant="plain" size={"sm"} onClick={onOpen}>
              <Trash size={ICON_SIZE} />
            </Button>
            <DeleteViewingModal
              isOpen={isOpen}
              onClose={onClose}
              isViewingRequest={"viewing_request_id" in viewingSchedule}
              onConfirm={() => {
                "viewing_request_id" in viewingSchedule
                  ? deleteViewingRequest(
                      viewingSchedule.viewing_request_id,
                      viewingSchedule.listing_id
                    )
                  : deleteViewingSchedule(
                      viewingSchedule.id,
                      viewingSchedule.listing_id
                    );
                onClose();
              }}
            />
          </Flex>
        )}
      </Td>
    </Tr>
  );
};
