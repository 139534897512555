import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { Product, ProductDict } from "../../types/services";
import { usePaymentApi } from "../../hooks/Stripe/usePaymentApi";
import { PricingTier } from "../createListing/MediaPricing";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { upgradeOpenAtom } from "../../recoil/services/atoms";
import { loginModalOpen, userState } from "../../recoil/atoms";

export const TierUpdgradeModal = () => {
  const [open, setOpen] = useRecoilState(upgradeOpenAtom);

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} size={"full"}>
      <ModalOverlay />
      <ModalContent bg={"container-bg"}>
        <ModalCloseButton />
        <ModalBody>
          <Heading px={4} pt={4} size={"md"}>
            Choose your plan
          </Heading>
          <TierUpgradePage />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const TierUpgradePage = () => {
  const { instantCheckout } = usePaymentApi();
  const user = useRecoilValue(userState);
  const setIsLoginOpen = useSetRecoilState(loginModalOpen);
  const [popupOpen, setPopupOpen] = useRecoilState(upgradeOpenAtom);
  return (
    <Flex
      wrap="wrap"
      justifyContent="center"
      alignItems="center"
      gap={10}
      my={10}
    >
      <PricingTier
        name={"Basic"}
        price={"Free"}
        points={[
          "Unlimited photos",
          "Unlimited videos",
          "Limited access to messaging",
        ]}
        antipoints={Points(Product.PlatinumAccess).slice(3)}
        onCtaClick={() => {
          setPopupOpen(false);
        }}
        noCta={!popupOpen}
        buttonText="Continue"
      />
      {[Product.PremiumAccess, Product.EliteAccess, Product.PlatinumAccess].map(
        (item) => (
          <PricingTier
            buttonText={!user || !user.token ? "Login to upgrade" : "Upgrade"}
            name={ProductDict[item].description}
            price={(
              ProductDict[item].price + (item === Product.EliteAccess ? 50 : 0)
            ).toFixed(2)}
            discountedPrice={
              item === Product.EliteAccess
                ? ProductDict[item].price.toFixed(2)
                : undefined
            }
            isDiscounted={item === Product.EliteAccess}
            isHighlighted={item === Product.EliteAccess}
            points={Points(item)}
            antipoints={AntiPoints(item)}
            onCtaClick={() => {
              if (!user || !user.token) {
                setIsLoginOpen(true);
              } else {
                instantCheckout(item);
              }
            }}
          />
        )
      )}
    </Flex>
  );
};

const Points = (p: Product) => {
  switch (p) {
    case Product.PremiumAccess:
      return [
        "Unlimited photos",
        "Unlimited videos",
        "Full access to messaging",
        "Accept/manage viewing requests",
        "AI-powered property appraisal",
      ];
    case Product.EliteAccess:
      return [
        "Unlimited photos",
        "Unlimited videos",
        "Full access to messaging",
        "Accept/manage viewing requests",
        "AI-powered property appraisal",
        "Property listed on MLS",
        "24/7 dedicated support",
      ];
    case Product.PlatinumAccess:
      return [
        "Unlimited photos",
        "Unlimited videos",
        "Full access to messaging",
        "Accept/manage viewing requests",
        "AI-powered property appraisal",
        "Property listed on MLS",
        "24/7 dedicated support",
        "Professional photography",
        "Professional videography",
        "3D Tour",
      ];
    default:
      return [];
  }
};

const AntiPoints = (p: Product) => {
  switch (p) {
    case Product.PremiumAccess:
      return [
        "Property listed on MLS",
        "24/7 dedicated support",
        "Professional photography",
        "Professional videography",
        "3D Tour",
      ];
    case Product.EliteAccess:
      return [
        "Professional photography",
        "Professional videography",
        "3D Tour",
      ];
    case Product.PlatinumAccess:
      return [];
    default:
      return [];
  }
};
