import {
  Button,
  Flex,
  Text,
  VStack,
  Tag,
  useColorModeValue,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  ProductsWidgetState,
  listingInputAtom,
  newListingStepAtom,
  productsWidgetState,
} from "../../recoil/listings/atoms";
import { ProductDict, Product } from "../../types/services";
import { ProductSelection } from "../services/ProductSelection";
import { useCart } from "../../hooks/Stripe/useCart";
import { Check, X } from "react-feather";

type PricingTierProps = {
  name: string;
  description?: string;
  price: string;
  discountedPrice?: string;
  points: string[];
  antipoints?: string[];
  onCtaClick: () => void;
  isDiscounted?: boolean;
  isHighlighted?: boolean;
  buttonText?: string;
  noCta?: boolean;
};

export const PricingTier: React.FC<PricingTierProps> = ({
  name,
  description,
  price,
  discountedPrice,
  points,
  antipoints,
  onCtaClick,
  isDiscounted,
  isHighlighted,
  buttonText,
  noCta,
}) => {
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const boxShadow = isHighlighted
    ? "0 4px 14px 0 rgba(0, 0, 0, 0.15)"
    : "0 2px 8px 0 rgba(0, 0, 0, 0.1)";
  const transform = isHighlighted ? "scale(1.01)" : "none";
  const zIndex = isHighlighted ? 1 : 0;

  return (
    <VStack
      padding={3}
      spacing={4}
      bg={"expandable-bg"}
      rounded={"xl"}
      shadow={boxShadow}
      dropShadow={boxShadow}
      borderWidth="1px"
      borderColor={borderColor}
      alignItems="stretch"
      width="100%"
      maxWidth="250px"
      textAlign={"center"}
      transform={transform}
      zIndex={zIndex}
    >
      {isDiscounted && (
        <Tag
          size="md"
          variant="solid"
          background={"ohBlue.100"}
          color={"white"}
          width={"fit-content"}
          alignSelf={"center"}
          position={"absolute"}
          top={"-12px"}
        >
          SAVINGS
        </Tag>
      )}
      <Text fontSize={24} fontWeight="semibold">
        {name}
      </Text>
      {description && (
        <Text fontSize="md" fontWeight={"semibold"}>
          {description}
        </Text>
      )}
      {!noCta && (
        <Text fontSize="lg" fontWeight="bold">
          {discountedPrice ? (
            <>
              <Text as="s" fontSize="lg">
                ${price}
              </Text>{" "}
              ${discountedPrice}
            </>
          ) : (
            `${!isNaN(Number(price)) ? "$" : ""}${price}`
          )}
        </Text>
      )}
      <Divider />
      <Box pl={4}>
        {points.map((p) => (
          <Flex alignItems={"center"}>
            <Check size={16} color="green" />
            <Text
              key={p}
              fontSize="15"
              fontWeight={"semibold"}
              ml={1}
              textAlign={"left"}
              mb={1}
            >
              {p}
            </Text>
          </Flex>
        ))}
        {antipoints &&
          antipoints.map((p) => (
            <Flex alignItems={"center"}>
              <X size={16} color="grey" />
              <Text
                key={p}
                color={"grey"}
                fontSize="15"
                ml={1}
                textAlign={"left"}
                mb={1}
              >
                {p}
              </Text>
            </Flex>
          ))}
      </Box>
      {!noCta && (
        <Button variant="primary" onClick={onCtaClick}>
          {buttonText ?? "Add to Cart"}
        </Button>
      )}
    </VStack>
  );
};

export const Packages = ({
  sqftTier,
  onAdd,
  instant,
}: {
  sqftTier: 0 | 1 | 2;
  onAdd?: () => void;
  instant?: (product: Product) => void;
}) => {
  const { addToCart } = useCart();
  const [essentials, premium, signature] = useMemo(() => {
    switch (sqftTier) {
      case 0:
        return [
          Product.EssentialsSmall,
          Product.PremiumSmall,
          Product.SignatureSmall,
        ];
      case 1:
        return [
          Product.EssentialsMedium,
          Product.PremiumMedium,
          Product.SignatureMedium,
        ];
      case 2:
        return [
          Product.EssentialsLarge,
          Product.PremiumLarge,
          Product.SignatureLarge,
        ];
    }
  }, [sqftTier]);

  const handleAddToCart = useCallback(
    (type: "Essentials" | "Premium" | "Signature") => {
      switch (type) {
        case "Essentials":
          instant ? instant(essentials) : addToCart(essentials);
          break;
        case "Premium":
          instant ? instant(premium) : addToCart(premium);
          break;
        case "Signature":
          instant ? instant(signature) : addToCart(signature);
          break;
      }
      onAdd && onAdd();
    },
    [addToCart, essentials, instant, onAdd, premium, signature]
  );

  return (
    <Flex
      wrap="wrap"
      justifyContent="center"
      alignItems="center"
      gap={10}
      mt={10}
    >
      <PricingTier
        name="Essentials"
        price={ProductDict[essentials].price.toFixed(2)}
        points={["Professional Photography", "High Quality Videography"]}
        onCtaClick={() => handleAddToCart("Essentials")}
      />
      <PricingTier
        name="Premium"
        price={(Number(ProductDict[premium].price) + 30).toFixed(2)}
        discountedPrice={ProductDict[premium].price.toFixed(2)}
        points={[
          "Professional Photography",
          "High Quality Videography",
          "2D & 3D Floor Plan",
          "3D Virtual Tour",
        ]}
        onCtaClick={() => handleAddToCart("Premium")}
        isDiscounted={true}
        isHighlighted={true}
      />
      <PricingTier
        name="Signature"
        price={ProductDict[signature].price.toFixed(2)}
        points={["Everything from Premium", "Virtual Staging"]}
        onCtaClick={() => handleAddToCart("Signature")}
      />
    </Flex>
  );
};

export const Products = () => {
  const area = Number(useRecoilValue(listingInputAtom).floor_area) ?? 0;
  const setListingStep = useSetRecoilState(newListingStepAtom);
  const [state, setState] = useRecoilState(productsWidgetState);
  const sqftTier = area < 1500 ? 0 : area < 2500 ? 1 : 2;
  return (
    <Box>
      <Text fontSize={32} fontWeight={"bold"} textAlign={"center"}>
        Make your listing stand out
      </Text>
      <Text fontSize={16} textAlign={"center"}>
        Getting professional media for your listing helps getting noticed
      </Text>
      {state === ProductsWidgetState.Packages && (
        <Packages
          sqftTier={sqftTier}
          onAdd={() => setListingStep((s) => s + 1)}
        />
      )}
      {state === ProductsWidgetState.Media && (
        <ProductSelection sqftTier={sqftTier} />
      )}

      <Flex justify={"end"}>
        {state < 1 ? (
          <Button
            onClick={() => setState((s) => (s === 1 ? 0 : s + 1))}
            variant={"ghost"}
          >
            I already have the photos
          </Button>
        ) : (
          <Button onClick={() => setState((s) => s - 1)} variant={"ghost"}>
            Back to packages
          </Button>
        )}
      </Flex>
    </Box>
  );
};
