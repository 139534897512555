import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Text,
  Box,
} from "@chakra-ui/react";
import { useCallback, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { userState } from "../../recoil/atoms";
import useUserApi from "../../hooks/useUserApi";
import { useLogOut } from "../../recoil/transactions";
import ArticleText, { listType } from "../common/ArticleText";

export const TermsOfService = () => {
  const user = useRecoilValue(userState);
  const isOpen = !!user && !user.permissions.includes("TERMS_ACCEPTED");
  const [loading, setLoading] = useState(false);
  const { createPermission } = useUserApi();
  const logout = useLogOut();
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const handleContinue = useCallback(async () => {
    try {
      setLoading(true);
      createPermission("TERMS_ACCEPTED");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [createPermission]);

  const handleScroll = useCallback(() => {
    const element = modalBodyRef.current;
    if (element) {
      const isAtBottom =
        element.scrollHeight - element.scrollTop < element.clientHeight + 100;
      if (isAtBottom) {
        setIsScrolledToBottom(true); // Only set to true when bottom is reached
      }
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      scrollBehavior="inside"
      isCentered
      blockScrollOnMount
    >
      <ModalOverlay />
      <ModalCloseButton />
      <ModalContent
        bg={"drawer-color"}
        mx={2}
        minW={{ md: "3xl", lg: "4xl", xl: "7xl" }}
      >
        <ModalHeader fontSize={"2xl"}>Terms of Service</ModalHeader>
        <ModalBody
          mx={4}
          px={5}
          py={4}
          ref={modalBodyRef}
          onScroll={handleScroll}
          borderRadius={"md"}
          bg={"drawer-color-secondary"}
        >
          <ArticleText article={terms} />
        </ModalBody>
        <ModalFooter>
          <Box width={"100%"} display={"block"}>
            <Text>*Scroll to bottom to agree</Text>
          </Box>
          <HStack>
            <Button colorScheme={"red"} onClick={() => logout()}>
              Logout
            </Button>
            <Button
              variant={"primary"}
              isLoading={loading}
              onClick={handleContinue}
              isDisabled={!isScrolledToBottom}
            >
              Agree
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const terms = {
  isNumbered: true,
  display_table_of_contents: false,
  content: [
    {
      title: "Searching, Viewing and Offering Terms",
      content: [
        { subtitle: "", body: "" },
        {
          subtitle: "Searcher identification.",
          body: `To search properties on OPENHAUS’ website you are not required to sign up. To interact with any lister, as in, for example, but not limited to, to book a visit for any listed property, you need to sign up,  identify yourself and be approved by our administrator. We request an uploaded photo of your valid identification document and proof of your current address. It is at the sole discretion of OPENHAUS to approve a user. Once approved you are obliged to update the uploaded documents under any circumstances requiring a change. OPENHAUS is not responsible for any update. OPENHAUS does not share this private information with its users under any circumstances.`,
        },
        {
          subtitle: "Searching.",
          body: `You can search for properties for sale by using the different criteria offered on our website such as property type, title, description, address, amenities, etc. You can also use advanced filters to refine your search results.`,
        },
        {
          subtitle: "Booking, viewing and visiting a listing.",
          body: `Once you are signed up and you are verified by our administrator, OPENHAUS’s website system allows you to book a visit to any listing you find on it. By requesting a booking you are establishing a direct connection with the lister of the listing, which is completely independent from OPENHAUS. 
        The booking is formed exclusively between the lister and the searcher by selecting available dates and times already proposed by each lister. By booking to visit a listing you agree to keep OPENHAUS out of any consequence occurring from this event, because OPENHAUS only verifies identities of listers and searchers before they connect on its website, and the result or failure or any discordance or consequence or situation resulting from this meeting is not a responsibility of OPENHAUS under any circumstance. 
        Any costs, torts, offences or liabilities associated with this viewing of a listing are between the lister and the searcher and OPENHAUS does not charge any fee or cost to promote this encounter. 
        `,
        },
        {
          subtitle: "Booking confirmations.",
          body: `Listers decide their availability to show their listings in either private viewings or open houses. They select times and dates for that on OPENHAUS’ website. Private viewings’ dates are offered by the lister not by OPENHAUS. Once you select one available date for the booking, the lister will accept or decline to confirm it directly on OPENHAUS’s website. If the lister accepts the booking, you and the lister will receive a confirmation of the booking from OPENHAUS  with the date, time and place for the viewing. This confirmation does not bind or make OPENHAUS responsible for the consequences of the booking, it only shows a result of the effective electronic communication between the lister and the searcher. Note that we do not provide phone numbers of our users and it is the responsibility of the lister and the searcher to confirm the booking under any means they consider appropriate.`,
        },
        {
          subtitle: "Booking modifications and cancellations.",
          body: `Modifying a booking is also available on OPENHAUS’s website but it does not compromise OPENHAUS under any circumstances. The result of any cancellation and modification of Booking is only between the lister and the searcher. OPENHAUS only ensures that connections between listers and searchers are effective in the sense that they are sent and responded to by real persons with verified IDs, with the only purpose of visiting a listing. The booking, visiting and any modifications of them are only between the lister and the searcher. OPENHAUS is not liable for any results, changes or confirmations for any booking on its website under any circumstances.`,
        },
        {
          subtitle: "Visits to listings.",
          body: `The images of the listings published on OPENHAUS’ website are exclusively uploaded by the listers. OPENHAUS only controls their digital quality and congruence with the listing. Once they are published on the website, they belong to OPENHAUS to use them in promoting its listings, services and website, but they do not represent the conditions or exact location or any other characteristic of the listed property that could be found at the booked visit. It is your responsibility to visit the listing in person and confirm what is published on the website. You have the right to send an email to our support center to provide your feedback about the visit. OPENHAUS can freely decide whether to request changes, updates or different images from the lister in order to improve the quality of the website. 
        `,
        },
        {
          subtitle: "Before offering.",
          body: `It is highly recommended you visit the listing in person before placing an offer on OPENHAUS' website. When you find a property in our website’s listings, you are agreeing to all the features and characteristics published by the lister under the part “Listing Terms”. 
        You are also responsible for confirming all the representations listed on the OPENHAUS website, which are only made by the lister, by visiting the listing in person. We keep a record of all your bookings when searching for properties listed on our website. Once the booking has been made by the searcher and the lister it is an agreement between them that is considered as if the property was visited. 
        `,
        },
        {
          subtitle: "Placing an offer.",
          body: `You can place an offer to purchase a listed property on OPENHAUS’ website by using its different offering tools. This means that you also have the full option to place an offer entirely on your own by sending it directly to the lister, without using them.
        Once you decide to place an offer using OPENHAUS’s website you will enter into a secured platform, starting with the price to be offered. The website will let you input multiple options changing according to the offering conditions that are normally used in British Columbia. You will need to set, by yourself, the effective date and time until when the offer will be considered valid by the lister, the date and time you have visited it in person or online, the adjustments, closing and possession dates, your information for the purchasing deposit if the offer is accepted, cost breakdowns, contingencies and more options including or excluding multiple items. 
        Filling all these boxes may require legal assistance which is not provided by OPENHAUS under any circumstances, so it is recommended that you find your own legal services to do it. If you feel uncertain about this system you should use your own means.
        The OPENHAUS website displays a template of an offer to buy a property, which can be used for free by credited offerers, but it does not imply any form of legal advice. Note that this form changes as long as you select different options and take the time to read it before signing it. Offerors also have the opportunity to upload and send their own offer of purchase by uploading it on the OPENHAUS website. 
        By using OPENHAUS’ platform for an offer, you are agreeing to act as an independent purchaser or seller, without the assistance of a realtor. OPENHAUS is a platform that helps to directly connect users interested in real estate, but it does not act as a real estate agent in any circumstances.`,
        },
        {
          subtitle:
            "Receiving and sending counteroffers. 72 hours of privacy period.",
          body: `OPENHAUS only connects its listers to sell with real estate searchers becoming potential offerors. Once an offer has been placed by using the OPENHAUS website, it will be immediately sent to the registered email we have on file from the lister. It is the lister's responsibility to respond, accept or counteroffer. Offers, responses to offers and counteroffers made outside of OPENHAUS’website are ignored by OPENHAUS. It is the lister and searcher’s responsibility to keep effective communication within and outside OPENHAUS’ website to ensure the effectiveness of their offer of purchase process. In that sense, receiving and sending counteroffers until an acceptance or refusal of the real estate purchase is the full responsibility of the users of the OPENHAUS website. Under any circumstances, OPENHAUS will be responsible for the results of these transactions on its website. OPENHAUS will remain independent from this relationship between users of its website as a third non-compromised party. OPENHAUS will only ensure parties’ privacy between these communications during the offering of the purchase process on its website for a maximum period of 72 hours from the date of the offer, which is the reasonable period of time to execute this transaction in Canada.`,
        },
        {
          subtitle:
            "Accepting an offer, cooling off period, and liabilities occurring from performance.",
          body: `You are the only one responsible for keeping your profile updated on OPENHAUS’s website. The email address on your profile is the only one we use to communicate with you. When you receive a counter offer or get your offer accepted you will receive an email to this address requesting you to access the website to take action. 
        We do not communicate your right to rescind your accepted offer. As an accepted offeror you will have to manage your cooling off period accorded by the law of British Columbia by contacting and giving written notice directly to the seller who had accepted your offer.
        Offeror and offeree are exclusively responsible for conducting their transactions outside or on our website. OPENHAUS website only provides communication and instruments to facilitate that relationship but is not responsible for the results or effectiveness. All liabilities and obligations occurring for any real estate transaction pursuant to the use of the OPENHAUS website are the sole responsibility of the users who can always use an alternative communication to conduct their business.`,
        },
        {
          subtitle: "Your Responsibilities and Assumption of Risk.",
          body: `You are responsible and liable for your own acts and omissions and are also responsible for the acts and omissions of anyone you invite to join or provide access to your profile and use it. For example, you are responsible for your own visits and for any visitor going with you to any listed real where you have booked a visit; you are responsible for anyone who uses your profile to make an offer or book a visit, to message listers and visitors; to represent your consideration for the purchase of a property; to perform the obligations you assume under an offer of purchase or any other contract available to you on our website.
        If you are representing a minor you are jointly and severally liable for any consequences of your representations.`,
        },
        {
          subtitle: "Your Assumption of Risk.",
          body: `You acknowledge that many activities carry inherent risks and agree that, to the maximum extent permitted by applicable law, you assume the entire risk arising out of your access to and use of the OPENHAUS website and any Content (as defined in part 2), including your physical and direct negotiations related to a listing on our website. This means it is your responsibility to investigate a user of OPENHAUS  to determine whether it is suitable for you to carry on business.`,
        },
        {
          subtitle: "Contract formation.",
          body: `When you receive the confirmation of the acceptance of your offer, a contract for the purchase of real estate is formed directly between you and the lister. By making a booking of a visit or an offer you are agreeing to the terms of the contract. The terms of the contract include all the current Terms, and all terms of using the website. It is your responsibility to read and understand these terms of the contract including the current Terms and the law of British Columbia.`,
        },
      ],
    },
    {
      title: "Listing Terms",
      content: [
        { subtitle: "Listing on OPENHAUS." },
        {
          subtitle: "Lister.",
          body: `As a Lister, OPENHAUS offers you the right to use the OPENHAUS website in accordance with these Terms to share your real estate property with our vibrant community of Searchers and sell it without a credited realtor on your own as a “listing by owner”. It’s easy to create a Listing and you are in control of how you list, set your price, availability, and rules to view and sell conditions for each of your Listings. You must be in legal condition to dispose (sell and show) the property you are listing and it is your responsibility to represent that with all the necessary documentation such as but not limited to title certificate, mortgage in your name and paid property taxes. You accept to be required to show this documentation at any time as long as you are signed up.`,
        },
        {
          subtitle: "Contracting with Searchers.",
          body: `When you accept an offer to purchase your listing, a request for viewing, or receive a selling or booking confirmation through the OPENHAUS website, you are entering into a contract directly with the Searchers, and are responsible for delivering the property under the terms and at the price specified in your Listing.`,
        },
        {
          subtitle: "Independence of Listers.",
          body: `Your relationship with OPENHAUS is that of an independent individual or entity and not an employee, agent, joint venturer, or partner of OPENHAUS. OPENHAUS does not act as a payment collection agent. OPENHAUS does not direct or control your Listing or selling activity, which includes but is not limited to booking visits, doing open houses and receiving offers. You understand that you have complete discretion over whether and when to sell your property listed on the OPENHAUS website, at what price, and on what terms to offer it.`,
        },
        {
          subtitle: "Creating and Managing Your Listing.",
          body: `The website of OPENHAUS provides tools that make it easy for you to set up and manage a real estate Listing to sell. Your Listing must include a complete and accurate description of your property, your price and conditions to sell. You are responsible for your acts or omissions as well as keeping your Listing information and content up to date and accurate at all times.`,
        },
        {
          subtitle: "Know your responsibilities.",
          body: `You are responsible for understanding and complying with any laws, rules, regulations, and contracts with third parties that apply to your Listing and the selling of your property. The legal information we provide is for informational purposes only and you should independently confirm your obligations. You are responsible for handling and using personal and property data in compliance with applicable privacy laws and these Terms.`,
        },
        {
          subtitle: "Understand Search Results.",
          body: `OPENHAUS organizes the listings without ranking them. Searchers can save them by creating their own portfolio by selecting the properties of their choice. OPENHAUS offers a service to promote them on its website, adding more and direct exposure of your listing to the searchers. This service does not represent more chances to sell your listing but it only gives it more attention. OPENHAUS does not sell real estate it only sells services of advertisement for them.OPENHAUS allows the listers to promote their listings outside of its website.
        Search results may be different on our mobile application than on our website, and may also differ in the map view.  More information about the factors that determine how your Listing appears in search results.`,
        },
        {
          subtitle: "Services to better create your listing.",
          body: `OPENHAUS offers third-party services to listers and searchers such as real estate imagery, inspection, staging, furniture moving, cleaning, notary services, and MLS listings, directly listed on the OPENHAUS website as self-providers. OPENHAUS gets a fee commission from these providers in an independent relationship with them which does not compromise the service offered and performed by these suppliers. OPENHAUS does not provide these services and the name, contact and all professional information of these suppliers is displayed on the website to facilitate a direct relationship with the users. OPENHAUS is not responsible for this performance of services under any circumstances and users are responsible for verifying the quality of these services.`,
        },
        {
          subtitle: "Your Assumption of Risk.",
          body: `You acknowledge that listing your real estate carries inherent risks and agree that you assume the entire risk arising out of your access to and use of the OPENHAUS website, offering the showing of your property, or any interaction you have with other users whether in person or online.
        You agree that you have had the opportunity to investigate the OPENHAUS platform and any laws, rules, regulations, or obligations that may be applicable to your Listings.`,
        },
        {
          subtitle: "Open houses and visit bookings.",
          body: `In general, viewing cancellations and modifications do not create any responsibility between the parties. As a lister, you offer available dates for visits and for open houses on the OPENHAUS website and you are responsible for hosting them unless you cancel or modify them with sufficient time to notify the known and registered searchers who have compromised their visit. This customary rule creates a direct obligation for the lister to confirm the potential visitors and get informed about their real interest, financial ability to purchase, profile data and all other information following their own criteria. OPENHAUS is not responsible for collecting this information for the lister because it is related to the creation of a seller-buyer relationship in which OPENHAUS does not have any interest. 
        Listers and Searchers are responsible for any Booking Modifications they agree to make via the OPENHAUS website or customer service on their behalf and agree to pay any additional amounts, fees, or taxes associated with a Booking Modification done by our customer services.`,
        },
        {
          subtitle: "Receiving and responding to offers.",
          body: `Because the OPENHAUS website is online 24/7 your active listing can receive an offer of purchase at any time even on non-business days and you are responsible for managing the offer or the multiple offers you have received by yourself. OPENHAUS website does not act as a realtor so there is no assistance at all in doing it. Once you select all the offers, you have to control by yourself as a lister all its conditions, terms and deadlines to reply on time or let it expire. OPENHAUS website displays to the offerors a series of elements which do not imply the existence of any form of realtor or legal advice for the transaction. These options to the offeror are only references to be used to help them to build their own offer, and offerors are responsible for including all the content of their offers that they judge important for them without any intervention. As a lister, you are aware of that and also know that you can receive an offer from outside OPENHAUS’s website. As a lister, once you decide to respond to the offer you have selected, accepting or counter-offering it for instance, the other offers will terminate automatically and be removed from the website. Listers can only respond to one offer at a time, so if they want to connect or get information from all the offerors they have to do it before responding to one.`,
        },
        {
          subtitle: "Selling your listing.",
          body: `As a lister, you are legally responsible as an offeror of a real estate property in Canada that you are in legal condition to sell and transfer the possession of it. When listing a real estate property you are obliged to inform any representation or issue to this obligation otherwise it will be understood that you are fully allowed to dispose of and sell your property. OPENHAUS suppliers offer to the listers and sellers title information directly from the Real Estate Property Registry and independent assessments. The use of these services does not constitute advice from OPENHAUS, and you are obliged to confirm all information or documentation received. Like in any other real estate transaction, listers are responsible for declaring to the best of their knowledge any issue with their listed listing on the OPENHAUS website. They are otherwise considered to be listing their property “as is” and OPENHAUS is not responsible for any legal situation that may arise with your listed listing.`,
        },
        {
          subtitle: "Listing removals or modifications.",
          body: `OPENHAUS reserves the right to cancel any listing on its website without notice to the lister or the users and without explanation.`,
        },
      ],
    },
    {
      title: "General Terms",
      content: [
        { subtitle: "Federal and Provincial Taxes." },
        {
          subtitle: "Real Estate and Other Taxes.",
          body: `When a Lister and a Searcher close into a real estate transaction, they are responsible for determining and fulfilling all their obligations under applicable laws to report, collect, remit, or include in your price any applicable federal provincial or other direct or indirect taxes, occupancy taxes, income, foreign taxations and other taxes ("Taxes"). This obligation includes the Land and owner transparency obligations and any other law or regulatory obligation existing or to be passed into law or mandatory rule in the Province. You are obliged to be updated with any pertinent tax obligations related to your listing and searched property.`,
        },
        {
          subtitle: "Tax Information.",
          body: `OPENHAUS reserves the right to communicate with you and directly with the taxation authorities the existence of any real estate transaction on its website without any restriction and any other related or required information.`,
        },
        {
          subtitle: "General Content.",
          body: `Parts of the OPENHAUS website enable you to provide feedback, text, photos, audio, video, information, and other content (“Content”). By providing Content, in whatever form and through whatever means, you grant OPENHAUS a non-exclusive, worldwide, royalty-free, perpetual, sub-licensable, and transferable license to access, use, store, copy, modify, prepare derivative works of, distribute, publish, transmit, stream, broadcast, and otherwise exploit, in any manner such that Content to provide and/or promote the OPENHAUS website, in any media or platform, known or unknown to date, and in particular on the Internet and social networks. 
        If Content includes personal information, such Content will only be used for these purposes if such use complies with applicable data protection laws in accordance with our Privacy Policy. 
        You are solely responsible for all Content that you provide and warrant that you either own it or are authorized to grant OPENHAUS the rights described in these Terms. You are responsible and liable if any of your Content violates or infringes the intellectual property or privacy rights of any third party.`,
        },
        {
          subtitle: "NOT A Legal advice, decision-making responsibility.",
          body: `OPENHAUS is not a law firm and does not provide Legal Advice (as defined below). Your use of OPENHAUS materials, documents or services does not create a solicitor-client relationship between you and OPENHAUS.
        You agree that all decisions you make on legal matters are your full responsibility and you agree to retain legal counsel licensed to practice in your jurisdiction regarding any legal issue of enough importance to reasonably require it.
        OPENHAUS does not provide Legal Advice. Communication sent from OPENHAUS, its agents, its employees or any other associated entity, is not intended to be legal advice. You must consult your lawyer and delete or disconsider any information received from OPENHAUS or its agents or employees that could signify to you something similar to legal advice. You agree to determine the value and validity of OPENHAUS materials and documents.
        For the purposes of these Terms, we define Legal Advice as follows:`,
          bullet_points: {
            list_type: listType.numeric,
            items: [
              "Any form of communication, work or service which is only allowed by a licensed lawyer;",
              "Any document or material which concerns your rights under any situation;",
              "The determination of legal consequences that will result from how you have created a document with legal effects;",
              "The information you have included in these documents or materials is conflicting or not appropriate;",
              "Your omission of important clauses or details that are required in law to execute and prepare a document creates legal effects;",
              "Any additional information or documentation you require from a legal transaction or procedure.",
            ],
          },
        },
        {
          subtitle: "Fees.",
          body: `OPENHAUS  may charge fees (and applicable Taxes) to its users for the right to use its website. More information about when service fees will be provided at this time. Fees are disclosed to users before they decide to pay them. All these fees are non-refundable. OPENHAUS reserves the right to change them at any time by giving notice on its website.`,
        },
        {
          subtitle: "Intellectual property. ",
          body: `You agree not to publish, re-publish, lend, license, give away, look at the software source code, modify the software source code, post to an Internet website, or use in an automated system any OPENHAUS material on its website nor will you utilize it in any way for the creation of an automated system or website, nor will you allow or assist a third party to do so. You must immediately respect any direction to cessation of violating OPENHAUS intellectual property no matter the subject in discussion. All copyrights, trademarks (including its distinguishing guise and/or trade dress), and other intellectual property rights (registered and unregistered) of OPENHAUS’ content, documents and materials on its website belong to OPENHAUS.`,
        },
        {
          subtitle: "OPENHAUS Rules of Conduct.",
          body: `
        You must follow these rules and must not help or induce others to break or circumvent these rules. You agree not to use any of our website and materials included on it for any purpose which is against any law in your jurisdiction or for any purpose which would not give full effect to the Terms even if that purpose is otherwise allowed under these terms.
        `,
          bullet_points: {
            list_type: listType.bullet,
            items: [
              "Act with respect and integrity, do not discriminate do not lie using the OPENHAUS website;",
              "Do not scrape, reverse engineer, hack or compromise in any way any part of the OPENHAUS website;",
              "Only use the OPENHHAUS website as authorized by these terms;",
              "Do not manipulate our algorithms;",
              "Do not make false, fraudulent or misleading offers, counteroffers, modifications, bookings, open houses or any other similar activity on the OPENHAUS website;",
              "Honor your legal obligations assumed on the OPENHAUS website;",
              "Any other rule or direction determined by OPENHAUS sent to your registered email address providing guidance.",
            ],
          },
        },
        {
          subtitle: "Reporting Violations: ",
          body: "If you believe that another person uses the OPENHAUS website to list or communicate any content that poses an imminent risk of harm to anyone, you should immediately contact local authorities and OPENHAUS. OPENHAUS is authorized by you to get a copy of your report from these authorities. OPENHAUS reserves the right to report any violation of the rules of conduct and to terminate your agreement to use its website at any time, by freezing all data and collecting all information about your activity or any activity in violation of the rules of these terms or the law. OPENHAUS may take any action representing a user if it determines is reasonably necessary to comply with applicable law, or the order or request of a court, law enforcement, or other administrative agency or governmental body, including the measures.",
        },
        {
          subtitle:
            "Not real estate services or related real estate services. ",
          body: `OPENHAUS does not render real estate services or related real estate services. It only provides website communications, listing assistance services and useful materials to assist you in the searching, offering, selling or purchasing of your property. For these terms, real estate services and related services are defined by the REAL ESTATE SERVICES ACT of British Columbia SBC 2004 as follows: "Real estate services" means: (a)rental property management services, (b)strata management services, or(c) trading services; "related" means,(a) in relation to a brokerage, a managing broker, associate broker or representative who is licensed in relation to the brokerage, and (b) in relation to a managing broker, associate broker or representative, the brokerage in relation to which the person is licensed.`,
        },
        {
          subtitle: "Termination, Suspension, and other Measures.",
          body: `As long as you have access to your profile and user on OPENHAUS website these terms remain in effect until we decide to terminate the agreement.
        You may terminate this agreement at any time by sending us an email or by deleting your account. OPENHAUS may also terminate without notice and stop providing you with access to its website if you violate the terms or if we reasonably believe termination is necessary to protect OPENHAUS or other users or the service providers on our website. If your account has been inactive for more than one year it will be suspended and/or terminated at our discretion without notice. Termination implies the immediate cancellation of any offer you made in the course of the decision, any offer of booking dates and open house, and the cancellation of your profile, no matter how it is or what usage is currently active at this moment.
        `,
        },
        {
          subtitle: "Effect of Termination.",
          body: `If you are a lister and terminate your OPENHAUS account, any confirmed offer will be automatically cancelled. You must keep your own record of your own transactions. 
      If you terminate your account as a searcher, any confirmed booking(s) or visits will be automatically cancelled. When this agreement has been terminated, you are not entitled to a restoration of your account or any of your Content. If your access to or use of the OPENHAUS website Platform has been limited, or your OPENHAUS account has been suspended, or this agreement has been terminated by us, you may not register a new account or access or use the OPENHAUS website through an account of another Member.
      `,
        },
        {
          subtitle: "Survival. ",
          body: `These Terms by their nature survive termination, will survive termination of this agreement.`,
        },
        {
          subtitle: "Binding Agreement to Arbitrate and Governing Laws. ",
          body: `You and OPENHAUS agree that any dispute, claim or controversy arising out of or relating to these terms, or the applicability, breach, termination, validity, enforcement or interpretation, any use of the OPENHAUS website, contracting and performance of services by third parties on OPENHAUS website will be settled by binding arbitration by one arbitrator on an individual basis in British Columbia and under the law of British Columbia, Canada. At least 30 days prior to initiating an arbitration, you and OPENHAUS each agree to send the other party an individualized notice of the dispute in writing and attempt in good faith to negotiate under mandatory mediation an informal resolution of the individual claim. You and OPENHAUS acknowledge and agree that both parties are each waiving the right to a trial by jury as to all arbitrable disputes. In the event that any portion of this Arbitration Agreement is deemed illegal or unenforceable, such provision will be severed and the remainder of the Arbitration Agreement will be given full force and effect.
        You will not seek to have the dispute heard as a class action, private attorney general action or in any other proceeding in which either party acts or proposes to act in a representative capacity. arbitration or any other proceeding to resolve any dispute, in any forum, will be conducted solely on an individual basis and not combined with another without the prior written consent of all parties to all affected arbitrations or proceedings.
        `,
        },
        {
          subtitle: "Modification and assignment.",
          body: `OPENHAUS  may modify these Terms at any time. Material changes to these Terms will be posted as revised Terms on the OPENHAUS website. You are responsible for knowing and getting updated. This agreement cannot be assigned.`,
        },
        {
          subtitle: "OPENHAUS’s  Role. ",
          body: `OPENHAUS offers you a website to list, publish, offer, accept offers, search for, hire independent services and book visits for its listed properties. You acknowledge that OPENHAUS has the right, without any obligation, to monitor the use of its website and verify all the information provided by its users.`,
        },
        {
          subtitle: "USERS Accounts.",
          body: `You must register an account to access and use many features of the OPENHAUS website. Registration is only permitted for individuals who are 19 years or older. You must provide accurate, current, and complete information during registration and keep your account information up to date. You may not transfer your account to someone else. You are responsible for maintaining the confidentiality and security of your account credentials and may not disclose your credentials to any third party. You are responsible and liable for activities conducted through your account and must immediately notify OPENHAUS  if you suspect that your credentials have been lost, or stolen, or your account is otherwise compromised.    `,
        },
        {
          subtitle: "Disclaimer of Warranties.",
          body: `OPENHAUS website and all its content including the independent services are considered “as is” without warranty of any kind. Neither OPENHAUS nor any other person or entity involved with OPENHAUS in the operation of its website, including but not limited to employees, agents or directors will be liable for any damage, including loss of data, profits and goodwill, service interruptions, computer damage, system failure or the cost of substitute products or services, or for any damages or emotional distress, arising out for the use of OPENHAUS website. You agree to release, defend, indemnify and hold OPENHAUS harmless from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal and accounting fees with your breach of any contract relating or connected to the use of or operated on OPENHAUS website and all its content, documents, services and materials. Your improper use of the OPENHAUS website, its content, documents, services and materials is your sole responsibility.`,
        },
        {
          subtitle: "Maximum liability. ",
          body: `The maximum liability of OPENHAUS is the amount paid to OPENHAUS by the user; however, where an independent service provider listed on the OPENHAUS website has provided a service, the maximum liability of OPENHAUS  is OPENHAUS’ commission, calculated as the amount directly paid to OPENHAUS less the cost of this service paid to the independent service provider. As a result of a clear OPENHAUS mistake, OPENHAUS will cover the damage only to a maximum of $500 CAD.`,
        },
        {
          subtitle: "Client Privacy.",
          body: `You agree and consent that OPENHAUS uses your personal information according to its Privacy Policy.`,
        },
      ],
    },
  ],
};
