import { atom, atomFamily } from "recoil";
import { recoilPersist } from "recoil-persist";
import {
  RentType,
  RentalApplicationForm,
  RentalDetails,
  RentalDetailsForm,
} from "../../types/rentals";

const { persistAtom } = recoilPersist();

export const applicationOpenAtom = atom<boolean>({
  key: "applicationOpenAtom",
  default: false,
});

export const userRentalsAtom = atom<string[]>({
  key: "userRentalsAtom",
  default: [],
  effects: [persistAtom],
});

export const rentalListingIdsAtom = atom<string[]>({
  key: "rentalListingIdsAtom",
  default: [],
  effects: [persistAtom],
});

// keyed by listing_id
export const rentalDetailsFamily = atomFamily<RentalDetails | null, string>({
  key: "rentalDetailsFamily",
  default: null,
  effects: [persistAtom],
});

export const rentalInputForm = atom<RentalDetailsForm>({
  key: "rentalInputForm",
  default: {
    listing_id: "", // this is the id the rental details are associated to
    rent_type: RentType.MonthToMonth, // smallint
    duration: 1, // in terms of months (has to be > 0)
    preferred_start_date: new Date(),
    max_occupancy: 1,
    dogs: false,
    cats: false,
    requirements: {
      smoker: false,
      currently_renting: true,
      current_landlord_contact: true,
      yearly_income: true,
      drivers_license: true,
      reason: false,
      credit_score: false,
      employer: false,
      bank_statement: false,
      occupation: false,
      employed_duration: false,
      reference_contact: false,
      insurance: false,
    },
    restriction_notes: "", // ex. "Dogs only under 30lbs, female only etc"
    utilities_included: "", // ex. "internet, water"
    utilities_excluded: "", // ex. "electricity, gas"
  },
});

export const rentalApplicationFormAtom = atom<RentalApplicationForm>({
  key: "rentalApplicationFormAtom",
  default: {
    applicant_id: "",
    listing_id: "",
    applicants: [],
    start_date: new Date(),
    document_ids: [],
  },
});
