import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardBody, Spinner } from "@chakra-ui/react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useAuthApi from "../hooks/useAuthApi";
import { Text } from "@chakra-ui/react";
export const VerifySuccess = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const { verifyUser } = useAuthApi();
  const [verified, setVerified] = useState<0 | 1 | 2 | 3>(0);

  useEffect(() => {
    (async () => {
      if (code && verified === 0) {
        setVerified(1);
        if (await verifyUser(code)) {
          setVerified(2);
        } else {
          setVerified(3);
        }
      }
    })();
  }, [code, verified, verifyUser]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (verified === 2) {
      timeout = setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [verified, navigate]);
  return (
    <Box m={"10"}>
      <Card marginBottom={5} border={"2px solid"}>
        <CardBody placeContent={"center"} textAlign={"center"}>
          {verified === 1 && <Spinner />}
          {verified === 2 && (
            <Box>
              <Text>Email verified! </Text>
              <Text>
                If you are not automatically redirected to login, please click
                the link below.{" "}
              </Text>
              <Button variant={"solid"}>
                <Link to="/login">Log in</Link>
              </Button>
            </Box>
          )}
          {verified === 3 && <Text>Failed Verification</Text>}
        </CardBody>
      </Card>
    </Box>
  );
};
