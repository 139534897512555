import { useRecoilTransaction_UNSTABLE } from "recoil";
import { Message, Offer, OfferStatus, Subject } from "../../types";
import { latestMessagesAtom, messageHistoryAtomFamily } from "./atoms";
import { userState } from "../atoms";
import { deserializeMessage } from "../../hooks/useMessagesApi";
import {
  offerAtomFamily,
  listingOfferIdsAtomFamily,
  subjectAtomFamily,
} from "../offers/atoms";
import uniq from "lodash/uniq";

export const useUpdateMessageHistory = () =>
  useRecoilTransaction_UNSTABLE<[Message]>(
    ({ set, get }) =>
      (rawMessage) => {
        const message = deserializeMessage(rawMessage);
        // me
        const currentUser = get(userState)?.id;
        // not me
        const messageUser =
          message.sender_id === currentUser
            ? message.receiver_id
            : message.sender_id;
        const currentMessageHistory = get(
          messageHistoryAtomFamily(message.listing_id + messageUser)
        );
        set(messageHistoryAtomFamily(message.listing_id + messageUser), [
          ...currentMessageHistory,
          message,
        ]);
        let found = false;
        const latestMessages = get(latestMessagesAtom);
        const withNewMessage = latestMessages.map((m) => {
          if (
            m.listing_id === message.listing_id &&
            [m.sender_id, m.receiver_id].includes(messageUser)
          ) {
            found = true;
            return message;
          } else {
            return m;
          }
        });
        if (!found) withNewMessage.push(message);

        set(latestMessagesAtom, withNewMessage);
      },
    []
  );

export const useUpdateOffers = () =>
  useRecoilTransaction_UNSTABLE<[Offer[]]>(
    ({ set, get }) =>
      (offers) => {
        const offersByListing = {} as { [listingId: string]: string[] };
        offers.forEach((o) => {
          const { listing_id, id } = o;
          if (!offersByListing[listing_id]) {
            offersByListing[listing_id] = [id];
          } else {
            offersByListing[listing_id].push(id);
          }
          set(offerAtomFamily(id), serializeOffer(o));
        });
        Object.entries(offersByListing).forEach(([listingId, offerIds]) => {
          const current = get(listingOfferIdsAtomFamily(listingId));
          set(
            listingOfferIdsAtomFamily(listingId),
            uniq([...current, ...offerIds])
          );
        });
      },
    []
  );

export const useUpdateOffer = () =>
  useRecoilTransaction_UNSTABLE<[Offer]>(
    ({ set, get }) =>
      (offer) => {
        set(offerAtomFamily(offer.id), serializeOffer(offer));
        const listingOffers = get(listingOfferIdsAtomFamily(offer.listing_id));
        if (!listingOffers.includes(offer.id)) {
          set(listingOfferIdsAtomFamily(offer.listing_id), [
            ...listingOffers,
            offer.id,
          ]);
        }
      },
    []
  );

export const useDeleteOffer = () =>
  useRecoilTransaction_UNSTABLE<[string]>(
    ({ reset }) =>
      (offer_id) => {
        reset(offerAtomFamily(offer_id));
      },
    []
  );

export const useAddOfferSubject = () =>
  useRecoilTransaction_UNSTABLE<[Subject]>(
    ({ set }) =>
      (subject) => {
        set(subjectAtomFamily(subject.id), subject);
      },
    []
  );

export const useSetOfferStatus = () =>
  useRecoilTransaction_UNSTABLE<[string, OfferStatus]>(
    ({ set, get }) =>
      (offerId, status) => {
        const offer = get(offerAtomFamily(offerId));
        if (offer) {
          set(offerAtomFamily(offerId), {
            ...offer,
            offer_status: status,
          });
        }
      },
    []
  );

export const serializeOffer = (o: Offer) => {
  return {
    ...o,
    date_created: new Date(o.date_created),
    date_updated: o.date_updated ? new Date(o.date_updated) : undefined,
  } as Offer;
};
