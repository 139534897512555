import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Image,
} from "@chakra-ui/react";

import { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { listingFamily } from "../../../recoil/listings";
import { MediaType } from "../../../types";
import { FullScreenSlideshow } from "./ImageSlideShow";

export const ViewAllMediaModal = ({
  listingId,
  isOpen,
  onClose,
}: {
  listingId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const listing = useRecoilValue(listingFamily(listingId));
  const [selectedIdx, setSelectedIdx] = useState<number | null>();

  const previewAll = useMemo(
    () =>
      listing?.file_urls.map(({ type, src, id }, index) => (
        <Box key={id} minW={"20"} onClick={() => setSelectedIdx(index)}>
          <Flex key={id} justify={"space-between"}>
            {type === MediaType.Image ? (
              <Image src={src} alt="Listing Asset" maxH={200} />
            ) : type === MediaType.Video ? (
              <video style={{ maxHeight: 200 }} src={src} controls />
            ) : (
              <p>{src}</p>
            )}
          </Flex>
        </Box>
      )),
    [listing?.file_urls]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
      <ModalOverlay />
      <ModalContent bg={"container-bg"}>
        <FullScreenSlideshow
          isOpen={!!selectedIdx}
          onClose={() => setSelectedIdx(null)}
          selected={selectedIdx ?? undefined}
          images={listing?.file_urls ?? []}
        />
        <ModalCloseButton />
        <ModalBody p={10}>
          <Flex wrap={"wrap"} gap={3} justify={"space-evenly"}>
            {previewAll}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
