import {
  Box,
  Center,
  Flex,
  Button,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useIsMobile } from "../hooks/utils/useWindowDimensions";
import { DollarSign, Map } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../recoil/atoms";
import { useLoadListings } from "../hooks/Listings";
import { useEffect } from "react";

export const Entryway = () => {
  const isMobile = useIsMobile();
  const user = useRecoilValue(userState);
  const { loadUserListings } = useLoadListings();
  useEffect(() => {
    if (user && user.token) {
      loadUserListings();
    }
  }, [loadUserListings, user]);
  const navigate = useNavigate();

  return (
    <Center width="100%">
      <Box
        width="100%"
        textAlign={"center"}
        justifyContent={"center"}
        padding={6}
      >
        <Heading
          mb={{ base: 8, md: 10 }}
          fontSize={{ base: "5xl", md: "6xl" }}
          fontWeight={"medium"}
          width={isMobile ? "100%" : "75%"}
          display={"inline-block"}
        >
          Real estate is simple with{" "}
          <Text as="span" color="blue.500">
            Openhaus
          </Text>
          .
        </Heading>
        <Center>
          <Flex
            justify={"center"}
            w={"full"}
            direction={isMobile ? "column" : "row"}
          >
            <Button
              onClick={() => navigate("/manage/listings")}
              variant={"secondary"}
              minW={isMobile ? "full" : 300}
              h={isMobile ? 200 : 300}
              borderRadius={isMobile ? "lg" : "xl"}
              border={"1.5px solid"}
              mr={isMobile ? 0 : 6}
            >
              <VStack spacing={6}>
                <DollarSign size={50} />
                <Text fontSize={18}>I'm a seller</Text>
              </VStack>
            </Button>
            {isMobile && <Box h={6} />}
            <Button
              onClick={() => navigate("/listings")}
              variant={"secondary"}
              minW={isMobile ? "full" : 300}
              h={isMobile ? 200 : 300}
              borderRadius={isMobile ? "lg" : "xl"}
              border={"1.5px solid"}
            >
              <VStack spacing={6}>
                <Map size={50} />
                <Text fontSize={18}>Browse properties</Text>
              </VStack>
            </Button>
          </Flex>
        </Center>
      </Box>
    </Center>
  );
};
