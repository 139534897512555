import { Listing, MediaItem } from ".";

export enum RentalStatus {
  Draft = 0,
  InReview = 1,
  Active = 2,
  Rented = 3,
  Archived = 4,
  Deleted = 5,
}

export type Rental = Listing & RentalDetails;

export enum RentType {
  SetTerm, // Specified duration
  MonthToMonth, // this is for rentals that start as a set term and then are on a month to month basis
}

export type RentalRequirements = {
  currently_renting: boolean;
  current_landlord_contact: boolean;
  reason: boolean;
  credit_score: boolean;
  yearly_income: boolean;
  employer: boolean;
  bank_statement: boolean;
  occupation: boolean;
  employed_duration: boolean;
  reference_contact: boolean;
  drivers_license: boolean;
  insurance: boolean;
  smoker: boolean;
};

export type RentalDetailsForm = {
  listing_id: string; // this is the id the rental details are associated to
  rent_type: RentType; // smallint
  duration: number; // in terms of months (has to be > 0)
  preferred_start_date: Date;
  end_date?: Date;
  max_occupancy: number;
  dogs: boolean;
  cats: boolean;
  requirements: RentalRequirements;
  restriction_notes: string; // ex. "Dogs only under 30lbs, female only etc"
  utilities_included: string; // ex. "internet, water"
  utilities_excluded: string; // ex. "electricity, gas"
};

export interface RentalDetails extends RentalDetailsForm {
  id: string;
  // rental status is based on joining with applications for listing_id thats is a rental
  // the viewability is based on listing status like with regular listings
  // if there is an application for listing_id that has been accepted, its done
  date_created?: Date | string;
  date_updated?: Date | string;
  date_deleted?: Date | string;
}

type Pet = {
  type: string;
  weight: number;
};

export type Applicant = {
  id: string;
  full_name: string;
  notes: string;
  credit_score: string;
  employer: string;
  occupation: string;
  employed_duration: string;
  yearly_income: string;
  address: string;
  currently_renting: boolean;
  current_landlord_contact: string;
  reason: string;
  signature_id?: string; // todo each applicant must have a signature before submitting
  smoker: boolean;
  reference_contact: string;
  insurance: boolean; // do you currently insure your personal belongings
  drivers_license: string;
  pets: Pet[];
};

export type RentalApplicationForm = {
  applicant_id: string;
  listing_id: string; // refers to rental listing
  applicants: Applicant[]; // will be stored as JSON
  start_date: Date;
  document_ids: string[]; // when creating the application, applicant will upload documents
};

export enum ApplicationStatus {
  Draft,
  Submitted,
  Accepted,
  Rejected,
}

export interface RentalApplication extends RentalApplicationForm {
  id: string;
  date_submitted: Date;
  status: ApplicationStatus;
  documents: MediaItem[]; // list of documents associated with application
}

// todo for existing endpoints add a check for is_rental === false
