import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  StepIndicator,
  Step,
  StepIcon,
  StepStatus,
  Stepper,
  useSteps,
  Box,
  StepNumber,
  StepSeparator,
  StepTitle,
  Text,
} from "@chakra-ui/react";
import { FileUploader } from "../common/FileUploader";
import useKycApi from "../../hooks/useKycApi";
import { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { filesToUploadAtomFamily } from "../../recoil/listings/atoms";
import { kycOpenAtom } from "../../recoil/atoms";
import { useIsMobile } from "../../hooks/utils/useWindowDimensions";
import { useToggleKycPendingState } from "../../recoil/users/transactions";
import { useKycPending } from "../../recoil/users/selectors";

const steps = [
  { title: "Get Started" },
  { title: "Proof of ID" },
  { title: "Proof of Address" },
];

export const KycWall = () => {
  const { uploadId, uploadProofAddress } = useKycApi();
  const idFiles = useRecoilValue(filesToUploadAtomFamily("kyc-id"));
  const proofFiles = useRecoilValue(filesToUploadAtomFamily("kyc-proof"));
  const [kycOpen, setKycOpen] = useRecoilState(kycOpenAtom);
  const [loading, setLoading] = useState(false);
  const setKycPending = useToggleKycPendingState();
  const kycPending = useKycPending();
  const isMobile = useIsMobile();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const handleContinue = useCallback(async () => {
    try {
      setLoading(true);
      switch (activeStep) {
        case 0:
          setActiveStep(1);
          break;
        case 1:
          // ID UPLOAD
          if (await uploadId(idFiles[0])) {
            setActiveStep(2);
          }
          break;
        case 2:
          // PROOF UPLOAD
          if (await uploadProofAddress(proofFiles[0])) {
            // set as completed and needs to wait for review to finish
            setKycPending(true);
          }

          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [
    activeStep,
    setKycPending,
    idFiles,
    proofFiles,
    setActiveStep,
    uploadId,
    uploadProofAddress,
  ]);

  return (
    <Modal
      isOpen={kycOpen}
      onClose={() => {
        setActiveStep(0);
        setKycOpen(false);
      }}
      scrollBehavior="inside"
      isCentered
      blockScrollOnMount
    >
      <ModalOverlay />
      <ModalCloseButton />
      {kycPending ? (
        <ModalContent bg={"drawer-color"}>
          <ModalHeader fontSize={"2xl"}>Verification Pending</ModalHeader>
          <ModalBody marginTop={6} mb={6}>
            <Text>
              Your documents have been uploaded! You should be verified within
              the next couple hours. You will receive an email confirming
              successful verification
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                setActiveStep(1);
                setKycPending(false);
              }}
            >
              Reupload files
            </Button>
            <Button
              variant={"primary"}
              ml={2}
              isLoading={loading}
              onClick={() => setKycOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent bg={"drawer-color"}>
          <ModalHeader fontSize={"2xl"}>Verification Needed</ModalHeader>
          <Stepper
            pl={5}
            pt={2}
            size="sm"
            index={activeStep}
            gap="3"
            maxWidth={isMobile ? "95%" : "90%"}
          >
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{isMobile ? "" : step.title}</StepTitle>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
          <ModalBody
            marginTop={6}
            mb={6}
            maxH={isMobile ? 300 : 500}
            overflowY={"scroll"}
          >
            {activeStep === 0 && (
              <Text>
                Just a quick step to ensure you get the best experience with us.
                Verifying your account takes only a moment and is a key part of
                maintaining trust between all our users.
              </Text>
            )}
            {activeStep === 1 && (
              <>
                <Text mb={2}>Upload a government issued ID or passport.</Text>
                <FileUploader fileLimit={1} type="kyc-id" />
              </>
            )}

            {activeStep === 2 && (
              <>
                <Text mb={2}>
                  Upload a utility bill or bank statement for proof address.
                </Text>
                <FileUploader fileLimit={1} type="kyc-proof" />
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant={"primary"}
              ml={2}
              isLoading={loading}
              onClick={handleContinue}
            >
              {activeStep === 0
                ? "Get Started"
                : activeStep === 2
                ? "Submit"
                : "Next"}
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
};
