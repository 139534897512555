import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Divider,
  Stack,
  Heading,
  Button,
  Center,
  VStack,
} from "@chakra-ui/react";
import { loginModalOpen, userState } from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import ImageSlideshow from "./Media/ImageSlideShow";
import { useIsMobile } from "../../hooks/utils/useWindowDimensions";
import { ListingStatus } from "../../types";
import { ShareButton } from "../common/ShareButton";
import { useListingApi } from "../../hooks/Listings";
import { useAdminState, useUserKycd } from "../../recoil/users/selectors";
import { useTriggerKycWindow } from "../../recoil/users/transactions";
import { ListingStatusBadge } from "../common/ListingStatusBadge";
import useMapboxApi from "../../hooks/useMapbox";
import { listingFamily } from "../../recoil/listings";
import { ViewAllMediaModal } from "./Media/ViewAllMediaModal";
import { ListingDetails } from "./ListingDetails";
import { useLoadListings } from "../../hooks/Listings";
import { ViewCounter } from "../common/ViewCounter";
import { formatPrice } from "../../utils/formatters";
import MortgageCalculator from "../mortgage/MortgageCalculator";
import { selectBestRate } from "../../recoil/perch/atoms";
import { useFetchPerchData } from "../../hooks/useGetPerchData";

interface ListingCardProps {
  listingId: string;
}

const ListingView: React.FC<ListingCardProps> = ({ listingId }) => {
  const listing = useRecoilValue(listingFamily(listingId));
  const { updateDraftListingToActive, updateDraftListingToReview } =
    useListingApi();
  useFetchPerchData();
  const { loadListing } = useLoadListings();
  const user = useRecoilValue(userState);
  const isLoggedIn = !!user?.id;
  const isMobile = useIsMobile();
  const setLoginOpen = useSetRecoilState(loginModalOpen);
  const isKyced = useUserKycd();
  const [seeAll, setSeeAll] = useState(false);
  const triggerKycWindow = useTriggerKycWindow();
  const isAdmin = useAdminState();
  const bestRate = useRecoilValue(selectBestRate);
  const { getListingGeocode } = useMapboxApi();
  const mortgageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listing && (!listing.latitude || !listing.longitude)) {
      getListingGeocode(
        listing.id,
        listing.address,
        listing.city,
        listing.state_province,
        listing.country,
        listing.zip
      );
    }
  }, [listing, getListingGeocode]);

  useEffect(() => {
    if (listingId) loadListing(listingId);
  }, [loadListing, listingId]);

  if (!listing) return null;

  return (
    <Box minHeight={"100%"} px={isMobile ? 0 : 5} pb={8}>
      <Box maxW={{ base: "90vw", md: "60vw" }} justifyContent={"center"}>
        {!!listing.file_urls.length && (
          <ImageSlideshow images={listing.file_urls} allowFullscreen={true} />
        )}
      </Box>
      {!!listing.file_urls.length && (
        <Flex
          justify={"space-between"}
          direction={"row"}
          align={"center"}
          mt={2}
          px={isMobile ? 0 : 5}
        >
          <Text color="gray.500">{listing.file_urls.length} Photos/Videos</Text>
          <Text
            color="gray.500"
            variant={"ghost"}
            cursor={"pointer"}
            onClick={() => setSeeAll(true)}
          >
            See all
          </Text>
          <ViewAllMediaModal
            listingId={listingId}
            isOpen={seeAll}
            onClose={() => setSeeAll(false)}
          />
        </Flex>
      )}
      <Stack spacing="3" mb={5}>
        <Heading size="md"> {listing.title}</Heading>
        <Flex
          alignItems="baseline"
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Box flexGrow={1}>
            <ListingStatusBadge
              status={listing.listing_status}
              isRental={listing.is_rental}
            />
            {isAdmin && listing.listing_status === ListingStatus.InReview && (
              <Button
                ml={4}
                onClick={() => {
                  updateDraftListingToActive(listingId);
                }}
                variant={"primary"}
              >
                Publish Listing
              </Button>
            )}
            <Text
              textTransform="uppercase"
              fontSize="md"
              fontWeight="semibold"
              color="gray.500"
              mt={2}
            >
              {listing.address} {listing.apt_no ? `#${listing.apt_no}` : ""}
              <br />
              {listing.city}, {listing.state_province}
            </Text>
          </Box>
          <Flex justify={"space-between"} gap={3} align={"center"}>
            {listing.views && <ViewCounter value={listing.views} />}
            <ShareButton listingId={listing.id} />
          </Flex>
        </Flex>
        <Flex>
          <Text>
            {listing.description.split("\n").map((line, i) => (
              <span key={i}>
                {line}
                <br />
              </span>
            ))}
          </Text>
        </Flex>
      </Stack>
      <Divider
        color={"lightgray"}
        marginLeft={"auto"}
        marginRight={"auto"}
        marginBottom={5}
      />
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        marginBottom={5}
        align={"center"}
      >
        <VStack>
          <Text fontSize="2xl" fontWeight={"semibold"}>
            ${formatPrice(listing.price)}
          </Text>
          <Button
            variant={"link"}
            color={"subheading-txt"}
            onClick={() => {
              mortgageRef.current &&
                mortgageRef.current.scrollIntoView({
                  behavior: "smooth",
                });
            }}
            fontSize={"sm"}
          >
            Mortgage calculator
          </Button>
        </VStack>
        <VStack>
          <Button
            as={"a"}
            variant={"primary"}
            href={"https://app.myperch.io/auth/sign-up?referral=OPENHAUS"}
            target="_blank"
            referrerPolicy="origin"
          >
            Get Qualified Now
          </Button>
          {bestRate && (
            <Text fontSize={"xs"}>
              Lowest rate in Canada: <b>{bestRate}%</b>
            </Text>
          )}
        </VStack>
      </Flex>
      {!isLoggedIn && (
        <Box
          position={"sticky"}
          top={"20vh"}
          bg={"expandable-bg"}
          bgBlendMode={"darken"}
          zIndex={20}
          rounded={"2xl"}
          pb={6}
        >
          <Box>
            <Text fontSize="lg" p={6} textAlign={"center"}>
              <Text fontWeight={600}>
                View detailed listing information
                <br />
                Contact seller <br />
                Submit an offer <br />
              </Text>
            </Text>
          </Box>
          <Center>
            <Button variant={"primary"} onClick={() => setLoginOpen(true)}>
              Log in / Sign up
            </Button>
          </Center>
        </Box>
      )}
      <ListingDetails listingId={listingId} />
      <MortgageCalculator listingId={listingId} />
      <div ref={mortgageRef} />
      {user?.token &&
      listing.seller_id === user.id &&
      listing.listing_status === ListingStatus.Draft ? (
        <Button
          onClick={() => {
            if (isKyced) {
              updateDraftListingToReview(listingId);
            } else {
              triggerKycWindow();
            }
          }}
        >
          Submit for Review
        </Button>
      ) : null}
    </Box>
  );
};

export default ListingView;
