import { Flex, Button, Text, Box, Divider, Heading } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import {
  CostBreakdown as CostBreakdownType,
  draftCostBreakdown,
} from "../../../recoil/offers/atoms";

export const CostBreakdown = ({ viewOnly }: { viewOnly?: boolean }) => {
  const [costs, setCosts] = useRecoilState(draftCostBreakdown);

  const handleInputChange = (key: keyof CostBreakdownType, value: boolean) => {
    setCosts({
      ...costs,
      [key]: value,
    });
  };

  return (
    <Box>
      <Heading size="md" mb={2}>
        Cost Breakdown
      </Heading>
      <Box rounded={"lg"} p={2} my={2}>
        <Text fontWeight={"semibold"} fontSize={"lg"} mb={2}>
          Lawyer or notary Fees and Expenses:
        </Text>
        <CostSelector
          title="Attending to execution documents"
          value={costs.attending_exec_docs}
          onChange={(v) => handleInputChange("attending_exec_docs", v)}
          viewOnly={viewOnly}
        />
        <CostSelector
          title="Searching title"
          value={costs.searching_title}
          onChange={(v) => handleInputChange("searching_title", v)}
          viewOnly={viewOnly}
        />
        <CostSelector
          title="Drafting documents"
          value={costs.drafting_documents}
          onChange={(v) => handleInputChange("drafting_documents", v)}
          viewOnly={viewOnly}
        />
      </Box>
      <Divider />
      <Box p={2} my={2}>
        <Text fontWeight={"semibold"} fontSize={"lg"} mb={2}>
          Costs of clearing title, including:
        </Text>
        <CostSelector
          title="Investigating title"
          value={costs.investigating_title}
          onChange={(v) => handleInputChange("investigating_title", v)}
          viewOnly={viewOnly}
        />
        <CostSelector
          title="Discharge fees charged by encumbrance holders"
          value={costs.discharge_fees}
          onChange={(v) => handleInputChange("discharge_fees", v)}
          viewOnly={viewOnly}
        />
        <CostSelector
          title="Prepayment penalties"
          value={costs.prepayment_penalties}
          onChange={(v) => handleInputChange("prepayment_penalties", v)}
          viewOnly={viewOnly}
        />
      </Box>
      <Divider />
      <Box p={2} my={2}>
        <Text fontWeight={"semibold"} fontSize={"lg"} mb={2}>
          Other:
        </Text>
        <CostSelector
          title="Real Estate Commission (plus GST)"
          value={costs.real_estate_commission}
          onChange={(v) => handleInputChange("real_estate_commission", v)}
          viewOnly={viewOnly}
        />

        <CostSelector
          title="Land Title Registration fees"
          value={costs.land_title_reg_fees}
          onChange={(v) => handleInputChange("land_title_reg_fees", v)}
          viewOnly={viewOnly}
        />
        <CostSelector
          title="Survey Certificate (if required)"
          value={costs.survey_certificate}
          onChange={(v) => handleInputChange("survey_certificate", v)}
          viewOnly={viewOnly}
        />
        <CostSelector
          title="Costs of Mortgage"
          value={costs.mortgage}
          onChange={(v) => handleInputChange("mortgage", v)}
          viewOnly={viewOnly}
        />
        <CostSelector
          title="Fire Insurance Premium"
          value={costs.fire_insurance_premium}
          onChange={(v) => handleInputChange("fire_insurance_premium", v)}
          viewOnly={viewOnly}
        />
        <CostSelector
          title="Sales Tax (if applicable)"
          value={costs.sales_tax}
          onChange={(v) => handleInputChange("sales_tax", v)}
          viewOnly={viewOnly}
        />
        <CostSelector
          title="Property Transfer Tax"
          value={costs.property_transfer_tax}
          onChange={(v) => handleInputChange("property_transfer_tax", v)}
          viewOnly={viewOnly}
        />
      </Box>
    </Box>
  );
};

export const CostSelector = ({
  title,
  value,
  onChange,
  viewOnly,
}: {
  title: string;
  value: boolean;
  onChange: (v: boolean) => void;
  viewOnly?: boolean;
}) => {
  return (
    <Flex
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      mb={1}
    >
      <Text fontWeight={"light"}>{title}:</Text>
      <Button
        bg={value ? "cyan.600" : "green.600"}
        onClick={() => !viewOnly && onChange(!value)}
        disabled={viewOnly}
      >
        {value ? "Buyer" : "Seller"}
      </Button>
    </Flex>
  );
};
