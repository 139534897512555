import { useCallback } from "react";
import axios from "axios";
import { FormattedFile } from "../types";
import { useRecoilValue } from "recoil";
import { decrypt } from "../utils/crypto";
import { selectAxiosInstance } from "../recoil/selectors";
import { userState } from "../recoil/atoms";

const useKycApi = () => {
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const user = useRecoilValue(userState);

  const uploadFile = useCallback(
    async (file: FormattedFile, type: "id" | "proof"): Promise<boolean> => {
      if (!user) return false;
      try {
        const response = await axiosInstance.get(
          `/kyc/upload/${file.storageName}`
        );
        const presignedUrl = decrypt(response.data.file_url, user.token);

        const res = await axios.put(presignedUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
        if (res.status !== 200)
          throw new Error("Something went wring with file upload");
        await axiosInstance.post(`/kyc/${type}`, {
          s3_file_name: file.storageName,
        });
        return true;
      } catch (error) {
        console.error({ error });

        return false;
      }
    },
    [axiosInstance, user]
  );

  const uploadId = useCallback(
    async (file: FormattedFile): Promise<boolean> => {
      return uploadFile(file, "id");
    },
    [uploadFile]
  );

  const uploadProofAddress = useCallback(
    async (file: FormattedFile): Promise<boolean> => {
      return uploadFile(file, "proof");
    },
    [uploadFile]
  );

  return { uploadProofAddress, uploadId };
};

export default useKycApi;
