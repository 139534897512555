import { Text } from "@chakra-ui/react";
import { Info } from "react-feather";
import { ICON_SIZE } from "../../theme";
import { OHTooltip } from "./OHTooltip";

export const InputName = ({
  name,
  description,
}: {
  name: string;
  description: string;
}) => {
  return (
    <OHTooltip label={description}>
      <Text fontWeight={600} mb={1}>
        {name}
      </Text>
      <Info size={ICON_SIZE} />
    </OHTooltip>
  );
};
