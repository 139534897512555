import { HStack, Button, Box } from "@chakra-ui/react";
import { useState, useCallback, useEffect } from "react";
import { ArrowLeft, ArrowRight, Save } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  attemptedNextStepAtom,
  selectedListingAtom,
} from "../../../recoil/listings";
import {
  newOfferStepAtom,
  NewOfferStep,
  selectedOfferAtom,
  draftPriceAtom,
  offerAtomFamily,
  draftDepositInfoAtom,
  draftOfferAdjustmentsDateAtom,
  draftOfferCompletionDateAtom,
  draftOfferEndAtom,
  draftOfferPossessionDateAtom,
  draftOfferViewedDateAtom,
  draftCostBreakdown,
  draftOfferExcludedItemsAtom,
  draftOfferIncludedItemsAtom,
  subjectIdsForOfferAtom,
} from "../../../recoil/offers/atoms";
import { ICON_SIZE } from "../../../theme";
import { useOffersApi } from "../../../hooks/Offers";
import { PriceCurrency } from "../../../types";
import { convertDateToString } from "../../../utils/date";
import { HeadingActions } from "../HeadingActions";
import { selectOfferCosts } from "../../../recoil/offers/selectors";
import { addDays, subDays } from "date-fns";

export const OfferStepsFooter = () => {
  const listingId = useRecoilValue(selectedListingAtom);
  const [selected, setSelected] = useRecoilState(selectedOfferAtom);
  const offer = useRecoilValue(offerAtomFamily(selected));
  const date = new Date();
  date && date.setDate(date.getDate() + 4);

  const { saveDraft, updateDraft } = useOffersApi();
  const [completion, setCompletion] = useRecoilState(
    draftOfferCompletionDateAtom
  );
  const [possesion, setPossession] = useRecoilState(
    draftOfferPossessionDateAtom
  );
  const [viewed, setViewed] = useRecoilState(draftOfferViewedDateAtom);
  const [adj, setAdj] = useRecoilState(draftOfferAdjustmentsDateAtom);
  const [offerPrice, setOfferPrice] = useRecoilState(draftPriceAtom);
  const [offerEnd, setOfferEnd] = useRecoilState(draftOfferEndAtom);
  const current = convertDateToString(offer?.offer_start_date);
  const plusYear = convertDateToString(offer ? offer.offer_end_date : current);

  const [depositInfo, setDepositInfo] = useRecoilState(draftDepositInfoAtom);
  const offerCosts = useRecoilValue(selectOfferCosts(selected));
  const [costs, setCosts] = useRecoilState(draftCostBreakdown);
  const [draftIncluded, setDraftIncluded] = useRecoilState(
    draftOfferIncludedItemsAtom
  );
  const [draftExcluded, setDraftExcluded] = useRecoilState(
    draftOfferExcludedItemsAtom
  );

  const contingencies = useRecoilValue(subjectIdsForOfferAtom);

  const plus4Days = convertDateToString(date);

  useEffect(() => {
    if (!offer) return;
    if (offer.deposit)
      setDepositInfo({
        deposit: offer.deposit,
        trust_info: offer.deposit_info ?? "",
      });
    setCosts(offerCosts);
    setOfferEnd(plusYear);
    setOfferPrice(offer.offer_price);
    setAdj(
      convertDateToString(
        offer.offer_possession_date ?? addDays(new Date(), 30)
      )
    );
    setCompletion(
      convertDateToString(
        offer.offer_completion_date ?? addDays(new Date(), 40)
      )
    );
    setPossession(
      convertDateToString(
        offer.offer_possession_date ?? addDays(new Date(), 50)
      )
    );
    setViewed(
      convertDateToString(offer.offer_viewed_date ?? subDays(new Date(), 1))
    );
    if (offer.included_items) {
      setDraftIncluded(offer.included_items);
    }
    if (offer.excluded_items) {
      setDraftExcluded(offer.excluded_items);
    }
  }, [
    offer,
    offerCosts,
    plusYear,
    setAdj,
    setCompletion,
    setCosts,
    setDepositInfo,
    setDraftExcluded,
    setDraftIncluded,
    setOfferEnd,
    setOfferPrice,
    setPossession,
    setViewed,
  ]);

  const onSaveDraft = useCallback(async () => {
    if (!offerPrice.length || !listingId) {
      return;
    }
    const offerId = await saveDraft({
      offer_price: offerPrice,
      listing_id: listingId,
      offer_start_date: new Date(current),
      offer_end_date: new Date(plus4Days),
      offer_price_currency: PriceCurrency.CAD,
    });
    setSelected(offerId);
    return offerId;
  }, [offerPrice, listingId, saveDraft, current, plus4Days, setSelected]);

  const [activeStep, setActiveStep] = useRecoilState(newOfferStepAtom);
  const navigate = useNavigate();
  const setAttempted = useSetRecoilState(attemptedNextStepAtom);

  const [loading] = useState(false);

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };
  const onUpdateDraft = useCallback(async () => {
    if (!offer) return;

    await updateDraft(
      selected,
      {
        offer_price: offerPrice,
        offer_start_date: new Date(),
        offer_end_date: new Date(offerEnd),
        offer_price_currency: PriceCurrency.CAD,
        listing_id: offer.listing_id,
        offer_id: offer.offer_id,
        deposit: depositInfo.deposit.length ? depositInfo.deposit : "0",
        deposit_info: depositInfo.trust_info,
        costs,
        included_items: draftIncluded,
        excluded_items: draftExcluded,
        offer_adjustments_date: new Date(adj),
        offer_possession_date: new Date(possesion),
        offer_completion_date: new Date(completion),
        offer_viewed_date: new Date(viewed),
      },
      contingencies
    );
  }, [
    offer,
    updateDraft,
    selected,
    offerPrice,
    offerEnd,
    depositInfo.deposit,
    depositInfo.trust_info,
    costs,
    draftIncluded,
    draftExcluded,
    adj,
    possesion,
    completion,
    viewed,
    contingencies,
  ]);

  useEffect(() => {
    if (!selected) setActiveStep(0);
  }, [selected, setActiveStep]);

  const handleForward = useCallback(async () => {
    setAttempted(true);
    if (activeStep === NewOfferStep.Price && !selected.length) {
      onSaveDraft();
      setActiveStep((prev) => prev + 1);
    } else if (activeStep < NewOfferStep.Review) {
      setActiveStep((prev) => prev + 1);
      onUpdateDraft();
    } else {
      navigate(`/offer`);
    }
  }, [
    setAttempted,
    activeStep,
    selected,
    onSaveDraft,
    setActiveStep,
    onUpdateDraft,
    navigate,
  ]);

  return (
    <HStack justifyContent={"space-between"} mx={4} my={3}>
      <Box>
        {activeStep > 0 && (
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={handleBack}
            leftIcon={<ArrowLeft />}
          >
            Back
          </Button>
        )}
      </Box>
      {activeStep < NewOfferStep.Review - 1 && (
        <Button
          rightIcon={<ArrowRight size={ICON_SIZE} />}
          colorScheme="blue"
          variant="solid"
          onClick={handleForward}
          isLoading={loading}
        >
          Next
        </Button>
      )}
      {activeStep + 1 === NewOfferStep.Review && (
        <HStack spacing={4}>
          <Button
            rightIcon={<Save size={ICON_SIZE} />}
            colorScheme="blue"
            variant="primary"
            onClick={handleForward}
            isLoading={loading}
          >
            Review
          </Button>
        </HStack>
      )}
      {activeStep === NewOfferStep.Review && <HeadingActions />}
    </HStack>
  );
};
