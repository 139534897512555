import { AxiosResponse } from "axios";
import { Message, PrivateUser } from "../types";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { selectAxiosInstance } from "../recoil/selectors";
import { useCustomToast } from "./useCustomToast";
import { useCallback } from "react";
import {
  latestMessagesAtom,
  messageHistoryAtomFamily,
  oldestMessageAtom,
} from "../recoil/messages/atoms";
import { userFamily } from "../recoil/users/atoms";
import { userState } from "../recoil/atoms";

type MessageData = {
  receiver_id: string;
  listing_id: string;
  offer_id?: string;
  content: string;
};

export const deserializeMessage = (m: Message) =>
  ({
    ...m,
    date_sent: new Date(m.date_sent),
  } as Message);

const useMessagesApi = () => {
  const { fail } = useCustomToast();
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const user = useRecoilValue(userState);

  const sendMessage = useCallback(
    async (message: MessageData): Promise<any> => {
      try {
        await axiosInstance.post(`/messages/send`, message);
        return true;
      } catch (error: any) {
        fail({
          title: "Couldn't send message",
        });
        throw new Error(`Couldn't send message: ${error.message}`);
      }
    },
    [axiosInstance, fail]
  );

  const getMessageHistory = useRecoilCallback(
    ({ set }) =>
      async (listingId: string, userId: string) => {
        try {
          const response: AxiosResponse<Message[]> = await axiosInstance.get(
            `/messages/history/${userId}/${listingId}`
          );

          if (response.data)
            set(
              messageHistoryAtomFamily(listingId + userId),
              response.data.map(deserializeMessage)
            );
        } catch (error) {
          console.error(`Couldn't fetch messages: ${error}`);
        }
      },
    [axiosInstance]
  );

  const getLatestMessages = useRecoilCallback(
    ({ set }) =>
      async () => {
        if (!user || !user?.token) return;
        try {
          const response: AxiosResponse<{
            messages: Message[];
            users: PrivateUser[];
            oldestMessage: Message | null;
          }> = await axiosInstance.get(`/messages/latest`);
          if (response.data) {
            const { users, messages, oldestMessage } = response.data;
            users.forEach((u) => set(userFamily(u.id), u));
            set(latestMessagesAtom, messages.map(deserializeMessage));
            set(oldestMessageAtom, oldestMessage);
          }
        } catch (error) {
          console.error(`Couldn't fetch latest messages: ${error}`);
        }
      },
    [axiosInstance]
  );

  const readMessage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (messageId: string) => {
        try {
          const messages = snapshot.getLoadable(latestMessagesAtom).getValue();

          const response: AxiosResponse<boolean> = await axiosInstance.post(
            `/messages/read`,
            [messageId]
          );
          const now = new Date();
          if (response.data)
            set(
              latestMessagesAtom,
              messages.map((m) => {
                if (m.id === messageId) {
                  return { ...m, date_read: now };
                } else {
                  return m;
                }
              })
            );
        } catch (error) {
          console.error(error);
        }
      },
    [axiosInstance]
  );

  return { getMessageHistory, sendMessage, getLatestMessages, readMessage };
};

export default useMessagesApi;
