import React from "react";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { ChevronLeft, ChevronRight } from "react-feather";

interface WeekNavigationProps {
  currentWeekStart: Date;
  goToPrevWeek: () => void;
  goToNextWeek: () => void;
  addDays: (date: Date, days: number) => Date;
}

const WeekNavigation: React.FC<WeekNavigationProps> = ({
  currentWeekStart,
  goToPrevWeek,
  goToNextWeek,
  addDays,
}) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const startDate = currentWeekStart.toLocaleDateString("en-US", options);
  const endDate = addDays(currentWeekStart, 6).toLocaleDateString(
    "en-US",
    options
  );

  return (
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      pb={4}
    >
      <IconButton
        icon={<ChevronLeft />}
        onClick={goToPrevWeek}
        aria-label="Prev Week"
        isRound
        isDisabled={currentWeekStart <= new Date()}
      />
      <Text fontSize="lg" fontWeight="semibold">
        {startDate} - {endDate}
      </Text>
      <IconButton
        icon={<ChevronRight />}
        onClick={goToNextWeek}
        aria-label="Next Week"
        isRound
      />
    </Flex>
  );
};

export default WeekNavigation;
