import { Flex } from "@chakra-ui/react";

export const VideoPlayer = ({
  src,
  fullscreen,
}: {
  src: string;
  fullscreen?: boolean;
}) => {
  return (
    <Flex h={fullscreen ? "full" : "300px"} justify={"center"}>
      <video
        src={src}
        controls
        autoPlay={false}
        style={fullscreen ? { minHeight: "100vh" } : { height: 300 }}
      >
        Your browser does not support the video tag.
      </video>
    </Flex>
  );
};
