import { useRecoilCallback } from "recoil";
import { kycOpenAtom, userState } from "../atoms";
import { User } from "../../types";
import { filesToUploadAtomFamily } from "../listings/atoms";

export const useToggleKycPendingState = () => {
  return useRecoilCallback(
    ({ set, snapshot, reset }) =>
      async (isPending: boolean) => {
        const user = snapshot.getLoadable(userState).getValue();
        if (!user) return;
        if (isPending) {
          set(userState, {
            ...user,
            permissions: [...user.permissions, "KYC_PENDING"],
          } as User);
        } else {
          reset(filesToUploadAtomFamily("kyc-id"));
          reset(filesToUploadAtomFamily("kyc-proof"));
          set(userState, {
            ...user,
            permissions: user.permissions.filter((p) => p !== "KYC_PENDING"),
          } as User);
        }
      },
    []
  );
};

export const useSetKycComplete = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const user = snapshot.getLoadable(userState).getValue();
        if (!user) return;
        set(userState, {
          ...user,
          permissions: [
            ...user.permissions.filter((p) => p !== "KYC_PENDING"),
            "KYC_COMPLETE",
          ],
        } as User);
      },
    []
  );
};

export const useTriggerKycWindow = () => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        set(kycOpenAtom, true);
      },
    []
  );
};
