import { useEffect, useMemo, useState } from "react";
import { getStartOfWeek } from "../../recoil/viewings/selectors";

const useWeekNavigation = (activeWeekDate?: Date) => {
  const currentWeek = getStartOfWeek(new Date());

  const initialWeek = useMemo(() => {
    return getStartOfWeek(
      activeWeekDate ? new Date(activeWeekDate) : new Date()
    );
  }, [activeWeekDate]);

  useEffect(() => {
    setActiveWeek(initialWeek);
  }, [initialWeek]);

  const [activeWeek, setActiveWeek] = useState<Date>(initialWeek);

  const addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const goToNextWeek = (): void =>
    setActiveWeek((prevDate) => addDays(prevDate, 7));
  const goToPrevWeek = (): void =>
    setActiveWeek((prevDate) => addDays(prevDate, -7));

  return { currentWeek, activeWeek, goToNextWeek, goToPrevWeek, addDays };
};

export default useWeekNavigation;
