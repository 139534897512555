import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  IconButton,
  HStack,
  Input,
  Spinner,
  Avatar,
  VStack,
} from "@chakra-ui/react";
import { FilePlus, PenTool, X } from "react-feather";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { userFamily } from "../../recoil/users/atoms";
import { useLoadMessageHistory } from "../../hooks/useLoadMessageHistory";
import { selectMessageHistory } from "../../recoil/messages/selectors";
import useMessagesApi from "../../hooks/useMessagesApi";
import format from "date-fns/format";
import { userState } from "../../recoil/atoms";
import { ListingModal } from "../Listings/ListingModal";
import { ICON_SIZE } from "../../theme";
import { CreateOfferDrawer, OfferDraftsDrawer } from "../Offers";
import { useIsMobile } from "../../hooks/utils/useWindowDimensions";
import { OfferBubble, MessageBubble } from "./Bubbles";
import { selectedOfferAtom } from "../../recoil/offers/atoms";
import { selectDraftOffers } from "../../recoil/offers/selectors";
import { ListingStatus } from "../../types";
import { selectListingCoverImg } from "../../recoil/listings/selectors";
import { listingFamily, selectedListingAtom } from "../../recoil/listings";
import { useRentalApi } from "../../hooks/useRentalApi";
import { CreateApplicationDrawer } from "../rentals/CreateApplication";

export const DialogWindow = ({
  userId,
  listingId,
  onClose,
}: {
  userId: string;
  listingId: string;
  onClose: () => void;
}) => {
  const endOfMessagesRef = useRef<HTMLDivElement>(null);
  useLoadMessageHistory(userId, listingId);
  const listingCover = useRecoilValue(selectListingCoverImg(listingId));
  const messages = useRecoilValue(selectMessageHistory({ listingId, userId }));
  const user = useRecoilValue(userFamily(userId));
  const myUser = useRecoilValue(userState);
  const setSelectedListing = useSetRecoilState(selectedListingAtom);
  const [ListingModalOpen, setListingModalOpen] = useState(false);
  const [draftOpen, setDraftOpen] = useState(false);
  const resetSelectedDraft = useResetRecoilState(selectedOfferAtom);
  const [createOfferOpen, setCreateOfferOpen] = useState(false);
  const listing = useRecoilValue(listingFamily(listingId));
  const { sendMessage } = useMessagesApi();
  const { openApplicationDrawer } = useRentalApi();
  const [messageContent, setMessageContent] = useState("");
  const myOfferCount = useRecoilValue(
    selectDraftOffers({ listingId: listingId ?? "", userId })
  ).length;
  useEffect(() => {
    endOfMessagesRef.current &&
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const isMobile = useIsMobile();
  const onMessageSend = useCallback(async () => {
    if (!!messageContent && user) {
      if (
        await sendMessage({
          content: messageContent,
          receiver_id: user.id,
          listing_id: listingId,
        })
      ) {
        setMessageContent("");
      }
    }
  }, [listingId, messageContent, sendMessage, user]);

  const isRental = listing?.is_rental;

  const onDraftOpen = useCallback(() => {
    setSelectedListing(listingId);
    setDraftOpen(true);
  }, [listingId, setSelectedListing]);

  const onCreateOpen = useCallback(() => {
    setSelectedListing(listingId);
    setCreateOfferOpen(true);
    if (listing?.is_rental) openApplicationDrawer();
  }, [listing, listingId, openApplicationDrawer, setSelectedListing]);

  useEffect(() => {
    endOfMessagesRef.current &&
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  if (!user || !listing) return <Spinner />;
  const isOwnListing = listing.seller_id === myUser?.id;
  const fullName = user.first_name + " " + user.last_name;
  return (
    <Flex direction="column" h="100%" w="100%">
      <CreateApplicationDrawer />
      <ListingModal
        handleClose={() => setListingModalOpen(false)}
        isOpen={ListingModalOpen}
      />
      <OfferDraftsDrawer
        handleClose={() => {
          setDraftOpen(false);
          resetSelectedDraft();
        }}
        isOpen={draftOpen}
      />
      <CreateOfferDrawer
        handleClose={() => {
          setCreateOfferOpen(false);
          resetSelectedDraft();
        }}
        isOpen={createOfferOpen}
      />
      {/* Header Section */}
      <Box
        boxShadow={"0 2px 6px rgba(0, 0, 0, 0.3)"}
        rounded={"md"}
        px={isMobile ? 1 : 3}
        py={isMobile ? 2 : 3}
        zIndex={3}
      >
        {user && (
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Button
              variant="secondary"
              h={"100%"}
              py={1}
              pr={isMobile ? 2 : 4}
              pl={isMobile ? 2 : 1}
              onClick={() => {
                setSelectedListing(listingId);
                setListingModalOpen(true);
              }}
            >
              <HStack>
                {isMobile ? null : (
                  <Avatar name={fullName} src={listingCover} />
                )}
                <VStack spacing={0.2} alignItems={"left"}>
                  <Text fontSize={12} textAlign={"left"}>
                    {listing.address},
                  </Text>
                  <Text fontSize={12} textAlign={"left"}>
                    {" "}
                    {listing.city}{" "}
                  </Text>
                </VStack>
              </HStack>
            </Button>
            <HStack ml={2}>
              <Text>{fullName}</Text>
              <Box
                rounded={"md"}
                p={0.2}
                px={2}
                bg={isOwnListing ? "blue.100" : "brutalistSecondary"}
              >
                <Text color="white">{isOwnListing ? "Buyer" : "Seller"}</Text>
              </Box>
            </HStack>
            <IconButton
              size={isMobile ? "sm" : "md"}
              aria-label="Close drawer"
              icon={<X size={isMobile ? 20 : 25} />}
              onClick={onClose}
            />
          </Flex>
        )}
      </Box>

      {/* Body Section */}
      <Box flex="1" overflowY="auto" p={isMobile ? 0 : 3}>
        {messages.map((m) => (
          <MessageRow key={m.id} message={m} />
        ))}
        <div ref={endOfMessagesRef} />
      </Box>
      {[
        ListingStatus.Active,
        ListingStatus.PreSale,
        ListingStatus.NewBuild,
      ].includes(listing.listing_status) && (
        <Box my={2}>
          {/* Footer Section */}
          <Flex>
            {!isOwnListing && (
              <Button
                variant="primary"
                h={10}
                w={"100%"}
                onClick={onCreateOpen}
                marginRight={2}
              >
                {isRental ? "Create Application" : "Create Offer"}&nbsp;{" "}
                <FilePlus size={ICON_SIZE} />
              </Button>
            )}
            {myOfferCount > 0 && isOwnListing && !isRental && (
              <Button
                variant="secondary"
                h={10}
                w={"100%"}
                onClick={onDraftOpen}
              >
                Counter-Offer Drafts&nbsp; <PenTool size={ICON_SIZE} />
              </Button>
            )}
          </Flex>
        </Box>
      )}

      <HStack borderRadius={6} justifyContent={"space-between"}>
        <Input
          bg={"input-bg"}
          border={"0.5px solid"}
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          placeholder="Type your message"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onMessageSend();
            }
          }}
        />
        <Button variant="primary" onClick={onMessageSend}>
          Send
        </Button>
      </HStack>
    </Flex>
  );
};

export const MessageRow = ({
  message,
}: {
  message: {
    fromUser: boolean;
    id: string;
    sender_id: string;
    receiver_id: string;
    listing_id: string;
    content: string;
    date_sent: Date;
    offer_id?: string;
  };
}) => {
  const { fromUser, date_sent, offer_id } = message;

  return (
    <Flex
      w="100%"
      justifyContent={"space-between"}
      alignItems={"center"}
      direction={fromUser ? "row-reverse" : "row"}
    >
      {offer_id ? (
        <OfferBubble offerId={offer_id} />
      ) : (
        <MessageBubble message={message} />
      )}
      <Text>{format(date_sent, "d MMM yy hh:mma")}</Text>
    </Flex>
  );
};
