import { useRecoilTransaction_UNSTABLE } from "recoil";
import {
  Amenities,
  HeatingType,
  ListingForm,
  ListingStatus,
  PROVINCES,
  PropertyType,
  ViewFacing,
} from "../../types";
import { listingFamily, listingInputAtom, userListingsAtom } from "./atoms";
import { RadioType } from "../../components/common/RadioOptionGroup";

export const checkboxNames = ["has_natural_gas"];

export const useDeleteListing = () =>
  useRecoilTransaction_UNSTABLE<[string]>(
    ({ set, get }) =>
      (listingId) => {
        const req = get(userListingsAtom);
        if (!req) return;
        set(
          userListingsAtom,
          req.filter((l) => l !== listingId)
        );
      },
    []
  );

export const useUpdateDraftListingToActive = () =>
  useRecoilTransaction_UNSTABLE<[string]>(
    ({ set, get }) =>
      (listingId) => {
        const draftListing = get(listingFamily(listingId));
        if (!draftListing) return;

        const updatedListing = {
          ...draftListing,
          listing_status: ListingStatus.Active,
        };

        set(listingFamily(listingId), updatedListing);
      },
    []
  );

export const useUpdateDraftListingToInReview = () =>
  useRecoilTransaction_UNSTABLE<[string]>(
    ({ set, get }) =>
      (listingId) => {
        const draftListing = get(listingFamily(listingId));
        if (!draftListing) return;

        const updatedListing = {
          ...draftListing,
          listing_status: ListingStatus.InReview,
        };

        set(listingFamily(listingId), updatedListing);
      },
    []
  );

export const useUpdateListingGeocode = () =>
  useRecoilTransaction_UNSTABLE<[string, { lat: number; lng: number } | null]>(
    ({ set, get }) =>
      (listingId, geocode) => {
        const listing = get(listingFamily(listingId));
        if (!listing) return;

        set(listingFamily(listingId), {
          ...listing,
          latitude: geocode?.lat ?? listing.latitude,
          longitude: geocode?.lng ?? listing.longitude,
        });
      },
    []
  );

export const useHandleInputChange = () =>
  useRecoilTransaction_UNSTABLE<[React.ChangeEvent<HTMLInputElement>]>(
    ({ set, get }) =>
      (event) => {
        const formState = get(listingInputAtom);
        const key = event.target.name as keyof ListingForm;
        let value = event.target.value;

        // checkbox workflow
        if (checkboxNames.includes(key)) {
          const current = !!formState[key] ?? false;
          set(listingInputAtom, {
            ...formState,
            [key]: !current,
          });

          // Price, property tax, or HOA fee workflow
        } else if (
          key === "price" ||
          key === "property_tax" ||
          key === "hoa_fee" ||
          key === "lot_size_unit" ||
          key === "buyer_agent_fee"
        ) {
          value = value.replace(/,/g, ""); // Remove all commas
          const number = Number(value);
          if (!isNaN(number)) {
            set(listingInputAtom, {
              ...formState,
              [key]: value,
            });
          }
        } else {
          if (key === "country") {
            set(listingInputAtom, {
              ...formState,
              country:
                value === "ca"
                  ? "Canada"
                  : value === "us"
                  ? "USA"
                  : value.toUpperCase(),
            });
          } else if (key === "state_province") {
            set(listingInputAtom, {
              ...formState,
              state_province: PROVINCES[value] ?? value,
            });
          } else if (key === "city") {
            const title = formState.title.length
              ? formState.title
              : formState.property_type !== 0
              ? `${value} ${propertyType(formState.property_type)}`
              : "";
            const description = formState.description.length
              ? formState.description
              : formState.property_type !== 0
              ? `${value} ${propertyType(formState.property_type)}`
              : "";
            set(listingInputAtom, {
              ...formState,
              title,
              description,
              [key]: value,
            });
          } else if (key.startsWith("address")) {
            set(listingInputAtom, {
              ...formState,
              address: value,
            });
          } else {
            set(listingInputAtom, {
              ...formState,
              [key]: value,
            });
          }
        }
      },
    []
  );

export const useHandleNumberInputChange = () =>
  useRecoilTransaction_UNSTABLE<[number, string]>(
    ({ set, get }) =>
      (value, name) => {
        const formState = get(listingInputAtom);
        const key = name as keyof ListingForm;
        set(listingInputAtom, {
          ...formState,
          [key]: value,
        });
      },
    []
  );

export const useHandleTextareaChange = () =>
  useRecoilTransaction_UNSTABLE<[React.ChangeEvent<HTMLTextAreaElement>]>(
    ({ set, get }) =>
      (event) => {
        const formState = get(listingInputAtom);
        const key = event.target.name as keyof ListingForm;
        set(listingInputAtom, {
          ...formState,
          [key]: event.target.value,
        });
      },
    []
  );

export const useHandleAreaChange = () =>
  useRecoilTransaction_UNSTABLE<[{ key: keyof ListingForm; value: string }]>(
    ({ set, get }) =>
      ({ key, value }: { key: keyof ListingForm; value: string }) => {
        const formState = get(listingInputAtom);
        const split = value.split(".");
        const onePeriod =
          split.length > 1 ? `${split[0]}.${split[1]}` : split[0];
        const numeric = Number(onePeriod);

        if (!isNaN(numeric)) {
          switch (key) {
            case "floor_area":
              set(listingInputAtom, {
                ...formState,
                [key]: onePeriod,
                total_area: (
                  numeric + Number(formState.balcony_area ?? 0)
                ).toFixed(2),
              });
              break;
            case "balcony_area":
              set(listingInputAtom, {
                ...formState,
                [key]: onePeriod,
                total_area: (
                  numeric + Number(formState.floor_area ?? 0)
                ).toFixed(2),
              });
              break;
            case "total_area":
              set(listingInputAtom, {
                ...formState,
                [key]: onePeriod,
              });
              break;
            case "lot_size":
              set(listingInputAtom, {
                ...formState,
                [key]: onePeriod,
              });
              break;
          }
        } else {
          set(listingInputAtom, {
            ...formState,
            [key]: onePeriod,
          });
        }
      },
    []
  );

export const useHandleRadioChange = () =>
  useRecoilTransaction_UNSTABLE<[RadioType, string]>(
    ({ set, get }) =>
      (radioType, nextValue) => {
        const formState = get(listingInputAtom);
        const intValue = parseInt(nextValue, 10);
        if (radioType === "property_type") {
          const title = formState.title.length
            ? formState.title
            : formState.city.length
            ? `${formState.city} ${propertyType(intValue)}`
            : "";
          const description = formState.description.length
            ? formState.description
            : formState.city.length
            ? `${formState.city} ${propertyType(intValue)}`
            : "";
          set(listingInputAtom, {
            ...formState,
            title,
            description,
            [radioType]: intValue,
          });
        } else {
          set(listingInputAtom, {
            ...formState,
            [radioType]: intValue,
          });
        }
      },
    []
  );

export const useHandleHeatingChange = () =>
  useRecoilTransaction_UNSTABLE<[HeatingType]>(
    ({ set, get }) =>
      (value) => {
        const formState = get(listingInputAtom);
        set(listingInputAtom, {
          ...formState,
          heating_type: value,
        });
      },
    []
  );

export const useHandleViewChange = () =>
  useRecoilTransaction_UNSTABLE<[ViewFacing]>(
    ({ set, get }) =>
      (value) => {
        const formState = get(listingInputAtom);
        set(listingInputAtom, {
          ...formState,
          view_facing: value,
        });
      },
    []
  );

export const useHandleCoordinateChange = () =>
  useRecoilTransaction_UNSTABLE<[number, number]>(
    ({ set, get }) =>
      (longitude, latitude) => {
        const formState = get(listingInputAtom);
        set(listingInputAtom, {
          ...formState,
          latitude,
          longitude,
        });
      },
    []
  );

export const useHandleAmenitiesChange = () =>
  useRecoilTransaction_UNSTABLE<[Amenities]>(
    ({ set, get }) =>
      (amenities) => {
        const formState = get(listingInputAtom);
        set(listingInputAtom, {
          ...formState,
          amenities,
        });
      },
    []
  );

export const propertyType = (type: PropertyType) => {
  switch (type) {
    case PropertyType.Apartment:
      return "Apartment";
    case PropertyType.House:
      return "House";
    case PropertyType.Lot:
      return "Lot";
    case PropertyType.Townhouse:
      return "Townhouse";
    default:
      return "";
  }
};
