import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
} from "@chakra-ui/react";
import { useOfferSubjectsApi } from "../../../hooks/Offers";
import { useCustomToast } from "../../../hooks/useCustomToast";

export const DeleteSubjectModal = ({
  isOpen,
  onClose,
  subject_id,
}: {
  isOpen: boolean;
  onClose: () => void;
  subject_id: string;
}) => {
  const { deleteSubject } = useOfferSubjectsApi();
  const { success } = useCustomToast();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={"container-bg"}>
        <ModalHeader>Delete Contingency</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize={17}>
            Are you sure you want to delete this contingency?
          </Text>
          <Text fontSize={14} fontStyle={"italic"}>
            Note: doing so will result in that contingency being removed from
            all your draft offers (or counter offers)
          </Text>
          <Button
            my={3}
            color={"white"}
            bg={"red.600"}
            w={"full"}
            onClick={() => {
              deleteSubject(subject_id);
              success({ title: "Deleted contingency" });
              onClose();
            }}
          >
            Delete
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
