import { Box, Collapse, Flex, VStack, Text } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { Minus, Plus } from "react-feather";
import { ICON_SIZE } from "../../theme";

export const ExpandableBox = ({
  children,
  title,
  my,
}: {
  children: ReactNode;
  title: string;
  my?: string | number;
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <VStack
      border={"1px"}
      borderColor={"border"}
      rounded={"xl"}
      bg={"container-bg"}
      my={my}
    >
      <Box w={"full"} px={4}>
        <Flex
          alignItems={"center"}
          justify={"space-between"}
          h={10}
          pt={2}
          cursor={"pointer"}
          onClick={() => setExpanded((p) => !p)}
        >
          <Text fontWeight={700} fontSize={20}>
            {title}
          </Text>
          {expanded ? <Minus size={ICON_SIZE} /> : <Plus size={ICON_SIZE} />}
        </Flex>
      </Box>
      <Box
        w={"full"}
        maxH={300}
        overflowY={"scroll"}
        roundedBottom={"xl"}
        borderTop={expanded ? "1px" : 0}
        borderColor={"border"}
        px={2}
        bg={"expandable-bg"}
      >
        <Collapse in={expanded}>{children}</Collapse>
      </Box>
    </VStack>
  );
};
