import { ListingForm, PropertyType } from "../../../types";

export const validateProperty = (
  formState: ListingForm,
  setFormErrors: (errors: { [key: string]: string | null }) => void
) => {
  let errors: { [key: string]: string | null } = {};

  if (!formState.property_type) {
    errors.property_type = "Property Type is required";
  }

  setFormErrors(errors);
  return !Object.values(errors).filter((v) => v && v.length).length;
};

export const validateArea = (
  formState: ListingForm,
  setFormErrors: (errors: { [key: string]: string | null }) => void
) => {
  let errors: { [key: string]: string | null } = {};
  if (formState.property_type !== PropertyType.Lot && !formState.floor_area) {
    errors.floor_area = "Floor Area is required";
  }
  if (
    (formState.property_type === PropertyType.House ||
      formState.property_type === PropertyType.Lot) &&
    !formState.lot_size
  ) {
    errors.lot_size = "Lot size is required";
  }
  setFormErrors(errors);
  return !Object.values(errors).filter((v) => v && v.length).length;
};

export const validateAddress = (
  formState: ListingForm,
  setFormErrors: (errors: { [key: string]: string | null }) => void
) => {
  let errors: { [key: string]: string | null } = {};

  if (formState.property_type === PropertyType.Apartment && !formState.apt_no) {
    errors.apt_no = "Unit Number is required";
  }
  if (!formState.address) {
    errors.address = "Address is required";
  }
  if (!formState.city) {
    errors.city = "City is required";
  }
  if (!formState.state_province) {
    errors.state_province = "State/ Province is required";
  }
  if (!formState.country) {
    errors.country = "Country is required";
  }
  if (!formState.zip) {
    errors.zip = "Postal/ Zip Code is required";
  }

  setFormErrors(errors);
  return !Object.values(errors).filter((v) => v && v.length).length;
};

export const validatePricing = (
  formState: ListingForm,
  setFormErrors: (errors: { [key: string]: string | null }) => void
): boolean => {
  let errors: { [key: string]: string | null } = {};

  if (!formState.price) {
    errors.price = "Listing price is required";
  }
  if (!formState.is_rental && !formState.property_tax) {
    errors.property_tax = "Property tax is required";
  }
  if (
    !formState.is_rental &&
    formState.property_type === PropertyType.Apartment &&
    formState.hoa_fee === undefined
  ) {
    errors.hoa_fee = "Strata is required";
  }

  setFormErrors(errors);
  return !Object.values(errors).filter((v) => v && v.length).length;
};

export const validateDetails = (
  formState: ListingForm,
  setFormErrors: (errors: { [key: string]: string | null }) => void
): boolean => {
  let errors: { [key: string]: string | null } = {};

  if (
    (formState.bedrooms_total === null ||
      formState.bedrooms_total === undefined ||
      Number.isNaN(formState.bedrooms_total) ||
      formState.bedrooms_total < 1) &&
    formState.property_type !== PropertyType.Lot
  ) {
    errors.bedrooms_total = "Bedrooms is required";
  }
  if (
    formState.bathrooms_full === null ||
    formState.bathrooms_full === undefined ||
    Number.isNaN(formState.bathrooms_full)
  ) {
    errors.bathrooms_full = "Full bathrooms is required";
  }
  if (
    formState.bathrooms_half === null ||
    formState.bathrooms_half === undefined ||
    Number.isNaN(formState.bathrooms_half)
  ) {
    errors.bathrooms_half = "Half bathrooms is required";
  }

  setFormErrors(errors);
  return !Object.values(errors).filter((v) => v && v.length).length;
};

export const validateMoreDetails = (
  formState: ListingForm,
  setFormErrors: (errors: { [key: string]: string | null }) => void
): boolean => {
  let errors: { [key: string]: string | null } = {};
  if (
    !formState.is_rental &&
    !formState.year_built &&
    formState.property_type !== PropertyType.Lot
  ) {
    errors.year_built = "Year built is required";
  }
  if (
    formState.parking_stalls_covered === null ||
    formState.parking_stalls_covered === undefined ||
    Number.isNaN(formState.parking_stalls_covered)
  ) {
    errors.parking_stalls_covered = "Parking stalls covered is required";
  }
  if (
    formState.parking_stalls_uncovered === null ||
    formState.parking_stalls_uncovered === undefined ||
    Number.isNaN(formState.parking_stalls_uncovered)
  ) {
    errors.parking_stalls_uncovered = "Parking stalls uncovered is required";
  }
  setFormErrors(errors);
  return !Object.values(errors).filter((v) => v && v.length).length;
};

export const validateMedia = (
  formState: ListingForm,
  setFormErrors: (errors: { [key: string]: string | null }) => void
): boolean => {
  let errors: { [key: string]: string | null } = {};

  if (!formState.title.trim()) {
    errors.title = "Title is required";
  }
  if (!formState.description.trim()) {
    errors.description = "Description is required";
  }
  setFormErrors(errors);
  return !Object.values(errors).filter((v) => v && v.length).length;
};
