import { Button } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { userState } from "../../recoil/atoms";
import { useKycPending, useUserKycd } from "../../recoil/users/selectors";
import { useTriggerKycWindow } from "../../recoil/users/transactions";

export const KycButton = () => {
  const isKyced = useUserKycd();
  const triggerKycWindow = useTriggerKycWindow();
  const isPending = useKycPending();
  const user = useRecoilValue(userState);
  return user && !isKyced ? (
    <Button onClick={triggerKycWindow} mr={3} variant={"ghost"}>
      {isPending ? "Awaiting Verification" : "Get Verified"}
    </Button>
  ) : null;
};
