import { Spinner, Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useIsMobile } from "../hooks/utils/useWindowDimensions";
import useAuthApi from "../hooks/useAuthApi";
import { useRecoilValue } from "recoil";
import { userState } from "../recoil/atoms";

export const TokenLogin = () => {
  const { token } = useParams<{ token: string }>();
  const user = useRecoilValue(userState);
  const { tokenLogin } = useAuthApi();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) tokenLogin(token);
  }, [tokenLogin, token]);

  useEffect(() => {
    if (user && user.token)
      navigate("/", {
        replace: true,
      });
  }, [navigate, user]);

  if (!token) {
    return <Spinner />;
  }

  return (
    <Flex justifyContent={isMobile ? "center" : "center"}>
      <Text fontSize={"xl"}>Logging in...</Text>
    </Flex>
  );
};
