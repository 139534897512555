import {
  Box,
  Text,
  Flex,
  Divider,
  CardBody,
  Stack,
  Heading,
  Card,
  SimpleGrid,
  Button,
  Tag,
  CardHeader,
} from "@chakra-ui/react";
import { userState } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import ImageSlideshow from "./Media/ImageSlideShow";
import { ListingViewingScheduleTable } from "../viewings/ListingViewingScheduleTable";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "../../hooks/utils/useWindowDimensions";
import { ICON_SIZE } from "../../theme";
import { Edit } from "react-feather";
import {
  ACType,
  HeatingType,
  ListingStatus,
  PropertyType,
  ViewFacing,
  acTypeLabels,
  fireplaceTypeLabels,
  heatingTypeLabels,
  propertyTypeLabels,
  stoveTypeLabels,
  viewTypeLabels,
} from "../../types";
import { ShareButton } from "../common/ShareButton";
import { useListingApi } from "../../hooks/Listings";
import { useAdminState, useUserKycd } from "../../recoil/users/selectors";
import { useTriggerKycWindow } from "../../recoil/users/transactions";
import { ListingStatusBadge } from "../common/ListingStatusBadge";
import { MapboxMap } from "../common/Map/MapboxMap";
import { selectRental } from "../../recoil/rentals/selectors";

export const RentalView = ({ listingId }: { listingId: string }) => {
  const rental = useRecoilValue(selectRental(listingId));
  const { updateDraftListingToActive, updateDraftListingToReview } =
    useListingApi();
  const user = useRecoilValue(userState);
  const isMobile = useIsMobile();
  const isKyced = useUserKycd();
  const triggerKycWindow = useTriggerKycWindow();
  const navigate = useNavigate();
  const isAdmin = useAdminState();

  if (!rental) return null;

  return (
    <>
      <Card pb={8} minHeight={"100%"}>
        <CardHeader px={isMobile ? 0 : 5} pt={3}>
          {!!rental.file_urls.length && (
            <ImageSlideshow images={rental.file_urls} />
          )}
        </CardHeader>
        <CardBody px={isMobile ? 3 : 5} pt={0}>
          <Stack mt="4" spacing="3" mb={5}>
            <Heading size="lg"> {rental.title}</Heading>
            <Flex
              alignItems="baseline"
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Box flexGrow={1}>
                <ListingStatusBadge status={rental.listing_status} isRental />
                {isAdmin &&
                  rental.listing_status === ListingStatus.InReview && (
                    <Button
                      ml={4}
                      onClick={() => {
                        updateDraftListingToActive(listingId);
                      }}
                      variant={"primary"}
                    >
                      Publish
                    </Button>
                  )}
                <Text
                  textTransform="uppercase"
                  fontSize="md"
                  fontWeight="semibold"
                  color="gray.500"
                >
                  {" "}
                  {rental.address}
                </Text>
              </Box>
              <Box>
                <ShareButton listingId={rental.id} />
              </Box>
            </Flex>
            <Flex>
              <Text>
                {rental.description.split("\n").map((line) => (
                  <>
                    {line}
                    <br />
                  </>
                ))}
              </Text>
            </Flex>
          </Stack>
          <Divider
            color={"lightgray"}
            marginLeft={"auto"}
            marginRight={"auto"}
            marginBottom={5}
          />
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginBottom={5}
          >
            <Box>
              <Text fontSize="2xl" fontWeight={"normal"}>
                $
                {rental.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Text>
            </Box>
          </Flex>
          <Divider
            color={"lightgray"}
            marginLeft={"auto"}
            marginRight={"auto"}
            marginBottom={5}
          />
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginBottom={5}
          >
            <Box>
              <Text fontSize="2xl" fontWeight="bold" marginBottom={6}>
                Listing Details
              </Text>
              <Box marginBottom={8}>
                <Text fontSize="xl" fontWeight={"bold"} marginBottom={3}>
                  Interior
                </Text>
                <SimpleGrid columns={2} spacingX={14} spacingY={4}>
                  <Box>
                    <Text fontSize="md" fontWeight={"semibold"}>
                      Bedrooms & Bathrooms
                    </Text>
                    <Text fontSize="md">Bedrooms: {rental.bedrooms_total}</Text>
                    <Text fontSize="md">
                      Full Bathrooms: {rental.bathrooms_full}
                    </Text>
                    <Text fontSize="md">
                      Half Bathrooms: {rental.bathrooms_half}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="md" fontWeight={"semibold"}>
                      Heating & AC
                    </Text>
                    <Text fontSize="md">
                      AC: {acTypeLabels[rental.ac_type ?? ACType.None]}
                    </Text>
                    <Text fontSize="md">
                      Heating:{" "}
                      {
                        heatingTypeLabels[
                          rental.heating_type ?? HeatingType.None
                        ]
                      }
                    </Text>
                    <Text fontSize="md">
                      Natural Gas: {rental.has_natural_gas ? "Yes" : "No"}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="md" fontWeight={"bold"}>
                      Features
                    </Text>
                    {rental.stove_type ? (
                      <Text fontSize="md">
                        Stove:{" "}
                        {rental.stove_type
                          ? stoveTypeLabels[rental.stove_type]
                          : "n/a"}
                      </Text>
                    ) : null}
                    {rental.fireplace_type ? (
                      <Text fontSize="md">
                        Fireplace:{" "}
                        {rental.fireplace_type
                          ? fireplaceTypeLabels[rental.fireplace_type]
                          : "n/a"}
                      </Text>
                    ) : null}
                  </Box>
                </SimpleGrid>
              </Box>
              <Box marginBottom={8}>
                <Text fontSize="xl" fontWeight={"bold"} marginBottom={3}>
                  Property
                </Text>
                <SimpleGrid columns={2} spacingX={14} spacingY={4}>
                  <Box>
                    <Text fontSize="md" fontWeight={"bold"}>
                      Building
                    </Text>
                    <Text fontSize="md">
                      Property Type: {propertyTypeLabels[rental.property_type]}
                    </Text>
                    {rental.property_type === PropertyType.Apartment ? (
                      <>
                        {rental.building_floor && (
                          <Text fontSize="md">
                            Building Floor: {rental.building_floor}
                          </Text>
                        )}
                        {rental.building_total_floors && (
                          <Text fontSize="md">
                            Total Floors: {rental.building_total_floors}
                          </Text>
                        )}
                      </>
                    ) : null}
                  </Box>

                  <Box>
                    <Text fontSize="md" fontWeight={"bold"}>
                      Parking
                    </Text>
                    <Text fontSize="md">
                      Covered stalls: {rental.parking_stalls_covered}
                    </Text>
                    <Text fontSize="md">
                      Uncovered stalls: {rental.parking_stalls_uncovered}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="md" fontWeight={"bold"}>
                      Area
                    </Text>
                    <Text fontSize="md">
                      Floor Area:
                      {` ${rental.floor_area} ${
                        rental.area_unit === 1 ? "sqft" : "sqm"
                      } `}
                    </Text>
                    {rental.balcony_area && (
                      <Text fontSize="md">
                        Balcony Area:
                        {` ${rental.balcony_area} ${
                          rental.area_unit === 1 ? "sqft" : "sqm"
                        } `}
                      </Text>
                    )}
                    <Text fontSize="md">
                      Total Area:
                      {` ${rental.total_area} ${
                        rental.area_unit === 1 ? "sqft" : "sqm"
                      } `}
                    </Text>
                    {rental.property_type !== PropertyType.Apartment ? (
                      <Text fontSize="md">
                        Lot Size:
                        {` ${rental.lot_size} ${
                          rental.lot_size_unit === 1 ? "sqft" : "sqm"
                        } `}
                      </Text>
                    ) : null}
                  </Box>
                  <Box>
                    <Text fontSize="md" fontWeight={"bold"}>
                      History
                    </Text>
                    <Text fontSize="md">Year built: {rental.year_built}</Text>
                    {rental.year_renovated && (
                      <Text fontSize="md">
                        Year renovated: {rental.year_renovated}
                      </Text>
                    )}
                  </Box>
                  <Box>
                    {rental.view_facing || rental.view_description ? (
                      <>
                        <Text fontSize="md" fontWeight={"bold"}>
                          View
                        </Text>
                        {rental.view_facing ? (
                          <Text fontSize="md">
                            Facing:{" "}
                            {
                              viewTypeLabels[
                                rental.view_facing ?? ViewFacing.Unknown
                              ]
                            }
                          </Text>
                        ) : null}
                        {rental.view_description ? (
                          <Text fontSize="md">
                            Description: {rental.view_description}
                          </Text>
                        ) : null}
                      </>
                    ) : null}
                  </Box>
                </SimpleGrid>
              </Box>
              {rental.amenities &&
              (rental.amenities?.standard?.length > 0 ||
                rental.amenities.custom.length > 0) ? (
                <Box marginBottom={8}>
                  <Text fontSize="xl" fontWeight={"bold"} marginBottom={3}>
                    Amenities
                  </Text>
                  <SimpleGrid columns={1} spacingX={14} spacingY={4}>
                    <Box>
                      {rental.amenities?.standard?.map((amenity) => (
                        <Tag
                          fontSize="md"
                          padding={3}
                          mr={2}
                          mb={2}
                          key={amenity}
                        >
                          {amenity}
                        </Tag>
                      ))}
                      {rental.amenities?.custom?.map((amenity) => (
                        <Tag
                          fontSize="md"
                          padding={3}
                          mr={2}
                          mb={2}
                          key={amenity}
                        >
                          {amenity}
                        </Tag>
                      ))}
                    </Box>
                  </SimpleGrid>
                </Box>
              ) : null}
              <Box marginBottom={5}>
                <Text fontSize="xl" fontWeight={"bold"} marginBottom={3}>
                  Pricing
                </Text>
                <SimpleGrid columns={2} spacingX={14} spacingY={4}>
                  <Box>
                    <Text fontSize="md" fontWeight={"bold"}>
                      Breakdown
                    </Text>
                    <Text fontSize="md">
                      Price:{" "}
                      {`$${rental.price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    </Text>
                    <Text fontSize="md">
                      Annual Property Tax: $
                      {rental.property_tax
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Text>
                    {rental.property_type === PropertyType.Apartment ? (
                      <Text fontSize="md">
                        Strata Fee: $
                        {rental.hoa_fee
                          ? `${rental.hoa_fee
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : "Not Available"}
                      </Text>
                    ) : null}
                  </Box>
                </SimpleGrid>
              </Box>
            </Box>
          </Flex>
          <Divider
            color={"lightgray"}
            marginLeft={"auto"}
            marginRight={"auto"}
            marginBottom={5}
          />
          <Flex flexDirection={"row"} mb={6}>
            <Box height={"100%"} width={"100%"}>
              <Text fontSize="2xl" fontWeight="bold" marginBottom={5}>
                Location
              </Text>
              <MapboxMap
                lat={rental.latitude ?? 0}
                lng={rental.longitude ?? 0}
              />
            </Box>
          </Flex>
          <Divider
            color={"lightgray"}
            marginLeft={"auto"}
            marginRight={"auto"}
            marginBottom={5}
          />
          <Flex flexDirection={"row"} justifyContent={"space-between"} mb={6}>
            <Box>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                marginBottom={4}
              >
                <Text fontSize="2xl" fontWeight="bold">
                  Schedule a Viewing
                </Text>
                {user?.token && rental.seller_id === user.id && (
                  <Button
                    justifySelf={"flex-end"}
                    variant="outline"
                    rightIcon={<Edit size={ICON_SIZE} />}
                    onClick={() =>
                      navigate(`/viewing-schedule/manage/${listingId}`)
                    }
                  >
                    Edit
                  </Button>
                )}
              </Flex>
              <ListingViewingScheduleTable
                listingId={listingId}
                disableButtons={!user || rental.seller_id === user.id}
              />
            </Box>
          </Flex>
          <Divider
            color={"lightgray"}
            marginLeft={"auto"}
            marginRight={"auto"}
            marginBottom={5}
          />
          {rental.seller_id !== user?.id ? (
            <Flex justifyContent={"space-between"} mb={10}>
              <Button
                isDisabled={rental.listing_status === ListingStatus.Sold}
                onClick={() => {
                  if (user && user.token) {
                    navigate(`/rental/apply/${listingId}`);
                  } else {
                    navigate("/login");
                  }
                }}
                w={"49%"}
                p={8}
                variant={"primary"}
              >
                {rental.listing_status === ListingStatus.Sold
                  ? "Rented"
                  : "Apply"}
              </Button>

              <Button
                onClick={() => {
                  if (user && user.token) {
                    navigate(`/message/${listingId}`);
                  } else {
                    navigate("/login");
                  }
                }}
                w={"49%"}
                p={8}
                variant={"secondary"}
              >
                Contact Landlord
              </Button>
            </Flex>
          ) : null}
          {user?.token &&
          rental.seller_id === user.id &&
          rental.listing_status === ListingStatus.Draft ? (
            <Button
              onClick={() => {
                if (isKyced) {
                  updateDraftListingToReview(listingId);
                } else {
                  triggerKycWindow();
                }
              }}
            >
              Submit for Review
            </Button>
          ) : null}
        </CardBody>
      </Card>
    </>
  );
};
