import { Box } from "@chakra-ui/react";
import React from "react";

type DraggableItemProps = {
  index: number;
  onDragStart: (index: number) => void;
  onDragOver: (index: number) => void;
  onDragEnd: () => void;
  children: React.ReactNode;
};

const DraggableItem: React.FC<DraggableItemProps> = ({
  index,
  onDragStart,
  onDragOver,
  onDragEnd,
  children,
}) => {
  return (
    <Box
      draggable
      onDragStart={() => onDragStart(index)}
      onDragOver={() => onDragOver(index)}
      onDragEnd={onDragEnd}
      style={{ cursor: "move" }}
      my={2}
    >
      {children}
    </Box>
  );
};

export default DraggableItem;
