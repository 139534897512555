import { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { API_URL } from "../recoil/selectors";
import {
  useSetOfferStatus,
  useUpdateMessageHistory,
} from "../recoil/messages/transactions";
import { useRecoilValue } from "recoil";
import { userState } from "../recoil/atoms";
import { useForceLogOut } from "./utils/useForceLogOut";
import { OfferStatus } from "../types";
import { useSetKycComplete } from "../recoil/users/transactions";
import { usePurchases } from "./Stripe/usePurchases";
import useAuthApi from "./useAuthApi";

const useSocketIO = () => {
  const ioRef = useRef<Socket>();
  const [connected, setConnected] = useState(false);
  const user = useRecoilValue(userState);
  const updateMessageHistory = useUpdateMessageHistory();
  const updateStatus = useSetOfferStatus();
  const { getPurchases } = usePurchases();
  const { getMyUser } = useAuthApi();
  const completeKyc = useSetKycComplete();
  const logOut = useForceLogOut();
  useEffect(() => {
    if (!user) {
      console.log("No userId provided, not establishing WebSocket connection.");
      return;
    }

    const socket = io(API_URL, {
      query: { userId: user.id, token: user.token.split(" ")[1] },
    });
    ioRef.current = socket;

    socket.on("connect", () => {
      console.info(`WebSocket connected for user`);
      setConnected(true);
    });

    socket.on("disconnect", () => {
      console.info(`WebSocket disconnected for user`);
      setConnected(false);
    });

    socket.on("connect_error", (err) => {
      console.error(`WebSocket connection error for user:`, err.message);
    });

    socket.on("message", (data) => {
      updateMessageHistory(data);
    });
    socket.on("purchase", () => {
      getPurchases();
    });

    socket.on("tier", () => {
      getMyUser();
    });

    socket.on("KYC_COMPLETE", () => {
      completeKyc();
    });

    socket.on(
      "offer_status",
      (data: { status: OfferStatus; offer_id: string }) => {
        updateStatus(data.offer_id, data.status);
      }
    );

    socket.on("kick", () => {
      logOut();
    });

    return () => {
      console.info(`Destroying WebSocket for user`);
      socket.close();
    };
  }, [
    completeKyc,
    getMyUser,
    getPurchases,
    logOut,
    updateMessageHistory,
    updateStatus,
    user,
  ]);

  return { io: ioRef.current, connected };
};

export default useSocketIO;
