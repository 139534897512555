import { DrawerWithClose } from "../common";
import {
  Flex,
  Heading,
  Button,
  Text,
  Divider,
  AbsoluteCenter,
  Box,
  List,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useOffersApi } from "../../hooks/Offers";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../recoil/atoms";
import { OfferDrafts } from ".";
import { selectDraftOffers } from "../../recoil/offers/selectors";
import useUserApi from "../../hooks/useUserApi";
import { selectedListingAtom } from "../../recoil/listings";
import { useResetDraft } from "../../recoil/offers/transactions";
import { newOfferDrawerOpenAtom } from "../../recoil/offers/atoms";

export const CreateOfferDrawer = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const listingId = useRecoilValue(selectedListingAtom);
  const draftOffers = useRecoilValue(
    selectDraftOffers({ listingId: listingId ?? "" })
  );
  const setDrawer = useSetRecoilState(newOfferDrawerOpenAtom);
  const date = new Date();
  date && date.setDate(date.getDate() + 4);
  const resetDraft = useResetDraft();
  const { loadOffers } = useOffersApi();
  const user = useRecoilValue(userState);
  const [accepting, setAccepting] = useState(false);
  const { createPermission } = useUserApi();

  const onAcceptDisclaimer = useCallback(async () => {
    setAccepting(true);
    await createPermission("OFFERFLOW_DISCLAIMER_HIDDEN");
    setAccepting(false);
  }, [createPermission]);

  const disclaimerHidden =
    user && user.permissions.includes("OFFERFLOW_DISCLAIMER_HIDDEN");

  useEffect(() => {
    loadOffers();
  }, [loadOffers]);

  return (
    <DrawerWithClose
      isOpen={isOpen}
      handleClose={handleClose}
      bg="container-bg"
    >
      {disclaimerHidden ? (
        <Flex p={3} direction={"column"}>
          <Heading>Create Offer</Heading>
          <Button
            mt={8}
            variant={"primary"}
            onClick={() => {
              resetDraft();
              setDrawer(true);
              handleClose();
            }}
          >
            New
          </Button>
          <List my={5} textAlign={"left"} justifyItems={"flex-start"}>
            {draftOffers.length && (
              <>
                <Box position="relative" py={10} px={4}>
                  <Divider bg={"chakra-body-text"} h={0.2} />
                  <AbsoluteCenter px="2" bg="container-bg">
                    <Text fontSize={"lg"}>Or use an existing draft:</Text>
                  </AbsoluteCenter>
                </Box>
                <OfferDrafts />
              </>
            )}
          </List>
        </Flex>
      ) : (
        <Box>
          <Heading>Disclaimer</Heading>
          <VStack pb={6}>
            <Text>
              By utilizing the Offerflow feature provided by Openhaus to create
              binding offers on real estate using our custom forms, you
              acknowledge and agree to the following:
            </Text>
            <Text>
              Use at Your Own Risk: The Offerflow feature is provided solely for
              convenience, and its use is entirely at your own risk. Openhaus
              does not guarantee the accuracy, legality, or suitability of any
              offers created using this feature and its forms.
            </Text>
            <Text>
              Consultation with Legal Counsel: Before submitting any offer
              created through Offerflow, it is strongly recommended that you
              consult with a qualified real estate attorney or legal
              professional. Real estate transactions involve complex legal
              considerations that may vary depending on your jurisdiction and
              specific circumstances. Our forms may not suit your specific legal
              or business needs.
            </Text>
            <Text>
              No Legal Advice: The use of Offerflow and any accompanying forms
              or templates does not constitute legal advice or create an
              attorney-client relationship between you and Openhaus. We are not
              responsible for any consequences arising from your use of
              Offerflow without appropriate legal guidance.
            </Text>
            <Text>
              Indemnification: You agree to indemnify and hold harmless
              Openhaus, its affiliates, officers, employees, and agents from any
              claims, damages, losses, or liabilities resulting from your use of
              Offerflow or any offers generated through it.
            </Text>
            <Text>
              Updates and Modifications: Openhaus reserves the right to modify,
              update, or discontinue the Offerflow feature at any time without
              prior notice. We are not liable for any loss or inconvenience
              resulting from such changes.
            </Text>
            <Text>
              By continuing to use Offerflow, you acknowledge that you have
              read, understood, and agreed to be bound by this disclaimer. If
              you do not agree with any part of this disclaimer, you should
              refrain from using Offerflow. You can upload your own offer forms
              if you wish.
            </Text>
            <Button
              variant={"primary"}
              w={"full"}
              isLoading={accepting}
              onClick={onAcceptDisclaimer}
            >
              Agree
            </Button>
          </VStack>
        </Box>
      )}
    </DrawerWithClose>
  );
};
