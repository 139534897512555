import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { offerAtomFamily, selectedOfferAtom } from "../recoil/offers/atoms";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { OfferPreview } from "../components/Offers/OfferPreview";
import { listingFamily } from "../recoil/listings";
import { currency, formatPrice } from "../utils/formatters";
import { useIsMobile } from "../hooks/utils/useWindowDimensions";
import { HeadingActions } from "../components/Offers/HeadingActions";
import {
  OfferInputs,
  OfferInputsViewOnly,
} from "../components/Offers/OfferInputs";
import { userState } from "../recoil/atoms";
import { ContingencyDrawer } from "../components/Offers";

export const OfferPage = ({ hideActions }: { hideActions?: boolean }) => {
  const selectedOffer = useRecoilValue(selectedOfferAtom);
  const offer = useRecoilValue(offerAtomFamily(selectedOffer));
  const isMobile = useIsMobile();
  const myUser = useRecoilValue(userState);
  const listing = useRecoilValue(listingFamily(offer?.listing_id ?? ""));

  const isMyOffer = offer?.user_id === myUser?.id;
  const isCounter = !!offer?.offer_id;
  const isSubmitted = useMemo(
    () =>
      offer &&
      myUser &&
      isMyOffer &&
      ((offer.seller_id === myUser.id && offer.seller_signature_id) ||
        (offer.buyer_id === myUser.id && offer.buyer_signature_id)),
    [isMyOffer, myUser, offer]
  );
  const editable = isMyOffer && !isSubmitted;

  if (!offer || !listing) return null;
  return (
    <Box w={"full"}>
      {hideActions ? (
        <Box h={4} />
      ) : (
        <Flex
          w={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={isMobile ? "column" : "row"}
          my={4}
        >
          <Heading size={"md"} mb={2}>
            {isCounter && "Counter-"}Offer for {listing.address}{" "}
            {listing.apt_no}, {listing.city} <br />
            <span>
              ${formatPrice(offer.offer_price)}{" "}
              {currency(offer.offer_price_currency)}
            </span>
          </Heading>
          <HeadingActions />
        </Flex>
      )}
      <Box w={"fit"} h={"100%"}>
        <Flex
          justifyContent={"space-between"}
          direction={isMobile ? "column" : "row"}
          alignItems={"start"}
        >
          <Box w={isMobile ? "100%" : "33%"} mr={4}>
            {editable ? <OfferInputs /> : <OfferInputsViewOnly />}
          </Box>
          <Box w={isMobile ? "100%" : "67%"}>
            <OfferPreview />
          </Box>
        </Flex>
      </Box>
      {!hideActions && <ContingencyDrawer />}
    </Box>
  );
};
