import { AxiosResponse } from "axios";
import {
  ListingSchedule,
  ViewingRequest,
  ViewingRequestForm,
  ViewingScheduleForm,
} from "../types";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { selectAxiosInstance } from "../recoil/selectors";
import { useCustomToast } from "./useCustomToast";
import {
  buildCreateViewingRequestInput,
  buildCreateViewingScheduleInput,
} from "../utils/serializier";
import { useCallback } from "react";
import {
  listingScheduleAtomFamily,
  viewingRequestAtomFamily,
  viewingRequestsForBuyerAtom,
  viewingRequestsForSellerAtom,
} from "../recoil/viewings/atoms";
import {
  useAcceptRequest,
  useCreateSchedule,
  useDeleteRequest,
  useDeleteSchedule,
  useRejectRequest,
  useUpdateSchedule,
  useUpdateViewingRequest,
} from "../recoil/viewings/transactions";

const useViewingsApi = () => {
  const { success, fail } = useCustomToast();
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const accept = useAcceptRequest();
  const reject = useRejectRequest();
  const deleteRequest = useDeleteRequest();
  const deleteSchedule = useDeleteSchedule();
  const updateViewingRequestTxn = useUpdateViewingRequest();
  const createScheduleTxn = useCreateSchedule();
  const updateScheduleTxn = useUpdateSchedule();

  const getListingSchedule = useRecoilCallback(
    ({ set }) =>
      async (listingId: string) => {
        try {
          const response: AxiosResponse<{
            message: string;
            listing_schedule: ListingSchedule;
          }> = await axiosInstance.get(`/listings/schedule/${listingId}`);
          set(
            listingScheduleAtomFamily(listingId),
            response.data.listing_schedule
          );
        } catch (error) {
          console.error(`Failed to fetch listing schedule: ${error}`);
        }
      },
    []
  );

  const getAllBuyerViewingRequests = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const response: AxiosResponse<{
            message: string;
            viewing_requests: ViewingRequest[];
          }> = await axiosInstance.get(`/viewings/requests/buyer/all`);
          let viewingRequestIds = [] as string[];
          response.data.viewing_requests.forEach((r) => {
            viewingRequestIds.push(r.id);
            set(viewingRequestAtomFamily(r.id), r);
          });
          set(viewingRequestsForBuyerAtom, viewingRequestIds);
        } catch (error) {
          console.error(`Failed to fetch viewing requests for buyer: ${error}`);
        }
      },
    []
  );

  const getAllSellerViewingRequests = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const {
            data: { viewing_requests },
          }: AxiosResponse<{
            message: string;
            viewing_requests: ViewingRequest[];
          }> = await axiosInstance.get(`/viewings/requests/seller/all`);
          let viewingRequestIds = [] as string[];
          viewing_requests.forEach((r) => {
            viewingRequestIds.push(r.id);
            set(viewingRequestAtomFamily(r.id), r);
          });
          set(viewingRequestsForSellerAtom, viewingRequestIds);
        } catch (error) {
          console.error(
            `Failed to fetch viewing requests for seller: ${error}`
          );
        }
      },
    []
  );

  const createViewingSchedule = useCallback(
    async (form: ViewingScheduleForm): Promise<any> => {
      try {
        const data = buildCreateViewingScheduleInput(form);
        const response: AxiosResponse<any> = await axiosInstance.post(
          `/viewings/schedule`,
          data
        );
        createScheduleTxn(response.data.viewing_schedule, form.listing_id);
        return response.data;
      } catch (error: any) {
        fail({
          title: "Failed to create viewing schedule",
        });
      }
    },
    [axiosInstance, fail, createScheduleTxn]
  );

  const updateViewingSchedule = useCallback(
    async (
      form: ViewingScheduleForm,
      viewing_schedule_id: string
    ): Promise<any> => {
      try {
        const data = buildCreateViewingScheduleInput(form);
        const response: AxiosResponse<any> = await axiosInstance.put(
          `/viewings/schedule/${viewing_schedule_id}`,
          data
        );
        updateScheduleTxn(response.data.updated_schedule, form.listing_id);
        success({ title: "Viewing Schedule updated" });
        return response.data;
      } catch (error: any) {
        fail({
          title: "Failed to update viewing schedule",
        });
      }
    },
    [axiosInstance, fail, success, updateScheduleTxn]
  );

  const deleteViewingSchedule = useCallback(
    async (viewing_schedule_id: string, listing_id: string): Promise<any> => {
      try {
        const response: AxiosResponse<any> = await axiosInstance.delete(
          `/viewings/schedule/${viewing_schedule_id}`
        );
        deleteSchedule(viewing_schedule_id, listing_id);
        success({ title: "Viewing Schedule deleted" });
        return response.data;
      } catch (error: any) {
        fail({
          title: "Failed to delete viewing schedule",
        });
      }
    },
    [axiosInstance, fail, success, deleteSchedule]
  );

  const createViewingRequest = useCallback(
    async (form: ViewingRequestForm): Promise<any> => {
      try {
        const data = buildCreateViewingRequestInput(form);
        const response: AxiosResponse<any> = await axiosInstance.post(
          `/viewings/request`,
          data
        );
        updateViewingRequestTxn(response.data.viewing_request);
        success({ title: "Viewing Request sent" });
        return response.data;
      } catch (error: any) {
        fail({
          title: "Failed to create viewing request",
        });
        throw new Error(`Failed to create viewing request: ${error.message}`);
      }
    },
    [axiosInstance, fail, success, updateViewingRequestTxn]
  );

  const acceptViewingRequest = useCallback(
    async (viewing_request_id: string, listing_id: string): Promise<any> => {
      try {
        const response: AxiosResponse<any> = await axiosInstance.put(
          `/viewings/request/accept/${viewing_request_id}`,
          { listing_id: listing_id }
        );
        accept(viewing_request_id);
        success({ title: "Viewing Request accepted" });
        return response.data;
      } catch (error: any) {
        fail({
          title: "Failed to accept viewing request",
        });
        throw new Error(`Failed to accept viewing request: ${error.message}`);
      }
    },
    [axiosInstance, fail, success, accept]
  );

  const rejectViewingRequest = useCallback(
    async (viewing_request_id: string): Promise<any> => {
      try {
        const response: AxiosResponse<any> = await axiosInstance.put(
          `/viewings/request/reject/${viewing_request_id}`
        );
        reject(viewing_request_id);
        success({ title: "Viewing Request rejected" });
        return response.data;
      } catch (error: any) {
        fail({
          title: "Failed to reject viewing request",
        });
        throw new Error(`Failed to reject viewing request: ${error.message}`);
      }
    },
    [axiosInstance, fail, success, reject]
  );

  const deleteViewingRequest = useCallback(
    async (viewing_request_id: string, listing_id?: string): Promise<any> => {
      try {
        const response: AxiosResponse<any> = await axiosInstance.delete(
          `/viewings/request/${viewing_request_id}`
        );
        deleteRequest(viewing_request_id, listing_id);
        success({ title: "Viewing Request deleted" });
        return response.data;
      } catch (error: any) {
        fail({
          title: "Failed to delete viewing request",
        });
      }
    },
    [axiosInstance, fail, success, deleteRequest]
  );

  return {
    getListingSchedule,
    createViewingSchedule,
    updateViewingSchedule,
    deleteViewingSchedule,
    getAllBuyerViewingRequests,
    getAllSellerViewingRequests,
    createViewingRequest,
    acceptViewingRequest,
    rejectViewingRequest,
    deleteViewingRequest,
  };
};

export default useViewingsApi;
