import { atom, atomFamily } from "recoil";
import { recoilPersist } from "recoil-persist";
import { CartContents, Purchase } from "../../types/services";

const { persistAtom } = recoilPersist();

export const cartAtom = atom<CartContents>({
  key: "cartAtom",
  default: [],
  effects: [persistAtom],
});

export const mlsOpenAtom = atom<boolean>({
  key: "mlsOpenAtom",
  default: false,
});

export const mediaOpenAtom = atom<boolean>({
  key: "mediaOpenAtom",
  default: false,
});

// this is just a list of all purchases for a user
export const purchasesAtom = atom<string[]>({
  key: "purchasesAtom",
  default: [],
});

export const purchasesAtomFamily = atomFamily<Purchase | null, string>({
  key: "purchasesAtomFamily",
  default: null,
});

export const purchasesForListing = atom<string[]>({
  key: "purchasesForListing",
  default: [],
});

export const upgradeOpenAtom = atom<boolean>({
  key: "upgradeOpenAtom",
  default: false,
});
