import { ViewingSchedule } from "../types";

export const convertDateToString = (custom?: Date | string) => {
  const date =
    custom instanceof Date ||
    (typeof custom === "string" && !isNaN(Date.parse(custom)))
      ? new Date(custom)
      : new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const convertDayToString = (custom?: Date | string) => {
  const date =
    custom instanceof Date ||
    (typeof custom === "string" && !isNaN(Date.parse(custom)))
      ? new Date(custom)
      : new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const calculateDateForDayOfWeek = (
  dayOfWeek: number,
  referenceDate: Date
): Date => {
  const date = new Date(referenceDate);
  date.setDate(date.getDate() + ((dayOfWeek - date.getDay() + 7) % 7));
  return date;
};

export const setHoursAndMinutes = (
  date: Date,
  hours: number,
  minutes: number
): Date => {
  const newDate = new Date(date);
  newDate.setUTCHours(hours, minutes, 0, 0);
  return newDate;
};

export const calculateCurrentWeekViewings = (
  viewings: ViewingSchedule[],
  currentWeekStart: Date,
  addDays: (date: Date, days: number) => Date
): ViewingSchedule[] => {
  const currentWeekViewings: ViewingSchedule[] = [];
  viewings.forEach((schedule) => {
    const scheduleStartDate = new Date(schedule.start_time);
    const scheduleEndDate = new Date(schedule.end_time);

    if (schedule.days_of_week && schedule.days_of_week.length > 0) {
      if (scheduleStartDate > addDays(currentWeekStart, 7)) {
        return;
      }
      schedule.days_of_week.forEach((day) => {
        const date = calculateDateForDayOfWeek(day, currentWeekStart);
        if (date >= currentWeekStart && date < addDays(currentWeekStart, 7)) {
          const viewing: ViewingSchedule = {
            ...schedule,
            start_time: setHoursAndMinutes(
              date,
              scheduleStartDate.getUTCHours(),
              scheduleStartDate.getUTCMinutes()
            ).toISOString(),
            end_time: setHoursAndMinutes(
              date,
              scheduleEndDate.getUTCHours(),
              scheduleEndDate.getUTCMinutes()
            ).toISOString(),
          };
          currentWeekViewings.push(viewing);
        }
      });
    } else if (
      scheduleStartDate >= currentWeekStart &&
      scheduleStartDate < addDays(currentWeekStart, 7)
    ) {
      currentWeekViewings.push(schedule);
    }
  });

  return currentWeekViewings.sort(
    (a, b) =>
      new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
  );
};

export const compareDates = (
  start_date1: string,
  end_date1: string,
  start_date2: string,
  end_date2: string
): boolean => {
  const start1 = new Date(start_date1);
  const end1 = new Date(end_date1);
  const start2 = new Date(start_date2);
  const end2 = new Date(end_date2);
  return (
    start1.getTime() === start2.getTime() &&
    end1.getTime() === end2.getTime() &&
    start1.getDate() === start2.getDate() &&
    end1.getDate() === end2.getDate()
  );
};

export const isTimeEqual = (
  start_date1: string,
  end_date1: string,
  start_date2: string,
  end_date2: string
): boolean => {
  const start1 = new Date(start_date1);
  const end1 = new Date(end_date1);
  const start2 = new Date(start_date2);
  const end2 = new Date(end_date2);
  return (
    start1.getHours() === start2.getHours() &&
    start1.getMinutes() === start2.getMinutes() &&
    end1.getHours() === end2.getHours() &&
    end1.getMinutes() === end2.getMinutes()
  );
};

export const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const dayOfWeekString = (day: number) => {
  return DAYS_OF_WEEK[day % 7];
};

export const timeDifferenceFromNow = (ms: Date): string => {
  const now = Date.now();
  const diff = ms.valueOf() - now;
  const absDiff = Math.abs(diff);

  const second = 1000; // 1000 milliseconds in a second
  const minute = second * 60; // 60 seconds in a minute
  const hour = minute * 60; // 60 minutes in an hour
  const day = hour * 24; // 24 hours in a day

  // Determine the scale of the difference
  if (absDiff < minute) {
    const seconds = Math.round(absDiff / second);
    if (seconds === 0) return "just now";
    return diff > 0 ? `In ${seconds} seconds` : `${seconds} seconds ago`;
  } else if (absDiff < hour) {
    const minutes = Math.round(absDiff / minute);
    return diff > 0 ? `In ${minutes} minutes` : `${minutes} minutes ago`;
  } else if (absDiff < day) {
    const hours = Math.round(absDiff / hour);
    return diff > 0 ? `In ${hours} hours` : `${hours} hours ago`;
  } else {
    const days = Math.round(absDiff / day);
    return diff > 0 ? `In ${days} days` : `${days} days ago`;
  }
};
