import { Box, FormLabel, Input } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  selectedOfferAtom,
  offerAtomFamily,
  draftOfferIncludedItemsAtom,
  draftOfferExcludedItemsAtom,
} from "../../../../recoil/offers/atoms";
import { ExpandableBox } from "../../../common";

export const Items = ({ viewOnly }: { viewOnly?: boolean }) => {
  const selectedOffer = useRecoilValue(selectedOfferAtom);
  const offer = useRecoilValue(offerAtomFamily(selectedOffer));
  const [draftIncluded, setDraftIncluded] = useRecoilState(
    draftOfferIncludedItemsAtom
  );
  const [draftExcluded, setDraftExcluded] = useRecoilState(
    draftOfferExcludedItemsAtom
  );
  useEffect(() => {
    if (!offer) return;
    if (offer.included_items) {
      setDraftIncluded(offer.included_items);
    }
    if (offer.excluded_items) {
      setDraftExcluded(offer.excluded_items);
    }
  }, [offer, setDraftExcluded, setDraftIncluded]);

  return (
    <ExpandableBox title="Incl/Excluded items">
      <Box p={2} my={2}>
        <FormLabel>Included Items</FormLabel>
        <Input
          disabled={viewOnly}
          value={draftIncluded}
          onChange={(e) => {
            setDraftIncluded(e.target.value);
          }}
          mb={4}
        />
        <FormLabel>Excluded Items</FormLabel>
        <Input
          disabled={viewOnly}
          value={draftExcluded}
          onChange={(e) => {
            setDraftExcluded(e.target.value);
          }}
        />
      </Box>
    </ExpandableBox>
  );
};
