import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { useIsMobile } from "../../hooks/utils/useWindowDimensions";
import { useOffersApi } from "../../hooks/Offers";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { format } from "date-fns";
import { useEffect, useCallback } from "react";
import { Eye } from "react-feather";
import {
  selectedOfferAtom,
  offerAtomFamily,
  offerDrawerOpenAtom,
} from "../../recoil/offers/atoms";
import { ICON_SIZE } from "../../theme";
import { formatPrice } from "../../utils/formatters";
import { OfferStatus } from "../../types";

export const MessageBubble = ({
  message: { fromUser, content },
}: {
  message: {
    fromUser: boolean;
    id: string;
    sender_id: string;
    receiver_id: string;
    listing_id: string;
    content: string;
  };
}) => {
  const isMobile = useIsMobile();
  return (
    <Box
      bg={fromUser ? "celestial" : "secondary"}
      borderRadius="3xl"
      borderBottomLeftRadius={!fromUser ? "none" : "3xl"}
      borderBottomRightRadius={fromUser ? "none" : "3xl"}
      p={3}
      maxWidth={isMobile ? "55%" : "70%"}
      my={2}
    >
      <Text color="white">{content}</Text>
    </Box>
  );
};
export const OfferBubble = ({ offerId }: { offerId: string }) => {
  const { loadOffer } = useOffersApi();
  const setSelectedOffer = useSetRecoilState(selectedOfferAtom);
  const setEditorOpen = useSetRecoilState(offerDrawerOpenAtom);
  const offer = useRecoilValue(offerAtomFamily(offerId ?? ""));
  useEffect(() => {
    if (offerId && !offer) loadOffer(offerId);
  }, [offerId, offer, loadOffer]);

  const handleView = useCallback(() => {
    setSelectedOffer(offerId);
    setEditorOpen(true);
  }, [offerId, setEditorOpen, setSelectedOffer]);
  if (!offer) return null;
  const now = new Date();
  const effectiveUntil = new Date(offer.offer_end_date);
  if (offer.offer_status === OfferStatus.Revoked) {
    return (
      <Box
        bg={"darkContainer"}
        rounded="lg"
        textColor={"white"}
        p={3}
        width="40%"
        my={2}
      >
        <Text fontWeight={600} fontSize={"xl"}>
          Revoked Offer for ${formatPrice(offer.offer_price)}
        </Text>
      </Box>
    );
  }
  const { outerBg, innerBg, hoverButton } = offerBoxColors(offer.offer_status);

  return (
    <Box
      bg={outerBg}
      rounded="lg"
      textColor={"gray.900"}
      p={2}
      width="60%"
      my={2}
    >
      <Box bg={innerBg} rounded={"md"} p={3}>
        <HStack justifyContent={"space-between"} alignItems={"center"}>
          <Text fontWeight={600} fontSize={"2xl"}>
            {offer.offer_status === OfferStatus.Pending &&
              effectiveUntil < now &&
              "Expired "}
            {offer.offer_status === OfferStatus.Accepted && "Accepted "}
            {offer.offer_status === OfferStatus.Rejected && "Rejected "}
            {offer.offer_id && "Counter-"}Offer
          </Text>
          <Text textAlign={"right"}>
            Exp.
            <br />
            {format(effectiveUntil, "d MMM/yy")}
          </Text>
        </HStack>

        <Text fontSize={"2xl"} textAlign={"center"}>
          ${formatPrice(offer.offer_price)}
        </Text>
        <Button
          mt={4}
          bg={outerBg}
          _hover={{
            bg: hoverButton,
          }}
          w={"100%"}
          leftIcon={<Eye size={ICON_SIZE} />}
          onClick={handleView}
        >
          View Details
        </Button>
      </Box>
    </Box>
  );
};

const offerBoxColors = (status: OfferStatus) => {
  let outerBg = "";
  let innerBg = "";
  let hoverButton = "";
  switch (status) {
    case OfferStatus.Accepted:
      outerBg = "green.300";
      innerBg = "green.100";
      hoverButton = "green.500";
      break;
    case OfferStatus.Rejected:
      outerBg = "red.300";
      innerBg = "red.100";
      hoverButton = "red.500";
      break;
    default:
    case OfferStatus.Pending:
      outerBg = "brutalistSecondary";
      innerBg = "orange.100";
      hoverButton = "orange.500";
      break;
  }
  return { outerBg, innerBg, hoverButton };
};
