import { atomFamily, atom } from "recoil";
import { Offer, Subject } from "../../types";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

export type OfferValueResponse = {
  id: number;
  addressUnit: string;
  addressNumber: string;
  addressStreet: string;
  pidNo: string;
  city: string;
  province: string;
  postalCode: string;
  home: number;
  estimatedValue: number;
  offerValueRange: number[];
  accuracy: number;
  createdAt: string;
  updatedAt: string;
  status: string;
};

// keyed by listingId+userId
export const listingOfferIdsAtomFamily = atomFamily<string[], string>({
  key: "listingOfferIdsAtomFamily",
  default: [],
});

// keyed by offer_id
export const offerAtomFamily = atomFamily<Offer | null, string>({
  key: "offerAtomFamily",
  default: null,
});

export const selectedOfferAtom = atom<string>({
  key: "selectedOfferAtom",
  default: "",
  effects: [persistAtom],
});

export const newOfferStepAtom = atom<NewOfferStep>({
  key: "newOfferStepAtom",
  default: 0,
});

export const contingencyDrawerOpenAtom = atom<boolean>({
  key: "contingencyDrawerOpenAtom",
  default: false,
});

export const newOfferDrawerOpenAtom = atom<boolean>({
  key: "newOfferDrawerOpenAtom",
  default: false,
});

export const offerDrawerOpenAtom = atom<boolean>({
  key: "offerDrawerOpenAtom",
  default: false,
});

// this should have each individual subject
export const subjectAtomFamily = atomFamily<Subject | null, string>({
  key: "subjectAtomFamily",
  default: null,
});

export const allSubjectIdsAtom = atom<string[]>({
  key: "allSubjectIdsAtom",
  default: [],
});

// used for adding to offer
export const subjectIdsForOfferAtom = atom<string[]>({
  key: "subjectIdsForOfferAtom",
  default: [],
});

// True is buyer | False is seller
export const draftCostBreakdown = atom<CostBreakdown>({
  key: "draftCostBreakdown",
  default: {
    attending_exec_docs: false,
    investigating_title: false,
    discharge_fees: false,
    prepayment_penalties: false,
    real_estate_commission: false,
    searching_title: true,
    drafting_documents: true,
    land_title_reg_fees: true,
    survey_certificate: true,
    mortgage: true,
    fire_insurance_premium: true,
    sales_tax: true,
    property_transfer_tax: true,
  },
});

export type CostBreakdown = {
  // usually seller below
  attending_exec_docs: boolean;
  investigating_title: boolean;
  discharge_fees: boolean;
  prepayment_penalties: boolean;
  real_estate_commission: boolean;
  // usually buyer below
  searching_title: boolean;
  drafting_documents: boolean;
  land_title_reg_fees: boolean;
  survey_certificate: boolean;
  mortgage: boolean;
  fire_insurance_premium: boolean;
  sales_tax: boolean;
  property_transfer_tax: boolean;
};

export const draftPriceAtom = atom<string>({
  key: "draftPriceAtom",
  default: "",
});

export const draftOfferEndAtom = atom<string>({
  key: "draftOfferEndAtom",
  default: "",
});

export type DepositInfo = {
  deposit: string;
  trust_info: string; // todo in preview delivered in trust to ___***___
};

export const draftDepositInfoAtom = atom<DepositInfo>({
  key: "draftDepositInfoAtom",
  default: {
    deposit: "10",
    trust_info: "",
  },
});

export const draftOfferPossessionDateAtom = atom<string>({
  key: "draftOfferPossessionDateAtom",
  default: "",
});

export const draftOfferAdjustmentsDateAtom = atom<string>({
  key: "draftOfferAdjustmentsDateAtom",
  default: "",
});

export const draftOfferCompletionDateAtom = atom<string>({
  key: "draftOfferCompletionDateAtom",
  default: "",
});

export const draftOfferViewedDateAtom = atom<string>({
  key: "draftOfferViewedDateAtom",
  default: "",
});

export const draftOfferIncludedItemsAtom = atom<string>({
  key: "draftOfferIncludedItemsAtom",
  default: "",
});

export const draftOfferExcludedItemsAtom = atom<string>({
  key: "draftOfferExcludedItemsAtom",
  default: "",
});

// keyed by listing_id
export const nearbyPropertyEstimateAtomFamily = atomFamily<
  OfferValueResponse | null,
  string
>({
  key: "nearbyPropertyEstimateAtomFamily",
  default: null,
});

export enum NewOfferStep {
  Price,
  Deposit,
  Dates,
  Inclusions,
  CostBreakdown,
  Contingencies,
  Review,
}
