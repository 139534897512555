import { Box, Text, Flex, Progress } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { newOfferStepAtom } from "../../../recoil/offers/atoms";
import { NewOfferStep } from "../../../recoil/offers/atoms";
import { OfferPage } from "../../../pages/OfferPage";
import {
  CostBreakdown,
  OfferPriceInfo,
  Items,
  OfferContingencies,
  DepositInfo,
  BaseInfo,
  OfferStepsFooter,
  ContingencyDrawer,
} from ".";
import { selectOffer } from "../../../recoil/offers/selectors";
import { useLoadListings } from "../../../hooks/Listings";
import { listingFamily, selectedListingAtom } from "../../../recoil/listings";
import { OfferStatus } from "../../../types";
import { timeDifferenceFromNow } from "../../../utils/date";
import { formatPrice } from "../../../utils/formatters";

const steps = Object.values(NewOfferStep).filter((v) =>
  isNaN(Number(v))
) as string[];

export const OfferSteps = () => {
  const activeStep = useRecoilValue(newOfferStepAtom);
  const offer = useRecoilValue(selectOffer);
  const selectedListing = useRecoilValue(selectedListingAtom);
  const listing = useRecoilValue(listingFamily(selectedListing ?? ""));
  const { loadListing } = useLoadListings();
  const StepContent = useMemo(() => {
    switch (activeStep) {
      case NewOfferStep.Price:
        return <OfferPriceInfo />;
      case NewOfferStep.Dates:
        return <BaseInfo />;
      case NewOfferStep.Deposit:
        return <DepositInfo />;
      case NewOfferStep.CostBreakdown:
        return <CostBreakdown />;
      case NewOfferStep.Contingencies:
        return <OfferContingencies />;
      case NewOfferStep.Inclusions:
        return <Items />;
      case NewOfferStep.Review:
        return <OfferPage hideActions />;
    }
  }, [activeStep]);

  useEffect(() => {
    if (offer && !listing) {
      loadListing(offer.listing_id);
    } else if (!listing && selectedListing) {
      loadListing(selectedListing);
    }
  }, [listing, loadListing, offer, selectedListing]);

  return (
    <Box w="full" display="flex" flexDirection="column">
      <Progress
        hasStripe
        colorScheme={"ohBlue"}
        mt={4}
        rounded={"lg"}
        value={((activeStep + 1) / steps.length) * 100}
      />
      {listing && (
        <Flex
          px={3}
          py={2}
          justify={"space-between"}
          wrap={"wrap-reverse"}
          align={"center"}
        >
          <Flex justify={"space-between"} w={"full"} fontWeight={600}>
            <Text>
              {listing.address}
              {listing.apt_no ? ` Unit ${listing.apt_no}` : ""}, {listing.city},{" "}
              {listing.state_province}
            </Text>
            <Text>${formatPrice(listing.price)}</Text>
          </Flex>
          {offer &&
            offer.offer_status === OfferStatus.Draft &&
            offer.date_updated && (
              <Text fontSize={14}>
                saved {timeDifferenceFromNow(offer.date_updated)}
              </Text>
            )}
        </Flex>
      )}
      <Box h={"100%"} px={3}>
        {StepContent}
      </Box>
      <ContingencyDrawer />
      <OfferStepsFooter />
    </Box>
  );
};
