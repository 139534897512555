import { useCallback } from "react";
import axios, { AxiosResponse } from "axios";
import { useRecoilValue, useRecoilCallback } from "recoil";
import {
  useDeleteListing,
  useUpdateDraftListingToActive,
  useUpdateDraftListingToInReview,
  listingFamily,
} from "../../recoil/listings";
import { selectAxiosInstance } from "../../recoil/selectors";
import { purchasesForListing } from "../../recoil/services/atoms";
import { FormattedFile, ListingForm } from "../../types";
import { decrypt } from "../../utils/crypto";
import { buildCreateListingInput } from "../../utils/serializier";
import { useCustomToast } from "../useCustomToast";

export const useListingApi = () => {
  const { success, fail } = useCustomToast();
  const deleteListingTxn = useDeleteListing();
  const purchasesForNewListing = useRecoilValue(purchasesForListing);
  const activateListingTxn = useUpdateDraftListingToActive();
  const makeListingInReview = useUpdateDraftListingToInReview();
  const axiosInstance = useRecoilValue(selectAxiosInstance);

  const uploadFiles = useCallback(
    async (files: FormattedFile[], key: string): Promise<boolean> => {
      try {
        await Promise.all(
          files.map(async (file: FormattedFile) => {
            const response = await axiosInstance.get(
              `/file/${encodeURIComponent(file.storageName)}`
            );
            const presignedUrl = decrypt(response.data.file_url, key);
            let res;
            res = await axios.put(presignedUrl, file, {
              headers: {
                "Content-Type": file.type,
              },
            });
            if (res.status !== 200)
              throw new Error("Something went wring with file upload");
            return res;
          })
        );
      } catch (error) {
        console.error({ error });
        fail({ title: String(error) });
        return false;
      } finally {
        return true;
      }
    },
    [axiosInstance, fail]
  );

  const deleteFile = useRecoilCallback(
    ({ snapshot, set }) =>
      async (s3name: string, listingId: string): Promise<boolean> => {
        try {
          const res = await axiosInstance.delete(
            `/listing-assets/${encodeURIComponent(s3name)}`
          );
          if (res.status !== 200)
            throw new Error("Something went wring with file deletion");
          const listing = snapshot
            .getLoadable(listingFamily(listingId))
            .getValue();
          if (listing) {
            const updatedAssets = listing.listing_assets.filter(
              (a) => a.s3_file_name !== s3name
            );
            const updatedUrls = listing.file_urls.filter(
              (u) => u.id !== s3name
            );
            set(listingFamily(listingId), {
              ...listing,
              file_urls: updatedUrls,
              listing_assets: updatedAssets,
            });
          }
        } catch (error) {
          console.error({ error });
          fail({ title: String(error) });
          return false;
        } finally {
          return true;
        }
      },
    [axiosInstance, fail]
  );
  const createListing = useCallback(
    async (form: ListingForm, files: FormattedFile[]): Promise<any> => {
      try {
        const data = buildCreateListingInput(form, files);
        const response: AxiosResponse<any> = await axiosInstance.post(
          "/listings",
          { ...data, purchases: purchasesForNewListing }
        );
        success({ title: "Listing created" });
        return response.data;
      } catch (error: any) {
        fail({
          title: "Couldn't upload listing",
        });
        throw new Error(`Couldn't upload listing: ${error.message}`);
      }
    },
    [axiosInstance, fail, purchasesForNewListing, success]
  );

  const updateDraftListingToActive = useCallback(
    async (listingId: string): Promise<any> => {
      try {
        const response: AxiosResponse<any> = await axiosInstance.put(
          `/listings/${listingId}/status/activate`
        );
        activateListingTxn(listingId);
        success({ title: "Listing is active" });
        return response.data;
      } catch (error: any) {
        fail({
          title: "Couldn't activate listing",
        });
        throw new Error(`Couldn't update listing: ${error.message}`);
      }
    },
    [axiosInstance, fail, success, activateListingTxn]
  );

  const updateDraftListingToReview = useCallback(
    async (listingId: string): Promise<any> => {
      try {
        const response: AxiosResponse<any> = await axiosInstance.put(
          `/listings/${listingId}/status/review`
        );
        makeListingInReview(listingId);
        success({ title: "Listing is in review" });
        return response.data;
      } catch (error: any) {
        fail({
          title: "Something went wrong",
        });
        throw new Error(`Couldn't update listing: ${error.message}`);
      }
    },
    [axiosInstance, makeListingInReview, success, fail]
  );

  const updateListing = useCallback(
    async (
      form: ListingForm,
      listingId: string,
      files: FormattedFile[]
    ): Promise<any> => {
      try {
        const data = buildCreateListingInput(form, files);

        const dataWithId = {
          ...data,
          id: listingId,
        };

        const response: AxiosResponse<any> = await axiosInstance.put(
          "/listings",
          { listing: dataWithId }
        );
        success({ title: "Listing updated" });
        return response.data;
      } catch (error: any) {
        fail({
          title: "Couldn't update listing",
        });
        throw new Error(`Couldn't update listing: ${error.message}`);
      }
    },
    [axiosInstance, fail, success]
  );

  const updateListingAssetPositions = useCallback(
    async (
      images: { id: string; position: number }[],
      listing_id: string
    ): Promise<any> => {
      try {
        await axiosInstance.post("/listing-assets/positions", {
          listing_id,
          images,
        });
      } catch (error: any) {
        fail({
          title: "Couldn't update listing asset positions",
        });
        console.error(error);
      }
    },
    [axiosInstance, fail]
  );

  const deleteListing = useCallback(
    async (listingId: string): Promise<any> => {
      try {
        const response: AxiosResponse<any> = await axiosInstance.delete(
          `/listings/${listingId}`
        );
        deleteListingTxn(listingId);
        success({ title: "Listing deleted" });
        return response.data;
      } catch (error: any) {
        fail({
          title: "Couldn't delete listing",
        });
        throw new Error(`Couldn't delete listing: ${error.message}`);
      }
    },
    [axiosInstance, fail, success, deleteListingTxn]
  );

  return {
    uploadFiles,
    deleteFile,
    createListing,
    updateListing,
    updateDraftListingToActive,
    updateDraftListingToReview,
    deleteListing,
    updateListingAssetPositions,
  };
};

export default useListingApi;
