import { DateInput, DrawerWithClose } from "../common";
import {
  Flex,
  Heading,
  Button,
  Text,
  Box,
  List,
  VStack,
  FormLabel,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../../recoil/atoms";
import { ArrowRight } from "react-feather";
import { ICON_SIZE } from "../../theme";
import useUserApi from "../../hooks/useUserApi";
import { selectedListingAtom } from "../../recoil/listings";
import {
  applicationOpenAtom,
  rentalApplicationFormAtom,
  rentalDetailsFamily,
} from "../../recoil/rentals";
import { convertDayToString } from "../../utils/date";
import {
  Applicant,
  RentalApplication,
  RentalRequirements,
} from "../../types/rentals";
import { parse } from "date-fns";
import { generate32String } from "../../utils/crypto";
import { ApplicantBox } from "./ApplicantBox";
import { useRentalApi } from "../../hooks/useRentalApi";

const DEFAULT_APPLICANT = {
  id: "",
  full_name: "",
  notes: "",
  credit_score: "",
  employer: "",
  occupation: "",
  employed_duration: "",
  yearly_income: "",
  address: "",
  currently_renting: true,
  current_landlord_contact: "",
  reason: "",
  smoker: false,
  reference_contact: "",
  insurance: true,
  drivers_license: "",
  pets: [],
} as Applicant;

export const CreateApplicationDrawer = () => {
  const listingId = useRecoilValue(selectedListingAtom);
  const rentalDetails = useRecoilValue(rentalDetailsFamily(listingId ?? ""));
  const [form, setForm] = useRecoilState(rentalApplicationFormAtom);
  const user = useRecoilValue(userState);
  const isOpen = useRecoilValue(applicationOpenAtom);
  const { closeApplicationDrawer } = useRentalApi();
  const draft = null as null | RentalApplication; // todo
  // if theres a draft application for this listing (selector based on lisrtingid)
  // ->>>> a useEffect should set the applicationformstate with draft contents
  // make sure theres only one draft per user (per listing)
  // this drawer shall display the draft one if it exists

  const handleAddApplicant = useCallback(async () => {
    if (!rentalDetails || rentalDetails.max_occupancy <= form.applicants.length)
      return;

    const applicants = [...form.applicants];
    applicants.push({
      ...DEFAULT_APPLICANT,
      id: generate32String(),
    });
    setForm((f) => ({
      ...f,
      applicants,
    }));
  }, [form.applicants, rentalDetails, setForm]);

  const [accepting, setAccepting] = useState(false);
  const { createPermission } = useUserApi();

  useEffect(() => {
    if (listingId && user && user.id) {
      setForm((f) => ({ ...f, listing_id: listingId, applicant_id: user.id! }));
    }
  }, [listingId, setForm, user]);

  useEffect(() => {
    if (draft) {
      setForm((f) => ({
        ...f,
        applicants: draft.applicants,
        start_date: draft.start_date,
        document_ids: draft.document_ids,
      }));
    }
  }, [draft, setForm]);

  const onAcceptDisclaimer = useCallback(async () => {
    setAccepting(true);
    await createPermission("RENTAL_APPLICATION_DISCLAIMER_HIDDEN");
    setAccepting(false);
  }, [createPermission]);

  const disclaimerHidden = true;
  //user && user.permissions.includes("RENTAL_APPLICATION_DISCLAIMER_HIDDEN");

  const onSaveDraft = useCallback(async () => {
    console.log({ rentalDetails });

    if (!listingId) {
      return;
    }
    // save application api
  }, [listingId, rentalDetails]);

  return (
    <DrawerWithClose
      isOpen={isOpen}
      handleClose={closeApplicationDrawer}
      bg="container-bg"
    >
      {disclaimerHidden ? (
        <Flex p={3} direction={"column"}>
          <Heading>Create Application</Heading>
          {rentalDetails && (
            <VStack>
              <Box w={"full"}>
                <FormLabel>
                  Lease start date (landlord prefers{" "}
                  {rentalDetails.preferred_start_date.toString()})
                </FormLabel>
                <DateInput
                  dateOnly
                  date={convertDayToString(form.start_date)}
                  setDate={(v) =>
                    setForm((f) => ({
                      ...f,
                      start_date: parse(v, "yyyy-MM-dd", new Date()),
                    }))
                  }
                />
              </Box>
              <Box w={"full"}>
                <FormLabel>
                  Max occupancy: {rentalDetails.max_occupancy}
                </FormLabel>
                {form.applicants.map((a, index) => {
                  return (
                    <ApplicantBox
                      applicant={a}
                      index={index}
                      listingId={listingId ?? ""}
                      key={a.id}
                      setForm={setForm}
                    />
                  );
                })}
                <Button
                  isDisabled={
                    form.applicants.length >= rentalDetails.max_occupancy
                  }
                  onClick={handleAddApplicant}
                  variant={form.applicants.length ? "primary" : "secondary"}
                >
                  Add {!form.applicants.length ? "primary" : "additional"}{" "}
                  applicant
                </Button>
              </Box>
            </VStack>
          )}
          <List my={5} textAlign={"left"} justifyItems={"flex-start"}>
            <Flex direction={"row-reverse"} mt={4}>
              <Button variant={"primary"} onClick={onSaveDraft}>
                Next
                <ArrowRight size={ICON_SIZE} />
              </Button>
            </Flex>
          </List>
        </Flex>
      ) : (
        <Box>
          <Heading>Disclaimer</Heading>
          <VStack pb={6}>
            <Text>TODO ADD Disclaimer text for rentals</Text>
            <Button
              variant={"primary"}
              w={"full"}
              isLoading={accepting}
              onClick={onAcceptDisclaimer}
            >
              Agree
            </Button>
          </VStack>
        </Box>
      )}
    </DrawerWithClose>
  );
};

export const ApplicantKeys = (k: keyof RentalRequirements) => {
  switch (k) {
    case "current_landlord_contact":
      return "Current landlord contact";
    case "reference_contact":
      return "Reference contact";

    default:
      break;
  }
};
