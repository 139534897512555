import React, { useMemo } from "react";
import {
  Box,
  Text,
  Stack,
  Heading,
  CardBody,
  Card,
  useColorModeValue,
  Button,
  CardFooter,
  ButtonGroup,
  Divider,
} from "@chakra-ui/react";
import { listingFamily } from "../../recoil/listings";
import { useRecoilValue } from "recoil";
import { useIsMobile } from "../../hooks/utils/useWindowDimensions";
import { viewingRequestAtomFamily } from "../../recoil/viewings/atoms";
import { formatDateTimes } from "../../utils/formatters";
import useViewingsApi from "../../hooks/useViewingsApi";
import { userState } from "../../recoil/atoms";
import { useOpenUpgradeWindow } from "../../recoil/transactions";

interface ViewingCardProps {
  viewingRequestId: string;
  seller_view: boolean;
  request_mode?: boolean;
}

export const ViewingRequestCard: React.FC<ViewingCardProps> = ({
  viewingRequestId,
  seller_view,
  request_mode = false,
}) => {
  const { acceptViewingRequest, rejectViewingRequest, deleteViewingRequest } =
    useViewingsApi();
  const viewing = useRecoilValue(viewingRequestAtomFamily(viewingRequestId));
  const listing = useRecoilValue(listingFamily(viewing?.listing_id ?? ""));
  const darkMode = useColorModeValue(false, true);
  const isMobile = useIsMobile();
  const user = useRecoilValue(userState);
  const upgrade = useOpenUpgradeWindow();
  const { day, date, start_time, end_time } = useMemo(() => {
    return formatDateTimes(viewing?.start_time ?? "", viewing?.end_time ?? "");
  }, [viewing?.start_time, viewing?.end_time]);

  if (!viewing || !user) return null;

  const handleViewingRequestResponse = async (
    response: boolean,
    viewing_request_id: string,
    listing_id?: string
  ) => {
    try {
      if (response && listing_id) {
        await acceptViewingRequest(viewing_request_id, listing_id);
      } else {
        await rejectViewingRequest(viewing_request_id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteViewingRequest = async (viewing_request_id: string) => {
    try {
      await deleteViewingRequest(viewing_request_id);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card
      marginBottom={5}
      w={isMobile ? "80vw" : 300}
      marginRight={6}
      minWidth={isMobile ? "fit-content" : 250}
      maxW={isMobile ? "90vw" : 340}
      border={"0.5px solid"}
      borderColor={"border"}
      borderRadius={"md"}
      _hover={
        darkMode
          ? { outline: "1px solid lightgray" }
          : { boxShadow: "0 4px 8px rgba(0, 0, 0, 0.6)" }
      }
      mt={2}
      bg={"card-bg"}
      style={{
        transition: "box-shadow 0.1s ease",
      }}
    >
      <CardBody>
        <Stack spacing="4">
          {seller_view && viewing.first_name && viewing.last_name && (
            <>
              <Box>
                <Heading size="xs" textTransform="uppercase" color={"gray.500"}>
                  From
                </Heading>
                <Text
                  pt="2"
                  fontSize="lg"
                  style={
                    user.tier === 0
                      ? {
                          filter: "blur(8px)",
                          userSelect: "none",
                        }
                      : {}
                  }
                >
                  {`${
                    viewing.first_name.charAt(0).toUpperCase() +
                    viewing.first_name.slice(1)
                  } ${
                    viewing.last_name.charAt(0).toUpperCase() +
                    viewing.last_name.slice(1)
                  }`}
                </Text>
              </Box>
              <Divider />
            </>
          )}
          <Box>
            <Heading size="xs" textTransform="uppercase" color={"gray.500"}>
              When
            </Heading>
            <Text pt="2" fontSize="lg">
              {`${day} ${date}, ${start_time} - ${end_time}`}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase" color={"gray.500"}>
              Property
            </Heading>
            <Text pt="2" fontSize="lg">
              {listing?.address}
            </Text>
          </Box>
        </Stack>
      </CardBody>
      <CardFooter paddingTop={2}>
        {user.tier === 0 ? (
          <Button
            onClick={upgrade}
            variant="outline"
            colorScheme="blue"
            width="100%"
          >
            Upgrade to Accept Requests
          </Button>
        ) : request_mode && listing ? (
          <ButtonGroup spacing="2" w={"100%"}>
            <Button
              variant="primary"
              width={"50%"}
              onClick={() =>
                handleViewingRequestResponse(true, viewing.id, listing.id)
              }
            >
              Accept
            </Button>
            <Button
              variant="danger_outline"
              width={"50%"}
              onClick={() => handleViewingRequestResponse(false, viewing.id)}
            >
              Reject
            </Button>
          </ButtonGroup>
        ) : (
          <Button onClick={() => handleDeleteViewingRequest(viewing.id)}>
            Cancel Viewing
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};
