import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  NewListingStep as StepType,
  formErrorsAtom,
  listingInputAtom,
  newListingStepAtom,
} from "../../recoil/listings";
import { useCallback } from "react";
import {
  validateAddress,
  validateArea,
  validateDetails,
  validateMedia,
  validateMoreDetails,
  validatePricing,
  validateProperty,
} from "../../components/createListing/Helpers/NewListingErrorCheckers";

export const useCheckNewListingErrors = () => {
  const activeStep = useRecoilValue(newListingStepAtom);
  const setFormErrors = useSetRecoilState(formErrorsAtom);
  const formState = useRecoilValue(listingInputAtom);

  return useCallback(() => {
    switch (activeStep) {
      case StepType.PropertyType:
        return validateProperty(formState, setFormErrors);
      case StepType.Area:
        return validateArea(formState, setFormErrors);
      case StepType.Address:
        return validateAddress(formState, setFormErrors);
      case StepType.Pricing:
        return validatePricing(formState, setFormErrors);
      case StepType.Details:
        return validateDetails(formState, setFormErrors);
      case StepType.ExtraDetails:
        return validateMoreDetails(formState, setFormErrors);
      case StepType.Media:
        return validateMedia(formState, setFormErrors);
      default:
        return true;
    }
  }, [activeStep, formState, setFormErrors]);
};
