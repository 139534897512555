import React, { useEffect, useMemo, useState } from "react";
import { Box, IconButton, Image } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/zoom";
import { Navigation, Pagination, Keyboard, Zoom } from "swiper/modules";
import { Maximize2, X } from "react-feather";
import { VideoPlayer } from "./VideoPlayer";
import { MediaType } from "../../../types";
import useWindowDimensions, {
  useIsMobile,
} from "../../../hooks/utils/useWindowDimensions";
import "../../../theme/customSwiper.css";

const ImageSlideshow = ({
  images,
  allowFullscreen,
}: {
  images: { src: string; type: MediaType; id?: string }[];
  allowFullscreen?: boolean;
}) => {
  const isMobile = useIsMobile();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };
  const { height } = useWindowDimensions();
  const imgs = useMemo(() => {
    return [...images].sort((a, b) => {
      if (a.type === MediaType.Image && b.type !== MediaType.Image) {
        return -1; // 'a' is an image, so it comes first
      } else if (a.type !== MediaType.Image && b.type === MediaType.Image) {
        return 1; // 'b' is an image, so 'a' comes after 'b'
      } else {
        return 0;
      }
    });
  }, [images]);
  return (
    <>
      <Box position="relative" maxW="full" overflow="hidden">
        {allowFullscreen && (
          <IconButton
            aria-label="Fullscreen"
            icon={<Maximize2 />}
            position="absolute"
            zIndex={2}
            border={"2px"}
            colorScheme="blackAlpha"
            top="1rem"
            right="1rem"
            onClick={handleFullscreenToggle}
          />
        )}
        <Swiper
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          style={
            {
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "white",
            } as React.CSSProperties
          }
          navigation
          keyboard
          lazyPreloadPrevNext={1}
          modules={[Navigation, Pagination, Keyboard]}
          autoHeight
        >
          {imgs.map(({ src, type }, idx) => (
            <SwiperSlide key={idx + "slide"}>
              {type === MediaType.Image ? (
                <Image
                  src={src}
                  loading="lazy"
                  objectFit="contain"
                  width="100%"
                  height={isMobile ? 300 : height * 0.4}
                />
              ) : (
                <VideoPlayer src={src} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      {isFullscreen && (
        <FullScreenSlideshow
          images={imgs}
          isOpen={isFullscreen}
          onClose={() => setIsFullscreen(false)}
        />
      )}
    </>
  );
};

export const FullScreenSlideshow = ({
  images,
  isOpen,
  onClose,
  selected,
}: {
  images: { src: string; type: MediaType; id?: string }[];
  isOpen: boolean;
  onClose: () => void;
  selected?: number;
}) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      e.key === "Escape" && onClose();
    };
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      bg="black"
      h="100vh"
      w="100vw"
      position="fixed"
      top="0"
      left="0"
      zIndex="modal"
    >
      <IconButton
        colorScheme={"blackAlpha"}
        aria-label="Close slideshow"
        icon={<X />}
        position="absolute"
        top="4"
        right="4"
        zIndex="popover"
        onClick={onClose}
      />
      <Swiper
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        style={
          {
            "--swiper-navigation-color": "white",
            "--swiper-pagination-color": "white",
          } as React.CSSProperties
        }
        navigation
        keyboard
        initialSlide={selected}
        lazyPreloadPrevNext={1}
        modules={[Navigation, Pagination, Keyboard, Zoom]}
        autoHeight
        zoom
      >
        {images.map(({ src, type }, idx) => (
          <SwiperSlide key={idx + "slide"}>
            <div className="swiper-zoom-container">
              {type === MediaType.Image ? (
                <Image
                  src={src}
                  loading="lazy"
                  objectFit="contain"
                  width="100%"
                  h="100vh"
                  borderRadius="md"
                />
              ) : (
                <VideoPlayer src={src} fullscreen />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
export default ImageSlideshow;
