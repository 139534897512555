import {
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useState, useCallback } from "react";
import { ArrowRight } from "react-feather";
import { useRecoilState, useRecoilValue } from "recoil";
import { useOffersApi } from "../../hooks/Offers";
import { selectedOfferAtom, offerAtomFamily } from "../../recoil/offers/atoms";
import { ICON_SIZE } from "../../theme";
import { PriceInput } from "../common";

export const CounterOfferModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [selectedOffer, setSelectedOffer] = useRecoilState(selectedOfferAtom);
  const offer = useRecoilValue(offerAtomFamily(selectedOffer));
  const bg = useColorModeValue("white", "darkBg");
  const [newPrice, setNewPrice] = useState(offer?.offer_price ?? "");
  const { saveCounter } = useOffersApi();
  const save = useCallback(async () => {
    const counter = await saveCounter(selectedOffer, newPrice);
    if (!counter) return;
    setSelectedOffer(counter.id);
  }, [newPrice, saveCounter, selectedOffer, setSelectedOffer]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={bg}>
        <ModalHeader>Counter-Offer details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2}>
            <PriceInput
              price={newPrice}
              placeholder="Counter Offer Price"
              setPrice={setNewPrice}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={() => onClose()}>
            Close
          </Button>
          <Button colorScheme="blue" onClick={save}>
            <ArrowRight size={ICON_SIZE} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
