import { Badge } from "@chakra-ui/react";
import { ListingStatus } from "../../types";

export const ListingStatusBadge = ({
  status,
  isRental,
}: {
  status: ListingStatus;
  isRental?: boolean;
}) => {
  let text = "";
  let color = "";
  switch (status) {
    case ListingStatus.Active:
      text = !isRental ? "For Sale" : "For Rent";
      color = "teal";
      break;
    case ListingStatus.Archived:
      text = "Archived";
      color = "red";
      break;
    case ListingStatus.Deleted:
      text = "Deleted";
      color = "facebook";
      break;
    case ListingStatus.InReview:
      text = "In Review";
      color = "orange";
      break;
    case ListingStatus.Draft:
      text = "Draft";
      break;
    case ListingStatus.Sold:
      text = "Sold";
      color = "facebook";
      break;
    case ListingStatus.PreSale:
      text = "Pre-Sale";
      color = "linkedin";
      break;
    case ListingStatus.NewBuild:
      text = "New Building";
      color = "linkedin";
      break;
  }

  return (
    <Badge
      borderRadius="full"
      px="3"
      py={1}
      size={"sm"}
      fontSize={12}
      colorScheme={color}
    >
      {text}
    </Badge>
  );
};
