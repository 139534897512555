import React, { useMemo } from "react";
import { Tr, Td, Stack, Text, Flex, Button } from "@chakra-ui/react";
import { Edit, Trash } from "react-feather";
import { formatDateTimes } from "../../utils/formatters";
import { dayOfWeekString } from "../../utils/date";
import { ICON_SIZE } from "../../theme";
import { ViewingSchedule } from "../../types";

interface ViewingScheduleRowProps {
  schedule: ViewingSchedule;
  deleteViewingSchedule: (viewingScheduleId: string, listingId: string) => void;
  onEdit: (viewingScheduleId: string) => void;
}

export const EditViewingScheduleRow: React.FC<ViewingScheduleRowProps> = ({
  schedule,
  deleteViewingSchedule,
  onEdit,
}) => {
  const { date, start_time, end_time } = useMemo(
    () => formatDateTimes(schedule.start_time, schedule.end_time),
    [schedule]
  );

  return (
    <Tr key={schedule.id}>
      <Td>{schedule.max_attendance ? "Open House" : "Private"}</Td>
      <Td>
        <Stack direction={{ base: "column", md: "row" }} align="left">
          <Text>{start_time}</Text>
          <Text>{`- ${end_time}`}</Text>
        </Stack>
      </Td>
      <Td>{date}</Td>
      <Td>
        {schedule.days_of_week && schedule.days_of_week.length > 0
          ? schedule.days_of_week.map((day) => dayOfWeekString(day)).join(", ")
          : "None"}
      </Td>
      <Td>
        <Flex>
          <Button
            variant="plain"
            size={"sm"}
            onClick={() => {
              onEdit(schedule.id);
            }}
          >
            <Edit size={ICON_SIZE} />
          </Button>
          <Button
            variant="plain"
            size={"sm"}
            onClick={() =>
              deleteViewingSchedule(schedule.id, schedule.listing_id)
            }
          >
            <Trash size={ICON_SIZE} />
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
};
