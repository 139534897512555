import { useCallback, useMemo, useRef, useState } from "react";
import { SignatureDialog } from "./SignatureDialog";
import { Button, Flex, Text } from "@chakra-ui/react";
import { Save, Send, XOctagon, CheckCircle, Repeat } from "react-feather";
import { useOffersApi } from "../../hooks/Offers";
import { ICON_SIZE } from "../../theme";
import { FormattedFile, OfferStatus, PriceCurrency } from "../../types";
import { generate32String } from "../../utils/crypto";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  selectedOfferAtom,
  offerAtomFamily,
  subjectIdsForOfferAtom,
  draftPriceAtom,
  draftOfferEndAtom,
  draftDepositInfoAtom,
  draftCostBreakdown,
  draftOfferIncludedItemsAtom,
  draftOfferExcludedItemsAtom,
  draftOfferAdjustmentsDateAtom,
  draftOfferPossessionDateAtom,
  draftOfferViewedDateAtom,
  draftOfferCompletionDateAtom,
  newOfferDrawerOpenAtom,
  offerDrawerOpenAtom,
} from "../../recoil/offers/atoms";
import { userState } from "../../recoil/atoms";
import { CounterOfferModal } from "./CounterOfferModal";
import { selectOfferIsCountered } from "../../recoil/offers/selectors";
import { useNavigate } from "react-router-dom";
import { useTriggerKycWindow } from "../../recoil/users/transactions";
import { useUserKycd } from "../../recoil/users/selectors";
import { listingFamily } from "../../recoil/listings";

export const HeadingActions = () => {
  const myUser = useRecoilValue(userState);
  const [selectedOffer, setSelectedOffer] = useRecoilState(selectedOfferAtom);
  const offer = useRecoilValue(offerAtomFamily(selectedOffer));
  const [signatureId, setSignatureId] = useState("");
  const [signatureOpen, setSignatureOpen] = useState(false);
  const [counterModalOpen, setCounterModalOpen] = useState(false);
  const listing = useRecoilValue(listingFamily(offer?.listing_id ?? ""));
  const offerPrice = useRecoilValue(draftPriceAtom);
  const offerEnd = useRecoilValue(draftOfferEndAtom);
  const adj = useRecoilValue(draftOfferAdjustmentsDateAtom);
  const possession = useRecoilValue(draftOfferPossessionDateAtom);
  const viewed = useRecoilValue(draftOfferViewedDateAtom);
  const completion = useRecoilValue(draftOfferCompletionDateAtom);
  const depositInfo = useRecoilValue(draftDepositInfoAtom);
  const costs = useRecoilValue(draftCostBreakdown);
  const includedItems = useRecoilValue(draftOfferIncludedItemsAtom);
  const excludedItems = useRecoilValue(draftOfferExcludedItemsAtom);
  const contingencies = useRecoilValue(subjectIdsForOfferAtom);
  const setOfferDrawer = useSetRecoilState(offerDrawerOpenAtom);
  const setNewOfferDrawer = useSetRecoilState(newOfferDrawerOpenAtom);
  const { updateDraft } = useOffersApi();
  const isMyOffer = offer?.user_id === myUser?.id;
  const isMyListing = listing?.seller_id === myUser?.id;
  const dialogRef = useRef();
  const isKyced = useUserKycd();
  const triggerKycWindow = useTriggerKycWindow();
  const navigate = useNavigate();
  const hasEscrow = offer && offer.deposit_info && offer.deposit_info.length;
  const isCountered = useRecoilValue(selectOfferIsCountered(selectedOffer));
  const isCounter = !!offer?.offer_id;
  const { signOffer, uploadSignature, revokeOffer, rejectOffer, acceptOffer } =
    useOffersApi();

  const closeOfferWindow = useCallback(() => {
    setSelectedOffer("");
    setNewOfferDrawer(false);
    setOfferDrawer(false);
    !isMyListing && navigate(`/message/${offer?.listing_id}`);
  }, [
    isMyListing,
    navigate,
    offer?.listing_id,
    setNewOfferDrawer,
    setOfferDrawer,
    setSelectedOffer,
  ]);

  const onOpenSignatureDialog = useCallback(() => {
    if (!hasEscrow && !isMyOffer) {
      // todo instead of opening sign or counter,
      // it should open an escrow info popup and force update deposit info
    }
    if (!isKyced) {
      triggerKycWindow();
      return;
    }
    setSignatureOpen(true);
  }, [hasEscrow, isKyced, isMyOffer, triggerKycWindow]);

  const onUpdateDraft = useCallback(async () => {
    if (!offer) return null;

    const id = await updateDraft(
      selectedOffer,
      {
        offer_price: offerPrice,
        offer_start_date: new Date(),
        offer_end_date: new Date(offerEnd),
        offer_price_currency: PriceCurrency.CAD,
        listing_id: offer.listing_id,
        offer_id: offer.offer_id,
        deposit: depositInfo.deposit.length ? depositInfo.deposit : "0",
        deposit_info: depositInfo.trust_info,
        costs,
        included_items: includedItems,
        excluded_items: excludedItems,
        offer_adjustments_date: new Date(adj),
        offer_possession_date: new Date(possession),
        offer_completion_date: new Date(completion),
        offer_viewed_date: new Date(viewed),
      },
      contingencies
    );
    return id;
  }, [
    offer,
    updateDraft,
    selectedOffer,
    offerPrice,
    offerEnd,
    depositInfo,
    costs,
    includedItems,
    excludedItems,
    adj,
    possession,
    completion,
    viewed,
    contingencies,
  ]);

  const onSignAndSendDraft = useCallback(async () => {
    const id = await onUpdateDraft();
    if (!id || !signatureId || !offer) {
      return;
    }
    await signOffer(id, signatureId);
    setSignatureOpen(false);
    closeOfferWindow();
  }, [closeOfferWindow, offer, onUpdateDraft, signOffer, signatureId]);

  const onSignAndAccept = useCallback(() => {
    acceptOffer(selectedOffer, signatureId);
    setSignatureOpen(false);
    closeOfferWindow();
  }, [acceptOffer, closeOfferWindow, selectedOffer, signatureId]);

  const { early, late } = useMemo(() => {
    if (!offer)
      return {
        early: true,
        late: true,
      };
    const now = new Date();
    const effectiveFrom = new Date(offer.offer_start_date);
    const effectiveUntil = new Date(offer.offer_end_date);
    return {
      early: effectiveFrom > now,
      late: effectiveUntil < now,
    };
  }, [offer]);

  const onSignatureSave = useCallback(
    async (fileUrl: string) => {
      if (!myUser?.token) return;
      const blob = await fetch(fileUrl).then((response) => response.blob());
      const storageName = generate32String() + ".png";
      const file = new File([blob], storageName, { type: "image/png" });
      const processed = Object.assign(file, {
        preview: fileUrl,
        storageName,
        mediaType: 0,
      }) as FormattedFile;
      const id = await uploadSignature(processed, myUser.token);
      setSignatureId(id);
    },
    [uploadSignature, myUser]
  );

  const HeadingActions = useMemo(() => {
    if (!offer) return <></>;
    const status = offer.offer_status;
    if (isMyOffer) {
      const saveButton = (
        <Button onClick={onUpdateDraft}>
          Save <Save size={ICON_SIZE} />
        </Button>
      );
      switch (status) {
        case OfferStatus.Accepted:
          return <>Accepted</>;
        case OfferStatus.Draft:
          return (
            <Flex gap={4}>
              {saveButton}
              <Button variant={"primary"} onClick={onOpenSignatureDialog}>
                Sign & Send {isCounter && "Counter-"}Offer &nbsp;
                <Send size={ICON_SIZE} />
              </Button>
            </Flex>
          );
        case OfferStatus.Pending:
          return (
            <Flex gap={4}>
              <Button
                colorScheme="red"
                onClick={() => revokeOffer(selectedOffer)}
              >
                Revoke &nbsp; <XOctagon size={ICON_SIZE} />
              </Button>
            </Flex>
          );
        case OfferStatus.Rejected:
          return <>Rejected</>;
        case OfferStatus.Revoked:
          return <>Revoked</>;
      }
    } else {
      switch (status) {
        case OfferStatus.Accepted:
          return <>Accepted</>;

        case OfferStatus.Pending:
          return early || late ? (
            <>
              {early && <Text>Offer hasn't started</Text>}
              {late && <Text>Offer has expired</Text>}
            </>
          ) : (
            <Flex gap={4} alignItems={"center"}>
              <Button
                colorScheme="red"
                onClick={() => rejectOffer(selectedOffer)}
              >
                Reject &nbsp; <XOctagon size={ICON_SIZE} />
              </Button>
              <Button
                colorScheme="whatsapp"
                onClick={onOpenSignatureDialog}
                disabled={early || late}
              >
                Accept &nbsp; <CheckCircle size={ICON_SIZE} />
              </Button>
              {!isCountered ? (
                <Button
                  variant={"primary"}
                  onClick={() => {
                    setCounterModalOpen(true);
                  }}
                >
                  Counter &nbsp; <Repeat size={ICON_SIZE} />
                </Button>
              ) : (
                <Text borderBottom={1} alignSelf={"center"}>
                  Countered
                </Text>
              )}
            </Flex>
          );
        case OfferStatus.Rejected:
          return <>Rejected</>;
        case OfferStatus.Revoked:
          return <>Revoked</>;
        default:
          return <></>;
      }
    }
  }, [
    early,
    isCounter,
    isCountered,
    isMyOffer,
    late,
    offer,
    onUpdateDraft,
    rejectOffer,
    revokeOffer,
    selectedOffer,
    onOpenSignatureDialog,
  ]);

  return (
    <>
      {HeadingActions}
      <SignatureDialog
        cancelRef={dialogRef}
        onClose={() => {
          setSignatureOpen(false);
        }}
        onSave={onSignatureSave}
        onSend={isMyOffer ? onSignAndSendDraft : onSignAndAccept}
        isOpen={signatureOpen}
      />
      {!isMyOffer && offer?.offer_status === OfferStatus.Pending && (
        <CounterOfferModal
          isOpen={counterModalOpen}
          onClose={() => setCounterModalOpen(false)}
        />
      )}
    </>
  );
};
