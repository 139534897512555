import { ListingStatus, PriceCurrency } from "../types";
import { format } from "date-fns";

export const formatPrice = (price: string | number | undefined) => {
  if (!price) return "-";
  const input = price.toString();

  const parts = input.split(".");
  const integerPart = parts[0];
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  if (parts.length > 1) {
    return formattedIntegerPart + "." + parts.slice(1).join(".");
  } else {
    return formattedIntegerPart;
  }
};

export const formatDateTimes = (start: string, end: string) => {
  const start_time = new Date(start);
  const end_time = new Date(end);

  const day = format(start_time, "EEE");
  const date = format(start_time, "MMM dd");
  const startFormattedTime = format(start_time, "hh:mm a");
  const endFormattedTime = format(end_time, "hh:mm a");

  return {
    day,
    date,
    start_time: startFormattedTime,
    end_time: endFormattedTime,
  };
};

export const currency = (c: PriceCurrency) => {
  switch (c) {
    case PriceCurrency.CAD:
      return "CAD";
    case PriceCurrency.USD:
      return "USD";
    default:
      return "$";
  }
};

export const listingStatusString = (status: ListingStatus) => {
  switch (status) {
    case ListingStatus.Active:
      return "Active";
    case ListingStatus.Archived:
      return "Archived";
    case ListingStatus.Deleted:
      return "Deleted";
    case ListingStatus.Draft:
      return "Draft";
    case ListingStatus.Sold:
      return "Sold";
    case ListingStatus.InReview:
      return "In Review";
  }
};
