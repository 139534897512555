import { atom } from "recoil";
import { User } from "../types";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const userState = atom<User | null>({
  key: "userState",
  default: null,
  effects: [persistAtom],
});

export const settingsOpenAtom = atom<boolean>({
  key: "settingsOpenAtom",
  default: false,
});

export const kycOpenAtom = atom<boolean>({
  key: "kycOpenAtom",
  default: false,
});

// its either a listing id or null
export const adminSelectedListingAtom = atom<string | null>({
  key: "adminSelectedListingAtom",
  default: null,
});

export const mapBoxKey = atom<{ token: string; expiresAt: number } | null>({
  key: "mapBoxKey",
  default: null,
});

export const loginModalOpen = atom<boolean>({
  key: "loginModalOpen",
  default: false,
});
