import { useRecoilCallback } from "recoil";
import {
  allSubjectIdsAtom,
  draftCostBreakdown,
  draftDepositInfoAtom,
  draftOfferAdjustmentsDateAtom,
  draftOfferEndAtom,
  draftOfferExcludedItemsAtom,
  draftOfferIncludedItemsAtom,
  draftOfferPossessionDateAtom,
  draftOfferViewedDateAtom,
  draftPriceAtom,
  subjectAtomFamily,
  subjectIdsForOfferAtom,
} from "./atoms";

export const useResetDraft = () =>
  useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(draftCostBreakdown);
        reset(draftDepositInfoAtom);
        reset(draftOfferAdjustmentsDateAtom);
        reset(draftOfferEndAtom);
        reset(draftOfferExcludedItemsAtom);
        reset(draftOfferIncludedItemsAtom);
        reset(draftOfferPossessionDateAtom);
        reset(draftOfferViewedDateAtom);
        reset(draftPriceAtom);
      },
    []
  );

export const useDeleteSubject = () => {
  return useRecoilCallback(
    ({ reset, set, snapshot }) =>
      (subject_id: string) => {
        const draftSubjects = snapshot
          .getLoadable(subjectIdsForOfferAtom)
          .getValue();
        const allSubjects = snapshot.getLoadable(allSubjectIdsAtom).getValue();
        set(
          subjectIdsForOfferAtom,
          draftSubjects.filter((s) => s !== subject_id)
        );
        set(
          allSubjectIdsAtom,
          allSubjects.filter((s) => s !== subject_id)
        );
        reset(subjectAtomFamily(subject_id));
      },
    []
  );
};

export const useAddSubjectToOffer = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (id: string) => {
        const offerSubjects = snapshot
          .getLoadable(subjectIdsForOfferAtom)
          .getValue();
        if (offerSubjects.includes(id)) return;
        set(subjectIdsForOfferAtom, [...offerSubjects, id]);
      },
    []
  );
};
