import { useRecoilState, useSetRecoilState } from "recoil";
import { OfferPage } from "../../pages/OfferPage";
import { DrawerWithClose } from "../common";
import {
  offerDrawerOpenAtom,
  selectedOfferAtom,
} from "../../recoil/offers/atoms";

export const OfferEditDrawer = () => {
  const [isOpen, setIsOpen] = useRecoilState(offerDrawerOpenAtom);
  const setSelectedOffer = useSetRecoilState(selectedOfferAtom);
  const handleClose = () => {
    setIsOpen(false);
    setSelectedOffer("");
  };
  return (
    <DrawerWithClose
      isOpen={isOpen}
      handleClose={() => {
        handleClose();
      }}
      full
    >
      <OfferPage />
    </DrawerWithClose>
  );
};
