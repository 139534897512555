import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  VStack,
  HStack,
  Divider,
  Checkbox,
  useColorModeValue,
  InputGroup,
  InputRightAddon,
  Flex,
  InputLeftAddon,
} from "@chakra-ui/react";
import { formatPrice } from "../../utils/formatters";
import { useRecoilValue } from "recoil";
import { perchDataAtom } from "../../recoil/perch/atoms";
import { useIsMobile } from "../../hooks/utils/useWindowDimensions";
import { listingFamily } from "../../recoil/listings";

const MortgageCalculator = ({ listingId }: { listingId: string }) => {
  const listing = useRecoilValue(listingFamily(listingId));
  const [propertyPrice, setPropertyPrice] = useState(Number(listing?.price));
  const [downPayment, setDownPayment] = useState(20);
  const [rate, setRate] = useState(0);
  const [amortization, setAmortization] = useState(25);
  const [useLowestRate, setUseLowestRate] = useState(true);
  const mortgageData = useRecoilValue(perchDataAtom);
  const isMobile = useIsMobile();
  const handlePropertyPriceChange = (price: string) => {
    setPropertyPrice(parseFloat(price));
  };

  const handleDownPaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseFloat(e.target.value);
    if (value < 1) {
      value = 1;
    } else if (value > 100) {
      value = 100;
    }
    setDownPayment(isNaN(value) ? 1 : value);
  };

  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRate(parseFloat(e.target.value));
  };

  const handleAmortizationChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setAmortization(parseInt(e.target.value));
  };

  const toggleUseLowestRate = () => {
    setUseLowestRate(!useLowestRate);
  };

  const bestRate = useMemo(() => {
    const principal = propertyPrice - (propertyPrice * downPayment ?? 0) / 100;
    const ltv = (principal / propertyPrice) * 100;

    const availableRates = mortgageData.filter((item) => {
      return (
        item.amortizationYears === amortization &&
        ltv <= item.maxLTV &&
        principal >= item.minPrincipal
      );
    });

    if (availableRates.length > 0) {
      const bestRate = availableRates.reduce((prev, curr) => {
        return prev.mortgageRate < curr.mortgageRate ? prev : curr;
      });
      return bestRate.mortgageRate;
    } else {
      return rate;
    }
  }, [amortization, downPayment, mortgageData, propertyPrice, rate]);

  useEffect(() => {
    if (useLowestRate) setRate(bestRate);
  }, [bestRate, useLowestRate]);

  const monthlyPayment = useMemo(() => {
    const selectedRate = useLowestRate ? bestRate : rate;
    setRate(selectedRate);

    const principal = propertyPrice - (propertyPrice * downPayment ?? 0) / 100;
    const monthlyRate = selectedRate / 100 / 12;
    const numberOfPayments = amortization * 12;

    const monthlyPayment =
      (principal *
        (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments))) /
      (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

    return monthlyPayment.toFixed(2);
  }, [amortization, downPayment, bestRate, propertyPrice, rate, useLowestRate]);

  const amortizationOptions = useMemo(() => {
    const options = downPayment >= 20 ? [15, 20, 25, 30] : [15, 20, 25];
    return options.map((year) => (
      <option key={year} value={year}>
        {year} Years
      </option>
    ));
  }, [downPayment]);

  const secondaryBg = useColorModeValue("gray.100", "gray.700");
  const primaryBg = useColorModeValue("white", "gray.800");

  return (
    <Box bg={primaryBg} borderRadius="md" mx="auto" p={4}>
      <VStack align="start" spacing={4}>
        <Text fontSize="xl" fontWeight="bold">
          Mortgage Payment
        </Text>
        <VStack spacing={4} align="stretch" w="full">
          <FormControl>
            <FormLabel>Property Price</FormLabel>
            <InputGroup>
              <InputLeftAddon>$</InputLeftAddon>
              <Input
                type="number"
                value={propertyPrice}
                onChange={(e) => handlePropertyPriceChange(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          <HStack gap={4} align={"start"}>
            <FormControl>
              <FormLabel>Down Payment (min. 1%) </FormLabel>
              <Flex
                direction={isMobile ? "column" : "row"}
                align={"center"}
                gap={1}
              >
                <Input
                  inputMode="decimal"
                  type="number"
                  value={downPayment}
                  onChange={handleDownPaymentChange}
                />
                <Text fontWeight={"bold"}>
                  ${formatPrice((propertyPrice * downPayment) / 100)}
                </Text>
              </Flex>
            </FormControl>
            <FormControl>
              <FormLabel>Amortization</FormLabel>
              <Select value={amortization} onChange={handleAmortizationChange}>
                {amortizationOptions}
              </Select>
            </FormControl>
          </HStack>
          <FormControl>
            <FormLabel>Rate</FormLabel>
            <Flex justify={"space-between"}>
              <InputGroup maxW={"40"}>
                <Input
                  type="number"
                  value={rate}
                  onChange={handleRateChange}
                  disabled={useLowestRate}
                />
                <InputRightAddon>%</InputRightAddon>
              </InputGroup>
              <Checkbox
                isChecked={useLowestRate}
                onChange={toggleUseLowestRate}
              >
                Use today's lowest rates
              </Checkbox>
            </Flex>
          </FormControl>
        </VStack>
        <Divider my={4} />
        <Box
          p={4}
          w="full"
          textAlign="center"
          bg={secondaryBg}
          borderRadius="md"
          boxShadow="inner"
        >
          <Text fontSize="3xl" fontWeight="bold">
            ${monthlyPayment}
          </Text>
          <Text>Monthly</Text>
        </Box>
        <Button
          as={"a"}
          variant={"primary"}
          href={"https://app.myperch.io/auth/sign-up?referral=OPENHAUS"}
          target="_blank"
          referrerPolicy="origin"
          w={"full"}
        >
          Get Qualified Now
        </Button>
      </VStack>
    </Box>
  );
};

export default MortgageCalculator;
