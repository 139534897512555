import { selectorFamily } from "recoil";
import { listingFamily } from "../listings";
import { Rental } from "../../types/rentals";
import { rentalDetailsFamily } from "./atoms";

export const selectRental = selectorFamily({
  key: "selectRental",
  get:
    (id: string) =>
    ({ get }) => {
      const listing = get(listingFamily(id));
      const details = get(rentalDetailsFamily(id));
      return { ...listing, ...details } as Rental;
    },
});
