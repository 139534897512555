import {
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { userState } from "../../recoil/atoms";
import { selectAxiosInstance } from "../../recoil/selectors";
import { useCustomToast } from "../useCustomToast";
import {
  cartAtom,
  purchasesAtom,
  purchasesAtomFamily,
} from "../../recoil/services/atoms";
import { Purchase } from "../../types/services";

export const usePurchases = () => {
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const user = useRecoilValue(userState);
  const setPurchaseList = useSetRecoilState(purchasesAtom);
  const { fail } = useCustomToast();
  const resetCart = useResetRecoilState(cartAtom);

  const getPurchases = useRecoilCallback(
    ({ set }) =>
      async () => {
        if (!user) return;
        try {
          const { data: purchases } = await axiosInstance.get<Purchase[]>(
            `/pay/purchases`
          );
          setPurchaseList(
            purchases.map((p) => {
              set(purchasesAtomFamily(p.id), p);
              return p.id;
            })
          );
          resetCart();
        } catch (error) {
          // fail({ title: (error as { message: string }).message });
        }
      },
    [axiosInstance, setPurchaseList, user]
  );

  const assignPurchase = useRecoilCallback(
    ({ snapshot, set }) =>
      async (listing_id: string, purchase_id: string) => {
        if (!purchase_id || !listing_id) return false;
        try {
          await axiosInstance.post(`/pay/assign`, {
            purchase_id,
            listing_id,
          });
          const current = snapshot
            .getLoadable(purchasesAtomFamily(purchase_id))
            .getValue();
          if (current) {
            set(purchasesAtomFamily(purchase_id), {
              ...current,
              listing_id,
            });
          }
        } catch (error) {
          console.error({ error });
          fail({ title: String(error) });
        }
      },
    [axiosInstance, fail]
  );

  const unassignPurchase = useRecoilCallback(
    ({ snapshot, set }) =>
      async (purchase_id: string) => {
        if (!purchase_id) return false;
        try {
          await axiosInstance.post(`/pay/unassign`, {
            purchase_id,
          });
          const current = snapshot
            .getLoadable(purchasesAtomFamily(purchase_id))
            .getValue();

          if (current) {
            set(purchasesAtomFamily(purchase_id), {
              ...current,
              listing_id: undefined,
            });
          }
        } catch (error) {
          console.error({ error });
          fail({ title: String(error) });
        }
      },
    [axiosInstance, fail]
  );

  return { unassignPurchase, assignPurchase, getPurchases };
};
