import { atom } from "recoil";
import { ListingSort } from "../../types";

export enum UserSort {
  createdDesc,
  createdAsc,
}

export const userSortAtom = atom<UserSort>({
  key: "userSortAtom",
  default: UserSort.createdDesc,
});

export const userFilterAtom = atom<string>({
  key: "userFilterAtom",
  default: "",
});

export const listingSortAtom = atom<UserSort>({
  key: "listingSortAtom",
  default: UserSort.createdDesc,
});

export const liveListingsOnlyAtom = atom<boolean>({
  key: "liveListingsOnlyAtom",
  default: true,
});

export const listingFilterAtom = atom<string>({
  key: "listingFilterAtom",
  default: "",
});

export const userSortString = (s: UserSort) => {
  switch (s) {
    case UserSort.createdAsc:
      return "Date Created ↑";

    case UserSort.createdDesc:
      return "Date Created ↓";
  }
};

export const listingSortString = (s: ListingSort) => {
  switch (s) {
    case ListingSort.priceDesc:
      return "Price ↑";
    case ListingSort.priceAsc:
      return "Price ↓";
    case ListingSort.totalAreaAsc:
      return "Area ↑";
    case ListingSort.totalAreaDesc:
      return "Area ↓";
  }
};

export const selectedUserSettings = atom<string | null>({
  key: "selectedUserSettings",
  default: null,
});
