import React, { createContext, useContext } from "react";
import useSocketIO from "./hooks/useSocketIO";
import { Socket } from "socket.io-client";

const SocketContext = createContext<
  { io: Socket | undefined; connected: boolean } | undefined
>(undefined);

type SocketProviderProps = {
  children: React.ReactNode;
};

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const { io, connected } = useSocketIO();

  return (
    <SocketContext.Provider value={{ io, connected }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
