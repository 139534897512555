import { Flex, FormLabel, Heading, Input, Text } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import {
  draftOfferIncludedItemsAtom,
  draftOfferExcludedItemsAtom,
} from "../../../recoil/offers/atoms";
import { GuideBox } from "../../common";
import { Tip } from "./DepositInfo";

export const Items = ({ viewOnly }: { viewOnly?: boolean }) => {
  const [draftIncluded, setDraftIncluded] = useRecoilState(
    draftOfferIncludedItemsAtom
  );
  const [draftExcluded, setDraftExcluded] = useRecoilState(
    draftOfferExcludedItemsAtom
  );

  return (
    <Flex direction={"column"} gap={"3"} my={2}>
      <Heading size="md">Inclusions and Exclusions</Heading>
      <Text fontWeight={"semibold"}>
        Specify what items are included and excluded in the sale. This can
        include appliances, fixtures, and any other items.
      </Text>
      <GuideBox title="Common Inclusions">
        <Text>
          Appliances like refrigerators, stoves, and dishwashers are often
          included. Fixtures such as lighting and window treatments might also
          be included.
        </Text>
      </GuideBox>
      <GuideBox title="Importance of Clear Agreements">
        Clearly listing inclusions and exclusions helps avoid misunderstandings
        and ensures both parties have the same expectations.
      </GuideBox>
      <FormLabel>Included Items</FormLabel>
      <Input
        disabled={viewOnly}
        background={"input-bg"}
        value={draftIncluded}
        onChange={(e) => {
          setDraftIncluded(e.target.value);
        }}
      />
      <FormLabel>Excluded Items</FormLabel>
      <Input
        background={"input-bg"}
        disabled={viewOnly}
        value={draftExcluded}
        onChange={(e) => {
          setDraftExcluded(e.target.value);
        }}
      />
      <Tip value="Be specific about what is included in the sale." />
      <Tip value="Confirm with the seller any items you want to be included." />
    </Flex>
  );
};
