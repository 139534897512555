import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { useIsMobile } from "../../hooks/utils/useWindowDimensions";
import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import {
  draftCostBreakdown,
  offerAtomFamily,
  selectedOfferAtom,
  subjectIdsForOfferAtom,
} from "../../recoil/offers/atoms";
import { userFamily } from "../../recoil/users/atoms";
import { userState } from "../../recoil/atoms";
import {
  selectOfferSubjects,
  selectSubjectsById,
} from "../../recoil/offers/selectors";
import { Box, Text as CText } from "@chakra-ui/react";
import { formatPrice } from "../../utils/formatters";
import { format } from "date-fns";
import { useOffersApi } from "../../hooks/Offers";
import { listingFamily } from "../../recoil/listings";

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 30,
    paddingVertical: 50,
  },
  section: {
    marginTop: 5,
    padding: 5,
  },
  signature: {
    height: 80,
    alignSelf: "flex-start",
    marginLeft: 30,
    borderBottom: "2px solid gray",
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Helvetica", // or 'OpenSans' if custom font is used
    paddingBottom: 20,
  },
  subtitle: {
    fontSize: 16,
    textAlign: "center",
    fontFamily: "Helvetica", // or 'OpenSans' if custom font is used
    paddingBottom: 15,
  },
  text: {
    marginHorizontal: 10,
    marginVertical: 6,
    fontSize: 12,
    lineHeight: 1.5,
    fontFamily: "Times-Roman",
  },
  bold: {
    fontSize: 12,
    lineHeight: 1.5,
    fontFamily: "Times-Bold",
  },
  customText: {
    margin: 10,
    fontSize: 14,
    fontFamily: "Helvetica",
    backgroundColor: "#f2f2f2",
    padding: 5,
    borderRadius: 5,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export const OfferPreview = () => {
  const selectedOffer = useRecoilValue(selectedOfferAtom);
  const offerContingencies = useRecoilValue(subjectIdsForOfferAtom);
  const selectedSubjects = useRecoilValue(selectOfferSubjects(selectedOffer));
  const editableSubjects = useRecoilValue(
    selectSubjectsById(offerContingencies)
  );
  const { loadOffer } = useOffersApi();
  const offer = useRecoilValue(offerAtomFamily(selectedOffer));
  const listing = useRecoilValue(listingFamily(offer?.listing_id ?? ""));
  const buyer = useRecoilValue(userFamily(offer?.buyer_id ?? ""));
  const seller = useRecoilValue(userFamily(offer?.seller_id ?? ""));
  const myUser = useRecoilValue(userState);
  const isMobile = useIsMobile();
  const isMyOffer = offer?.user_id === myUser?.id;
  const defaultCosts = useRecoilValue(draftCostBreakdown);
  const costs = offer?.costs ?? defaultCosts;
  const isSubmitted = useMemo(
    () =>
      offer &&
      myUser &&
      isMyOffer &&
      ((offer.seller_id === myUser.id && offer.seller_signature_id) ||
        (offer.buyer_id === myUser.id && offer.buyer_signature_id)),
    [isMyOffer, myUser, offer]
  );
  const editable = isMyOffer && !isSubmitted;
  const contingencies = editable ? editableSubjects : selectedSubjects;
  const buyerSignature = offer?.buyer_signature_url;
  const sellerSignature = offer?.seller_signature_url;
  useEffect(() => {
    if (
      !offer ||
      (offer.buyer_signature_id && !offer.buyer_signature_url) ||
      (offer.seller_signature_id && !offer.seller_signature_url)
    ) {
      loadOffer(selectedOffer);
    }
  }, [loadOffer, offer, selectedOffer]);

  if (!offer || !listing) return null;

  const document = (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Offer to Buy Property</Text>
        <Text style={styles.text}>
          THIS INFORMATION IS INCLUDED FOR THE ASSISTANCE OF THE PARTIES ONLY.
          IT DOES NOT FORM PART OF THE CONTRACT AND SHOULD NOT AFFECT THE PROPER
          INTERPRETATION OF ANY OF ITS TERMS.
        </Text>
        <Text style={styles.text}>
          1. CONTRACT: This document, when signed by both parties, is a legally
          binding contract. READ IT CAREFULLY. The parties should ensure that
          everything that is agreed to is in writing.{"\n\n"}
          Notwithstanding the foregoing, under section 42 of the Property Law
          Act a purchaser of “residential real property” (as defined in the Home
          Buyer Rescission Period Regulation) that is not exempt may rescind
          (cancel) the Contract of Purchase and Sale by serving written notice
          to the seller within the prescribed period after the date that the
          acceptance of the offer is signed. If the buyer exercises the right of
          rescission within the prescribed time and in the prescribed manner,
          this contract of purchase and sale will be of no further force and
          effect, except for Provisions related to payment of the deposits, if
          any.
        </Text>
        <Text style={styles.text}>
          2. Deposits(s): the Real Estate Services Act, under Section 28
          requires that money held by a brokerage and respect of a real estate
          transaction for which there is an agreement between the parties for
          the acquisition and disposition of real estate be held by The
          Brokerage as a stakeholder. The money is held for the real estate
          transaction and not on behalf of one of the parties. If a party does
          not remove one or more conditions The Brokerage requires a written
          agreement of both parties in order to release the deposit. If both
          parties do not sign the authorization to release the deposit, then the
          parties will have to apply to court for a determination of the deposit
          issue.
          {"\n\n"}
          Notwithstanding the foregoing, if the buyer exercises the rescission
          rights under section 42 of the Property Law Act and the deposit has
          been paid to the seller or the seller's brokerage or anyone else, the
          prescribed amount that the buyer is required to pay in connection with
          the exercise of their decision right will be paid to the seller from
          the deposit and the balance, if any, will be paid to the buyer without
          any further direction or agreement of the parties.
        </Text>
        <Text style={styles.text}>
          3. COMPLETION: (Section 4) Unless the parties are prepared to meet at
          the land title office and exchange title documents for the purchase
          price, it is, in every case, advisable for the completion of the sale
          to take place in the following sequence: {"\n\t\t\t"}
          &nbsp; &nbsp; &nbsp;a) The buyer pays the purchase price or down
          payment in Trust to the buyer's lawyer or notary (who should advise
          the buyer of the exact amount required ) several days before the
          completion date and the buyer signs the documents. {"\n\t\t\t"}
          &nbsp; &nbsp; &nbsp;b) The buyer's lawyer and notary prepare the
          documents and forward them for Signature to the seller's lawyer or
          notary who returns the documents to the buyer's lawyer or notary.
          {"\n\t\t\t"}&nbsp; &nbsp; &nbsp; c) The buyer's lawyer or notary then
          attends to the deposit of the signed title documents (and any
          mortgages) in the appropriate Land Title Office. {"\n\t\t\t"}&nbsp;
          &nbsp; &nbsp;d) The buyer's lawyer or notary releases the sale
          proceeds of the buyer's lawyer's or notary's office.
        </Text>
        <Text style={styles.text}>
          4. POSSESSION: (Section 5) The buyer should make arrangements directly
          with the seller to obtain possession. The seller will not generally
          let the buyer move in before the seller has received the sale
          proceeds. Where residential tenants are involved, buyers and sellers
          should consult the Residential Tenancy Act.
        </Text>
        <Text style={styles.text}>
          5. TITLE (Section 9) It is up to the buyer to satisfy the buyer on
          matters of zoning or building or use restrictions, toxic or
          environmental hazards, encroachments on or by the property and any
          encumbrances that are staying on title before becoming legally bound.
          It is up to the seller to specify the contract if there are any
          incumbrances, other than those listed in section 9, which are staying
          on title before becoming legally bound. If you as the buyer are taking
          out a mortgage make sure that title, zoning and building restrictions
          are all acceptable to your mortgage company. In certain circumstances,
          the mortgage company could refuse to advance funds. if you as a seller
          are allowing the buyer to assume your mortgage may still be
          responsible for payment of the mortgage unless arrangements are made
          with your mortgage company.
        </Text>

        <Text style={styles.text}>
          6. CUSTOMARY COSTS: (Section 15) In particular circumstances there may
          be additional costs, but the following costs are applicable in most
          circumstances:
          {"\n\t\t\t"}
          <Text style={styles.text}>
            Costs to be bourne by the seller:
            {"\n\n\t\t\t"}
            {!costs.attending_exec_docs &&
              "• Attending to execution documents\n"}
            {!costs.discharge_fees && "• Discharge fees\n"}
            {!costs.drafting_documents && "• Drafting documents\n"}
            {!costs.fire_insurance_premium && "• Fire insurance premium\n"}
            {!costs.investigating_title && "• Investigating title\n"}
            {!costs.land_title_reg_fees && "• Land Title Registration fees.\n"}
            {!costs.mortgage && "• Mortgage\n"}
            {!costs.prepayment_penalties && "• Prepayment penalties\n"}
            {!costs.property_transfer_tax && "• Property Transfer Tax\n"}
            {!costs.real_estate_commission &&
              "• Real Estate Commission (plus GST).\n"}
            {!costs.sales_tax && "• Sales tax\n"}
            {!costs.searching_title && "• Searching title\n"}
            {!costs.survey_certificate && "• Survey certificate\n"}
            {"• Goods and services tax\n"}
          </Text>
          {"\n\t\t\t"}
          <Text style={styles.text}>
            Costs to be bourne by the buyer:
            {"\n\n\t\t\t"}
            {costs.attending_exec_docs &&
              "• Attending to execution documents\n"}
            {costs.discharge_fees && "• Discharge fees\n"}
            {costs.drafting_documents && "• Drafting documents\n"}
            {costs.fire_insurance_premium && "• Fire insurance premium\n"}
            {costs.investigating_title && "• Investigating title\n"}
            {costs.land_title_reg_fees && "• Land Title Registration fees\n"}
            {costs.mortgage && "• Mortgage\n"}
            {costs.prepayment_penalties && "• Prepayment penalties\n"}
            {costs.property_transfer_tax && "• Property Transfer Tax\n"}
            {costs.real_estate_commission &&
              "• Real Estate Commission (plus GST)\n"}
            {costs.sales_tax && "• Sales tax\n"}
            {costs.searching_title && "• Searching title\n"}
            {costs.survey_certificate && "• Survey certificate\n"}
            {"• Goods and services tax\n"}
          </Text>
        </Text>

        <Text style={styles.text}>
          In addition to the above costs there may be financial adjustments
          between the seller and the buyer pursuant to section 6 and additional
          taxes payable by one or more of the parties in respect of the property
          or the transaction contemplated hereby (eg. Empty Home Tax and
          Speculation Tax).
        </Text>
        <Text style={styles.text}>
          7. CLOSING MATTERS: The closing documents referred to in Sections 11,
          11A and 11B of this contract will, in most cases, be prepared by the
          buyer's lawyer or notary and provided to the seller's lawyer or notary
          for review and approval. Once settled, the lawyers/notaries will
          arrange for execution by the parties and delivery on or prior to the
          completion date. The matters addressed in the closing documents
          referred to in sections 11A and 11 B will assist the lawyers/notaries
          as they finalize and attend to various closing matters arising in
          connection with the purchase and sale contemplated by this contract.
        </Text>
        <Text style={styles.text}>
          8. RISK: (Section 16) The buyer should arrange for insurance to be
          effective as of 12:01 am on the completion date.
        </Text>
        <Text style={styles.text}>
          9. FORM OF CONTRACT: This Contract of Purchase and Sale is designed
          primarily for the purchase and sale of freehold residences. If your
          transaction involves: a house or other building under construction, a
          lease, a business, an assignment, or other special circumstances
          (including the acquisition of land situated on a First Nations
          reserve), additional provisions, not contained in this form, may be
          needed, and professional advice should be obtained. In some instances,
          a Contract of Purchase and Sale specifically related to these
          circumstances may be available. Please check with your REALTOR® or
          legal professional for more information. A Property Disclosure
          Statement completed by the seller may be available.
        </Text>
        <Text style={styles.text}>
          10. REALTOR® Code, Article 11: A REALTOR® shall not buy or sell, or
          attempt to buy or sell an interest in property either directly or
          indirectly for himself or herself, any member of his or her immediate
          family, or any entity in which the REALTOR® has a financial interest,
          without making the REALTOR®' position known to the buyer or seller in
          writing. Among the obligations included in Section 53 of the Real
          Estate Services Rules: If a licensee acquires, directly or indirectly,
          or disposes of real estate, or if the licensee assists an associate in
          acquiring, directly or indirectly, or disposing of real estate, the
          licensee must make a disclosure in writing to the opposite party
          before entering into any agreement for the acquisition or disposition
          of the real estate.
        </Text>
        <Text style={styles.text}>
          11. RESIDENCY: When completing their residency and citizenship status,
          the buyer and the seller should confirm their residency and
          citizenship status and the tax implications thereof with their
          lawyer/accountant.
        </Text>
        <Text style={styles.text}>
          12. AGENCY DISCLOSURE: (Section 21) All designated agents with whom
          the seller or the buyer has an agency relationship should be listed.
          If additional space is required, list the additional designated agents
          on an addendum to the Contract of Purchase and Sale.
        </Text>
        <Text
          fixed
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>

      <Page size="A4" style={styles.page}>
        <Text style={styles.subtitle}>Contract of Purchase and Sale</Text>
        {/* DATE:
            BROKERAGE:
            BROKERAGE ADDRESS:
            BROKERAGE PHONE:
            PREPARED BY:
            BUYER:
            BUYER:
            BUYER:
            BUYER ADDRESS:
            BUYER POSTAL CODE:
            MLS® NO:
            SELLER:
            SELLER:
            SELLER:
            SELLER ADDRESS:
            SELLER POSTAL CODE:
            This may not be the Seller's address for the purpose of giving notice
            to exercise the Rescission Right. See the address in Section 26. */}
        <Text style={styles.subtitle}>PROPERTY</Text>
        <View
          style={{
            marginHorizontal: 10,
          }}
        >
          {listing.apt_no && (
            <Text style={styles.bold}>UNIT NO.: {listing.apt_no}</Text>
          )}
          <Text style={styles.bold}>
            CITY/TOWN/MUNICIPALITY: {listing.city}
          </Text>
          <Text style={styles.bold}>PID: -----</Text>
          <Text style={styles.bold}>
            ADDRESS OF PROPERTY: {listing.address}
          </Text>
          <Text style={styles.bold}>POSTAL CODE: {listing.zip}</Text>
          <Text style={styles.bold}>LEGAL DESCRIPTION: -----</Text>
        </View>

        <Text style={styles.text}>
          The Buyer agrees to purchase the Property from the Seller on the
          following terms and subject to the following conditions:
        </Text>
        <Text style={styles.text}>
          1. PURCHASE PRICE: The Purchase Price of the Property will be
          <Text style={styles.bold}>
            {" "}
            ${formatPrice(offer.offer_price)} dollars
          </Text>{" "}
          (Purchase Price) and, if the Property is "residential real property"
          (as defined in the Home Buyer Rescission Period Regulation) that is
          not exempt from the Rescission Right (as defined below) and the Buyer
          exercises the Rescission Right the amount payable by the Buyer to the
          Seller will be the total amount of the deposit in section DEPOSIT. The
          parties acknowledge and agree that if the Buyer exercises the
          Rescission Right, the Buyer will pay (or cause to be paid) the
          Rescission Amount to the Seller promptly and in any event within 14
          days after the Buyer exercises the Rescission Right.
        </Text>
        <Text style={styles.text}>
          2. DEPOSIT:
          <Text style={styles.bold}>
            A deposit of ${formatPrice(offer.deposit)} which will form part of
            the Purchase Price, will be paid within 24 hours of acceptance.
          </Text>
          {"\n\n"}
          All monies paid pursuant to this Section (Deposit) will be paid in
          accordance with Section 10 or by uncertified cheque except as
          otherwise set out in this Section 2 and will be delivered in trust to{" "}
          <Text style={styles.bold}>
            {offer.deposit_info?.trim().length
              ? offer.deposit_info
              : " <TO BE COMPLETED BY SELLER>"}
          </Text>{" "}
          and held in trust in accordance with the provisions of the Real Estate
          Services Act.
          {"\n\n"}
          In the event the Buyer fails to pay the Deposit as required by this
          Contract, the Seller may, at the Seller's option, terminate this
          Contract.
          {"\n\n"}
          The parties acknowledge and agree that if the Buyer exercises the
          Rescission Right ` within the prescribed period and in the prescribed
          manner and the Deposit has been paid by the Buyer, the prescribed
          amount that the Buyer is required to pay in connection with the
          exercise of the Rescission Right will be paid to the Seller from the
          Deposit and the balance of the Deposit, if any, will be paid to the
          Buyer, all without any further direction or agreement of the parties.
          If the Deposit is less than the prescribed amount required to be paid
          by the Buyer, the Buyer must promptly pay the shortfall to the Seller
          in accordance with the Home Buyer Rescission Period Regulation and
          this Contract of Purchase and Sale.
          {"\n\n"}
          <Text style={styles.bold}>
            The Seller acknowledges that the Seller has been advised to obtain
            legal advice before signing this Contract, regarding the provisions
            in this Contract for holding the Deposit. {"\n"} The Buyer
            acknowledges that the Buyer has been advised to obtain legal advice
            before signing this Contract, regarding the provisions in this
            Contract for holding the Deposit.
          </Text>
        </Text>
        <Text style={styles.text}>
          3. TERMS AND CONDITIONS: The purchase and sale of the Property
          includes the following terms and is subject to the following
          conditions:
          {"\n\n"}
          {contingencies.map(({ subject_name, subject_text }, index) => (
            <View style={styles.section} key={`${subject_text + index}`}>
              <Text style={styles.text}>
                <Text style={styles.bold}>{subject_name.toUpperCase()}</Text>:{" "}
                {subject_text}
                {"\n"}
              </Text>
            </View>
          ))}
          Each condition, if so indicated, is for the sole benefit of the party
          indicated. Unless each condition is waived or declared fulfilled by
          written notice given by the benefiting party to the other party on or
          before the date specified for each condition, this Contract will be
          terminated thereupon and the Deposit returnable in accordance with the
          Real Estate Services Act.
        </Text>
        <Text style={styles.text}>
          4. COMPLETION: The sale will be completed by{" "}
          <Text style={styles.bold}>
            {formatFancyDate(offer.offer_completion_date)}
          </Text>{" "}
          (Completion Date) at the appropriate Land Title Office. This is the
          date by which the Buyer will pay the full remaining balance on the
          property purchase price.
        </Text>
        <Text style={styles.text}>
          5. POSSESSION: The Buyer will have vacant possession of the Property
          at{" "}
          <Text style={styles.bold}>
            {formatFancyDate(offer.offer_possession_date)}
          </Text>{" "}
          (Possession Date).
        </Text>
        <Text style={styles.text}>
          6. ADJUSTMENTS: The Buyer will assume and pay all taxes, rates, local
          improvement assessments, fuel utilities and other charges from, and
          including, the date set for adjustments, and all adjustments both
          incoming and outgoing of whatsoever nature will be made as of{" "}
          <Text style={styles.bold}>
            {formatFancyDate(offer.offer_possession_date)}
          </Text>
          (Adjustments date).
        </Text>
        <Text style={styles.text}>
          7. INCLUDED ITEMS: The Purchase Price includes any buildings,
          improvements, fixtures, appurtenances and attachments thereto, and all
          blinds, awnings, screen doors and windows, curtain rods, tracks and
          valances, fixed mirrors, fixed carpeting, electric, plumbing, heating
          and air conditioning fixtures and all appurtenances and attachments
          thereto as viewed by the Buyer at the date of inspection, INCLUDING:
          {"\n\n"}
          <Text style={styles.bold}>{offer.included_items}</Text>
          {"\n\n"}
        </Text>
        <Text style={styles.text}>
          But EXCLUDING:
          {"\n\n"}
          <Text style={styles.bold}>{offer.excluded_items}</Text>
          {"\n\n"}
        </Text>
        <Text style={styles.text}>
          8. VIEWED: The Property and all included items will be in
          substantially the same condition at the Possession Date as when viewed
          by the Buyer on{" "}
          <Text style={styles.bold}>
            {formatFancyDate(offer.offer_viewed_date)}
          </Text>
          .
        </Text>
        <Text style={styles.text}>
          9. TITLE: Free and clear of all encumbrances except subsisting
          conditions, provisions, restrictions exceptions and reservations,
          including royalties, contained in the original grant or contained in
          any other grant or disposition from the Crown, registered or pending
          restrictive covenants and rights-of-way in favour of utilities and
          public authorities, existing tenancies set out in Section 5, if any,
          and except as otherwise set out herein.
        </Text>
        <Text style={styles.text}>
          10. TENDER: Tender or payment of monies by the Buyer to the Seller
          will be by certified cheque, bank draft, wire transfer or
          Lawyer's/Notary's or real estate brokerage's trust cheque.
        </Text>
        <Text style={styles.text}>
          11. DOCUMENTS: All documents required to give effect to this Contract
          will be delivered in registrable form where necessary and will be
          lodged for registration in the appropriate Land Title Office by 4pm on
          the Completion Date.
        </Text>
        <Text style={styles.text}>
          11A. SELLER'S PARTICULARS AND RESIDENCY: The Seller shall deliver to
          the Buyer on or before the Completion Date a statutory declaration of
          the Seller containing: (1) particulars regarding the Seller that are
          required to be included in the Buyer's Property Transfer Tax Return to
          be filed in connection with the completion of the transaction
          contemplated by this Contract (and the Seller hereby consents to the
          Buyer inserting such particulars on such return); (2) a declaration
          regarding the Vancouver Vacancy By-Law for residential properties
          located in the City of Vancouver; and (3) if the Seller is not a
          non-resident of Canada as described in the non-residency provisions of
          the Income Tax Act, confirmation that the Seller is not then, and on
          the Completion Date will not be, a non-resident of Canada. If on the
          Completion Date the Seller is a non-resident of Canada as described in
          the residency provisions of the Income Tax Act, the Buyer shall be
          entitled to hold back from the Purchase Price the amount provided for
          under Section 116 of the Income Tax Act.
        </Text>
        <Text style={styles.text}>
          11B. GST CERTIFICATE: If the transaction contemplated by this Contract
          is exempt from the payment of Goods and Services Tax ("GST"), the
          Seller shall execute and deliver to the Buyer on or before the
          Completion Date, an appropriate GST exemption certificate to relieve
          the parties of their obligations to pay, collect and remit GST in
          respect of the transaction. If the transaction contemplated by this
          Contract is not exempt from the payment of GST, the Seller and the
          Buyer shall execute and deliver to the other party on or before the
          Completion Date an appropriate GST certificate in respect of the
          transaction.
        </Text>
        <Text style={styles.text}>
          12. TIME: Time will be of the essence hereof, and unless the balance
          of the payment is paid and such formal agreements to pay the balance
          as may be necessary is entered into on or before the Completion Date,
          the Seller may, at the Seller's option, terminate this Contract, and,
          in such event, the amount paid by the Buyer will be non-refundable and
          absolutely forfeited to the Seller, subject to the provisions under
          the Real Estate Services Act, on account of damages, without prejudice
          to the Seller's other remedies.
        </Text>
        <Text style={styles.text}>
          13. BUYER FINANCING: If the Buyer is relying upon a new mortgage to
          finance the Purchase Price, the Buyer, while still required to pay the
          Purchase Price on the Completion Date, may wait to pay the Purchase
          Price to the Seller until after the transfer and new mortgage
          documents have been lodged for registration in the appropriate Land
          Title Office, but only if, before such lodging, the Buyer has: (a)
          made available for tender to the Seller that portion of the Purchase
          Price not secured by the new mortgage, and (b) fulfilled all the new
          mortgagee's conditions for funding except lodging the mortgage for
          registration, and (c) made available to the Seller, a Lawyer's or
          Notary's undertaking to pay the Purchase Price upon the lodging of the
          transfer and new mortgage documents and the advance by the mortgagee
          of the mortgage proceeds pursuant to the Canadian Bar Association (BC
          Branch) (Real Property Section) standard undertakings (the "BA
          Standard Undertakings").
        </Text>
        <Text style={styles.text}>
          14. CLEARING TITLE: If the Seller has existing financial charges to be
          cleared from title, the Seller, while still required to clear such
          charges, may wait to pay and discharge existing financial charges
          until immediately after receipt of the Purchase Price, but in this
          event, the Seller agrees that payment of the Purchase Price shall be
          made by the Buyer's Lawyer or Notary to the Seller's Lawyer or Notary,
          on the CBA Standard Undertakings to pay out and discharge the
          financial charges, and remit the balance, if any, to the Seller.
        </Text>
        <Text style={styles.text}>
          15. COSTS: The Buyer will bear all costs of the conveyance and, if
          applicable, any costs related to arranging a mortgage and the Seller
          will bear all costs of clearing title.
        </Text>
        <Text style={styles.text}>
          16. RISK: All buildings on the Property and all other items included
          in the purchase and sale will be, and remain, at the risk of the
          Seller until 12:01am on the Completion Date. After that time, the
          Property and all included items will be at the risk of the Buyer.
        </Text>
        <Text style={styles.text}>
          17. PLURAL: In this Contract, any reference to a party includes that
          party's heirs, executors, administrators, successors and assigns;
          singular includes plural and masculine includes feminine.
        </Text>
        <Text style={styles.text}>
          18. REPRESENTATIONS AND WARRANTIES: There are no representations,
          warranties, guarantees, promises or agreements other than those set
          out in this Contract and the representations contained in the Property
          Disclosure Statement if incorporated into and forming part of this
          Contract, all of which will survive the completion of the sale.
        </Text>
        <Text style={styles.text}>
          19. PERSONAL INFORMATION: The Buyer and the Seller hereby consent to
          the collection, use and disclosure by the Brokerages and by the
          Managing Broker(s), Associate Broker(s) and representative(s) of those
          Brokerages (collectively the "Designated Agent(s)") described in
          Section 21, the real estate boards of which those Brokerages and
          Licensees are members and, if the Property is listed on a Multiple
          Listing Service®, the real estate board that operates the Multiple
          Listing Service®, of personal information about the Buyer and the
          Seller:
          {"\n\t\t\t"}
          &nbsp; &nbsp; &nbsp;a) for all purposes consistent with the
          transaction contemplated herein:
          {"\n\t\t\t"}
          &nbsp; &nbsp; &nbsp;b) if the Property is listed on a Multiple Listing
          Service®, for the purpose of the compilation, retention and
          publication by the real estate board that operates the Multiple
          Listing Service® and other real estate boards of any statistics
          including historical Multiple Listing Service® data for use by persons
          authorized to use the Multiple Listing Service® of that real estate
          board and other real estate boards;
          {"\n\t\t\t"}
          &nbsp; &nbsp; &nbsp;c) for enforcing codes of professional conduct and
          ethics for members of real estate boards; and
          {"\n\t\t\t"}
          &nbsp; &nbsp; &nbsp;d) for the purposes (and to the recipients)
          described in the British Columbia Real Estate Association's Privacy
          Notice and Consent form.
          {"\n\n"}The personal information provided by the Buyer and Seller may
          be stored on databases outside Canada, in which case it would be
          subject to the laws of the jurisdiction in which it is located.
        </Text>
        <Text style={styles.text}>
          20. ASSIGNMENT OF REMUNERATION: The Buyer and the Seller agree that
          the Seller's authorization and instruction set out in Section 26(c)
          below is a confirmation of the equitable assignment by the Seller in
          the listing contract and is notice of the equitable assignment to
          anyone acting on behalf of the Buyer or Seller.
        </Text>
        <Text style={styles.text}>
          20A. RESTRICTION ON ASSIGNMENT OF CONTRACT: The Buyer and the Seller
          agree that this Contract: (a) must not be assigned without the written
          consent of the Seller; and (b) the Seller is entitled to any profit
          resulting from an assignment of the Contract by the Buyer or any
          subsequent assignee.
        </Text>
        <Text style={styles.text}>
          21. AGENCY DISCLOSURE: The Seller and the Buyer acknowledge and
          confirm as follows (initial appropriate box(es) and complete details
          as applicable):
        </Text>
        {/* NEEDS LEGAL COUNCIL */}
        <Text style={styles.text}>
          22. OFFER: This offer, or counter-offer, will be open for acceptance
          until{" "}
          <Text style={styles.bold}>
            {formatFancyDate(offer.offer_end_date)}
          </Text>
          . (unless withdrawn in writing with notification to the other party of
          such revocation prior to notification of its acceptance), and upon
          acceptance of the offer, or counter-offer, by accepting in writing and
          notifying the other party of such acceptance, there will be a binding
          Contract of Purchase and Sale on the terms and conditions set forth.
          {"\n\n"}
          <Text style={styles.bold}>
            If the Buyer is an individual, the Buyer declares that they are a
            Canadian citizen or a permanent resident as defined in the
            Immigration and Refugee Protection Act.
          </Text>
        </Text>
        {buyerSignature && (
          <View style={{ margin: 10 }}>
            <Text style={styles.bold}>BUYER FULL NAME:</Text>
            <Text style={styles.bold}>
              {buyer?.first_name} {buyer?.last_name}
            </Text>
            <Text style={styles.bold}>BUYER SIGNATURE</Text>
            <Image
              source={{
                uri: buyerSignature,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
              style={styles.signature}
            />
            <Text style={styles.bold}>
              Signed: {formatFancyDate(offer.date_buyer_signed)}
            </Text>
          </View>
        )}
        <Text style={styles.text}>
          23. ACCEPTANCE: The Seller (a) hereby accepts the above offer and
          agrees to complete the sale upon the terms and conditions set out
          above, (b) agrees to pay a commission as per the Listing Contract, and
          (c) authorizes and instructs the Buyer and anyone acting on behalf of
          the Buyer or Seller to pay the commission out of the proceeds of sale
          and forward copies of the Seller's Statement of Adjustments to the
          Cooperating/Listing Brokerage, as requested forthwith after
          Completion. Seller's acceptance is dated{" "}
          <Text style={styles.bold}>
            {formatFancyDate(offer.date_seller_signed)}
          </Text>
          {"\n\n"}
          The Seller declares their residency: RESIDENT OF CANADA
        </Text>
        {sellerSignature && (
          <View style={{ margin: 10 }}>
            <Text style={styles.bold}>SELLER FULL NAME:</Text>
            <Text style={styles.bold}>
              {seller?.first_name} {seller?.last_name}
            </Text>
            <Text style={styles.bold}>SELLER SIGNATURE</Text>
            <Image
              source={{
                uri: sellerSignature,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
              style={styles.signature}
            />
            <Text style={styles.bold}>
              Signed
              {formatFancyDate(offer.date_seller_signed)}
            </Text>
          </View>
        )}
        <Text style={styles.text}>
          {"\n\n"}
          NOTICE FOR BUYER'S RESCISSION RIGHT: If the Buyer is entitled to
          exercise the Rescission Right, the Seller's email address for notice
          of rescission is as follows:
          {"\n\n"}
          EMAIL: <Text style={styles.bold}>{seller?.email}</Text>
          {"\n\n"}
          Any notice of rescission given by the Buyer will be deemed to have
          been delivered on the day it was sent if delivered in accordance with
          the Home Buyer Rescission Period Regulation.
          {"\n\n"}
          The date of acceptance of this contract is ___________(the "Final
          Acceptance Date") and, if applicable, the date by which the Buyer must
          exercise the Rescission Right, is ________.
        </Text>
        <Text
          fixed
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
  if (isMobile)
    return (
      <Box
        rounded={"xl"}
        bg={"blue"}
        w={"full"}
        justifyContent={"center"}
        p={3}
        my={3}
      >
        <PDFDownloadLink document={document} fileName={`offer_${offer.id}.pdf`}>
          <CText color="white" textAlign={"center"}>
            Download PDF
          </CText>
        </PDFDownloadLink>
      </Box>
    );
  return (
    <PDFViewer
      width={"100%"}
      height={600}
      style={{
        borderRadius: 10,
        margin: 2,
      }}
    >
      {document}
    </PDFViewer>
  );
};

export const formatFancyDate = (d: Date | string | undefined | null) => {
  if (!d) return "";
  const date = new Date(d);

  // Extract the necessary components
  const hours = format(date, "h");
  const minutes = format(date, "mm");
  const ampm = format(date, "a");
  const day = format(date, "do");
  const month = format(date, "MMMM");
  const year = format(date, "yyyy");

  // Construct the formatted string
  const timeString = `${hours}${minutes === "00" ? "" : `:${minutes}`} ${ampm}`;
  const dateString = `on ${month} ${day}, ${year}`;

  return `${timeString} ${dateString}`;
};
