import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
  useColorModeValue,
  Checkbox,
} from "@chakra-ui/react";
import { useState, useCallback } from "react";
import { Send } from "react-feather";
import { ICON_SIZE } from "../../theme";
import { SignaturePad } from "../common";

// TODO here we need to have buyer/seller flows
// both buyers and sellers have to list their address and postal code (and kyc here?)

export const SignatureDialog = ({
  onClose,
  cancelRef,
  isOpen,
  onSave,
  onSend,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSave: (url: string) => void;
  onSend: () => void;
  cancelRef: React.RefObject<any>;
}) => {
  const [signatureSaved, setSignatureSaved] = useState(false);
  const [residencyChecked, setResidencyChecked] = useState(false);
  const bg = useColorModeValue("lightBg", "darkBg");
  const save = useCallback(
    (uri: string) => {
      onSave(uri);
      setTimeout(() => {
        setSignatureSaved(true);
      }, 1000);
    },
    [onSave]
  );
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent bg={bg}>
        <AlertDialogHeader>Sign Offer</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <SignaturePad onFileReady={save} />
          <Checkbox
            required
            size="lg"
            isChecked={residencyChecked}
            onChange={(e) => setResidencyChecked(e.target.checked)}
          >
            By checking this box, I hereby confirm that im a legal resident or
            citizen of Canada
          </Checkbox>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          {signatureSaved ? (
            <>
              {residencyChecked ? (
                <Button ml={3} variant={"primary"} onClick={onSend}>
                  Send &nbsp; <Send size={ICON_SIZE} />
                </Button>
              ) : (
                <Text color="red.600">Confirm Residency</Text>
              )}
            </>
          ) : (
            <Text color={"red.600"}>Save Your Signature</Text>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
