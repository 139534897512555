import { useToast } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";

export const useCustomToast = () => {
  const toast = useToast();
  const success = useCallback(
    ({
      title,
      description,
      duration = 800,
    }: {
      title: string;
      description?: string;
      duration?: number;
    }) => {
      toast({
        title,
        description,
        status: "success",
        duration,
        variant: "solid",
        isClosable: true,
        containerStyle: {
          color: "white",
        },
        colorScheme: "teal",
      });
    },
    [toast]
  );
  const fail = useCallback(
    ({
      title,
      description,
      duration = 9000,
    }: {
      title: string;
      description?: string;
      duration?: number;
    }) => {
      toast({
        title,
        description,
        status: "error",
        duration,
        variant: "solid",
        isClosable: true,
      });
    },
    [toast]
  );
  return useMemo(
    () => ({
      success,
      fail,
      toast,
    }),
    [fail, success, toast]
  );
};
