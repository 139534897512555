import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLogOut } from "../../recoil/transactions";

export const useForceLogOut = () => {
  const logout = useLogOut();
  const navigate = useNavigate();
  return useCallback(() => {
    logout();
    navigate("/login");
  }, [logout, navigate]);
};
