import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  NumberInput,
  NumberInputField,
  Stack,
  Input,
  CheckboxGroup,
  Checkbox,
  Button,
  Flex,
  Select,
  Switch,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { userState } from "../../recoil/atoms";
import useViewingsApi from "../../hooks/useViewingsApi";
import { useLoadListings } from "../../hooks/Listings";
import { ListingOption } from "../../pages/Inbox";
import {
  listingScheduleAtomFamily,
  viewingScheduleInputAtom,
} from "../../recoil/viewings/atoms";
import { addMinutes, differenceInMinutes, format } from "date-fns";
import { useCustomToast } from "../../hooks/useCustomToast";
import { ViewingScheduleForm } from "../../types";

interface ScheduleFormProps {
  viewingScheduleId?: string;
  listingId?: string;
  onClose: () => void;
}

enum EventType {
  OPENHOUSE = "openhouse",
  PRIVATE = "private",
}

export type SplitOption = "0" | "10" | "15" | "20";

export const ScheduleForm: React.FC<ScheduleFormProps> = ({
  viewingScheduleId,
  listingId,
  onClose,
}) => {
  const [formState, setFormState] = useRecoilState(viewingScheduleInputAtom);
  const resetFormData = useResetRecoilState(viewingScheduleInputAtom);
  const { fail, success } = useCustomToast();
  const user = useRecoilValue(userState);
  const { loadUserListings } = useLoadListings();
  const { createViewingSchedule, updateViewingSchedule } = useViewingsApi();

  const selectedListingSchedule = useRecoilValue(
    listingScheduleAtomFamily(listingId ?? "")
  );
  const selectedViewingSchedule = selectedListingSchedule.availabilities.find(
    (s) => s.id === viewingScheduleId
  );

  const [eventType, setEventType] = useState(EventType.PRIVATE);
  const [showRepeat, setShowRepeat] = useState(false);
  const [splitValue, setSplitValue] = useState<SplitOption>("0");

  const [isMaxAttendanceEnabled, setIsMaxAttendanceEnabled] = useState(false);
  useEffect(() => {
    if (user?.token) {
      loadUserListings();
    }
  }, [loadUserListings, user?.token]);

  useEffect(() => {
    if (selectedViewingSchedule) {
      const startDate = new Date(selectedViewingSchedule.start_time);
      const endDate = new Date(selectedViewingSchedule.end_time);

      const repeat =
        selectedViewingSchedule.days_of_week &&
        selectedViewingSchedule.days_of_week?.length > 0
          ? true
          : false;

      setFormState({
        date: format(startDate, "yyyy-MM-dd"), // Formats the date as 'YYYY-MM-DD'
        start_time: format(startDate, "HH:mm"), // Formats the start time as 'HH:MM'
        end_time: format(endDate, "HH:mm"), // Formats the end time as 'HH:MM'
        days_of_week: selectedViewingSchedule.days_of_week,
        max_attendance: selectedViewingSchedule.max_attendance?.toString(),
      });

      // Set the event type and max attendance switch based on the existing schedule
      setEventType(
        selectedViewingSchedule.max_attendance
          ? EventType.OPENHOUSE
          : EventType.PRIVATE
      );
      setIsMaxAttendanceEnabled(!!selectedViewingSchedule.max_attendance);
      setShowRepeat(repeat); // Set the repeat checkbox based on the existing schedule
    }
  }, [selectedViewingSchedule, setFormState]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox" && name === "days_of_week") {
      // Ensure days_of_week is not undefined before proceeding
      const daysOfWeek = formState.days_of_week || [];
      const newValue = parseInt(value, 10);

      const newDaysOfWeek = checked
        ? [...daysOfWeek, newValue]
        : daysOfWeek.filter((day) => day !== newValue);

      setFormState({
        ...formState,
        days_of_week: newDaysOfWeek,
      });
    } else {
      // Handle other input types
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  const handleSubmit = useCallback(async () => {
    if (!listingId) {
      return;
    }

    const updatedFormState = {
      ...formState,
      max_attendance:
        eventType === EventType.OPENHOUSE && !isMaxAttendanceEnabled
          ? "-1"
          : formState.max_attendance,
      listing_id: listingId,
    };
    try {
      if (viewingScheduleId) {
        await updateViewingSchedule(updatedFormState, viewingScheduleId);
      } else {
        if (splitValue !== "0") {
          const startTime = new Date(
            `${formState.date}T${formState.start_time}`
          );
          const endTime = new Date(`${formState.date}T${formState.end_time}`);

          const splitDuration = parseInt(splitValue, 10);
          const totalDuration = differenceInMinutes(endTime, startTime);

          const numberOfSplits = Math.floor(totalDuration / splitDuration);

          const splitSchedules: ViewingScheduleForm[] = Array.from({
            length: numberOfSplits,
          }).map((_, index) => {
            const periodStart = addMinutes(startTime, splitDuration * index);
            const periodEnd = addMinutes(periodStart, splitDuration);

            return {
              ...updatedFormState,
              start_time: format(periodStart, "HH:mm"),
              end_time: format(periodEnd, "HH:mm"),
            };
          });
          await Promise.all(
            splitSchedules.map((s) => createViewingSchedule(s))
          );
        } else {
          await createViewingSchedule(updatedFormState);
        }
        resetFormData();
      }
      success({ title: "Successfully added available time(s)" });
      onClose();
    } catch (error) {
      fail({ title: "error", description: String(error) });
    }
  }, [
    createViewingSchedule,
    eventType,
    fail,
    formState,
    isMaxAttendanceEnabled,
    listingId,
    onClose,
    resetFormData,
    splitValue,
    success,
    updateViewingSchedule,
    viewingScheduleId,
  ]);

  const handleDaysOfWeekChange = (newDays: (string | number)[]) => {
    setFormState({
      ...formState,
      days_of_week: newDays.map(Number),
    });
  };

  const handleMaxAttendanceChange = (value: string) => {
    setFormState({
      ...formState,
      max_attendance: isMaxAttendanceEnabled ? value : "-1",
    });
  };

  const handleEventTypeChange = (value: string) => {
    setEventType(value as EventType);
    if (value === EventType.OPENHOUSE && !isMaxAttendanceEnabled) {
      setFormState({ ...formState, max_attendance: "-1" });
    }
  };

  return (
    <Box>
      <FormControl>
        <Box marginBottom={4}>
          <FormLabel>Selected Listing</FormLabel>
          <Select value={listingId ?? ""} isReadOnly>
            <ListingOption key={listingId} listingId={listingId ?? ""} />
          </Select>
        </Box>
        <Box marginBottom={4}>
          <FormLabel>Event Type</FormLabel>
          <RadioGroup onChange={handleEventTypeChange} value={eventType}>
            <Stack direction="row">
              <Radio value={EventType.OPENHOUSE}>Open House</Radio>
              <Radio value={EventType.PRIVATE}>Private Viewing</Radio>
            </Stack>
          </RadioGroup>
        </Box>
        {eventType === EventType.OPENHOUSE && (
          <Box mb={4}>
            <FormControl mb={2} display="flex" alignItems="center">
              <FormLabel mb="0">Max Attendance</FormLabel>
              <Switch
                isChecked={isMaxAttendanceEnabled}
                onChange={(e) => setIsMaxAttendanceEnabled(e.target.checked)}
              />
            </FormControl>
            {isMaxAttendanceEnabled && (
              <FormControl mb={4}>
                <NumberInput
                  min={1}
                  onChange={(value) => handleMaxAttendanceChange(value)}
                >
                  <NumberInputField
                    name="max_attendance"
                    placeholder="Enter number"
                    value={formState.max_attendance}
                  />
                </NumberInput>
              </FormControl>
            )}
          </Box>
        )}
        <FormControl mb={4}>
          <FormLabel>Date</FormLabel>
          <Input
            type="date"
            name="date"
            min={format(new Date(), "yyyy-MM-dd")}
            value={formState.date}
            onChange={handleInputChange}
          />
        </FormControl>
        <Flex justifyContent={"space-between"}>
          <FormControl mb={4}>
            <FormLabel>Start Time</FormLabel>
            <Input
              type="time"
              name="start_time"
              value={formState.start_time}
              onChange={handleInputChange}
              onBlur={handleInputChange} // Add this to handle changes on blur
              onInput={handleInputChange}
              w={"92%"}
              padding={3}
              step={300}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>End Time</FormLabel>
            <Input
              type="time"
              name="end_time"
              value={formState.end_time}
              onChange={handleInputChange}
              onBlur={handleInputChange} // Add this to handle changes on blur
              onInput={handleInputChange}
              w={"92%"}
              padding={3}
            />
          </FormControl>
        </Flex>
        {eventType === EventType.PRIVATE && !viewingScheduleId && (
          <HStack alignItems={"center"} justifyContent={"space-between"}>
            <Checkbox
              name="split"
              checked={splitValue === "0"}
              onChange={(e) => setSplitValue(e.target.checked ? "15" : "0")}
            >
              Split
            </Checkbox>
            {splitValue !== "0" ? (
              <RadioGroup
                flexDirection={"row"}
                size="md"
                value={splitValue}
                onChange={(nextValue) =>
                  setSplitValue(nextValue as SplitOption)
                }
              >
                <HStack spacing={3}>
                  <Radio value="15">15 min</Radio>
                  <Radio value="20">20 min</Radio>
                  <Radio value="30">30 min</Radio>
                </HStack>
              </RadioGroup>
            ) : null}
          </HStack>
        )}
        <Checkbox
          mb={2}
          name="repeat"
          checked={showRepeat}
          onChange={(e) => setShowRepeat(e.target.checked)}
        >
          Repeat
        </Checkbox>
        {showRepeat && (
          <CheckboxGroup
            value={formState.days_of_week?.map(String) || []}
            onChange={handleDaysOfWeekChange}
          >
            <Stack pl={6} mb={4} spacing={1}>
              {[
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ].map((day, index) => (
                <Checkbox key={day} value={((index + 1) % 7).toString()}>
                  {day}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        )}

        {showRepeat && (
          <Text color={"red.400"}>
            Be aware of daylight saving time changes: clocks "spring forward" an
            hour in spring and "fall back" in autumn. This can affect recurring
            schedules, shifting times forward or back.
          </Text>
        )}
        <Box>
          <Button mt={4} variant={"primary"} onClick={handleSubmit}>
            {viewingScheduleId ? "Update" : "Create"}
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
};
