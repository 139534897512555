import {
  Box,
  Button,
  Text,
  Divider,
  Flex,
  Heading,
  Tag,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Check } from "react-feather";
import { ICON_SIZE } from "../../theme";

export const DeveloperPackages = () => {
  return (
    <Flex
      wrap="wrap"
      justifyContent="center"
      alignItems="center"
      gap={10}
      mt={10}
    >
      <DevPricing
        name="Monthly subscribtion"
        price="99.00/mo"
        isHighlighted
        points={[
          "Unlimited support from the Openhaus team via email",
          "List up to 5 properties",
          "Receive direct messages from interested buyers and investors",
          "Showcase your project in front of our Canada-wide network of buyers",
          "Buyers can submit offers directly through the platform",
          "No commitment",
        ]}
        href="https://buy.stripe.com/8wM3dXahth2m6He6oo"
      />
      <DevPricing
        name="1 Year (OTP)"
        price="549.00"
        isHighlighted
        isDiscounted
        points={[
          "Unlimited support from the Openhaus team via email",
          "List up to 5 properties",
          "Get featured above regular listings",
          "Receive direct messages from interested buyers and investors",
          "Showcase your project in front of our Canada-wide network of buyers",
          "Buyers can submit offers directly through the platform",
          "Save by paying in advance",
        ]}
        href="https://buy.stripe.com/4gw29T9dp4fA6He3cd"
      />
    </Flex>
  );
};

export const DevPricing = ({
  name,
  description,
  price,
  discountedPrice,
  points,

  isDiscounted,
  isHighlighted,
  href,
}: {
  name: string;
  href: string;
  description?: string;
  price: string;
  discountedPrice?: string;
  points: string[];

  isDiscounted?: boolean;
  isHighlighted?: boolean;
}) => {
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const boxShadow = isHighlighted
    ? "0 4px 20px 0 rgba(0, 0, 0, 0.15)"
    : "0 2px 8px 0 rgba(0, 0, 0, 0.1)";
  const transform = isHighlighted ? "scale(1.05)" : "none";
  const zIndex = isHighlighted ? 1 : 0;

  return (
    <VStack
      padding={5}
      spacing={4}
      bg={"expandable-bg"}
      rounded={"xl"}
      shadow={boxShadow}
      dropShadow={boxShadow}
      borderWidth="1px"
      borderColor={borderColor}
      alignItems="stretch"
      width="100%"
      maxWidth="300px"
      textAlign={"center"}
      transform={transform}
      zIndex={zIndex}
    >
      {isDiscounted && (
        <Tag
          size="md"
          variant="solid"
          background={"ohBlue.100"}
          color={"white"}
          width={"fit-content"}
          alignSelf={"center"}
          position={"absolute"}
          top={"-12px"}
        >
          SAVINGS
        </Tag>
      )}
      <Heading size="md" fontWeight="semibold">
        {name}
      </Heading>
      {description && (
        <Text fontSize="lg" fontWeight={"semibold"}>
          {description}
        </Text>
      )}
      <Text fontSize="lg" fontWeight="bold">
        {discountedPrice ? (
          <>
            <Text as="s" fontSize="lg">
              ${price}
            </Text>{" "}
            ${discountedPrice}
          </>
        ) : (
          `$${price}`
        )}
      </Text>
      <Divider />
      <Box pl={4}>
        {points.map((p) => (
          <Flex alignItems={"center"} justify={"space-evenly"} gap={2} key={p}>
            <Box w={8}>
              <Check size={ICON_SIZE} color="green" />
            </Box>
            <Text key={p} w={"100%"} fontSize="sm" textAlign={"left"} mb={1}>
              {p}
            </Text>
          </Flex>
        ))}
      </Box>
      <Button
        as="a"
        variant="primary"
        href={href}
        target="_blank"
        referrerPolicy="origin"
      >
        Continue
      </Button>
    </VStack>
  );
};
