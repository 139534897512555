import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

type DeleteViewingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isViewingRequest: boolean;
};

export const DeleteViewingModal: React.FC<DeleteViewingModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isViewingRequest,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={"container-bg"}>
        <ModalHeader>Delete Showing</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isViewingRequest
            ? "Deleting this private showing will notify the buyer and only cancel this individual showing, but not delete this availability from your listing's schedule."
            : "Deleting this open house showing will notify all buyers that have RSVP'd, and remove this availability from your listing's schedule."}
        </ModalBody>
        <ModalFooter>
          <Button variant="tertiary" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={onConfirm}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
