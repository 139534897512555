import { atom, atomFamily } from "recoil";
import {
  ACType,
  FireplaceType,
  FormattedFile,
  HeatingType,
  Listing,
  ListingForm,
  ListingSort,
  ListingStatus,
  PriceCurrency,
  PropertyType,
  StoveType,
  Unit,
  UploadStatus,
  ViewFacing,
} from "../../types";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export type FileBatchType = "listing-assets" | "kyc-id" | "kyc-proof";

export const filesToUploadAtomFamily = atomFamily<
  FormattedFile[],
  FileBatchType
>({
  key: "filesToUploadAtomFamily",
  default: [],
});

export const listingFamily = atomFamily<Listing | undefined, string>({
  key: "listingFamily",
  default: undefined,
  effects: [persistAtom],
});

export const listingIdsAtom = atom<string[]>({
  key: "listingIdsAtom",
  default: [],
  effects: [persistAtom],
});

export const reviewListingIdsAtom = atom<string[]>({
  key: "reviewListingIdsAtom",
  default: [],
  effects: [persistAtom],
});

export const activeUserListingIdsAtom = atom<string[]>({
  key: "activeUserListingIdsAtom",
  default: [],
  effects: [persistAtom],
});

export const userListingsAtom = atom<string[]>({
  key: "userListingsAtom",
  default: [],
  effects: [persistAtom],
});

export const selectedListingAtom = atom<string | null>({
  key: "selectedListingAtom",
  default: null,
  effects: [persistAtom],
});

export const selectedListingScheduleAtom = atom<string | null>({
  key: "selectedListingScheduleAtom",
  default: null,
});

export const listingInputAtom = atom<ListingForm>({
  key: "listingInputAtom",
  default: {
    listing_status: ListingStatus.Draft,
    property_type: PropertyType.Unknown,
    title: "",
    description: "",
    price_currency: PriceCurrency.CAD,
    price: "",
    property_tax: "",
    hoa_fee: "",
    bathrooms_full: 0,
    bedrooms_total: 0,
    bathrooms_half: 0,
    parking_stalls_uncovered: 0,
    parking_stalls_covered: 0,
    address: "",
    city: "",
    state_province: "",
    country: "Canada",
    zip: "",
    apt_no: "",
    latitude: 0,
    longitude: 0,
    building_floor: undefined,
    building_total_floors: undefined,
    ac_type: ACType.Unknown,
    heating_type: HeatingType.Unknown,
    stove_type: StoveType.Unknown,
    fireplace_type: FireplaceType.Unknown,
    has_natural_gas: false,
    total_area: "",
    floor_area: "",
    balcony_area: "",
    lot_size: "",
    lot_size_unit: Unit.Unknown,
    area_unit: Unit.SquareFoot,
    view_facing: ViewFacing.Unknown,
    view_description: "",
    year_built: undefined,
    year_renovated: undefined,
    is_rental: false,
    amenities: { custom: [], standard: [] },
    buyer_agent_fee: "",
  },
  // effects_UNSTABLE: [persistAtom],
});

// 0 is in progress , 1 is photos uploaded, 2 is form uploaded
export const listingUploadStatus = atom<UploadStatus>({
  key: "listingUploadStatus",
  default: 0,
});

export const listingSearchFilterAtom = atom<string>({
  key: "listingSearchFilterAtom",
  default: "",
});

export const newListingId = atom<string>({
  key: "newListingId",
  default: "",
});

export const productsWidgetState = atom<ProductsWidgetState>({
  key: "productsWidgetState",
  default: 0,
});

export const listingForPaymentAtom = atom<string | undefined>({
  key: "listingForPaymentAtom",
  default: undefined,
  effects: [persistAtom],
});

export enum ProductsWidgetState {
  Packages,
  Media,
}

export enum NewListingStep {
  PropertyType,
  Area,
  Address,
  Pricing,
  Fee,
  Details,
  ExtraDetails,
  Amenities,
  Media,
  // MLS,
}

export const newListingStepAtom = atom<NewListingStep>({
  key: "newListingStepAtom",
  default: 0,
});

export const formErrorsAtom = atom<{
  [key: string]: string | null;
}>({
  key: "formErrorsAtom",
  default: {},
});

export const attemptedNextStepAtom = atom<boolean>({
  key: "attemptedNextStepAtom",
  default: false,
});

export const mapHighlightListingIdAtom = atom<string>({
  key: "mapHighlightListingIdAtom",
  default: "",
});

// strictly for use with listings page
export const listingPageDrawerOpenAtom = atom<boolean>({
  key: "listingPageDrawerOpenAtom",
  default: false,
});

export const publicListingsSortAtom = atom<ListingSort>({
  key: "publicListingsSortAtom",
  default: ListingSort.priceDesc,
});

export enum ListingsView {
  Normal,
  MapOnly,
  ListOnly,
}
export const listingsViewMode = atom<ListingsView>({
  key: "listingsViewMode",
  default: ListingsView.Normal,
});

// keyed by listing_id
export const walkscoreAtomFamily = atomFamily<WalkScoreResponse | null, string>(
  {
    key: "walkscoreAtomFamily",
    default: null,
  }
);

export type WalkScoreResponse = {
  status: number;
  walkscore: number;
  description: string;
  updated: string;
  logo_url: string;
  more_info_icon: string;
  more_info_link: string;
  ws_link: string;
  snapped_lat: number;
  snapped_lon: number;
  transit?: {
    score: number;
    description: string;
    summary: string;
  };
  bike?: {
    score: number;
    description: string;
  };
};
