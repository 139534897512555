import { PriceCurrency } from ".";

export type StripeLineItem = {
  price: string; //in stripe, price is the id of the product
  quantity: number;
};
export enum TransactionStatus {
  Pending = 0,
  Successful = 1,
  Rejected = 2,
  Refunded = 3,
  Expired = 4,
}

export type Transaction = {
  id: string;
  user_id: string;
  amount_total: number;
  amount_subtotal: number;
  line_items: StripeLineItem[];
  payment_status: TransactionStatus;
  date_created: Date;
  currency: PriceCurrency;
  listing_id?: string;
  session_id?: string; // refers to stripe session id
  date_updated?: Date;
  date_deleted?: Date;
};

export type Purchase = {
  id: string;
  user_id: string;
  transaction_id: string;
  listing_id?: string; // if null means this puchase is not assigned to a listing
  price_id: string; // refers to stripe price
  is_refunded: boolean;
  date_created: Date;
  date_updated?: Date;
  date_deleted?: Date;
};

export enum TestProduct {
  SignatureLarge = "price_1P1tZ6B7YIZHg3AguITAVOZE",
  SignatureMedium = "price_1P1tYeB7YIZHg3AgQjO9Q3Ji",
  SignatureSmall = "price_1P1tY7B7YIZHg3AgwdpnjxLa",
  PremiumLarge = "price_1P1tWYB7YIZHg3AgQUFtl7nZ",
  PremiumMedium = "price_1P1tVzB7YIZHg3AgT9NjvGjG",
  PremiumSmall = "price_1P1tVSB7YIZHg3AgwRk9Vkun",
  EssentialsLarge = "price_1P1tUTB7YIZHg3AgDUHkKkJk",
  EssentialsMedium = "price_1P1tTzB7YIZHg3AgXO0sK2nG",
  EssentialsSmall = "price_1P1tTVB7YIZHg3Agao1hphWN",
  VirtualStaging20 = "price_1P1tSCB7YIZHg3AgDqJepTao",
  VirtualStaging10 = "price_1P1tRjB7YIZHg3Ag4T2Mp1aG",
  VirtualStaging5 = "price_1P1tRDB7YIZHg3AgI6w6CODg",
  VirtualStaging3 = "price_1P1tQgB7YIZHg3Agz6AdkUxe",
  MatterportLarge = "price_1P1tPnB7YIZHg3Agjra6sE0o",
  MatterportMedium = "price_1P1tPOB7YIZHg3AgzBbjotjA",
  MatterportSmall = "price_1P1tOzB7YIZHg3Ag65tFWRmT",
  FloorPlanLarge = "price_1P1tOCB7YIZHg3AgQ2rz6Tvi",
  FloorPlanMedium = "price_1P1tNjB7YIZHg3AgReTMDbg2",
  FloorPlanSmall = "price_1P1tNKB7YIZHg3AgPaN5S8j1",
  VideoLarge = "price_1P1tM8B7YIZHg3AgB5RjjMch",
  VideoMedium = "price_1P1tLYB7YIZHg3AgQuFwlLKm",
  VideoSmall = "price_1P1tL1B7YIZHg3Ag8p2fvG32",
  PhotographyLarge = "price_1P1tJZB7YIZHg3AglR4ydi9n",
  PhotographyMedium = "price_1P1tI4B7YIZHg3AglbJl9Guy",
  PhotographySmall = "price_1P1tIvB7YIZHg3AgjkW9axXz",
  MLSListing = "price_1OzOsEB7YIZHg3AgP6KF79er",
  // PremiumAccess = "price_1PjVpAB7YIZHg3AgNCGTNZd0",
  // EliteAccess = "price_1PjVq7B7YIZHg3Ag91jGRKRJ",
  // PlatinumAccess = "price_1PjVr9B7YIZHg3AgWvt3AX6T",
}

export enum Product {
  SignatureLarge = "price_1PBtytB7YIZHg3Ag4fJXtXGm",
  SignatureMedium = "price_1PBtyyB7YIZHg3AgbiDE6PbP",
  SignatureSmall = "price_1PBtyzB7YIZHg3AgBQwKGRfB",
  PremiumLarge = "price_1PBtz1B7YIZHg3AgawlX4IDH",
  PremiumMedium = "price_1PBtz3B7YIZHg3AgmUyyF3rp",
  PremiumSmall = "price_1PBtz5B7YIZHg3Ag7XLZ0amg",
  EssentialsLarge = "price_1PBtz6B7YIZHg3Ag6IWMrWSW",
  EssentialsMedium = "price_1PBtz8B7YIZHg3Agh3PIAl0X",
  EssentialsSmall = "price_1PBtz9B7YIZHg3Ag5JgIfnix",
  VirtualStaging20 = "price_1PBtzBB7YIZHg3AgRBKqhUkZ",
  VirtualStaging10 = "price_1PBtzCB7YIZHg3AgpZA1vcgw",
  VirtualStaging5 = "price_1PBtzEB7YIZHg3AgkdKNKu84",
  VirtualStaging3 = "price_1PBtzGB7YIZHg3AgybCTjGbP",
  MatterportLarge = "price_1PBtzKB7YIZHg3AgBuV4iImG",
  MatterportMedium = "price_1PBtzMB7YIZHg3AgMwE2490J",
  MatterportSmall = "price_1PBtzQB7YIZHg3AggNAbgYYj",
  FloorPlanLarge = "price_1PBtzRB7YIZHg3Ag1Qre3Mdq",
  FloorPlanMedium = "price_1PBtzUB7YIZHg3AgdeeVMOOq",
  FloorPlanSmall = "price_1PBtzXB7YIZHg3Ag0RCMfjIl",
  VideoLarge = "price_1PBtzaB7YIZHg3AgeSZuy9ql",
  VideoMedium = "price_1PBtzeB7YIZHg3AgCuaDG4sb",
  VideoSmall = "price_1PBtzgB7YIZHg3AgWI0frqRM",
  PhotographyLarge = "price_1PBtziB7YIZHg3AgAGmKrfMY",
  PhotographyMedium = "price_1PBtzlB7YIZHg3Ag97J8yte5",
  PhotographySmall = "price_1PBtzjB7YIZHg3AgXevwL9kX",
  MLSListing = "price_1PCHOcB7YIZHg3AgIIMi2tSk",
  PremiumAccess = "price_1PkIntB7YIZHg3AgY2dEspeD",
  EliteAccess = "price_1PkInrB7YIZHg3AgRBdbELzn",
  PlatinumAccess = "price_1PkInnB7YIZHg3Ag34b6E8qa",
}

export const ProductDict: {
  [key in Product | TestProduct]: {
    description: string;
    price: number;
  };
} = {
  [Product.SignatureLarge]: {
    description: "Signature - All Inclusive (2500-4500 sqft)",
    price: 999.0,
  },
  [Product.SignatureMedium]: {
    description: "Signature - All Inclusive (1500-2500 sqft)",
    price: 869.0,
  },
  [Product.SignatureSmall]: {
    description: "Signature - All Inclusive (<1500 sqft)",
    price: 799.0,
  },
  [Product.PremiumLarge]: {
    description:
      "Premium - Photos + Floor Plan + 3D Virtual Tour (2500-4500 sqft)",
    price: 699.0,
  },
  [Product.PremiumMedium]: {
    description:
      "Premium - Photos + Floor Plan + 3D Virtual Tour (1500-2500 sqft)",
    price: 599.0,
  },
  [Product.PremiumSmall]: {
    description: "Premium - Photos + Floor Plan + 3D Virtual Tour (<1500 sqft)",
    price: 549.0,
  },
  [Product.EssentialsLarge]: {
    description: "Essentials - Photo + Video (2500-4500 sqft)",
    price: 539.0,
  },
  [Product.EssentialsMedium]: {
    description: "Essentials - Photo + Video (1500-2500 sqft)",
    price: 519.0,
  },
  [Product.EssentialsSmall]: {
    description: "Essentials - Photos + Video (<1500 sqft)",
    price: 469.0,
  },
  [Product.VirtualStaging20]: {
    description: "Virtual Staging (20 photos)",
    price: 1100.0,
  },
  [Product.VirtualStaging10]: {
    description: "Virtual Staging (10 photos)",
    price: 550.0,
  },
  [Product.VirtualStaging5]: {
    description: "Virtual Staging (5 photos)",
    price: 275.0,
  },
  [Product.VirtualStaging3]: {
    description: "Virtual Staging (3 photos)",
    price: 165.0,
  },
  [Product.MatterportLarge]: {
    description: "3D Matterport Virtual Tour (2500-4500 sqft)",
    price: 379.0,
  },
  [Product.MatterportMedium]: {
    description: "3D Matterport Virtual Tour (1500-2500 sqft)",
    price: 329.0,
  },
  [Product.MatterportSmall]: {
    description: "3D Matterport Virtual Tour (<1500 sqft)",
    price: 289.0,
  },
  [Product.FloorPlanLarge]: {
    description: "2D Floor Plan (2500-4500 sqft)",
    price: 424.0,
  },
  [Product.FloorPlanMedium]: {
    description: "2D Floor Plan (1500-2500 sqft)",
    price: 289.0,
  },
  [Product.FloorPlanSmall]: {
    description: "2D Floor Plan (<1500 sqft)",
    price: 244.0,
  },
  [Product.VideoLarge]: {
    description: "4K Cinematic Listing Video (2500-4500 sqft)",
    price: 469.0,
  },
  [Product.VideoMedium]: {
    description: "4K Cinematic Listing Video (1500-2500 sqft)",
    price: 449.0,
  },
  [Product.VideoSmall]: {
    description: "4K Cinematic Listing Video (<1500 sqft)",
    price: 399.0,
  },
  [Product.PhotographyLarge]: {
    description: "Professional Photography (2500-4500 sqft)",
    price: 309.0,
  },
  [Product.PhotographyMedium]: {
    description: "Professional Photography (1500-2500 sqft)",
    price: 289.0,
  },
  [Product.PhotographySmall]: {
    description: "Professional Photography (<1500 sqft)",
    price: 269.0,
  },
  [Product.MLSListing]: {
    description: "List your property on MLS",
    price: 500.0,
  },
  [TestProduct.SignatureLarge]: {
    description: "Signature - All Inclusive (2500-4500 sqft)",
    price: 999.0,
  },
  [TestProduct.SignatureMedium]: {
    description: "Signature - All Inclusive (1500-2500 sqft)",
    price: 869.0,
  },
  [TestProduct.SignatureSmall]: {
    description: "Signature - All Inclusive (<1500 sqft)",
    price: 799.0,
  },
  [TestProduct.PremiumLarge]: {
    description:
      "Premium - Photos + Floor Plan + 3D Virtual Tour (2500-4500 sqft)",
    price: 699.0,
  },
  [TestProduct.PremiumMedium]: {
    description:
      "Premium - Photos + Floor Plan + 3D Virtual Tour (1500-2500 sqft)",
    price: 599.0,
  },
  [TestProduct.PremiumSmall]: {
    description: "Premium - Photos + Floor Plan + 3D Virtual Tour (<1500 sqft)",
    price: 549.0,
  },
  [TestProduct.EssentialsLarge]: {
    description: "Essentials - Photo + Video (2500-4500 sqft)",
    price: 539.0,
  },
  [TestProduct.EssentialsMedium]: {
    description: "Essentials - Photo + Video (1500-2500 sqft)",
    price: 519.0,
  },
  [TestProduct.EssentialsSmall]: {
    description: "Essentials - Photos + Video (<1500 sqft)",
    price: 469.0,
  },
  [TestProduct.VirtualStaging20]: {
    description: "Virtual Staging (20 photos)",
    price: 1100.0,
  },
  [TestProduct.VirtualStaging10]: {
    description: "Virtual Staging (10 photos)",
    price: 550.0,
  },
  [TestProduct.VirtualStaging5]: {
    description: "Virtual Staging (5 photos)",
    price: 275.0,
  },
  [TestProduct.VirtualStaging3]: {
    description: "Virtual Staging (3 photos)",
    price: 165.0,
  },
  [TestProduct.MatterportLarge]: {
    description: "3D Matterport Virtual Tour (2500-4500 sqft)",
    price: 379.0,
  },
  [TestProduct.MatterportMedium]: {
    description: "3D Matterport Virtual Tour (1500-2500 sqft)",
    price: 329.0,
  },
  [TestProduct.MatterportSmall]: {
    description: "3D Matterport Virtual Tour (<1500 sqft)",
    price: 289.0,
  },
  [TestProduct.FloorPlanLarge]: {
    description: "2D Floor Plan (2500-4500 sqft)",
    price: 424.0,
  },
  [TestProduct.FloorPlanMedium]: {
    description: "2D Floor Plan (1500-2500 sqft)",
    price: 289.0,
  },
  [TestProduct.FloorPlanSmall]: {
    description: "2D Floor Plan (<1500 sqft)",
    price: 244.0,
  },
  [TestProduct.VideoLarge]: {
    description: "4K Cinematic Listing Video (2500-4500 sqft)",
    price: 469.0,
  },
  [TestProduct.VideoMedium]: {
    description: "4K Cinematic Listing Video (1500-2500 sqft)",
    price: 449.0,
  },
  [TestProduct.VideoSmall]: {
    description: "4K Cinematic Listing Video (<1500 sqft)",
    price: 399.0,
  },
  [TestProduct.PhotographyLarge]: {
    description: "Professional Photography (2500-4500 sqft)",
    price: 309.0,
  },
  [TestProduct.PhotographyMedium]: {
    description: "Professional Photography (1500-2500 sqft)",
    price: 289.0,
  },
  [TestProduct.PhotographySmall]: {
    description: "Professional Photography (<1500 sqft)",
    price: 269.0,
  },
  [TestProduct.MLSListing]: {
    description: "List your property on MLS",
    price: 500.0,
  },
  [Product.PremiumAccess]: {
    description: "Premium Plan",
    price: 99.0,
  },
  [Product.PlatinumAccess]: {
    description: "Platinum Plan",
    price: 1499.0,
  },
  [Product.EliteAccess]: {
    description: "Elite Plan",
    price: 549.0,
  },
};

export const ProductItems = Object.entries(ProductDict).map(
  ([product, { description, price }]) => ({
    id: product as Product,
    description,
    price,
  })
);

export type CartContents = Product[];
