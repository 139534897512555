import {
  Container,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { X } from "react-feather";
import { ICON_SIZE } from "../../theme";
import { ReactNode, useEffect, useRef } from "react";

export const DrawerWithClose = ({
  isOpen,
  handleClose,
  children,
  placement = "right",
  full,
  bg,
}: {
  isOpen: boolean;
  handleClose: () => void;
  children: ReactNode;
  placement?: "left" | "right" | "top" | "bottom";
  full?: boolean;
  bg?: string;
}) => {
  const startRef = useRef<HTMLDivElement>(null);
  const defaultBg = useColorModeValue("white", "darkBg");
  setTimeout(() => {
    startRef.current && startRef.current.scrollIntoView({ behavior: "smooth" });
  }, 1);
  useOverrideReactRemoveScrollWheelListener();
  return (
    <Drawer
      placement={placement}
      onClose={handleClose}
      isOpen={isOpen}
      size={full ? "full" : ["lg", "md"]}
      blockScrollOnMount={false}
    >
      <DrawerOverlay />
      <DrawerContent height={"100%"} bg={bg ?? defaultBg}>
        <DrawerHeader
          p={0}
          borderBottom={"1px solid"}
          rounded={"md"}
          boxShadow={" 0em 0.1em rgba(0, 0, 0, 0.3)"}
        >
          <Flex direction={"row"} justifyContent={"flex-start"} py={2} px={4}>
            <X size={ICON_SIZE} onClick={handleClose} />
          </Flex>
        </DrawerHeader>

        <Container
          maxW={"none"}
          className="hide-scrollbar"
          sx={{
            overflowY: "scroll",
            flex: "grow",
          }}
        >
          {children}
        </Container>
      </DrawerContent>
    </Drawer>
  );
};

export const useOverrideReactRemoveScrollWheelListener = () => {
  useEffect(() => {
    const handler = (e: Event) => {
      e.stopImmediatePropagation();
    };

    document.addEventListener("wheel", handler, {
      capture: true,
      passive: true,
    });
    document.addEventListener("touchmove", handler, {
      capture: true,
      passive: true,
    });
    return () => document.removeEventListener("wheel", handler);
  }, []);
};
