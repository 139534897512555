import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomToast } from "../hooks/useCustomToast";
import { useIsMobile } from "../hooks/utils/useWindowDimensions";
import useAuthApi from "../hooks/useAuthApi";
const successToast = {
  title: "Password Updated Successfully",
  duration: 3000,
};

export const ResetPage = ({ loggedIn }: { loggedIn?: boolean }) => {
  const { verification_code } = useParams<{ verification_code: string }>();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const [form, setForm] = useState<{
    password: string;
    password1: string;
    old_password: string;
  }>({
    password1: "",
    password: "",
    old_password: "",
  });
  const { success, fail } = useCustomToast();
  const { resetPassword, updatePassword } = useAuthApi();
  const handleSubmit = useCallback(async () => {
    try {
      if (loggedIn) {
        if (await updatePassword(form.old_password, form.password))
          success(successToast);
      } else {
        if (!verification_code) return;
        if (await resetPassword(verification_code, form.password)) {
          success({
            ...successToast,
            description: "Please log in",
          });
          navigate("/login");
        }
      }
    } catch (err) {
      fail({ title: "Error signing up", description: String(err) });
    }
  }, [
    fail,
    form,
    loggedIn,
    navigate,
    resetPassword,
    success,
    updatePassword,
    verification_code,
  ]);
  const inputBg = "input-bg";
  const content = (
    <Box
      rounded={"2xl"}
      bg={"container-bg"}
      minW={isMobile ? undefined : "lg"}
      p={3}
    >
      <Text>Reset password</Text>
      <Box mx="auto">
        <VStack spacing="24px">
          {loggedIn && (
            <FormControl id="old_password" isRequired>
              <FormLabel>Enter Your Old Password</FormLabel>
              <Input
                border={"1px solid lightgray"}
                backgroundColor={inputBg}
                autoComplete="old-password"
                type="password"
                name="old_password"
                value={form.old_password}
                onChange={handleInputChange}
              />
            </FormControl>
          )}
          <FormControl id="password" isRequired>
            <FormLabel>Enter Your New Password</FormLabel>
            <Input
              border={"1px solid lightgray"}
              backgroundColor={inputBg}
              autoComplete="new-password"
              type="password"
              name="password"
              value={form.password}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl
            id="password1"
            isRequired
            isInvalid={form.password !== form.password1}
          >
            <FormLabel>Re-type Your New Password</FormLabel>
            <Input
              border={"1px solid lightgray"}
              backgroundColor={inputBg}
              autoComplete="new-password"
              type="password"
              name="password1"
              value={form.password1}
              onChange={handleInputChange}
            />
          </FormControl>
          <Flex justifyContent={"end"} w={"full"}>
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit}
              isDisabled={
                form.password !== form.password1 || !form.password.length
              }
            >
              Reset Password
            </Button>
          </Flex>
        </VStack>
      </Box>
    </Box>
  );
  return isMobile ? content : <Flex justify={"center"}>{content}</Flex>;
};
