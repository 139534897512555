import { PrivateUser, User } from "../types";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import { useCustomToast } from "./useCustomToast";
import { selectAxiosInstance } from "../recoil/selectors";
import {
  NotificationPreferences,
  notificationSettingsAtom,
  userFamily,
} from "../recoil/users/atoms";
import { userState } from "../recoil/atoms";
import { useCallback } from "react";

const useUserApi = () => {
  const { fail } = useCustomToast();
  const axiosInstance = useRecoilValue(selectAxiosInstance);
  const [notificationSettings, setNotificationSettings] = useRecoilState(
    notificationSettingsAtom
  );
  const [loggedInUser, setLoggedInUser] = useRecoilState(userState);
  const { success } = useCustomToast();
  const getUser = useRecoilCallback(
    ({ snapshot, set }) =>
      async (userId: string) => {
        try {
          const user = snapshot.getLoadable(userFamily(userId)).getValue();
          if (user) {
            return true;
          } else {
            const { data: userFromDB } = await axiosInstance.get(
              "/user/" + userId
            );
            if (userFromDB)
              set(userFamily(userId), userFromDB.user as PrivateUser);
          }
        } catch (error) {
          console.error({ error });
          return false;
        } finally {
          return true;
        }
      },
    [axiosInstance, fail]
  );

  const updateUser = useCallback(
    async (first_name: string, last_name: string) => {
      try {
        if (!loggedInUser) {
          return false;
        } else {
          const { data: res } = await axiosInstance.post("/user/change-name", {
            first_name,
            last_name,
          });
          if (!!res) {
            setLoggedInUser({ ...loggedInUser, first_name, last_name });
            success({ title: "Updated Account Settings" });
          }
        }
        return true;
      } catch (error) {
        fail({ title: String(error) });
        return false;
      }
    },
    [axiosInstance, fail, loggedInUser, setLoggedInUser, success]
  );

  const updateEmailPreferences = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        if (!loggedInUser) return false;

        try {
          const notificationSettings = snapshot
            .getLoadable(notificationSettingsAtom)
            .getValue();

          await axiosInstance.post(`/user/notification-settings`, {
            settings: notificationSettings,
          });

          success({ title: "Updated Notification Settings" });
          return true;
        } catch (error) {
          fail({ title: String(error) });
          return false;
        }
      },
    [success, fail, loggedInUser, axiosInstance]
  );

  const fetchEmailPreferences = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/user/notification-settings`);
      const disabledSettings = response.data.disabledSettings;
      const updatedSettings = {
        ...notificationSettings,
      };

      disabledSettings.forEach((setting: string) => {
        if ((setting as keyof NotificationPreferences) in updatedSettings) {
          (updatedSettings as Record<keyof NotificationPreferences, boolean>)[
            setting as keyof NotificationPreferences
          ] = false;
        }
      });
      setNotificationSettings(updatedSettings);
    } catch (error) {
      console.error("Failed to fetch and update notification settings:", error);
    }
  }, [axiosInstance, notificationSettings, setNotificationSettings]);

  const createPermission = useCallback(
    async (
      permission:
        | "OFFERFLOW_DISCLAIMER_HIDDEN"
        | "TERMS_ACCEPTED"
        | "RENTAL_APPLICATION_DISCLAIMER_HIDDEN"
    ) => {
      try {
        const res = await axiosInstance.post("/user/custom", { permission });
        if (res.status === 200) {
          setLoggedInUser((u) => {
            if (!u) return null;
            return {
              ...u,
              permissions: [...u.permissions, permission],
            } as User;
          });
        }
      } catch (error) {
        console.error(
          "Failed to fetch and update notification settings:",
          error
        );
      }
    },
    [axiosInstance, setLoggedInUser]
  );

  return {
    getUser,
    updateUser,
    updateEmailPreferences,
    fetchEmailPreferences,
    createPermission,
  };
};

export default useUserApi;
