import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { SetterOrUpdater, useRecoilValue } from "recoil";
import { rentalDetailsFamily } from "../../recoil/rentals";
import {
  Applicant,
  RentalApplicationForm,
  RentalRequirements,
} from "../../types/rentals";
import { ExpandableBox } from "../common";
import { ApplicantKeys } from "./CreateApplication";
import { ButtonSwitch } from "../common/ButtonSwitch";

export const ApplicantBox = ({
  index,
  applicant: a,
  listingId,
  setForm,
}: {
  applicant: Applicant;
  index: number;
  setForm: SetterOrUpdater<RentalApplicationForm>;
  listingId: string;
}) => {
  const rentalDetails = useRecoilValue(rentalDetailsFamily(listingId));
  const changeApplicant = useCallback(
    (id: string, field: keyof Applicant, value: string | boolean) => {
      setForm((f) => ({
        ...f,
        applicants: f.applicants.map((ap) => {
          if (ap.id !== id) return ap;
          return { ...ap, [field]: value };
        }),
      }));
    },
    [setForm]
  );
  const onRemove = () =>
    setForm((f) => ({
      ...f,
      applicants: f.applicants.filter((ap) => ap.id !== a.id),
    }));

  if (!rentalDetails) return null;

  return (
    <ExpandableBox
      title={a.full_name.length ? a.full_name : `Applicant ${index + 1}`}
      my={2}
    >
      <VStack px={3} py={2} rounded={"xl"} spacing={2}>
        <FormControl>
          <FormLabel>Full Name</FormLabel>
          <Input
            bg={"input-bg"}
            value={a.full_name}
            onChange={(e) => {
              changeApplicant(a.id, "full_name", e.target.value);
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Current full address</FormLabel>
          <Input
            bg={"input-bg"}
            value={a.address}
            onChange={(e) => {
              changeApplicant(a.id, "address", e.target.value);
            }}
          />
        </FormControl>
        {(
          Object.keys(
            rentalDetails.requirements
          ) as (keyof RentalRequirements)[]
        )
          .sort((a, b) => a.charCodeAt(0) - b.charCodeAt(0))
          .map((k) => {
            if (!rentalDetails.requirements[k]) return null;
            if (k === "currently_renting") {
              return (
                <ButtonSwitch
                  key={k}
                  optionA="Currently rent"
                  optionB="Currently own"
                  value={a.currently_renting}
                  onChange={(v) =>
                    changeApplicant(a.id, k as keyof Applicant, v)
                  }
                />
              );
            }
            if (
              (
                [
                  "current_landlord_contact",
                  "employer",
                  "occupation",
                  "reference_contact",
                  "reason",
                  "employed_duration",
                ] as (keyof RentalRequirements)[]
              ).includes(k)
            ) {
              return (
                <FormControl key={k}>
                  <FormLabel>{ApplicantKeys(k) ?? k}</FormLabel>
                  <Input
                    bg={"input-bg"}
                    type="text"
                    value={a[k as keyof Applicant]?.toString()}
                    onChange={(e) =>
                      changeApplicant(
                        a.id,
                        k as keyof Applicant,
                        e.target.value
                      )
                    }
                  />
                </FormControl>
              );
            }
            if (
              (
                [
                  "credit_score",
                  "yearly_income",
                ] as (keyof RentalRequirements)[]
              ).includes(k)
            ) {
              return (
                <FormControl key={k}>
                  <FormLabel>{ApplicantKeys(k) ?? k}</FormLabel>
                  <Input
                    bg={"input-bg"}
                    type="number"
                    value={a[k as keyof Applicant]?.toString()}
                    onChange={(e) =>
                      changeApplicant(
                        a.id,
                        k as keyof Applicant,
                        e.target.value
                      )
                    }
                  />
                </FormControl>
              );
            }
            return (
              <FormControl key={k}>
                <FormLabel>{k}</FormLabel>
              </FormControl>
            );
          })}
        <Button onClick={onRemove} variant={"ghost"} textColor={"red.400"}>
          Remove applicant
        </Button>
      </VStack>
    </ExpandableBox>
  );
};
