import { useRecoilValue } from "recoil";
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ListingView from "./ListingView";
import { selectedListingAtom } from "../../recoil/listings";
import { Maximize2, X } from "react-feather";
import { ICON_SIZE } from "../../theme";
import { useRef } from "react";
import { useIsMobile } from "../../hooks/utils/useWindowDimensions";
import { RentalView } from "./RentalView";
import { useNavigate } from "react-router-dom";

export const ListingModal = ({
  isOpen,
  handleClose,
  rental,
}: {
  isOpen: boolean;
  handleClose: () => void;
  rental?: boolean;
}) => {
  const listingId = useRecoilValue(selectedListingAtom);
  const isMobile = useIsMobile();
  const startRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const handleMaximize = () => navigate(`/listing/${listingId}`);

  const close = () => {
    handleClose();
  };

  setTimeout(() => {
    startRef.current && startRef.current.scrollIntoView({ behavior: "smooth" });
  }, 1);

  return (
    <Modal isOpen={isOpen} onClose={close} size={"3xl"}>
      <ModalOverlay />
      <ModalContent bg={"drawer-color"}>
        <ModalHeader>
          <Flex
            direction={"row"}
            justifyContent={"space-between"}
            py={4}
            px={2}
          >
            {isMobile ? (
              <div />
            ) : (
              <Maximize2 size={ICON_SIZE} onClick={handleMaximize} />
            )}
            <X size={ICON_SIZE} onClick={close} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box>
            <div ref={startRef} />
            {rental ? (
              <RentalView listingId={listingId ?? ""} />
            ) : (
              <ListingView listingId={listingId ?? ""} />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
