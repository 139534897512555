export function wait(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const DEV_MODE = process.env.REACT_APP_ENVIRONMENT === "dev";
export const STAGING_MODE = process.env.REACT_APP_ENVIRONMENT === "staging";

export const calculateCenter = (
  coordinates: Coordinate[]
): { lat: number; lon: number } => {
  const { sumLat, sumLon, count } = coordinates.reduce(
    (acc, coordinate) => {
      if (coordinate.lat && coordinate.lon) {
        acc.sumLat += coordinate.lat;
        acc.sumLon += coordinate.lon;
        acc.count += 1;
      }
      return acc;
    },
    { sumLat: 0, sumLon: 0, count: 0 }
  );

  if (count === 0) {
    return {
      lat: 0,
      lon: 0,
    };
  }

  return {
    lat: sumLat / count,
    lon: sumLon / count,
  };
};

type Coordinate = {
  lat: number;
  lon: number;
  id: string;
};
