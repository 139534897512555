import { Flex, Button } from "@chakra-ui/react";

export const ButtonSwitch = ({
  optionA,
  optionB,
  onChange,
  value,
}: {
  optionA: string;
  optionB: string;
  onChange: (v: boolean) => void;
  value: boolean;
}) => {
  return (
    <Flex justify={"space-evenly"} w={"full"}>
      <Button
        variant={value ? "primary" : "secondary"}
        onClick={() => onChange(true)}
      >
        {optionA}
      </Button>
      <Button
        variant={!value ? "primary" : "secondary"}
        onClick={() => onChange(false)}
      >
        {optionB}
      </Button>
    </Flex>
  );
};
