import CryptoJS from "crypto-js";

export function generate32String(): string {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
}

export const convertToNumber = (input: string | null | undefined) => {
  if (!input) return undefined;
  const num = Number(input);
  if (isNaN(num)) return undefined;
  return num;
};

export function decrypt(ciphertext: string, secret: string): string {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secret);
  return bytes.toString(CryptoJS.enc.Utf8);
}
