import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import {} from "react-feather";

export const GuideBox = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => {
  return (
    <Accordion allowToggle minW={"full"}>
      <AccordionItem>
        <AccordionButton
          rounded={"lg"}
          fontWeight={"thin"}
          fontSize={18}
          sx={{ border: "2px blue solid", color: "container-txt" }}
          _hover={{ bg: "blue.300", color: "white" }}
        >
          <Box as="span" flex="1" textAlign="left">
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel fontWeight={"semibold"} fontSize={18}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
