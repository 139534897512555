import { selector, selectorFamily } from "recoil";
import {
  Listing,
  ListingSort,
  ListingStatus,
  ListingWithServices,
  MediaType,
} from "../../types";
import { purchasesAtom, purchasesAtomFamily } from "../services/atoms";
import { Purchase } from "../../types/services";
import {
  listingFamily,
  listingForPaymentAtom,
  listingIdsAtom,
  listingSearchFilterAtom,
  publicListingsSortAtom,
  userListingsAtom,
} from "./atoms";

export const selectAllPurchases = selector({
  key: "selectAllPurchases",
  get: ({ get }) => {
    const purchaseIds = get(purchasesAtom);
    return purchaseIds
      .map((id) => get(purchasesAtomFamily(id)))
      .filter((l) => !!l) as Purchase[];
  },
});

export const selectAllListingIds = selector({
  key: "selectAllListingIds",
  get: ({ get }) => {
    const filter = get(listingSearchFilterAtom);
    const listingIds = get(listingIdsAtom);
    const sort = get(publicListingsSortAtom);
    return (
      listingIds
        .map((id) => {
          const listing = get(listingFamily(id));
          return listing;
        })
        .filter((listing) => {
          if (!listing || listing.is_rental) return false;
          if (
            [ListingStatus.PreSale, ListingStatus.NewBuild].includes(
              listing.listing_status ?? 0
            )
          ) {
            return false;
          }
          if (filter.length) {
            return `
              ${listing.address.toLowerCase()}
              ${listing.city.toLowerCase()}
              ${listing.state_province.toLowerCase()}
              ${listing.description.toLowerCase()}
              ${listing.title.toLowerCase()}
            `.includes(filter.toLowerCase());
          }
          return true;
        }) as Listing[]
    )
      .sort((a, b) => {
        if (
          [ListingStatus.PreSale, ListingStatus.NewBuild].includes(
            a.listing_status ?? 0
          )
        )
          return -1;
        switch (sort) {
          case ListingSort.priceAsc:
            return Number(a.price) > Number(b.price) ? -1 : 1;
          case ListingSort.priceDesc:
            return Number(b.price) > Number(a.price) ? -1 : 1;
          case ListingSort.totalAreaAsc:
            return Number(a.total_area) < Number(b.total_area) ? -1 : 1;
          case ListingSort.totalAreaDesc:
            return Number(b.total_area) < Number(a.total_area) ? -1 : 1;
          default:
            return 0;
        }
      })
      .map(({ id }) => id);
  },
});
export const selectPromotedListingIds = selector({
  key: "selectPromotedListingIds",
  get: ({ get }) => {
    const listingIds = get(listingIdsAtom);
    const sort = get(publicListingsSortAtom);
    return (
      listingIds
        .map((id) => {
          const listing = get(listingFamily(id));
          return listing;
        })
        .filter((listing) => {
          if (!listing || listing.is_rental) return false;

          return [ListingStatus.PreSale, ListingStatus.NewBuild].includes(
            listing.listing_status ?? 0
          );
        }) as Listing[]
    )
      .sort((a, b) => {
        if (!a || !b) return 0;
        switch (sort) {
          case ListingSort.priceAsc:
            return Number(a.price) > Number(b.price) ? -1 : 1;
          case ListingSort.priceDesc:
            return Number(b.price) > Number(a.price) ? -1 : 1;
          case ListingSort.totalAreaAsc:
            return Number(a.total_area) < Number(b.total_area) ? -1 : 1;
          case ListingSort.totalAreaDesc:
            return Number(b.total_area) < Number(a.total_area) ? -1 : 1;
          default:
            return 0;
        }
      })

      .map(({ id }) => id);
  },
});

export const selectAllRentalIds = selector({
  key: "selectAllRentalIds",
  get: ({ get }) => {
    const listingIds = get(listingIdsAtom);
    return listingIds.filter((id) => {
      const listing = get(listingFamily(id));
      return listing && listing.is_rental;
    });
  },
});

export const selectUnusedPurchases = selector({
  key: "selectUnusedPurchases",
  get: ({ get }) => {
    const purchaseIds = get(purchasesAtom);
    return purchaseIds
      .map((id) => get(purchasesAtomFamily(id)))
      .filter((l) => !!l && !l.listing_id) as Purchase[];
  },
});

export const selectListingdPurchases = selector({
  key: "selectListingdPurchases",
  get: ({ get }) => {
    const listingId = get(listingForPaymentAtom);
    const purchaseIds = get(purchasesAtom);
    return purchaseIds
      .map((id) => get(purchasesAtomFamily(id)))
      .filter((l) => !!l && l.listing_id === listingId) as Purchase[];
  },
});

export const selectUserListings = selector({
  key: "selectUserListings",
  get: ({ get }) => {
    const listingIds = get(userListingsAtom);
    const purchases = get(selectAllPurchases);
    return listingIds
      .map((id) => {
        const listing = get(listingFamily(id));
        const services = purchases.filter((p) => p.listing_id === id);
        return { ...listing, services } as ListingWithServices;
      })
      .filter((l) => !!l) as ListingWithServices[];
  },
});

export const selectListing = selectorFamily({
  key: "selectListing",
  get:
    (id: string) =>
    ({ get }) => {
      const purchases = get(selectAllPurchases);
      const listing = get(listingFamily(id));
      const services = purchases.filter((p) => p.listing_id === id);
      return { ...listing, services } as ListingWithServices;
    },
});

export const selectListingCoverImg = selectorFamily({
  key: "selectListingCoverImg",
  get:
    (id: string) =>
    ({ get }) => {
      const listing = get(listingFamily(id));
      if (!listing || !listing.file_urls.length) return "";
      const image =
        listing.file_urls.find((v) => v.type === MediaType.Image)?.src ?? "";
      return image;
    },
});

export const selectAllListingCoordinates = selector({
  key: "selectAllListingCoordinates",
  get: ({ get }) => {
    const filter = get(listingSearchFilterAtom);
    const listingIds = get(listingIdsAtom);
    const coordinates = (
      listingIds
        .map((id) => {
          const listing = get(listingFamily(id));
          return listing;
        })

        .filter((listing) => {
          if (!listing || listing.is_rental) return false;
          if (filter.length) {
            return `
              ${listing.address.toLowerCase()}
              ${listing.city.toLowerCase()}
              ${listing.state_province.toLowerCase()}
              ${listing.description.toLowerCase()}
              ${listing.title.toLowerCase()}
            `.includes(filter.toLowerCase());
          }
          return true;
        }) as Listing[]
    ).map(({ id, latitude, longitude }) => ({
      id,
      lat: latitude,
      lon: longitude,
    }));
    return { coordinates };
  },
});
