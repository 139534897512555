import { useRecoilValue } from "recoil";
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  VStack,
  Divider,
  useColorModeValue,
  Button,
  DrawerHeader,
  Flex,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { useNavigate } from "react-router-dom";
import { userState } from "../recoil/atoms";
import {
  PlusSquare,
  X as CloseIcon,
  Paperclip as AttachmentIcon,
  Calendar,
  LogOut,
  LogIn,
  Mail,
  DollarSign,
  Settings,
  Users,
  Map,
  User,
} from "react-feather";
import { Logo } from "./Logo";
import { ICON_SIZE } from "../theme";
import { NumberBadge } from "./common/NumberBadge";
import { KycButton } from "./kyc/KycButton";
import { useUserKycd } from "../recoil/users/selectors";
import { selectAllPendingViewingRequests } from "../recoil/viewings/selectors";
import { useLogOut } from "../recoil/transactions";
import { selectAxiosInstance } from "../recoil/selectors";
import { useOverrideReactRemoveScrollWheelListener } from "./common";

interface MenuItemProps {
  to: string;
  icon: JSX.Element;
  children: React.ReactNode;
}

export const MobileDrawer = ({
  isOpen,
  handleClose,
  unreadCount,
  offerCount,
}: {
  isOpen: boolean;
  handleClose: () => void;
  unreadCount: number;
  offerCount: number;
}) => {
  const drawerBg = useColorModeValue("white.100", "ohBlue.300");
  const user = useRecoilValue(userState);
  const ax = useRecoilValue(selectAxiosInstance);
  const logout = useLogOut();
  const requestCount = useRecoilValue(selectAllPendingViewingRequests).length;
  const navigate = useNavigate();
  const isKyced = useUserKycd();
  const handleSignOut = async () => {
    await ax.post("/auth/logout");
    logout();
    navigate("/");
    handleClose();
  };
  useOverrideReactRemoveScrollWheelListener();
  const MenuItemLink: React.FC<MenuItemProps> = ({ to, icon, children }) => {
    const onClick =
      (to: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // Prevent the default action of the button
        navigate(to);
        handleClose();
      };

    return (
      <Button
        variant={"MenuItemLink"}
        color="current"
        fontWeight={"normal"}
        width={"100%"}
        justifyContent="flex-start"
        leftIcon={icon}
        alignItems={"center"}
        onClick={onClick(to)}
      >
        {children}
      </Button>
    );
  };

  return (
    <Drawer
      placement={"right"}
      onClose={handleClose}
      isOpen={isOpen}
      size={"xl"}
    >
      <DrawerOverlay />
      <DrawerContent backgroundColor={drawerBg}>
        {/* add logo and close button at the top */}
        <DrawerHeader borderBottomWidth="1px">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Box
              sx={{
                maxWidth: 90,
                maxHeight: 90,
              }}
            >
              <Logo scale={0.2} />
            </Box>
            <IconButton
              aria-label="Close drawer"
              icon={<CloseIcon size={ICON_SIZE} />}
              onClick={handleClose}
            />
          </Flex>
        </DrawerHeader>
        <Box
          p={"1em"}
          flexDirection={"column"}
          height={"100vh"}
          overflowY={"scroll"}
        >
          <VStack align="stretch">
            {user ? (
              <>
                <Flex justify={"start"}>
                  <KycButton />
                </Flex>
                <Text
                  fontSize={"sm"}
                  fontWeight={"bold"}
                  textColor={"gray.500"}
                  paddingX={3}
                  paddingY={1.5}
                >
                  Selling Property
                </Text>
                <MenuItemLink
                  to="/listings/create"
                  icon={<PlusSquare size={ICON_SIZE} />}
                >
                  Post Listing
                </MenuItemLink>
                <MenuItemLink
                  to="/manage/listings"
                  icon={<AttachmentIcon size={ICON_SIZE} />}
                >
                  Manage Listings
                </MenuItemLink>
                <MenuItemLink
                  to="/manage/requests"
                  icon={<Users size={ICON_SIZE} />}
                >
                  <span style={{ marginRight: "6px" }}> Viewing Requests</span>
                  <NumberBadge count={requestCount} />
                </MenuItemLink>
                <MenuItemLink
                  to="/manage/offers"
                  icon={<DollarSign size={ICON_SIZE} />}
                >
                  <span style={{ marginRight: "6px" }}>Offers</span>
                  <NumberBadge count={offerCount} />
                </MenuItemLink>
                <Divider my={2} />
                <Text
                  fontSize={"sm"}
                  fontWeight={"bold"}
                  textColor={"gray.500"}
                  paddingX={3}
                  paddingY={1.5}
                >
                  Buying Property
                </Text>
                <MenuItemLink to="/listings" icon={<Map size={ICON_SIZE} />}>
                  Explore Listings
                </MenuItemLink>
                {isKyced && (
                  <MenuItemLink
                    to="/viewings-schedule/upcoming"
                    icon={<Calendar size={ICON_SIZE} />}
                  >
                    Upcoming viewings
                  </MenuItemLink>
                )}
                <Divider my={2} />
                <Text
                  fontSize={"sm"}
                  fontWeight={"bold"}
                  textColor={"gray.500"}
                  paddingX={3}
                  paddingY={1.5}
                >
                  Account & Activity
                </Text>
                {isKyced && (
                  <MenuItemLink to="/messages" icon={<Mail size={ICON_SIZE} />}>
                    <span style={{ marginRight: "6px" }}>Messages</span>
                    <NumberBadge count={unreadCount} />
                  </MenuItemLink>
                )}
                <MenuItemLink
                  icon={<Settings size={ICON_SIZE} />}
                  to="/account-settings"
                >
                  Preferences
                </MenuItemLink>
                <Divider my={2} />
                <Button
                  variant={"MenuItemLink"}
                  fontWeight={"normal"}
                  width={"100%"}
                  justifyContent="flex-start"
                  leftIcon={<LogOut size={ICON_SIZE} />}
                  onClick={handleSignOut}
                  mb={2}
                >
                  Log Out
                </Button>
              </>
            ) : (
              <>
                <MenuItemLink to="/listings" icon={<Map size={ICON_SIZE} />}>
                  Explore Listings
                </MenuItemLink>
                <MenuItemLink to="/login" icon={<LogIn size={ICON_SIZE} />}>
                  Log In
                </MenuItemLink>
                <MenuItemLink to="/signup" icon={<User size={ICON_SIZE} />}>
                  Sign Up
                </MenuItemLink>
              </>
            )}
            <Divider my={2} />
            <Flex mt={4} pl={4} alignItems={"center"} justify={"space-between"}>
              Toggle Dark Mode:
              <ColorModeSwitcher />
            </Flex>
          </VStack>
        </Box>
      </DrawerContent>
    </Drawer>
  );
};
