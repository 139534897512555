import { CostBreakdown } from "../recoil/offers/atoms";
import { MediaType } from "./listing";
export * from "./listing";
export * from "./user";

export interface SignupForm {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

export interface LoginForm {
  email: string;
  password: string;
}

export enum Unit {
  Unknown = 0,
  SquareFoot = 1,
  Acres = 2,
}

export type FormattedFile = File & {
  storageName: string;
  preview: string;
  mediaType: MediaType;
};

export enum UploadStatus {
  InProgress = 0,
  PhotoUploaded = 1,
  FormUploaded = 2,
}

export enum PriceCurrency {
  USD = 0,
  CAD = 1,
  ETH = 2,
}

export interface ViewingSchedule {
  id: string;
  listing_id: string;
  start_time: string;
  end_time: string;
  max_attendance?: number;
  days_of_week?: number[];
}

export interface ViewingScheduleForm {
  listing_id: string;
  date: string;
  start_time: string;
  end_time: string;
  max_attendance?: string;
  days_of_week?: number[];
}

export interface ViewingRequestForm {
  viewing_schedule_id?: string;
  start_time: string;
  end_time: string;
}

export enum RequestStatus {
  Pending = 0,
  Accepted = 1,
  Rejected = 2,
  Deleted = 3,
}

export interface ViewingRequest {
  id: string;
  request_status: RequestStatus;
  start_time: string;
  end_time: string;
  listing_id: string;
  viewer_id: string;
  viewing_schedule_id: string;
  email: string;
  first_name: string;
  last_name: string;
}

export interface SellerViewing {
  id: string;
  viewing_request_id: string;
  listing_id: string;
  start_time: string;
  end_time: string;
  max_attendance?: number;
  date_created?: string;
  date_updated?: string;
  date_deleted?: string;
  days_of_week?: number[];
}

export interface ListingSchedule {
  availabilities: ViewingSchedule[];
  bookings: ViewingRequest[];
}

export type Offer = {
  id: string;
  offer_price_currency: PriceCurrency;
  offer_price: string;
  deposit?: string;
  offer_start_date: Date;
  offer_end_date: Date;
  listing_id: string;
  offer_status: OfferStatus;
  offer_id?: string;
  user_id: string;
  subject_ids?: string[];
  buyer_id: string;
  buyer_signature_id?: string;
  seller_signature_id?: string;
  date_closed?: Date;
  date_created: Date;
  date_deleted?: Date;
  date_buyer_signed?: Date;
  date_submitted?: Date;
  date_updated?: Date;
  date_seller_signed?: Date;
  seller_id: string;
  buyer_signature_url?: string;
  seller_signature_url?: string;
  costs?: CostBreakdown;
  included_items?: string;
  excluded_items?: string;
  offer_adjustments_date?: Date | null;
  offer_possession_date?: Date | null;
  offer_completion_date?: Date | null;
  offer_viewed_date?: Date | null;
  deposit_info?: string;
};

export enum OfferStatus {
  Draft = 0,
  Pending = 1,
  Accepted = 2,
  Revoked = 3,
  Rejected = 4,
}

export type OfferDraft = Omit<
  Offer & {
    // needed if countering
    offer_id?: string;
  },
  "id" | "offer_status" | "user_id" | "seller_id" | "buyer_id" | "date_created"
>;

export type Subject = {
  id: string;
  user_id?: string;
  subject_name: string;
  subject_description: string;
  subject_text: string;
  date_created: Date;
  editable: boolean;
};

export type DefaultSubject = {
  subject_name: string;
  subject_description: string;
  subject_text: string;
  custom_fields: CustomField[];
};

export type CustomField = {
  field_name: string;
  required: boolean;
  field_description?: string;
  placeholder?: string;
  large?: boolean;
};

export const PROVINCES: { [key: string]: string } = {
  Alberta: "AB",
  "British Columbia": "BC",
  Manitoba: "MB",
  "New Brunswick": "NB",
  "Newfoundland and Labrador": "NL",
  "Nova Scotia": "NS",
  Ontario: "ON",
  "Prince Edward Island": "PE",
  Quebec: "QC",
  Saskatchewan: "SK",
  "Northwest Territories": "NT",
  Nunavut: "NU",
  Yukon: "YT",
};

export enum UserTier {
  Basic = 0,
  Premium = 1,
  Platinum = 2,
  Elite = 3,
}
