import { Input } from "@chakra-ui/react";

export const DateInput = ({
  date,
  setDate,
  disabled,
  dateOnly,
}: {
  date: string;
  setDate: (d: string) => void;
  disabled?: boolean;
  dateOnly?: boolean;
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  };
  return (
    <div>
      <Input
        background={"input-bg"}
        value={date}
        type={dateOnly ? "date" : "datetime-local"}
        disabled={disabled}
        onChange={handleInputChange}
      />
    </div>
  );
};
