import { DefaultSubject } from "../types";

export const TAG_REGEX = /<#\$([\w_]+)\$#>/g;

export const DEFAULT_SUBJECTS = [
  {
    subject_name: "Subject to Financing (w/o details)",
    subject_description:
      "Subject to the Buyer arranging satisfactory financing on or before a specified date.",
    subject_text: `
      Subject to the Buyer arranging satisfactory financing on or before <#$date$#>. This condition is for the sole benefit of the Buyer.
    `,
    custom_fields: [
      {
        field_name: "date",
        field_description: "Date by which financing should be arranged",
        required: true,
        placeholder: "MM/DD/YYYY",
      },
    ],
  },
  {
    subject_name: "Subject to Financing (with details)",
    subject_description:
      "Subject to a new first mortgage being made available to the Buyer with specified terms.",
    subject_text: `
      Subject to a new first mortgage being made available to the Buyer, on or before <#$date$#>, in the amount of $<#$amount$#>, which provides for:
      (a) interest rate not to exceed <#$interest_rate$#>% per annum, calculated <#$interest_calculation$#>, not in advance;
      (b) <#$amortization_period$#> year amortization period;
      (c) <#$term$#> year term; and
      (d) blended monthly payments of approximately $<#$monthly_payment$#> including principal and interest (plus 1/12 of the annual taxes, if required by the lender).
      This condition is for the sole benefit of the Buyer.
    `,
    custom_fields: [
      {
        field_name: "date",
        field_description: "Date by which financing should be arranged",
        required: true,
        placeholder: "MM/DD/YYYY",
      },
      {
        field_name: "amount",
        field_description: "Loan amount",
        required: true,
      },
      {
        field_name: "interest_rate",
        field_description: "Maximum interest rate",
        required: true,
      },
      {
        field_name: "interest_calculation",
        field_description:
          "Calculation period (annually, semi-annually, monthly)",
        required: true,
      },
      {
        field_name: "amortization_period",
        field_description: "Amortization period in years",
        required: true,
      },
      {
        field_name: "term",
        field_description: "Term in years",
        required: true,
      },
      {
        field_name: "monthly_payment",
        field_description: "Approximate monthly payment amount",
        required: true,
      },
    ],
  },
  {
    subject_name: "Subject to Inspection",
    subject_description:
      "Subject to the Buyer obtaining an inspection report and being satisfied with the results.",
    subject_text: `
      Subject to the Buyer obtaining, at the Buyer’s expense, an inspection report and the Buyer being satisfied, on or before <#$date$#>, with the results of the inspection report. This condition is for the sole benefit of the Buyer. The Seller, on reasonable notice, will allow access to the Property for the purpose of the inspection(s).
    `,
    custom_fields: [
      {
        field_name: "date",
        field_description: "Date by which inspection should be completed",
        required: true,
        placeholder: "MM/DD/YYYY",
      },
    ],
  },
  {
    subject_name:
      "Subject to Inspection (with a max. dollar amount in repairs)",
    subject_description:
      "Subject to the Buyer obtaining an inspection report and the cost to remedy defects not exceeding a specified amount.",
    subject_text: `
      Subject to:
      (a) the Buyer obtaining, at the Buyer’s expense, an inspection report that provides that the cumulative cost to remedy any defects does not exceed <#$max_cost$#>; and
      (b) the Buyer being satisfied, on or before <#$date$#>, that any defects identified in that report would not reasonably adversely affect the use or value of the Property. This condition is for the sole benefit of the Buyer. The Seller, on reasonable notice, will allow access to the Property for the purpose of the inspection(s).
    `,
    custom_fields: [
      {
        field_name: "max_cost",
        field_description: "Maximum cost to remedy defects",
        required: true,
      },
      {
        field_name: "date",
        field_description: "Date by which inspection should be completed",
        required: true,
        placeholder: "MM/DD/YYYY",
      },
    ],
  },
  {
    subject_name:
      "Subject to Buyer’s Satisfaction with Property Disclosure Statement (PDS)",
    subject_description:
      "Subject to the Buyer obtaining and being satisfied with a Property Disclosure Statement.",
    subject_text: `
      Subject to the Buyer obtaining and being satisfied, on or before <#$date$#>, with a Property Disclosure Statement with respect to the Property. This condition is for the sole benefit of the Buyer.
      If this condition is satisfied or waived, the Property Disclosure Statement will be incorporated into and form part of this Contract.
    `,
    custom_fields: [
      {
        field_name: "date",
        field_description: "Date by which Buyer should be satisfied with PDS",
        required: true,
        placeholder: "MM/DD/YYYY",
      },
    ],
  },
  {
    subject_name: "Subject to Sale of Buyer’s Home",
    subject_description:
      "Subject to the Buyer entering into a contract to sell their property and that contract becoming unconditional on or before a specified date.",
    subject_text: `
      Subject to the Buyer entering into a contract to sell the Buyer’s property at <#$property_address$#> and that contract becoming unconditional on or before <#$date$#>. This condition is for the sole benefit of the Buyer.
    `,
    custom_fields: [
      {
        field_name: "property_address",
        field_description: "Address of Buyer's current property",
        required: true,
        large: true,
      },
      {
        field_name: "date",
        field_description: "Date by which the sale must be completed",
        required: true,
        placeholder: "MM/DD/YYYY",
      },
    ],
  },
  {
    subject_name: "Subject to Buyer Satisfaction with the Title",
    subject_description:
      "Subject to the Buyer being satisfied with the title, encumbrances, and legal notations affecting the property.",
    subject_text: `
      Subject to the Buyer being satisfied, on or before <#$date$#>, with the title, encumbrances, and legal notations affecting or benefitting the Property. This condition is for the sole benefit of the Buyer.
      On the Completion Date, in addition to the encumbrances set out in section 9 of the Contract, title to the Property will be subject to the encumbrances and legal notations expressly indicated to remain on title as shown in the title search that is attached to this Contract.
    `,
    custom_fields: [
      {
        field_name: "date",
        field_description:
          "Date by which Buyer should be satisfied with the title",
        required: true,
      },
    ],
  },
  {
    subject_name: "Subject to Buyer Satisfaction with the Strata Documents",
    subject_description:
      "Subject to the Buyer receiving and being satisfied with specified strata documents.",
    subject_text: `
      Subject to the Buyer, receiving and being satisfied with, on or before <#$date$#>, the following documents:
      1. A Form B Information Certificate from the strata corporation, or applicable section, which includes the strata corporation’s rules, current budget, the developer’s Rental Disclosure Statement (if any), the most recent depreciation report (if any), and all other attachments referred to in the Form B, as may be applicable;
      2. A copy of the registered strata plan, any amendments to the strata plan, and any resolutions dealing with changes to common property;
      3. The current bylaws and financial statements of the strata corporation, and any section to which the strata corporation lot belongs;
      4. The minutes of any meetings held in the past two years which includes strata council meetings, annual or special general meetings, and meetings of the executive of any section to which the Property belongs; and
      5. The current insurance cover note or other evidence of insurance issued by the insurer explaining the strata corporation’s insurance coverage, coverage limits, expiration date(s), and deductibles.
      Immediately upon acceptance of the offer or counter-offer, the Seller hereby authorizes the Buyer (or the Buyer’s agent), to request, at the Seller’s expense, complete copies of the documents listed above from the strata corporation, or applicable section, or other source and to immediately, upon receipt, deliver the documents to the Buyer (or the Buyer’s agent). This condition is for the sole benefit of the Buyer.
    `,
    custom_fields: [
      {
        field_name: "date",
        field_description:
          "Date by which Buyer should be satisfied with strata documents",
        required: true,
      },
    ],
  },
] as DefaultSubject[];
